import { grey } from "@mui/material/colors";

const styles = {
  arrow: { ml: 1 },
  mobileList: {
    borderBottom: `1px solid ${grey[200]}`,
    py: 1,
    px: 3,
    cursor: "pointer",
  },
  children: {
    pt: 1,
    pl: 4,
    borderBottom: `1px solid ${grey[200]}`,
  },
};

export default styles;
