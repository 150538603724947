import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { CustomEvent } from "schemas/functions";

import { PAGE_VISITED } from "constants/trackingProps";
import { trackEvent } from "utils/tracking";

import { ContactViewProperty } from "../../schema";
import AddColumn from "./AddColumn";
import AddRow from "./AddRow";
import DraggableTableHeader from "./DraggableHeader";
import DragAlongCell from "./DraggbleCell";
import RowMenu from "./RowMenu";
import TableProperties from "./TableProperties";
import styles from "./styles";

const REF_INDEX = 25;

export default function DraggableTable() {
  const tbodyRef = useRef<null | HTMLTableSectionElement>(null);

  const {
    selectedView,
    outreachContacts,
    total,
    highlightedNewRow,
    setHighlightedNewRow,
    newContact,
  } = useContext(ContactViewContext);
  const { handleSearch, contactViewParams, fetchMoreLoading, tableRef } =
    useContext(ContactViewSearchContext);
  const { discoverTab } = useContext(DiscoverViewContext);
  const [paginationRef, isPaginationRefVisible] = useInView({
    rootMargin: "0px 0px",
  });
  const highlightedRowRef = useRef<HTMLTableRowElement | null>(null);

  const [rowMenuEl, setRowMenuEl] = useState<null | HTMLElement>(null);
  const openRowMenu = Boolean(rowMenuEl);
  const handleCloseRowMenu = () => {
    setRowMenuEl(null);
  };
  const [contactToDelete, setContactToDelete] = useState<number | undefined>();

  const props =
    selectedView?.contactViewProperties.filter(
      (p) => p.displayOrder !== undefined && p.displayOrder >= 0,
    ) || [];

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPaginationRefVisible) {
      if (outreachContacts.length < total && !fetchMoreLoading) {
        handleSearch({
          query: contactViewParams.query,
          page: contactViewParams.page + 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaginationRefVisible]);

  const handleKeyDown = (
    event: CustomEvent,
    rowIndex: number,
    cellIndex: number,
  ) => {
    // do not handle arrow keystrokes if the current active element is an input
    if (
      document.activeElement?.tagName.toLowerCase() === "input" ||
      document.activeElement?.tagName.toLowerCase() === "textarea"
    ) {
      return;
    }
    event.stopPropagation();
    const currentRow = tbodyRef.current?.children[rowIndex];
    const cells = currentRow?.children;

    switch (event.key) {
      case "ArrowUp":
        // @ts-ignore
        tbodyRef.current?.children[rowIndex - 1]?.children[cellIndex]?.focus();
        break;
      case "ArrowDown":
        // @ts-ignore
        tbodyRef.current?.children[rowIndex + 1]?.children[cellIndex]?.focus();
        break;
      case "ArrowLeft":
        // @ts-ignore
        cells?.[cellIndex - 1]?.focus();
        break;
      case "ArrowRight":
        // @ts-ignore
        cells?.[cellIndex + 1]?.focus();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (highlightedRowRef.current) {
      highlightedRowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [highlightedNewRow]);

  return (
    <Box sx={styles.root}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <TableProperties />
      </Grid>

      <Grid
        container
        onClick={() => {
          if (highlightedNewRow) {
            setHighlightedNewRow(undefined);
          }
        }}
      >
        <Grid
          item
          xs
          sx={styles.container(discoverTab !== null)}
          ref={tableRef}
        >
          <Table stickyHeader size="small" sx={styles.table}>
            <TableHead>
              <TableRow sx={styles.header}>
                {props.map((prop) => (
                  <DraggableTableHeader key={prop.id} prop={prop} />
                ))}
              </TableRow>
            </TableHead>

            <TableBody ref={tbodyRef}>
              {outreachContacts.map((outreachContact, rowIndex: number) => (
                <TableRow
                  key={outreachContact.id}
                  ref={(element: HTMLTableRowElement | null) => {
                    if (element) {
                      if (rowIndex === outreachContacts.length - REF_INDEX) {
                        paginationRef(element);
                      }
                      if (highlightedNewRow === outreachContact.id) {
                        highlightedRowRef.current = element;
                      }
                    }
                  }}
                  sx={[
                    styles.tableRow(false),
                    !!highlightedNewRow &&
                      highlightedNewRow === outreachContact.id &&
                      styles.highlightedNewRow,
                  ]}
                >
                  {props.map((prop: ContactViewProperty, cellIndex: number) => (
                    <DragAlongCell
                      key={prop.id}
                      property={prop}
                      outreachContact={outreachContact}
                      setRowMenuEl={setRowMenuEl}
                      setContactToDelete={setContactToDelete}
                      onKeyDown={(e) => handleKeyDown(e, rowIndex, cellIndex)}
                    />
                  ))}
                </TableRow>
              ))}

              {newContact && (
                <TableRow sx={styles.tableRow(true)}>
                  {props.map((prop: ContactViewProperty, cellIndex: number) => (
                    <DragAlongCell
                      key={prop.id}
                      property={prop}
                      outreachContact={newContact}
                      setRowMenuEl={setRowMenuEl}
                      setContactToDelete={setContactToDelete}
                      onKeyDown={(e) =>
                        handleKeyDown(e, outreachContacts.length, cellIndex)
                      }
                    />
                  ))}
                </TableRow>
              )}

              <AddRow props={props} tbody={tbodyRef.current} />
            </TableBody>
          </Table>
        </Grid>
        <Box sx={styles.totalRows}>
          {total} row{total === 1 ? "" : "s"}
        </Box>
        <Grid item xs="auto">
          <AddColumn />
        </Grid>
      </Grid>
      {fetchMoreLoading && (
        <Box sx={styles.fetchMoreLoader}>
          Loading More Rows{" "}
          <CircularProgress size={20} disableShrink sx={{ ml: 1 }} />
        </Box>
      )}
      <RowMenu
        open={openRowMenu}
        anchorEl={rowMenuEl}
        handleClose={handleCloseRowMenu}
        contactToDelete={contactToDelete}
      />
    </Box>
  );
}
