import { grey } from "@mui/material/colors";

const styles = {
  root: {
    height: "40px",
    "& .MuiOutlinedInput-root": {
      color: "green.dark",
      backgroundColor: grey[100],
      borderRadius: 4,
      height: "40px",
      padding: "0 14px",
    },
  },
  searchIcon: {
    textTransform: "none",
    ml: 2,
    minWidth: 0,
    mr: 1,
    height: 50,
  },
};

export default styles;
