import { headerFontFamily } from "styles/theme";

const styles = {
  button: {
    borderRadius: 1,
    textTransform: "none",
    mb: 1,
    maxWidth: "100%",
  },
  smallScreenButton: {
    mx: 1,
    mt: 1,
  },
  textItem: {
    "& .MuiTypography-root": {
      fontFamily: headerFontFamily,
      fontSize: "0.8125rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  listItem: {
    "& .MuiListItemButton-root": {
      pr: "38px",
    },
  },
};

export default styles;
