import {
  KeyboardShortcutCommand,
  findParentNodeClosestToPos,
} from "@tiptap/core";
import Table from "@tiptap/extension-table";
import { CellSelection } from "@tiptap/pm/tables";

function isCellSelection(value: unknown): value is CellSelection {
  return value instanceof CellSelection;
}

const deleteTableWhenAllCellsSelected: KeyboardShortcutCommand = ({
  editor,
}) => {
  const { selection } = editor.state;

  if (!isCellSelection(selection)) {
    return false;
  }

  let cellCount = 0;
  const table = findParentNodeClosestToPos(
    selection.ranges[0].$from,
    (node) => {
      return node.type.name === "table";
    },
  );

  table?.node.descendants((node) => {
    if (node.type.name === "table") {
      return false;
    }

    if (["tableCell", "tableHeader"].includes(node.type.name)) {
      cellCount += 1;
    }
  });

  const allCellsSelected = cellCount <= selection.ranges.length;

  if (!allCellsSelected) {
    editor.commands.deleteRow();
    return true;
  } else {
    editor.commands.deleteTable();
    return true;
  }
};

export default Table.extend({
  name: "custom-table",

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        if (this.editor.commands.goToNextCell()) {
          return true;
        }

        if (!this.editor.can().addRowAfter()) {
          return false;
        }

        return this.editor.chain().addRowAfter().goToNextCell().run();
      },
      "Shift-Tab": () => this.editor.commands.goToPreviousCell(),
      Backspace: deleteTableWhenAllCellsSelected,
      "Mod-Backspace": deleteTableWhenAllCellsSelected,
      Delete: deleteTableWhenAllCellsSelected,
      "Mod-Delete": deleteTableWhenAllCellsSelected,
    };
  },
});
