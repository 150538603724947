import { grey } from "@mui/material/colors";

const styles = {
  container: {
    alignItems: "center",
    display: "flex",
    background: grey[300],
    borderRadius: 8,
    ml: 1,
    px: 1,
    py: 1,
    width: "max-content",
  },
  inactive: {
    color: grey[600],
    textTransform: "none",
    borderRadius: 4,
    fontSize: 13,
  },
  active: {
    borderRadius: 4,
    backgroundColor: "white",
    color: "black",
    textTransform: "none",
    fontSize: 13,
  },
  yearlyText: {
    color: "info.main",
    fontSize: 12,
    ml: 1,
  },
};

export default styles;
