import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext, useEffect } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { OutreachContactStatus } from "schemas/dashboard";
import { routes } from "schemas/routes";

import Drawer from "components/Drawer";
import OutreachStatusChip from "components/OutreachStatusChip";
import { isPage } from "utils/general";
import { hideEmail } from "utils/string";
import { trackEvent } from "utils/tracking";

import ArchiveButton from "../NewOutreachTable/ContactRow/components/ArchiveButton";
import IconAction from "../NewOutreachTable/ContactRow/components/IconAction";
import SelectStatus from "../NewOutreachTable/ContactRow/components/SelectStatus";
import EmailThreadRow from "./EmailThreadRow";
import OutreachEmail from "./OutreachEmail";
import ScheduledEmail from "./ScheduledEmail";
import ScheduledEmailRow from "./ScheduledEmailRow";
import styles from "./styles";

export default function OutreachContactDrawer() {
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("md"));
  const fullScreenEmail = isLargeScreen;

  const breakColumnScreen = useMediaQuery("(min-width:800px)");

  const { outreachContactId: id } = useParams();
  const location = useLocation();
  const outreachContactId = Number(id);
  const {
    currentContact,
    currentContactEmailThreads,
    fetchContact,
    fetchEmailThreads,
    currentContactLoading,
    currentContactThreadsLoading,
    currentContactPageToken,
    currentContactScheduledEmailsLoading,
    currentContactScheduledEmails,
    fetchScheduledEmails,
    missingIntegration,
  } = useContext(OutreachContactsContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { contactViewId } = useParams();

  const closeDrawer = () => {
    if (isPage(routes.inbox)) {
      navigate(`/${routes.inbox}`);
    } else {
      if (contactViewId) {
        navigate(`/${routes.trackingView}/${contactViewId}`);
      } else {
        navigate(`/dashboard/${routes.tracking}`);
      }
    }
  };

  const fetchMore = () => {
    fetchEmailThreads(outreachContactId, false);
  };

  useEffect(() => {
    if (isNaN(outreachContactId)) {
      return;
    }
    fetchContact(outreachContactId);
    fetchEmailThreads(outreachContactId, true);
    fetchScheduledEmails(outreachContactId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outreachContactId, currentOrg?.id]);

  if (isNaN(outreachContactId) || (!currentContactLoading && !currentContact)) {
    return <Navigate to={`/${routes.inbox}`} replace />;
  }

  const renderErrorText = () => {
    if (currentContactThreadsLoading) {
      return <></>;
    } else if (missingIntegration) {
      return (
        <Alert severity="error">
          Log in to Google on your{" "}
          <Link style={{ color: "black" }} to={`/${routes.settings}`}>
            settings page
          </Link>{" "}
          to continue using this feature
        </Alert>
      );
    } else if (currentContactEmailThreads.length === 0) {
      return (
        <Grid item xs sx={{ maxWidth: "100%" }}>
          <Typography component="div" variant="caption" color="textSecondary">
            No threads could be found in your inbox for this contact. Deleted
            threads are not shown here.
          </Typography>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  const goToLink = (link: string) => {
    window.open(link, "_blank");
    trackEvent("Link Clicked On", { link, "Clicked From": "View Thread" });
  };

  return (
    <>
      <Drawer
        width={{ xs: "auto", md: 890, lg: 1000, xl: 1200 }}
        open={true}
        onClose={closeDrawer}
      >
        <Box sx={styles.root}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <Box sx={{ mt: 2, ml: 1 }}>
                <Button color="secondary" size="small" onClick={closeDrawer}>
                  <Box
                    component="i"
                    className="fa-solid fa-chevron-left"
                    sx={{ mr: 1 }}
                  />{" "}
                  Back
                </Button>
              </Box>
            </Grid>

            <Grid
              container
              alignItems="flex-start"
              item
              xs={12}
              md={fullScreenEmail ? 12 : 6}
              lg={fullScreenEmail ? 12 : 5}
              xl={4}
              spacing={1}
              sx={styles.contactInfoContainer}
            >
              {currentContactLoading && (
                <Grid
                  container
                  sx={styles.progressContainer}
                  justifyContent="center"
                  gap={2}
                >
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={20}
                    width="95%"
                  />
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={40}
                    width="95%"
                    sx={{ mb: 3 }}
                  />
                </Grid>
              )}
              {!currentContactLoading && currentContact && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5" component="span" paragraph>
                      {currentContact.brand?.name}
                    </Typography>
                    <Box sx={{ mx: 1 }} component="span">
                      <IconAction
                        fieldName="isPinned"
                        contact={currentContact}
                        setAlert={setAlert}
                      />
                    </Box>

                    <ArchiveButton
                      contact={currentContact}
                      setAlert={setAlert}
                    />
                  </Grid>
                  <Grid item xs={12} md={breakColumnScreen ? 6 : 4}>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      Contact Details
                    </Typography>
                    {currentContact.contactName && (
                      <Typography noWrap sx={styles.clipText} component="div">
                        {currentContact.contactName}
                      </Typography>
                    )}
                    {currentContact.contactTitle && (
                      <Typography
                        noWrap
                        sx={styles.clipText}
                        component="div"
                        variant="caption"
                      >
                        {currentContact.contactTitle}
                      </Typography>
                    )}
                    <Typography
                      noWrap
                      sx={styles.clipText}
                      component="div"
                      variant="caption"
                    >
                      {currentContact.email
                        ? currentContact.status ===
                          OutreachContactStatus.pending
                          ? hideEmail(currentContact.email)
                          : currentContact.email
                        : ""}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={breakColumnScreen ? 6 : 4}
                    sx={{ pb: { xs: 2, sm: 1 } }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      Status
                    </Typography>

                    {currentContact.status ===
                      OutreachContactStatus.received_response ||
                    currentContact.status ===
                      OutreachContactStatus.not_interested ||
                    currentContact.status ===
                      OutreachContactStatus.interested ? (
                      <SelectStatus
                        contact={currentContact}
                        setAlert={setAlert}
                      />
                    ) : (
                      <>
                        {currentContact.status && (
                          <OutreachStatusChip status={currentContact?.status} />
                        )}
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} md={breakColumnScreen ? 6 : 4}>
                    {(currentContact?.brand?.website ||
                      currentContact?.brand?.instagramUsername) && (
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        Social Media Links
                      </Typography>
                    )}

                    {currentContact?.brand?.website && (
                      <Typography
                        noWrap
                        sx={styles.link}
                        onClick={() =>
                          goToLink(`https://${currentContact?.brand?.website}`)
                        }
                      >
                        <Box
                          component="i"
                          className="fa-regular fa-globe"
                          sx={{ mr: 1 }}
                        />
                        {currentContact?.brand?.website}
                      </Typography>
                    )}

                    {currentContact?.brand?.instagramUsername && (
                      <Typography
                        sx={styles.link}
                        onClick={() =>
                          goToLink(
                            `https://instagram.com/${currentContact?.brand?.instagramUsername}`,
                          )
                        }
                      >
                        <Box
                          component="i"
                          className="fa-brands fa-instagram"
                          sx={{ mr: 1 }}
                        />
                        {currentContact?.brand?.instagramUsername}
                      </Typography>
                    )}
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Divider sx={{ mb: 2 }} />
              </Grid>

              {currentContactScheduledEmailsLoading && (
                <Grid
                  container
                  sx={styles.progressContainer}
                  justifyContent="center"
                  gap={2}
                >
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={30}
                    width="95%"
                  />

                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={50}
                    width="95%"
                  />
                </Grid>
              )}
              {!currentContactScheduledEmailsLoading &&
                currentContactScheduledEmails.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h5" paragraph>
                        Scheduled Outreach Emails
                      </Typography>

                      <Grid container item xs={12}>
                        {currentContactScheduledEmails.map((scheduledEmail) => (
                          <Grid item xs={12} key={scheduledEmail.id}>
                            <ScheduledEmailRow
                              scheduledEmail={scheduledEmail}
                              setAlert={setAlert}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} />
                    </Grid>
                  </>
                )}

              <Grid item xs={12}>
                <Typography variant="h5" paragraph>
                  Recent Email Threads
                </Typography>

                <Grid
                  container
                  item
                  xs={12}
                  sx={{ mt: 2 }}
                  justifyContent="center"
                >
                  {renderErrorText()}
                </Grid>

                <Grid container item xs={12}>
                  {currentContactEmailThreads.map((emailThread) => (
                    <Grid item xs={12} key={emailThread.id}>
                      <EmailThreadRow
                        selected={location.pathname.includes(emailThread.id)}
                        gmailThread={emailThread}
                      />
                    </Grid>
                  ))}
                </Grid>

                {!currentContactThreadsLoading && currentContactPageToken && (
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ mt: 2 }}
                    justifyContent="center"
                  >
                    <Grid item xs="auto">
                      <Button onClick={fetchMore} variant="outlined">
                        View More
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {currentContactThreadsLoading && (
                  <Grid
                    container
                    sx={styles.progressContainer}
                    justifyContent="center"
                    gap={2}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width="100%"
                    />
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width="100%"
                    />
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={40}
                      width="100%"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!fullScreenEmail && (
              <Grid item xs={12} md={6} lg={7} xl={8}>
                <Routes>
                  <Route
                    path="/email/:threadId/*"
                    element={<OutreachEmail setAlert={setAlert} />}
                  />
                  <Route
                    path="/scheduled/:scheduledId/*"
                    element={<ScheduledEmail />}
                  />
                  <Route path="/scheduled/*" element={<ScheduledEmail />} />
                </Routes>
              </Grid>
            )}
          </Grid>
        </Box>
      </Drawer>
      {fullScreenEmail && (
        <Routes>
          <Route
            path="/email/:threadId/*"
            element={
              <Box sx={styles.root}>
                <OutreachEmail setAlert={setAlert} />
              </Box>
            }
          />
          <Route
            path="/scheduled/:scheduledId/*"
            element={
              <Box sx={styles.root}>
                <ScheduledEmail />
              </Box>
            }
          />
          <Route
            path="/scheduled/*"
            element={
              <Box sx={styles.root}>
                <ScheduledEmail />
              </Box>
            }
          />
        </Routes>
      )}
    </>
  );
}
