import {
  Button,
  FormHelperText,
  Grid,
  Typography,
  capitalize,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { StringMap } from "schemas/functions";

import {
  getContents,
  getGoals,
  getHighlights,
  getTones,
} from "utils/templates";
import { trackEvent } from "utils/tracking";

import {
  HandleSurveyChangeFunction,
  TemplateContent,
  TemplateGoal,
  TemplateHighlight,
  TemplateSurvey,
  TemplateTone,
} from "../schema";
import styles from "./styles";

type Field = "content" | "goal" | "tone" | "highlight";
type SelectedValue =
  | TemplateContent
  | TemplateGoal
  | TemplateTone
  | TemplateHighlight;
type Option = {
  key: SelectedValue;
  value: string;
};

interface GoalStepProps {
  survey: TemplateSurvey;
  handleSurveyChange: HandleSurveyChangeFunction;
  isGeneratingFollowUp?: boolean;
  validation: StringMap;
  setValidation: Dispatch<SetStateAction<StringMap>>;
}

export default function GoalStep({
  survey,
  handleSurveyChange,
  isGeneratingFollowUp,
  validation,
  setValidation,
}: GoalStepProps) {
  const selectOption = (field: Field, selectedValue: SelectedValue) => {
    trackEvent(`Template ${capitalize(field)} Selected`, {
      value: selectedValue,
    });

    if (
      field === "content" &&
      selectedValue === TemplateContent.UGC &&
      survey?.highlight === TemplateHighlight.ENGAGEMENT_NUMBERS
    ) {
      handleSurveyChange({ target: { name: "highlight", value: "" } });
    }
    handleSurveyChange({ target: { name: field, value: selectedValue } });

    setValidation((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const renderItemButton = (field: any, selectedValue: any, label: string) => {
    const isSelected = survey[field as keyof TemplateSurvey] === selectedValue;

    return (
      <Button
        key={selectedValue}
        onClick={() => selectOption(field, selectedValue)}
        variant="outlined"
        sx={[styles.button, isSelected ? styles.selected : {}]}
      >
        {label}
      </Button>
    );
  };

  return (
    <>
      {!isGeneratingFollowUp && (
        <>
          <Typography sx={{ mt: 3, mb: 1 }}>
            <strong>
              What type of content do you want to pitch to brands? *
            </strong>
            {validation?.content && (
              <FormHelperText sx={{ color: "error.main" }}>
                {validation.content}
              </FormHelperText>
            )}
          </Typography>

          <Grid container gap={1}>
            {getContents()?.map((content: Option) =>
              renderItemButton("content", content.key, content.value),
            )}
          </Grid>

          <Typography sx={{ mt: 3, mb: 1 }}>
            <strong>What are you looking for? *</strong>
            {validation?.goal && (
              <FormHelperText sx={{ color: "error.main" }}>
                {validation.goal}
              </FormHelperText>
            )}
          </Typography>

          <Grid container gap={1}>
            {getGoals()?.map((goal: Option) =>
              renderItemButton("goal", goal.key, goal.value),
            )}
          </Grid>
        </>
      )}

      <Typography sx={{ mt: 3, mb: 1 }}>
        <strong>Tone of voice *</strong>
        {validation?.tone && (
          <FormHelperText sx={{ color: "error.main" }}>
            {validation.tone}
          </FormHelperText>
        )}
      </Typography>

      <Grid container gap={1}>
        {getTones()?.map((tone: Option) =>
          renderItemButton("tone", tone.key, tone.value),
        )}
      </Grid>

      {!isGeneratingFollowUp && (
        <>
          <Typography sx={{ mt: 3, mb: 1 }}>
            <strong>What you would like to highlight in your pitch? *</strong>{" "}
            {validation?.highlight && (
              <FormHelperText sx={{ color: "error.main" }}>
                {validation.highlight}
              </FormHelperText>
            )}
          </Typography>
          <Grid container gap={1}>
            {getHighlights(survey?.content === TemplateContent.UGC)?.map(
              (highlight: Option) =>
                renderItemButton("highlight", highlight.key, highlight.value),
            )}
          </Grid>
        </>
      )}
    </>
  );
}
