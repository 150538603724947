import { useAuth } from "@clerk/clerk-react";
import { Box, Grid } from "@mui/material";
import { APIProvider } from "@vis.gl/react-google-maps";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import {
  BentoBrand,
  MetadataType,
  SearchParams,
  Sort,
} from "schemas/dashboard";

import { useAutoCompletePrediction } from "components/LocationAutocomplete/useAutoCompletePrediction";
import PopoverSavedCollection from "components/PopoverSavedCollections";
import { PAGE_VISITED } from "constants/trackingProps";
import { arrayQuery, fetcherAuth } from "utils/api";
import {
  getCategoriesParams,
  getMinAndMaxInstagram,
} from "utils/discoverSearch";
import {
  DISCOVER_SEARCH_PARAMS,
  DISPLAYED_CITY_DIALOG,
} from "utils/localStorage";
import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";
import { useSearchBrand } from "utils/useSearchBrand";

import DiscoverVideo from "../Video";
import BrandsList from "./BrandsList";
import SimilarBrands from "./BrandsList/SimilarBrands";
import CategoryFilter from "./CategoryFilter";
import MobileCategoryFilterDialog from "./CategoryFilter/mobile";
import CityDialog from "./Dialogs/CityDialog";
import DiscoverFilter from "./DiscoverFilter";
import OpenSendDialog from "./OpenSendDialog";
import styles from "./styles";

const RESULTS_PER_PAGE = 20;

export default function DiscoverResults() {
  const { breaksDiscoverScreen } = usePageSize();
  const { API_KEY } = useAutoCompletePrediction();

  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { setAlert } = useContext(AlertContext);
  const { currentOrg, profile, hideCityDialog } = useContext(
    OrganizationUserContext,
  );

  const [ref, isBrandsRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const [similarBrandsRef, isSimilarBrandsRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const headRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const brandQuery = searchParams.get(SearchParams.QUERY_DISCOVER);

  const {
    selectedMetadataTags,
    brands,
    setBrands,
    abortController,
    setSimilarBrands,
    similarBrandsCursor,
    setSimilarBrandsCursor,
    discoverFilterParams,
    cursor,
    setCursor,
  } = useContext(BrandsContext);

  const { hasTags } = useSearchBrand();

  const isSearching = (brandQuery && brandQuery?.length > 0) || hasTags;

  // State variables
  const [brandsLoading, setBrandsLoading] = useState<boolean>(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState<boolean>(false);
  const [hitsLimit, setHitsLimit] = useState<boolean>(false);
  const [similarBrandsLimit, setSimilarBrandsLimit] = useState<boolean>(false);

  const [openCityDialog, setOpenCityDialog] = useState(false);
  const [openMobileCategoryFilter, setOpenMobileCategoryFilter] =
    useState(false);

  const resultsRef = useRef<HTMLDivElement>(null);

  const {
    selectedSort,
    selectedCategories,
    selectedCompanyTypes,
    selectedCompanySizes,
    selectedInstagramFollowings,
    selectedLocations,
    selectedCity,
    selectedGeopoints,
  } = { ...discoverFilterParams };

  const query = sessionStorage.getItem(DISCOVER_SEARCH_PARAMS);

  const fetchInspiredBrands = async (
    bentoBrands: BentoBrand[],
    avoidBrands: BentoBrand[],
    cursor: number,
  ) => {
    if (!currentOrg?.id) {
      setFetchMoreLoading(false);
      return;
    }

    setFetchMoreLoading(true);

    try {
      abortController.current = new AbortController();
      const brandIds = bentoBrands.map((b) => b.id).join(",");
      const avoidIds = avoidBrands.map((b) => b.id).join(",");
      const url = `/api/organization/${currentOrg?.id}/bento-brands/inspired-by?ids=${brandIds}&avoid=${avoidIds}&size=${RESULTS_PER_PAGE}&cursor=${cursor}`;
      const res = await fetcherAuth(
        getToken,
        url,
        "GET",
        {},
        false,
        false,
        false,
        false,
        abortController.current.signal,
      );
      setSimilarBrands((prevBrands: BentoBrand[]) => [
        ...prevBrands,
        ...res.bentoBrands,
      ]);

      if (
        (res.cursor >= res.totalResults && res.totalResults !== 0) ||
        (res.cursor <= res.totalResults && res.totalResults < RESULTS_PER_PAGE)
      ) {
        trackEvent("Discover Page Reached End of Similar Brands List");
        setSimilarBrandsLimit(true);
      } else {
        setSimilarBrandsLimit(false);
      }
      setSimilarBrandsCursor(res.cursor);
    } catch (error) {
      if (error?.message?.includes("aborted")) {
        return;
      }
      setAlert(
        error?.message ||
          "Something went wrong when fetching brands, please reload and try again",
        "error",
      );
    } finally {
      setFetchMoreLoading(false);
    }
  };

  const callFetchInspiredBrands = (brands: BentoBrand[], cursor: number) => {
    const firstBrandName = brands[0]?.brandName;
    if (!firstBrandName) {
      return;
    }
    if (
      firstBrandName.toLowerCase() ===
      searchParams.get(SearchParams.QUERY_DISCOVER)?.toLowerCase()
    ) {
      fetchInspiredBrands([brands[0]], brands, cursor);
    } else {
      fetchInspiredBrands(brands.slice(0, 3), brands, cursor);
    }
  };

  const fetchBrands = async (
    searchQuery: string | null,
    fetchCursor: number[] | null,
    useFetchMoreLoading?: boolean,
    appendToList?: boolean,
  ) => {
    if (!currentOrg) {
      setBrandsLoading(false);
      return;
    }

    if (abortController?.current) {
      abortController.current?.abort();
    }

    try {
      abortController.current = new AbortController();

      let query = searchQuery || "";
      if (selectedMetadataTags?.length > 0) {
        const clickedTags = selectedMetadataTags?.filter(
          (x) => x.type === MetadataType.tags,
        );
        query += clickedTags?.map((x) => x.value);
      }

      const encodedQuery = encodeURIComponent(query || "");

      let url = `/api/organization/${currentOrg?.id}/bento-brands?query=${encodedQuery}&size=${RESULTS_PER_PAGE}`;
      if (selectedSort) {
        url += `&sort=${selectedSort?.key}`;
      } else {
        url += `&sort=${Sort.Recommended}`;
      }
      if (selectedCategories?.length > 0) {
        const categoryQueries = getCategoriesParams(selectedCategories, true);
        url += `${arrayQuery(categoryQueries, "categories")}`;
      }
      if (selectedCompanySizes?.length > 0) {
        url += `${arrayQuery(
          selectedCompanySizes?.map((x) => x.key),
          "company_sizes",
        )}`;
      }
      if (selectedCompanyTypes?.length > 0) {
        url += `${arrayQuery(
          selectedCompanyTypes?.map((x) => x.key),
          "company_types",
        )}`;
      }

      if (profile?.city && selectedCity?.key) {
        url += `&city=${profile?.city}`;
      }

      if (selectedLocations?.length > 0) {
        url += `${arrayQuery(
          selectedLocations?.map((x) => x.key),
          "locations",
        )}`;
      }

      if (selectedGeopoints?.length > 0) {
        url += `${arrayQuery(
          selectedGeopoints?.map((x) => x.key),
          "geopoints",
        )}`;
      }

      if (selectedInstagramFollowings?.length > 0) {
        const followers = getMinAndMaxInstagram(selectedInstagramFollowings);
        if (followers.min_instagram_followers) {
          url += `&min_instagram_followers=${followers.min_instagram_followers}`;
        }
        if (followers.max_instagram_followers) {
          url += `&max_instagram_followers=${followers.max_instagram_followers}`;
        }
      }
      if (fetchCursor) {
        url += `&cursor=${JSON.stringify(fetchCursor)}`;
      }

      if (useFetchMoreLoading) {
        setFetchMoreLoading(true);
      } else {
        setBrandsLoading(true);
      }

      const res = await fetcherAuth(
        getToken,
        url,
        "GET",
        {},
        {},
        false,
        false,
        true,
        abortController.current.signal,
      );
      if (res.bentoBrands.length < RESULTS_PER_PAGE) {
        setHitsLimit(true);
        if (res.totalResults !== 0) {
          if (appendToList) {
            callFetchInspiredBrands([...brands, ...res.bentoBrands], 0);
          } else {
            callFetchInspiredBrands(res.bentoBrands, 0);
          }
        } else {
          setFetchMoreLoading(false);
        }
      } else {
        setHitsLimit(false);
        setFetchMoreLoading(false);
      }
      const matchQuery = brandQuery ? query === brandQuery : true;

      if (appendToList && matchQuery) {
        setBrands((prevBrands: BentoBrand[]) => {
          const brandSet = new Set(prevBrands.map((brand) => brand.id));
          const newBrands = [...prevBrands];

          res.bentoBrands.forEach((brand: BentoBrand) => {
            if (!brandSet.has(brand.id)) {
              brandSet.add(brand.id);
              newBrands.push(brand);
            }
          });

          return newBrands;
        });
      } else {
        setBrands([...res.bentoBrands]);
      }

      setCursor(res.cursor);
    } catch (error) {
      if (error?.message?.includes("aborted")) {
        return;
      }
      setAlert(
        error?.message ||
          "Something went wrong when fetching brands, please reload and try again",
        "error",
      );
    } finally {
      setBrandsLoading(false);
    }
  };

  const handleSearch = async () => {
    scrollToTop();
    setSimilarBrands([]);
    setBrandsLoading(true);
    setSimilarBrandsCursor(0);
    setCursor(null);
    setSimilarBrandsLimit(false);
    setHitsLimit(false);
    fetchBrands(brandQuery, null, false, false);
  };

  const scrollToTop = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isBrandsRefVisible) {
      if (!hitsLimit && !brandsLoading) {
        fetchBrands(brandQuery, cursor, true, true);
        trackEvent("Discover Page Scrolled To Bottom", {
          keyword: brandQuery,
          category: selectedCategories,
          "Company Type": selectedCompanyTypes,
          sort: selectedSort,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrandsRefVisible, brandsLoading]);

  useEffect(() => {
    if (isSimilarBrandsRefVisible) {
      if (!similarBrandsLimit && !fetchMoreLoading && !brandsLoading) {
        callFetchInspiredBrands(brands, similarBrandsCursor);
        trackEvent("Discover Page Scrolled To Bottom of Similar Brands List", {
          keyword: brandQuery,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSimilarBrandsRefVisible]);

  useEffect(() => {
    const isElementInView = () => {
      const rect = headRef?.current?.getBoundingClientRect();
      if (!rect) {
        return false;
      }
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    if (headRef.current && !isElementInView()) {
      headRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
    if (query && !window.location.href?.includes("?")) {
      navigate(`?${query}`);
    } else {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(discoverFilterParams),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedMetadataTags?.length,
    brandQuery,
    currentOrg?.id,
  ]);

  useEffect(
    () => () => {
      if (abortController.current) {
        abortController.current?.abort();
      }
      if (query) {
        setBrands([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const alreadyDisplayedCityDialog =
      localStorage.getItem(`${DISPLAYED_CITY_DIALOG}-${currentOrg?.id}`) ===
      "true";
    if (
      profile &&
      !profile?.city &&
      !alreadyDisplayedCityDialog &&
      !hideCityDialog
    ) {
      setOpenCityDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.city, currentOrg?.id]);

  const handleCloseCityDialog = (city?: string) => {
    localStorage.setItem(`${DISPLAYED_CITY_DIALOG}-${currentOrg?.id}`, "true");
    setOpenCityDialog(false);
    if (city) {
      searchParams.set(SearchParams.LOCATION_DISCOVER, city);
      setSearchParams(searchParams);
    }
  };

  return (
    <APIProvider apiKey={API_KEY || ""}>
      <div ref={resultsRef} />
      <Box>
        {!isSearching && (
          <Grid container justifyContent="center">
            <Grid sx={{ width: 850, mb: { xs: 2, lg: 0 } }}>
              <DiscoverVideo />
            </Grid>
          </Grid>
        )}

        <DiscoverFilter
          setOpenMobileCategoryFilter={setOpenMobileCategoryFilter}
        />

        <Grid
          container
          item
          xs={12}
          columnSpacing={1}
          sx={
            breaksDiscoverScreen
              ? styles.cardContainerMobile
              : styles.cardContainer
          }
        >
          {!breaksDiscoverScreen && (
            <Grid item md={1.5} sx={{ px: -2 }}>
              <CategoryFilter />
            </Grid>
          )}

          <Grid
            gap={1}
            md
            alignItems="flex-start"
            item
            sx={styles.brandsList(hasTags)}
          >
            <BrandsList
              brands={brands}
              brandsLoading={brandsLoading}
              fetchMoreLoading={fetchMoreLoading}
              ref={ref}
            />
            <SimilarBrands
              brandsLoading={brandsLoading}
              fetchMoreLoading={fetchMoreLoading}
              ref={similarBrandsRef}
            />
          </Grid>
        </Grid>

        <CityDialog open={openCityDialog} handleClose={handleCloseCityDialog} />

        <Routes>
          <Route path="send/:bentoBrandId/*" element={<OpenSendDialog />} />
        </Routes>
      </Box>

      {breaksDiscoverScreen && (
        <MobileCategoryFilterDialog
          openMobileCategoryFilter={openMobileCategoryFilter}
          setOpenMobileCategoryFilter={setOpenMobileCategoryFilter}
        />
      )}

      <PopoverSavedCollection />
    </APIProvider>
  );
}
