import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext } from "react";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const styles = {
  warning: {
    color: orange[500],
    mr: 1,
  },
  moveToBrandButton: {
    fontWeight: "bold",
    color: "primary.main",
  },
  removeButton: {
    fontWeight: "bold",
    color: "secondary.main",
  },
  closeIcon: {
    color: "primary.main",
    fontSize: 14,
    position: "absolute",
    right: 10,
    top: 10,
  },
};

export default function ImportsDialog({ open, handleClose }: IProps) {
  const { userIntegration } = useContext(UserIntegrationsContext);

  return (
    <Dialog maxWidth="sm" open={open} onClose={handleClose}>
      <IconButton sx={styles.closeIcon} onClick={handleClose}>
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle component="div" display="flex" alignItems="center">
        <Box
          component="i"
          className="fa-solid fa-triangle-exclamation fa-lg"
          sx={styles.warning}
        />
        <Typography component="span" variant="h5">
          Bento has auto-filled your sheet
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography paragraph>
          We have auto-filled your sheet with contacts from Gmail. We use AI, so
          please be aware that we cannot guarantee 100% accuracy. We encourage
          you to review your sheet, and use the{" "}
          <Typography component="span" sx={styles.moveToBrandButton}>
            Move Brand To
          </Typography>{" "}
          button to move brands into the best fit sheets.
        </Typography>
        <Typography paragraph>
          You can always right click and delete a row or click on the{" "}
          <Typography component="span" sx={styles.removeButton}>
            Remove
          </Typography>{" "}
          action as well.
        </Typography>
        {!userIntegration?.partnershipsSearchCompletedAt && (
          <Typography>
            Your import is still <strong>in progress</strong>, we will send you
            an email once it is complete.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
