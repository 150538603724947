import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { Map } from "schemas/functions";
import { Plans } from "schemas/payments";

import { titleCase } from "utils/string";

interface ManageDialogProps {
  plan: Plans;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  openCancel: () => void;
  handleCheckout: (
    plan: Plans,
    flow?: string,
    openTarget?: "_self" | "_blank",
  ) => void;
  allLoading: Map;
}

export default function ManageDialog({
  plan,
  open,
  onClose,
  handleCheckout,
  openCancel,
  loading,
  allLoading,
}: ManageDialogProps) {
  const { subscription, plans } = useContext(SubscriptionContext);

  const getUpdatePlan = () => {
    if (plan === Plans.STARTER) {
      return Plans.STARTER_YEARLY;
    } else {
      return Plans.PRO_YEARLY;
    }
  };

  const getPriceDiscount = () => {
    if (!plans) return <></>;
    if (plan === Plans.STARTER) {
      return (
        <Box component="span" sx={{ color: "info.main" }}>
          (${plans[Plans.STARTER_YEARLY]?.price}/month)
        </Box>
      );
    } else {
      return (
        <Box component="span" sx={{ color: "info.main" }}>
          (${plans[Plans.PRO_YEARLY]?.price}/month)
        </Box>
      );
    }
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <IconButton
        sx={{
          color: "primary.main",
          fontSize: 14,
          position: "absolute",
          right: 10,
          top: 10,
        }}
        onClick={onClose}
      >
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle>Manage Current Plan</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          You are currently on the{" "}
          <strong>
            {subscription?.subscription.planName
              ? titleCase(subscription?.subscription.planName)
              : "Free"}
          </strong>{" "}
          plan. Use the link below to change your current plan, modify your
          payment method, and see invoice history.
        </Typography>
        <LoadingButton
          loading={loading}
          variant="outlined"
          onClick={() => {
            handleCheckout(plan, undefined, "_blank");
          }}
        >
          Manage Plan
        </LoadingButton>

        {(plan === Plans.PRO || plan === Plans.STARTER) && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" component="div">
              Get Annual Discount {getPriceDiscount()}
            </Typography>
            <Typography paragraph>
              By switching to yearly, you will save <strong>25%</strong> on your
              monthly payment. Click on the button below to switch to an annual
              subscription.
            </Typography>
            <LoadingButton
              variant="contained"
              onClick={() => {
                handleCheckout(
                  getUpdatePlan(),
                  "subscription_update",
                  "_blank",
                );
              }}
              disableElevation
              loading={
                allLoading[Plans.STARTER_YEARLY] || allLoading[Plans.PRO_YEARLY]
              }
            >
              Switch
            </LoadingButton>
          </>
        )}
        {!subscription?.subscription.cancelAt && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" paragraph>
              Cancel Subscription
            </Typography>
            <Typography paragraph>
              By ending your subscription, you will lose access to your{" "}
              <strong>
                {subscription?.subscription.planName
                  ? titleCase(subscription?.subscription.planName)
                  : "Free"}
              </strong>{" "}
              plan benefits.
            </Typography>
            <Button variant="outlined" color="secondary" onClick={openCancel}>
              Cancel Subscription
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
