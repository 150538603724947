import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { Moment } from "moment";
import { useRef, useState } from "react";
import { CustomEvent } from "schemas/functions";

import ScheduleDialog from "./ScheduleDialog";

enum ButtonType {
  send = "Send",
  schedule = "Schedule",
}

interface Props {
  handleSendDraftEmail: (sendAt?: null | string) => void;
  sendLoading: boolean;
  defaultTime: Moment;
  disabled?: boolean;
}

export default function SendButtonGroup({
  handleSendDraftEmail,
  sendLoading,
  defaultTime,
  disabled,
}: Props) {
  const [buttonType, setButtonType] = useState<
    ButtonType.send | ButtonType.schedule
  >(ButtonType.send);

  const [open, setOpen] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: ButtonType,
  ) => {
    event.stopPropagation();
    setButtonType(option);
    setOpen(false);
  };

  const handleToggle = (e: CustomEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    event.stopPropagation();
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleClick = (e: CustomEvent) => {
    if (buttonType === ButtonType.send) {
      handleSendDraftEmail();
    } else {
      setScheduleDialogOpen(true);
    }
  };

  return (
    <Grid>
      <Grid container alignItems="center">
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          size="small"
          disableElevation
          sx={{
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              borderColor: "secondary.light",
            },
            my: 1,
          }}
          disabled={disabled}
        >
          <LoadingButton
            variant="contained"
            loading={sendLoading}
            onClick={handleClick}
            sx={{ height: 30 }}
          >
            {buttonType}
          </LoadingButton>
          <Button size="small" onClick={handleToggle}>
            <Box component="i" className="fa-regular fa-chevron-down" />
          </Button>
        </ButtonGroup>
      </Grid>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {[ButtonType.send, ButtonType.schedule].map(
                    (option: ButtonType) => (
                      <MenuItem
                        key={option}
                        selected={buttonType === option}
                        onClick={(event) => handleMenuItemClick(event, option)}
                      >
                        {option}
                      </MenuItem>
                    ),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <ScheduleDialog
        open={scheduleDialogOpen}
        defaultTime={defaultTime}
        handleClose={() => {
          setScheduleDialogOpen(false);
        }}
        onSchedule={handleSendDraftEmail}
      />
    </Grid>
  );
}
