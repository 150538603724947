import { green, grey } from "@mui/material/colors";

const styles = {
  button: {
    textTransform: "none",
    color: grey[400],
    borderRadius: 0,
    borderColor: grey[400],
    "&:hover": {
      color: "black",
    },
    fontSize: 13,
  },
  selected: {
    color: "black",
    borderColor: "black",
    backgroundColor: grey[50],
    fontSize: 13,
  },
  option: {
    borderRadius: "16px",
    border: `1px solid ${grey[300]}`,
    px: 2,
    py: 4,
    flex: 1,
    display: "flex",
    position: "relative",
    textAlign: "center",
    "&:hover": { backgroundColor: "primary.light" },
    "&.Mui-selected": {
      backgroundColor: "primary.light",
    },
  },
  highlightOption: {
    my: 1,
    display: "flex",
    width: "100%",
    "&:hover": { backgroundColor: "primary.light" },
    "&.Mui-selected": {
      backgroundColor: "primary.light",
    },
    textTransform: "none",
    color: "black",
  },
  optionName: { flexGrow: 1 },
  checkmark: {
    color: green[600],
    fontSize: 18,
    position: "absolute",
    top: "-5px",
    right: "-5px",
  },
  listContainer: {
    display: "flex",
    gap: 2,
    flexWrap: "nowrap",
    flexDirection: { xs: "column", md: "row" },
  },
  options: {
    display: "block",
    "& .MuiToggleButtonGroup-grouped, & .MuiToggleButtonGroup-grouped:not(:last-of-type), & .MuiToggleButtonGroup-grouped:not(:first-of-type)":
      {
        borderRadius: 2,
      },
  },
};

export default styles;
