import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { CombinedRequest, EmailStatus } from "schemas/dashboard";
import { Map } from "schemas/functions";

import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

const PER_PAGE = 20;

type TotalMap = {
  [key in keyof typeof EmailStatus]?: number | undefined;
};

type PageMap = {
  [key in keyof typeof EmailStatus]?: number;
};

interface UserRequestsContextInterface {
  tab: EmailStatus;
  setTab: Dispatch<SetStateAction<EmailStatus>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  userRequests: (status?: EmailStatus) => CombinedRequest[];
  setUserRequests: (
    status?: EmailStatus,
  ) => Dispatch<SetStateAction<CombinedRequest[]>>;
  total: TotalMap;
  setTotal: Dispatch<SetStateAction<TotalMap>>;
  page: PageMap;
  setPage: Dispatch<SetStateAction<PageMap>>;
  sentRef: any;
  unsentRef: any;
  fetchUserRequest: (sent: boolean, nextPage?: number) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  open: false,
  setOpen: defaultContextMissingFunction,
  tab: EmailStatus.sent,
  setTab: defaultContextMissingFunction,
  userRequests: defaultContextMissingFunction,
  setUserRequests: defaultContextMissingFunction,
  total: {},
  setTotal: defaultContextMissingFunction,
  page: {},
  setPage: defaultContextMissingFunction,
  sentRef: null,
  unsentRef: null,
  fetchUserRequest: defaultContextMissingFunction,
};

const UserRequestsContext =
  createContext<UserRequestsContextInterface>(defaultInterface);

interface UserRequestsProviderProps {
  children: React.ReactNode;
}

const UserRequestsProvider = ({ children }: UserRequestsProviderProps) => {
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);

  const [open, setOpen] = useState(false);

  const [tab, setTab] = useState<EmailStatus>(EmailStatus.unsent);
  const [sentRequests, setSentRequests] = useState<CombinedRequest[]>([]);
  const [unsentRequests, setUnsentRequests] = useState<CombinedRequest[]>([]);

  const [sentRef, isSentRefVisible] = useInView({
    rootMargin: "0px 0px",
  });
  const [unsentRef, isUnsentRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const [page, setPage] = useState<Map>({
    sent: 1,
    unsent: 1,
  });
  const [total, setTotal] = useState<Map>({
    unsent: undefined,
    sent: undefined,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUserRequest = async (sent: boolean, nextPage?: number) => {
    if (!currentOrg?.id) return;

    setLoading(true);

    const status = sent ? EmailStatus.sent : EmailStatus.unsent;
    const pageToFetch = nextPage || page[status];

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/combined-requests?sent=${sent}&per_page=${PER_PAGE}&page=${pageToFetch}`,
      );
      if (page[status] === 1) {
        setUserRequests(status)(res.combinedRequests);
      } else {
        setUserRequests(status)((prev) => [...prev, ...res.combinedRequests]);
      }
      setTotal((prev) => ({ ...prev, [status]: res.total }));
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const userRequests = (status = EmailStatus.unsent) => {
    if (status === EmailStatus.sent) {
      return sentRequests;
    } else {
      return unsentRequests;
    }
  };

  const setUserRequests = (status = EmailStatus.unsent) => {
    if (status === EmailStatus.sent) {
      return setSentRequests;
    } else {
      return setUnsentRequests;
    }
  };

  useEffect(() => {
    if (isUnsentRefVisible) {
      if (unsentRequests.length < total.unsent && !loading) {
        setPage((prev) => {
          prev.unsent = prev.unsent + 1;
          fetchUserRequest(false, prev.unsent);
          return prev;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnsentRefVisible]);

  useEffect(() => {
    if (isSentRefVisible) {
      if (sentRequests.length < total.sent && !loading) {
        setPage((prev) => {
          prev.sent = prev.sent + 1;
          fetchUserRequest(true, prev.sent);
          return prev;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSentRefVisible]);

  return (
    <UserRequestsContext.Provider
      value={{
        open,
        setOpen,
        tab,
        setTab,
        setUserRequests,
        userRequests,
        total,
        setTotal,
        page,
        setPage,
        sentRef,
        unsentRef,
        fetchUserRequest,
      }}
    >
      {children}
    </UserRequestsContext.Provider>
  );
};

export { UserRequestsProvider, UserRequestsContext };
