import { Box, ListItemText, MenuItem, MenuList } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { AlertContext } from "contexts/Alert";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useEffect, useState } from "react";
import { SavedBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { HEART_COLOR } from "styles/oneOffColor";

import NewCollectionDialog from "features/Home/Sidebar/FavoritesList/Collections/NewCollectionDialog";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

export default function PopoverSavedCollection() {
  const { setAlert } = useContext(AlertContext);
  const { anchorEl, setAnchorEl, brandBeingSaved, handleMoveDebounced } =
    useContext(SavedBrandContext);
  const { collections, setCollectionsMap, currentTab } = useContext(
    SavedBrandCollectionsContext,
  );

  const open = Boolean(anchorEl);
  const id = open ? "saved-collection-popover" : undefined;

  // States
  const [openCollectionDialog, setOpenCollectionDialog] = useState(false);
  const [collectionBeingMoveTo, setCollectionBeingMoveTo] = useState(-1);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createNewList = () => {
    setOpenCollectionDialog(true);
  };

  const handleMoveToCollection = (
    e: CustomEvent,
    collectionId: number,
    collectionName: string,
  ) => {
    if (!brandBeingSaved) return;
    e.stopPropagation();

    trackEvent("Favorites List - Quick Move Saved Brand Button Clicked", {
      "Collection ID": collectionId,
    });

    const oldCollectionId =
      brandBeingSaved.bentoBrand.savedBrandCollectionId || 0;

    handleMoveDebounced(
      brandBeingSaved.bentoBrand,
      oldCollectionId,
      collectionId,
    );

    setCollectionsMap(currentTab)((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy[0]?.findIndex(
        (sb: SavedBrand) =>
          Number(sb.bentoBrandId) === Number(brandBeingSaved.bentoBrandId),
      );
      if (index > -1) {
        copy[0][index]["bentoBrand"]["savedBrandCollectionId"] = collectionId;
      }
      return copy;
    });
    setCollectionBeingMoveTo(collectionId);
    setAlert(`Successfully moved to ${collectionName}`, "success");
    handleClose();
  };

  useEffect(() => {
    if (!open && collectionBeingMoveTo !== -1) {
      setCollectionBeingMoveTo(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <ListItemText>Brand Saved To All</ListItemText>

          <Box
            component="i"
            className="fa-solid fa-bookmark"
            sx={{ color: HEART_COLOR }}
          />
        </MenuItem>

        <MenuList sx={{ minWidth: 200 }}>
          {collections?.map((collection) => (
            <MenuItem
              key={collection.id}
              sx={styles.menuItem}
              onClick={(e) =>
                handleMoveToCollection(e, collection.id, collection.name)
              }
            >
              <ListItemText>{collection.name}</ListItemText>
              {collectionBeingMoveTo === collection?.id ? (
                <Box
                  component="i"
                  className="fa-regular fa-check"
                  sx={{ color: "primary.main" }}
                />
              ) : (
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  <Box component="i" className="fa-thin fa-circle-plus" />
                </Typography>
              )}
            </MenuItem>
          ))}

          <MenuItem sx={styles.menuItem} onClick={createNewList}>
            + Create New
          </MenuItem>
        </MenuList>
      </Popover>

      <NewCollectionDialog
        open={openCollectionDialog}
        handleClose={() => setOpenCollectionDialog(false)}
      />
    </div>
  );
}
