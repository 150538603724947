import { grey } from "@mui/material/colors";

export const getStyles = (tabWidth: string) => {
  const styles = {
    root: {
      position: "fixed",
      top: 0,
      bottom: 0,
      right: tabWidth,
      borderLeft: `1px solid ${grey[200]}`,
      backgroundColor: "white",
      zIndex: 100,
    },
    active: {
      borderRight: `3px solid`,
    },
    icon: {
      fontSize: 24,
      p: 2,
      width: "57px", // border right + width = 60px
    },
  };
  return styles;
};
