import { grey } from "@mui/material/colors";

const styles = {
  container: {
    maxHeight: "calc(100vh - 100px)",
    overflow: "auto",
    width: "100%",
    pb: 10,
  },
  collection: {
    cursor: "pointer",
    py: 2,
    px: 1,
    borderBottom: `1px solid ${grey[200]}`,
  },
  newList: {
    cursor: "pointer",
    py: 2,
    px: 1,
  },
  chevron: {
    color: grey[800],
    fontSize: 12,
  },
  caption: {
    py: 2,
    px: 1,
    borderBottom: `1px solid ${grey[200]}`,
    fontSize: 12,
  },
  circlePlus: {
    fontSize: 12,
    color: "black",
    width: 25,
    height: 25,
  },
};

export default styles;
