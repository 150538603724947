import { grey } from "@mui/material/colors";

const styles = {
  container: {
    display: "grid",
    gridAutoFlow: "column",
    overflowX: "auto",
  },
  image: {
    display: "block",
    objectFit: "cover",
    borderRadius: 4,
    width: 180,
    height: 180,
    cursor: "pointer",
    border: `1px solid ${grey[200]}`,
    "&:hover": {
      border: `1px solid ${grey[300]}`,
    },
  },
  postBy: {
    color: grey[500],
    my: 1,
    fontSize: 12,
    cursor: "pointer",
  },
  textBox: {
    width: 180,
    height: 180,
    borderRadius: 4,
    p: 2,
    textDecoration: "none",
    color: "black",
    cursor: "pointer",
  },
  card: {
    cursor: "pointer",
    border: `1px solid ${grey[200]}`,
    mb: 2,
    "&:hover": {
      backgroundColor: grey[50],
    },
  },
};

export default styles;
