import { Drawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DiscoverTabView, DiscoverViewContext } from "contexts/DiscoverView";
import { useContext } from "react";

import ChatHelper from "features/Home/Sidebar/ChatHelper";
import FavoritesList from "features/Home/Sidebar/FavoritesList";
import MetricsHelper from "features/Home/Sidebar/MetricsHelper";
import TemplateList from "features/Home/Sidebar/TemplateList";
import UserRequestsList from "features/Home/Sidebar/UserRequestsList";

interface PermanentDrawerProps {
  drawerWidth: number;
  open: boolean;
}

const PermanentDrawer = ({ drawerWidth, open }: PermanentDrawerProps) => {
  const { discoverTab, setDiscoverTab } = useContext(DiscoverViewContext);

  const renderContent = () => {
    if (discoverTab === DiscoverTabView.SAVED_BRANDS) {
      return <FavoritesList closeDialog={() => setDiscoverTab(null)} />;
    } else if (discoverTab === DiscoverTabView.TEMPLATES) {
      return <TemplateList />;
    } else if (discoverTab === DiscoverTabView.CHAT) {
      return <ChatHelper />;
    } else if (discoverTab === DiscoverTabView.USER_REQUESTS) {
      return <UserRequestsList />;
    } else if (discoverTab === DiscoverTabView.METRICS) {
      return <MetricsHelper />;
    } else {
      return <></>;
    }
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        top: 0,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          border: "none",
          borderLeft: `1px solid ${grey[200]}`,
          top: 0,
          width: drawerWidth,
          overflowY: "hidden",
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      {renderContent()}
    </Drawer>
  );
};

export default PermanentDrawer;
