import { drawerStyles } from "components/Drawer/reusableDrawerStyles";

const styles = {
  ...drawerStyles,
  dialogContent: {
    p: 0,
    height: "100%",
    overflow: "scroll",
  },
  quickSendWrapper: {
    height: "100%",
    maxHeight: "100svh",
    minHeight: "60svh",
    overflow: "hidden",
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  dialogTitleText: {
    py: 0,
    px: 0,
    backgroundColor: "white",
    cursor: "default",
  },
  titleWrapper: { flexShrink: 0 },
  pagination: {
    ul: {
      "& .MuiPaginationItem-root": {
        "&.Mui-selected": {
          backgroundColor: "primary.light",
        },
      },
    },
  },
  inboxLink: {
    color: "white",
    textDecoration: "underline",
    cursor: "pointer",
  },
  editorWrapper: { flexGrow: 1, overflowY: "auto", maxHeight: "100svh" },
};

export default styles;
