import {
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { forwardRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { BentoBrand, SearchParams, UserRequestSource } from "schemas/dashboard";

import { useSearchBrand } from "utils/useSearchBrand";

import BrandCard from "../BrandCard";
import { BrandCardSource } from "../schema";
import styles from "./styles";

interface Props {
  brandsLoading: boolean;
  fetchMoreLoading: boolean;
  brands: BentoBrand[];
  ref: any;
}
const REF_INDEX = 9; // The index used to start loading more recommendations

export default forwardRef<HTMLDivElement, Props>(
  ({ brandsLoading, fetchMoreLoading, brands }, ref) => {
    const { setAlert } = useContext(AlertContext);
    const [searchParams] = useSearchParams();

    const { handleRequestForBrand } = useSearchBrand();

    const brandQuery = searchParams.get(SearchParams.QUERY_DISCOVER);
    const isSearching = brandQuery && brandQuery?.length > 0;

    return (
      <>
        <Grid item xs={12}>
          <Typography sx={styles.searchingText} variant="h6" textAlign="left">
            {isSearching && `Searching "${brandQuery}"`}
          </Typography>
        </Grid>

        {!brandsLoading && !fetchMoreLoading && brands.length === 0 && (
          <Grid container item rowGap={2}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              No results found{" "}
              {isSearching && (
                <FormControlLabel
                  sx={{ mx: 1 }}
                  control={
                    <Switch
                      size="small"
                      onChange={() =>
                        handleRequestForBrand(
                          searchParams.get("queryBrand") || "",
                          UserRequestSource.SOURCE_TOGGLE,
                        )
                      }
                    />
                  }
                  label={
                    <Typography sx={styles.notifyText} component="span">
                      Notify me when results for{" "}
                      <strong>"{searchParams.get("queryBrand")}"</strong> are
                      found.
                    </Typography>
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              Try changing the filters above to see more results.
            </Grid>
          </Grid>
        )}

        {brandsLoading && (
          <Grid
            container
            justifyContent={"center"}
            sx={styles.skeletonContainer}
          >
            {Array.from({ length: 20 }, (x, i) => (
              <Skeleton
                sx={{ mb: 2 }}
                key={i}
                variant="rounded"
                width={"100%"}
                height={100}
              />
            ))}
          </Grid>
        )}

        <Grid container justifyContent="center" gap={1}>
          {!brandsLoading &&
            brands?.map((brand: BentoBrand, idx: number) => (
              <BrandCard
                ref={
                  (
                    brands.length > REF_INDEX
                      ? idx === brands.length - REF_INDEX
                      : idx === brands.length - 1
                  )
                    ? ref
                    : undefined
                }
                key={`brand-card-${brand.id}-${idx}`}
                brand={brand}
                setAlert={setAlert}
                source={BrandCardSource.DISCOVER_BRAND}
              />
            ))}
        </Grid>
      </>
    );
  },
);
