import { Box, Button, Link, Typography } from "@mui/material";
import gmailLogo from "assets/logos/gmail-logo.png";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { OrganizationUserContext } from "contexts/Organization";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "schemas/routes";

import styles from "./styles";

export default function ImportsEmptyView() {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { userIntegration, fetchIntegrationHealth } = useContext(
    UserIntegrationsContext,
  );
  const { trackTable } = useContext(ContactViewContext);
  const { handleSearch } = useContext(ContactViewSearchContext);
  const navigate = useNavigate();

  if (userIntegration?.partnershipsSearchCompletedAt) {
    return (
      <Box component="tr" sx={styles.root}>
        <Typography component="td">
          Import is complete! We could not find any additional contacts after
          searching your Gmail inbox.
        </Typography>
      </Box>
    );
  } else if (userIntegration?.partnershipsSearchedAt) {
    return (
      <Box component="tr" sx={styles.root}>
        <Typography component="td">
          We are still scanning your Gmail for more contacts. We will send you
          an email when this has been complete. You can{" "}
          <Link
            onClick={() => {
              trackTable("Imports Table Refresh Page Button Pressed");
              handleSearch({ query: "", page: 1 });
              if (currentOrg?.id) {
                fetchIntegrationHealth(currentOrg.id);
              }
            }}
            sx={styles.link}
          >
            refresh the page
          </Link>{" "}
          anytime to see the progress.
        </Typography>
      </Box>
    );
  }

  return (
    <Box component="tr" sx={styles.root}>
      <Typography component="td">
        You do not have any contacts in your Imports tab. Auto-fill all of your
        inbound conversations with our Gmail Integration.
        <Button
          onClick={() => {
            trackTable("Imports Table Auto-fill with Gmail Button Pressed");
            navigate(`${routes.pitchTrackerImport}/gmail`);
          }}
          variant="outlined"
          color="inherit"
          sx={styles.button}
        >
          <Box
            component="i"
            className="fa-solid fa-wand-magic-sparkles"
            sx={styles.wand}
          />
          <Typography component="span">Auto-fill with</Typography>
          <Box
            component="img"
            src={gmailLogo}
            alt="Gmail"
            sx={styles.gmailLogo}
          />
        </Button>
      </Typography>
    </Box>
  );
}
