import { ContactViewContext } from "contexts/ContactView";
import {
  SelectMenuItem,
  TrackingColumnsContext,
} from "contexts/TrackingColumns";
import { useContext } from "react";

import { ADD_FILTER_BUTTON_CLICKED } from "features/Influencer/Tracking/events";
import {
  BuiltInContactPropertyNames,
  ContactViewProperty,
  CustomFilterColumns,
  FilterType,
} from "features/Influencer/Tracking/schema";

import { makeDeepCopy } from "./updateLocalState";

export const useTrackerFilters = () => {
  const {
    setSortedMenuItems,
    setSelectedColumns,
    selectedColumns,
    displayedColumns,
  } = useContext(TrackingColumnsContext);
  const { trackTable, selectedView } = useContext(ContactViewContext);

  const notSelectedColumns = displayedColumns?.filter(
    (column) => !selectedColumns?.find((x) => x.name === column.name),
  );

  const handleAddFilter = () => {
    trackTable(ADD_FILTER_BUTTON_CLICKED);
    const firstNonSelectedColumn = notSelectedColumns?.[0];
    setSelectedColumns((prev) => {
      const copy = makeDeepCopy(prev);
      copy.push(firstNonSelectedColumn);
      return copy;
    });
  };

  const onChangeFilter = async (
    oldColumn: SelectMenuItem,
    newColumn: SelectMenuItem,
    columnIndex: number,
  ) => {
    setSelectedColumns((prev) => {
      const copy = makeDeepCopy(prev);
      copy[columnIndex] = newColumn;
      return copy;
    });
  };

  const onChangeOption = async (
    column: SelectMenuItem,
    columnIndex: number,
    value: string[],
  ) => {
    if (!selectedView?.id) return;
    let prop = column?.contactViewProperty;
    if (!prop) {
      prop = {
        contactViewId: selectedView?.id,
        contactPropertyName: column?.name,
        filters: [],
      };
    }
    const type =
      prop.contactPropertyName &&
      CustomFilterColumns.includes(
        prop.contactPropertyName as BuiltInContactPropertyNames,
      )
        ? FilterType.customBuiltIn
        : FilterType.selection;

    const strValue = [...new Set(value?.map((x) => x.toString()))];
    const filter = {
      type,
      values: strValue,
    };

    const updatedCvp = makeDeepCopy(prop);
    updatedCvp.filters = [filter];
    setSelectedColumns((prev) => {
      const copy = makeDeepCopy(prev);
      copy[columnIndex] = { ...column, contactViewProperty: updatedCvp };
      return copy;
    });
    _updateSortMenuItems(updatedCvp);
  };

  const onRemoveFilter = async (
    column: SelectMenuItem,
    columnIndex: number,
  ) => {
    setSelectedColumns((prev) => {
      const copy = makeDeepCopy(prev);
      copy.splice(columnIndex, 1);
      return copy;
    });
    const prop = column?.contactViewProperty;
    if (prop) {
      _updateSortMenuItems({ ...prop, filters: [] });
    }
  };

  const getFilterValue = (column: SelectMenuItem) => {
    const value = column?.contactViewProperty?.filters?.[0]?.values || [];
    const strValue = [...new Set(value?.map((x) => x.toString()))];
    return strValue;
  };

  const _updateSortMenuItems = (updatedCvp: ContactViewProperty) => {
    setSortedMenuItems((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy?.findIndex(
        (x: SelectMenuItem) => x.contactViewProperty?.id === updatedCvp?.id,
      );
      if (index === 0 || index > -1) {
        copy[index] = { ...copy[index], contactViewProperty: updatedCvp };
      }
      return copy;
    });
  };

  return {
    handleAddFilter,
    onChangeFilter,
    onChangeOption,
    onRemoveFilter,
    getFilterValue,
    notSelectedColumns,
  };
};
