import { grey } from "@mui/material/colors";

const styles = {
  whiteFixedBackGround: (isSavedBrandOpen: boolean) => ({
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    pr: isSavedBrandOpen ? "410px" : "55px",
    background: "white",
  }),
  columnContainer: {
    overflowY: "auto",
    height: "calc(100vh - 70px)", // Leave space for navigation bar
    border: `1px solid ${grey[300]}`,
    backgroundColor: "white",
  },
  columnContainerMobile: {
    paddingBottom: 8,
  },
};

export default styles;
