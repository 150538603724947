import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import {
  AutogeneratedDraftsContext,
  TabPage,
} from "contexts/AutogenerateDrafts";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { OutreachDraft } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";
import { HEART_COLOR } from "styles/oneOffColor";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";
import { useDrafts } from "utils/useDrafts";

import PickCollectionDropdown from "./PickCollectionDropdown";
import SelectedBrand from "./SelectedBrand";
import styles from "./styles";

const REF_OFFSET = 5;

interface Props {
  endRef: any;
}

const DraftsBrandList = ({ endRef }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);
  const { setUpgradeDialogSource, setSubscription, subscription } =
    useContext(SubscriptionContext);

  const { tab } = useParams();
  const {
    isPolling,
    fetchDrafts,
    frozenIds,
    fetchLoading,
    pollForNewDrafts,
    setDraftsMap,
    setFetchLoading,
  } = useContext(AutogeneratedDraftsContext);
  const { getDrafts } = useDrafts();
  const drafts = getDrafts(tab);

  const [prevDrafts, setPrevDrafts] = useState<OutreachDraft[]>([]);

  const handleChange = (e: CustomEvent, value: TabPage) => {
    fetchDrafts(value);
    navigate(`/${routes.recommendations}/${value}${location.search}`);
  };

  const handleRefreshBrands = async () => {
    if (!currentOrg) return;

    if (
      subscription &&
      (subscription?.remainingRecommendationsRefresh || 0) <= 0
    ) {
      setUpgradeDialogSource("Hits Refreshing Recommendations Limit");
      return;
    }

    setFetchLoading(true);

    setDraftsMap((prev) => {
      const copy = makeDeepCopy(prev);
      setPrevDrafts(copy[TabPage.bento_recommendations]["drafts"]);
      copy[TabPage.bento_recommendations]["drafts"] = [];
      return copy;
    });

    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-drafts/refresh`,
        "POST",
      );
      pollForNewDrafts(TabPage.bento_recommendations, undefined, [], true);
      setSubscription((s) => {
        if (s) {
          return {
            ...s,
            remainingRecommendationsRefresh:
              s.remainingRecommendationsRefresh - 1,
          };
        } else {
          return s;
        }
      });
    } catch (error) {
      if (error?.message.includes("upgrade your account")) {
        setUpgradeDialogSource("Hits Refreshing Recommendations Limit");
        return;
      }
      setErrorAlert(error);
      setDraftsMap((prev) => {
        const copy = makeDeepCopy(prev);
        copy[TabPage.bento_recommendations]["drafts"] = prevDrafts;
        return copy;
      });
    }
  };

  return (
    <>
      <Grid item xs sx={isMobileScreen ? {} : styles.brandListContainer}>
        <Box>
          <Tabs value={tab} onChange={handleChange}>
            <Tab
              sx={styles.tab}
              value={TabPage.favorite_drafts}
              label={
                <Box component="span">
                  Saved{" "}
                  <Box
                    component="i"
                    sx={{ color: HEART_COLOR, ml: 0.5 }}
                    className="fa-solid fa-bookmark"
                  />
                </Box>
              }
            />
            <Tab
              sx={styles.tab}
              value={TabPage.bento_recommendations}
              label="Recommendations"
            />
          </Tabs>

          {tab === TabPage.favorite_drafts ? (
            <PickCollectionDropdown />
          ) : (
            <Typography sx={styles.subtitle} variant="body2">
              20 personalized email drafts to brands you'll love, all the time.
            </Typography>
          )}
        </Box>

        {drafts.map((draft: OutreachDraft, index: number) => (
          <SelectedBrand
            key={draft.bentoBrand.id}
            ref={drafts.length - REF_OFFSET === index ? endRef : null}
            bentoBrand={draft.bentoBrand}
            collectionId={draft?.savedBrandCollectionId}
          />
        ))}

        {(isPolling[tab as TabPage] || fetchLoading) &&
          (frozenIds?.length > 0 || drafts?.length === 0) && (
            <Grid sx={{ px: 2 }}>
              {Array.from({ length: frozenIds?.length || 10 }, (x, i) => (
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={80}
                  sx={{ my: 1 }}
                  key={i}
                />
              ))}
            </Grid>
          )}
        {!isPolling[tab as TabPage] &&
          !fetchLoading &&
          drafts?.length === 0 && (
            <Grid sx={{ px: 2 }}>
              No brands with autogenerated drafts on this list.
            </Grid>
          )}
      </Grid>
      {tab === TabPage.bento_recommendations &&
        !(isPolling[tab as TabPage] && drafts?.length === 0) && (
          <Grid item sx={styles.explanation}>
            {isPolling[tab as TabPage] ? (
              <>
                <LinearProgress sx={{ my: 1 }} />
                <Typography sx={{ fontSize: 12 }}>
                  Fetching all your new recommendations...
                </Typography>
              </>
            ) : (
              <>
                <Button
                  sx={styles.refreshButton}
                  disableElevation
                  size="small"
                  onClick={handleRefreshBrands}
                >
                  Refresh Recommendations
                  <Box
                    component="i"
                    className="fa-regular fa-refresh"
                    sx={{ ml: 1 }}
                  />
                </Button>
                <Typography sx={{ fontSize: 12 }}>
                  Adjust your <Link to={`/${routes.profile}`}>profile</Link> for
                  better fitting recommendations
                </Typography>
              </>
            )}
          </Grid>
        )}
    </>
  );
};

export default DraftsBrandList;
