import {
  Box,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { formatNumber } from "utils/string";
import { trackEvent } from "utils/tracking";
import { useCollection } from "utils/useCollection";

import BrandMetadata from "../DetailedBrandView/components/BrandMetadata";
import BrandIcons from "./BrandIcons";

const styles = {
  brandName: {
    fontSize: 14,
    fontWeight: 700,
  },
  followerCount: {
    fontSize: 12,
  },
  instagramUsername: {
    width: "max-content",
    fontSize: 12,
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  draft: {
    color: "error.main",
  },
  metadata: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "capitalize",
  },
  about: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
  },
};

interface Props {
  bentoBrand: BentoBrand;
  hideMetadata?: boolean;
}

export default function BrandTitle({ bentoBrand, hideMetadata }: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const wrapLength = isMobileScreen ? 20 : 50;
  const { isDraftBrand } = useCollection();
  const isDrafted = isDraftBrand(bentoBrand);

  return (
    <Grid item container xs>
      <Grid
        item
        xs={12}
        container
        gap={1}
        sx={{ mb: { xs: 0.5, sm: 0 } }}
        alignItems="center"
      >
        <Box sx={styles.brandName}>
          {bentoBrand?.brandName}
          {isDrafted && (
            <>
              ,{" "}
              <Box component="span" sx={styles.draft}>
                Draft
              </Box>
            </>
          )}
        </Box>
        {!hideMetadata && <BrandIcons bentoBrand={bentoBrand} />}
      </Grid>
      {!hideMetadata && (
        <Grid item xs={12} sx={{ my: 0.5 }}>
          <BrandMetadata selectedBrand={bentoBrand} />
        </Grid>
      )}

      <Grid item xs={12}>
        {(bentoBrand?.instagramUsername || bentoBrand.website) && (
          <Typography sx={styles.instagramUsername} component="div">
            {bentoBrand.instagramFollowerCount && (
              <Typography
                component="span"
                color="textSecondary"
                sx={styles.followerCount}
              >
                {formatNumber(bentoBrand.instagramFollowerCount)} followers{" "}
              </Typography>
            )}
            {bentoBrand.instagramUsername && (
              <Link
                target="_blank"
                href={`https://instagram.com/${bentoBrand?.instagramUsername}`}
                sx={{ width: "max-content" }}
                onClick={(e: CustomEvent) => {
                  e.stopPropagation();
                  trackEvent("Instagram Link Brand Card Clicked", {
                    "Instagram Username": bentoBrand.instagramUsername,
                    "Bento Brand ID": bentoBrand.id,
                  });
                }}
              >
                @{bentoBrand?.instagramUsername}
              </Link>
            )}
            {bentoBrand?.website && (
              <>
                {bentoBrand.instagramUsername && " | "}
                <Link
                  target="_blank"
                  href={`https://${bentoBrand?.website}`}
                  sx={{ width: "max-content" }}
                  onClick={(e: CustomEvent) => {
                    e.stopPropagation();
                    trackEvent("Website Brand Card Clicked", {
                      Website: bentoBrand.website,
                      "Bento Brand ID": bentoBrand.id,
                    });
                  }}
                >
                  {bentoBrand?.website?.length > wrapLength
                    ? `${bentoBrand?.website.substring(0, wrapLength)}...`
                    : bentoBrand.website}
                </Link>
              </>
            )}
          </Typography>
        )}
      </Grid>
      {!isMobileScreen && bentoBrand.aboutSummary && (
        <Grid item xs={12} sx={{ mt: 0.5 }}>
          <Typography color="textSecondary" variant="caption" sx={styles.about}>
            {bentoBrand.aboutSummary}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
