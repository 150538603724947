import { grey } from "@mui/material/colors";

const styles = {
  button: {
    p: 2,
    width: "100%",
    backgroundColor: `${grey[200]}`,
    border: `1px solid ${grey[300]}`,
    borderRadius: 2,
    my: 1,
    cursor: "pointer",
    "&:hover": {
      color: "black",
      backgroundColor: "primary.light",
    },
  },
  active: {
    backgroundColor: "primary.light",
    border: `1px solid ${grey[500]}`,
    color: "black",
  },
  form: {
    minWidth: { xs: "auto", md: 400 },
    my: 1,
  },
  noBorder: {
    ml: { xs: -2, md: 0 },
    fontSize: 12,
    maxWidth: 350,
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  templateName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "primary.main",
    fontSize: 14,
  },
  label: {
    color: grey[500],
  },
};

export default styles;
