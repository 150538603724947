import { Box } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { OutreachContact, PropertyType } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { ROW_MENU_OPENED } from "features/Influencer/Tracking/events";
import {
  BRAND_NAME_COLUMN,
  ContactViewProperty,
  EMAIL_COLUMN,
} from "features/Influencer/Tracking/schema";

import TableNotes from "./components/TableNotes";
import { getDefaultValue, renderCustomProperty } from "./helpers";
import styles from "./styles";

interface Props {
  property: ContactViewProperty;
  outreachContact: OutreachContact;
  setRowMenuEl: Dispatch<SetStateAction<HTMLElement | null>>;
  setContactToDelete: Dispatch<SetStateAction<number | undefined>>;
  onKeyDown: (e: CustomEvent) => void;
}
const DragAlongCell = ({
  property,
  outreachContact,
  setRowMenuEl,
  setContactToDelete,
  onKeyDown,
}: Props) => {
  const { trackTable, trackCell } = useContext(ContactViewContext);
  // To avoid rendering thousands of text fields at once, text fields are only
  // shown when this component is focused
  const [showTextField, setShowTextField] = useState<boolean>(false);

  const childRef = useRef();
  const navigate = useNavigate();

  const isEmailCell = property?.contactPropertyName === EMAIL_COLUMN;
  const isBrandCell = property?.contactPropertyName === BRAND_NAME_COLUMN;

  const getZIndex = () => {
    if (isEmailCell) return 2;
    return 0;
  };

  const style: CSSProperties = {
    zIndex: getZIndex(),
    left: isEmailCell ? 0 : undefined,
    position: isEmailCell ? "sticky" : "relative",
    backgroundColor: outreachContact.isPinnedForTracking ? "white" : "#fcfcfc",
  };

  const handleDetectRightClick = (e: CustomEvent) => {
    e.preventDefault();
    trackTable(ROW_MENU_OPENED, {
      "Outreach Contact ID": outreachContact?.id,
      "Outreach Contact Email": outreachContact?.email,
    });
    setRowMenuEl(e.currentTarget);
    setContactToDelete(outreachContact.id);
  };

  const goToThread = (
    url: string,
    name: string,
    openBlank?: boolean,
    extraProperties = {},
  ) => {
    if (property) {
      trackCell(property, outreachContact, {
        Action: name,
        Link: url,
        ...extraProperties,
      });
    }
    if (openBlank) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  const getFormattedValue = () => {
    if (outreachContact.id && outreachContact.id < 0) {
      if (isEmailCell) {
        return (
          <TableNotes
            notes=""
            outreachContact={outreachContact}
            type={PropertyType.shortText}
            property={property}
            ref={childRef}
            showTextField={showTextField}
          />
        );
      } else if (isBrandCell) {
        return getDefaultValue(
          property,
          outreachContact,
          goToThread,
          childRef,
          showTextField,
        );
      } else {
        return <Box sx={styles.disabled}></Box>;
      }
    } else if (property?.contactPropertyName) {
      return getDefaultValue(
        property,
        outreachContact,
        goToThread,
        childRef,
        showTextField,
      );
    } else if (property?.contactProperty) {
      return renderCustomProperty(
        property,
        outreachContact,
        childRef,
        showTextField,
      );
    }
  };

  const handleKeyDown = (e: CustomEvent) => {
    if (
      e.key !== "ArrowUp" &&
      e.key !== "ArrowDown" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Tab" &&
      childRef.current &&
      // @ts-ignore
      childRef.current.childRefKeyPressed
    ) {
      // @ts-ignore
      childRef.current.childRefKeyPressed(e);
    } else {
      onKeyDown(e);
    }
  };

  const handleBlur = (e: CustomEvent) => {
    setShowTextField(false);
    // @ts-ignore
    if (childRef.current && childRef.current.childRefOnBlur) {
      // @ts-ignore
      childRef.current.childRefOnBlur(e);
    }
  };

  const handleSelect = (e: CustomEvent) => {
    // @ts-ignore
    if (childRef.current && childRef.current.childRefClick) {
      // @ts-ignore
      childRef.current.childRefClick(e);
    }
  };

  return (
    <Box
      component="td"
      style={style}
      sx={styles.tc(!!outreachContact.id && outreachContact.id < 0)}
      tabIndex={
        (outreachContact.id && outreachContact.id > 0) ||
        isEmailCell ||
        isBrandCell
          ? 0
          : -1
      }
      onContextMenu={handleDetectRightClick}
      onClick={handleSelect}
      onFocus={() => {
        setShowTextField(true);
      }}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      key={`${outreachContact?.id}-${property?.id}`}
    >
      {getFormattedValue()}
    </Box>
  );
};

export default DragAlongCell;
