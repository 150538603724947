const styles = {
  root: {
    position: { xs: "relative", md: "relative" },
    maxWidth: { xs: "auto", md: 130 },
    zIndex: 20,
    minHeight: "100svh",
  },
};

export default styles;
