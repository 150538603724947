import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { grey, orange, red } from "@mui/material/colors";
import {
  OutreachContact,
  OutreachContactAutomatedStatus,
  OutreachContactStatus,
} from "schemas/dashboard";

import { formatEpochTimestamp } from "utils/time";

interface Props {
  outreachContact: OutreachContact;
}

const styles = {
  respondedChip: {
    fontSize: 11,
    backgroundColor: "primary.light",
    borderRadius: 2,
    border: `1px solid ${grey[300]}`,
    width: "fit-content",
  },
  bouncedChip: {
    fontSize: 11,
    backgroundColor: red[100],
    borderRadius: 2,
    border: `1px solid ${grey[300]}`,
    width: "fit-content",
  },
  automatedChip: {
    fontSize: 11,
    backgroundColor: orange[100],
    borderRadius: 2,
    border: `1px solid ${grey[300]}`,
    width: "fit-content",
  },
  infoIcon: {
    color: grey[500],
  },
};

const TableStatus = ({ outreachContact }: Props) => {
  return (
    <Box
      sx={{ m: 1, minWidth: "200px" }}
      display="flex"
      alignItems="center"
      gap={1}
    >
      {outreachContact.latestSequenceSentCount ? (
        <Typography variant="body2">
          {outreachContact.latestSequenceSentCount}/
          {outreachContact.latestSequenceTotalCount} Emails Sent{" "}
          <Tooltip title="This shows how many emails have been sent and how many are planned in total.">
            <Box
              component="i"
              className="fa-regular fa-circle-info"
              sx={styles.infoIcon}
            />
          </Tooltip>
        </Typography>
      ) : outreachContact.latestSequenceNextSendAt ? (
        <Typography variant="body2">
          Scheduled for{" "}
          {formatEpochTimestamp({
            epochTimestamp: outreachContact.latestSequenceNextSendAt,
            fullDate: true,
          })}
        </Typography>
      ) : (
        <></>
      )}
      {outreachContact.status === OutreachContactStatus.bounced ? (
        <Chip size="small" label="Bounced" sx={styles.bouncedChip} />
      ) : outreachContact.hasResponded ? (
        <Chip size="small" label="Responded" sx={styles.respondedChip} />
      ) : (
        outreachContact.automatedStatus ===
          OutreachContactAutomatedStatus.automated && (
          <Chip size="small" label="Auto-response" sx={styles.automatedChip} />
        )
      )}
    </Box>
  );
};

export default TableStatus;
