import { Box, Dialog, DialogTitle, Grid, IconButton } from "@mui/material";

import VideoEmbed from "components/VideoEmbed";

interface Props {
  link: string;
  open: boolean;
  handleClose: () => void;
  title: string;
  videoPreview?: string;
}
const VideoDialog = ({
  link,
  open,
  handleClose,
  title,
  videoPreview,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 750,
          },
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "secondary.light" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          {title}

          <IconButton onClick={handleClose}>
            <Box
              sx={{ fontSize: 18 }}
              component="i"
              className="fa-regular fa-xmark"
            />
          </IconButton>
        </Grid>
      </DialogTitle>

      <VideoEmbed linkPreview={videoPreview} link={link} />
    </Dialog>
  );
};

export default VideoDialog;
