import { grey } from "@mui/material/colors";

const styles = {
  root: {
    position: "absolute",
    width: "280px",
    height: "82px",
    textAlign: "center",
    top: "calc(50% - 62px)",
    right: "calc(50% - 140px)",
  },
  link: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  button: {
    mt: 1,
    height: 34,
    textTransform: "none",
    fontSize: 14,
    px: 1.5,
    color: grey[900],
    borderColor: grey[400],
  },
  wand: {
    mr: 1,
    color: "green.main",
  },
  gmailLogo: {
    height: "15px",
    ml: 1,
  },
};

export default styles;
