import { Button, Grid } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { OutreachContact, OutreachContactStatus } from "schemas/dashboard";
import { Map } from "schemas/functions";

import { ContactViewProperty } from "features/Influencer/Tracking/schema";

import { buttonStyles as styles } from "./styles";

interface Props {
  property: ContactViewProperty;
  outreachContact: OutreachContact;
  goToThread: (
    url: string,
    name: string,
    openBlank?: boolean,
    properties?: Map,
  ) => void;
}

const TableAction = ({ property, outreachContact, goToThread }: Props) => {
  const { selectedView } = useContext(ContactViewContext);
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);
  const {
    setOutreachContacts,
    updateOutreachContactDefaultValues,
    trackCell,
    setTotal,
  } = useContext(ContactViewContext);

  const isScheduled =
    !outreachContact.latestSequenceSentCount &&
    outreachContact.latestSequenceNextSendAt;

  const inProgress =
    !!outreachContact.latestSequenceSentCount &&
    outreachContact.latestSequenceTotalCount &&
    outreachContact.latestSequenceSentCount <
      outreachContact.latestSequenceTotalCount;

  let threadUrl = outreachContact?.lastGmailThreadId
    ? `${outreachContact?.id}/email/${outreachContact?.lastGmailThreadId}`
    : isScheduled
      ? `${outreachContact?.id}/scheduled`
      : `${outreachContact?.id}/email`;

  const handleRemove = () => {
    trackCell(property, outreachContact, {
      Action: "Tracking Table Action Pressed",
      button: "Remove",
    });
    if (outreachContact?.id && outreachContact.id > -1) {
      updateOutreachContactDefaultValues(outreachContact, { isArchived: true });
    }
    setOutreachContacts((prev) => {
      return prev.filter((c) => c.id !== outreachContact.id);
    });
    setTotal((prev) => prev - 1);
  };

  if (selectedView?.name === "Imports") {
    return (
      <Grid
        container
        justifyContent="center"
        sx={{ cursor: "pointer", my: 0.5, minWidth: "160px" }}
      >
        <Button
          tabIndex={-1}
          size="small"
          variant="text"
          color="secondary"
          onClick={handleRemove}
          sx={styles.smallButton}
          disableElevation
        >
          Remove
        </Button>
      </Grid>
    );
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ cursor: "pointer", my: 0.5, minWidth: "160px" }}
    >
      {!outreachContact.hasResponded &&
        outreachContact.bentoBrand?.id &&
        !!outreachContact.latestSequenceSentCount &&
        selectedView?.name !== "Collaboration" && (
          <Grid item container justifyContent="center" xs={12}>
            <Button
              tabIndex={-1}
              size="small"
              variant="text"
              color="info"
              onClick={() => {
                if (outreachContact.bentoBrand) {
                  handleOpenQuickSendIndividual(
                    outreachContact.bentoBrand.id,
                    "Tracking Page",
                    undefined,
                    true,
                  );
                }
              }}
              sx={styles.smallButton}
              disableElevation
            >
              Email New Contact
            </Button>
          </Grid>
        )}
      {(selectedView?.name === "Collaboration" ||
        selectedView?.name === "In Discussion" ||
        selectedView?.name === "Reach Out Later" ||
        !outreachContact.hasResponded) &&
        outreachContact.status !== OutreachContactStatus.bounced && (
          <Grid item container justifyContent="center" xs={12}>
            <Button
              tabIndex={-1}
              size="small"
              variant="text"
              color="secondary"
              onClick={() => {
                if (outreachContact.hasResponded) {
                  goToThread(
                    threadUrl,
                    "Tracking Table Action Pressed",
                    false,
                    { button: "Respond Button" },
                  );
                } else if (isScheduled || inProgress) {
                  goToThread(
                    threadUrl,
                    "Tracking Table Action Pressed",
                    false,
                    { button: "Send Now Button" },
                  );
                } else {
                  goToThread(
                    `${threadUrl}?display_followup_templates=${true}`,
                    "Tracking Table Action Pressed",
                    false,
                    { button: "Send Follow-up Button" },
                  );
                }
              }}
              sx={styles.smallButton}
              disableElevation
            >
              {outreachContact.hasResponded
                ? "Respond"
                : isScheduled || inProgress
                  ? "Send Now"
                  : "Send Follow-up"}
            </Button>
          </Grid>
        )}
    </Grid>
  );
};

export default TableAction;
