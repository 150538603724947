import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  Accordion as MuiAccordion,
  Pagination,
  Typography,
  styled,
} from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Email, GmailThread, MessageStatus } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";

import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";
import { getPageName } from "constants/trackingProps";
import AttachmentManager from "features/Influencer/ContactList/AttachmentManager";
import { TemplateContentType } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { generateColorFromString } from "utils/color";
import { sanitize } from "utils/sanitize";
import { formatEpochTimestamp } from "utils/time";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

interface PendingEmailsDraftProps {
  pendingEmails: Email[];
  setPendingEmails: React.Dispatch<SetStateAction<Email[]>>;
  setAlert: SetAlertType;
  gmailThread: GmailThread | undefined;
  setGmailThread: Dispatch<SetStateAction<GmailThread | undefined>>;
  setShowDraftEditor: Dispatch<SetStateAction<boolean>>;
}

export default function PendingEmailsDraft({
  pendingEmails,
  setPendingEmails,
  setAlert,
  setGmailThread,
  gmailThread,
  setShowDraftEditor,
}: PendingEmailsDraftProps) {
  const { getToken } = useAuth();
  const { outreachContactId: id } = useParams();
  const outreachContactId = Number(id);
  const { currentUser, currentOrg } = useContext(OrganizationUserContext);
  const {
    fetchContact,
    fetchEmailThreads,
    currentContactEmailThreads,
    setCurrentContactEmailThreads,
  } = useContext(OutreachContactsContext);
  const { currentDraftBody, setCurrentDraftBody } = useContext(
    OutreachTemplatesContext,
  );

  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const [page, setPage] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const pendingEmail = pendingEmails[page];

  const body = sanitize(pendingEmails[page]?.body || "");

  const editor = useEditor(
    {
      extensions: extensions(),
      content: body,
      onUpdate({ editor }) {
        setPendingEmails((prev) => {
          const copy = [...prev];
          copy[page] = {
            ...copy[page],
            isChanged: true,
            body: editor.getHTML(),
          };
          return copy;
        });
      },
    },
    [page],
  ) as TipTapEditor;

  const handleSavePendingEmail = async () => {
    try {
      setSaveLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/${pendingEmail?.id}`,
        "PATCH",
        {},
        {
          overrideBody: pendingEmails[page].body,
          overrideAttachment: pendingEmails[page].attachment,
        },
      );
      setAlert("Successfully saved changes!", "success");
    } catch (error) {
      setAlert(
        "Something went wrong, unable to save changes. Please refresh page and retry again.",
        "error",
      );
    } finally {
      setSaveLoading(false);
    }
  };

  const handleSendPendingEmail = async () => {
    try {
      setSendLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/${pendingEmail?.id}/send`,
        "POST",
        {},
        {
          overrideBody: pendingEmails[page].body,
          overrideAttachment: pendingEmails[page].attachment,
          sendFrom: getPageName(),
        },
      );
      decreasePendingEmailsCount();
      setShowDraftEditor(false);
      if (res.email.status === MessageStatus.COMPLETED) {
        setAlert("Successfully sent scheduled email!", "success");
        if (gmailThread?.messages && gmailThread?.messages?.length > 0) {
          const copy = makeDeepCopy(
            gmailThread.messages?.[gmailThread.messages?.length - 1],
          );
          copy["body"] = res.email.body;
          copy["attachment"] = res.email.attachment;
          setGmailThread((prev) => {
            if (prev) {
              return {
                ...prev,
                messages: [...(prev.messages || []), copy],
              };
            }

            return prev;
          });
        }
        fetchEmailThreads(outreachContactId, true);
        fetchContact(outreachContactId);
      } else {
        setAlert(
          "Email cancelled, most likely because you have exceeded follow up emails allowed or your contact has already responded",
          "success",
        );
      }
      resetEmailPagination(res.email);
    } catch (error) {
      setAlert(
        "Something went wrong, unable to send scheduled email. Please reload and retry again.",
        "error",
      );
    } finally {
      setSendLoading(false);
    }
  };

  const handleCancelMessage = async () => {
    try {
      setCancelLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/${pendingEmail?.id}/cancel`,
        "POST",
      );
      setAlert("Successfully cancelled followup email", "success");
      setShowDraftEditor(false);
      if (pendingEmails?.length === 0) {
        fetchContact(outreachContactId);
      } else {
        resetEmailPagination(pendingEmail);
      }
      decreasePendingEmailsCount();
    } catch (error) {
      setAlert(
        "Something went wrong, unable to cancel followup email. Please reload and retry again.",
        "error",
      );
    } finally {
      setCancelLoading(false);
    }
  };

  const resetEmailPagination = (email: Email) => {
    setPendingEmails((prev) => {
      const copy = [...prev];
      const index = copy.findIndex((x) => x.id === email.id);
      if (index > -1) {
        copy.splice(index, 1);
      }
      const newEmail = copy[0];
      editor.commands.setContent(newEmail?.body || "");
      return copy;
    });
    setPage(0);
  };

  const decreasePendingEmailsCount = () => {
    const copy = makeDeepCopy(currentContactEmailThreads);
    const index = copy?.findIndex(
      (thread: GmailThread) => thread.id === gmailThread?.id,
    );
    if (index > -1) {
      copy[index]["pendingEmailsCount"] = copy[index]["pendingEmailsCount"] - 1;
    }
    setCurrentContactEmailThreads(copy);
  };

  useEffect(() => {
    if (
      editor &&
      currentDraftBody?.length > 0 &&
      editor.getHTML() !== currentDraftBody
    ) {
      editor.commands.setContent(makeDeepCopy(currentDraftBody), true);
      setCurrentDraftBody("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDraftBody, editor?.getHTML()]);

  return (
    <Accordion
      disableGutters
      square
      expanded={true}
      elevation={0}
      sx={{
        width: "100%",
        borderTop: "none",
        borderBottom: "none",
      }}
    >
      <AccordionSummary sx={styles.summary}>
        <Grid container item xs={12}>
          <Grid item xs="auto">
            <Avatar
              sx={{
                background: generateColorFromString(currentUser?.email || ""),
                textTransform: "uppercase",
                mr: 1,
              }}
            >
              {currentUser?.name?.charAt(0) || currentUser?.email.charAt(0)}
            </Avatar>
          </Grid>

          <Grid item xs sx={styles.summaryText}>
            <Typography>
              {currentUser?.name || currentUser?.email}{" "}
              <Typography
                component="span"
                color="textSecondary"
                variant="caption"
                sx={{ ml: 1 }}
              >
                now
              </Typography>
            </Typography>

            <Typography color="textSecondary" sx={styles.snippet}>
              to {pendingEmails[0]?.toEmail}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Editor
            editor={editor}
            allowAttachment={true}
            openAttachmentManager={() => {
              setAttachmentDialogOpen(true);
            }}
            attachment={pendingEmail?.attachment}
            removeAttachment={() => {
              setPendingEmails((prev) => {
                const copy = [...prev];
                copy[page] = { ...copy[page], isChanged: true, attachment: "" };
                return copy;
              });
            }}
            allowImage={true}
            editorDecoratorBelowMenuBar={
              <Box sx={styles.scheduledAtContainer}>
                {pendingEmail.contentType === TemplateContentType.FOLLOWUP
                  ? "Follow-up scheduled "
                  : "Reply scheduled "}
                to send at:{" "}
                {formatEpochTimestamp({
                  epochTimestamp: pendingEmail?.sendAt,
                  fullDate: true,
                })}
              </Box>
            }
          />
          <Grid
            container
            justifyContent="flex-end"
            item
            xs={12}
            gap={1}
            sx={{ my: 2 }}
          >
            <LoadingButton
              loading={cancelLoading}
              onClick={handleCancelMessage}
            >
              Delete
            </LoadingButton>
            <LoadingButton
              onClick={handleSendPendingEmail}
              loading={sendLoading}
            >
              Send Now
            </LoadingButton>
            <LoadingButton
              disableElevation
              variant="contained"
              onClick={handleSavePendingEmail}
              loading={saveLoading}
            >
              Save Changes
            </LoadingButton>
          </Grid>

          <AttachmentManager
            enteredSelection={(attachment: string) =>
              setPendingEmails((prev) => {
                const copy = [...prev];
                copy[page] = { ...copy[page], isChanged: true, attachment };
                return copy;
              })
            }
            open={attachmentDialogOpen}
            setOpen={setAttachmentDialogOpen}
          />
        </Box>

        <Pagination
          size="small"
          count={pendingEmails?.length}
          page={page + 1}
          onChange={handleChange}
        />
      </AccordionDetails>
    </Accordion>
  );
}
