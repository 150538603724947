import { Box, Chip, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  OutreachContactsContext,
  ScheduledType,
} from "contexts/OutreachContacts";
import { useContext } from "react";

const styles = {
  scheduledChip: { fontSize: 12, borderRadius: 2, color: grey[800], mb: 0.5 },
  selected: {
    backgroundColor: "primary.main",
    color: "white",
    "&:hover": {
      backgroundColor: "primary.dark",
    },
  },
};

export default function ScheduledTabs() {
  const { fetchContactsParams, handleFetchContacts, scheduledTotals } =
    useContext(OutreachContactsContext);

  const handleClick = (scheduledType: ScheduledType) => {
    const updatedParams = { ...fetchContactsParams, scheduledType, page: 1 };
    handleFetchContacts(updatedParams);
  };

  return (
    <Grid item container gap={1} xs={12}>
      <Grid item xs="auto">
        <Chip
          clickable
          onClick={() => {
            handleClick(ScheduledType.outreach);
          }}
          sx={[
            styles.scheduledChip,
            fetchContactsParams.scheduledType === ScheduledType.outreach &&
              styles.selected,
          ]}
          label={
            <>
              Outreach (
              {scheduledTotals[ScheduledType.outreach] !== null
                ? scheduledTotals[ScheduledType.outreach]
                : "-"}
              ){" "}
              <Box
                component="i"
                sx={{ ml: 0.5 }}
                className="fa-regular fa-paper-plane"
              />
            </>
          }
        />
      </Grid>
      <Grid item xs="auto">
        <Chip
          clickable
          onClick={() => {
            handleClick(ScheduledType.followUp);
          }}
          sx={[
            styles.scheduledChip,
            fetchContactsParams.scheduledType === ScheduledType.followUp &&
              styles.selected,
          ]}
          label={
            <>
              Follow-ups (
              {scheduledTotals[ScheduledType.followUp] !== null
                ? scheduledTotals[ScheduledType.followUp]
                : "-"}
              ){" "}
              <Box
                component="i"
                sx={{ ml: 0.5 }}
                className="fa-regular fa-paper-plane"
              />
            </>
          }
        />
      </Grid>
    </Grid>
  );
}
