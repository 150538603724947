import { Box } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import {
  QuickSendScheduleContext,
  ScheduleTimeDialogSource,
} from "contexts/QuickSendSchedule";
import moment from "moment";
import { useContext } from "react";

import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import { HandleSendEmail } from "features/Influencer/ContactList/schema";
import { DISPLAY_SCHEDULED_DIALOG } from "utils/localStorage";
import { makeDeepCopy } from "utils/updateLocalState";

interface BusinessHourDialogProps {
  handleSendEmail: HandleSendEmail;
}

export default function BusinessHourDialog({
  handleSendEmail,
}: BusinessHourDialogProps) {
  const { setConditions } = useContext(QuickSendDrawerContext);
  const {
    openBusinessHourDialog,
    setOpenBusinessHourDialog,
    setScheduleDialogSource,
  } = useContext(QuickSendScheduleContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  return (
    <ConfirmDialog
      header={
        <>
          <Box
            component="i"
            className="fa-regular fa-triangle-exclamation"
            sx={{ color: "warning.main", mr: 1 }}
          />
          Hey! We noticed it's {moment().format("LT")}
        </>
      }
      subheader={
        <>
          Brands are more likely to respond when you email them during business
          hours. Do you want to schedule your email instead?
        </>
      }
      open={openBusinessHourDialog}
      handleClose={() => setOpenBusinessHourDialog(false)}
      handleConfirm={() => {
        setScheduleDialogSource(ScheduleTimeDialogSource.BUSINESS_HOUR);
        setOpenBusinessHourDialog(false);
      }}
      handleCancel={() => {
        setOpenBusinessHourDialog(false);
        setConditions((prev) => {
          const copy = makeDeepCopy(prev);
          copy.skipsBusinessHour = true;
          handleSendEmail(null, copy);
          return copy;
        });
        localStorage.setItem(
          `${DISPLAY_SCHEDULED_DIALOG}-${currentOrg?.id}`,
          "true",
        );
      }}
      cancelText="No, send anyway"
      confirmText="Yes, Schedule"
    />
  );
}
