import {
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { PreviewMessage } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface SelectTemplateProps {
  preview: PreviewMessage;
  setPreviewMessages: Dispatch<SetStateAction<PreviewMessage[]>>;
  index: number;
  selectedTemplates: { [key in number]: Template | null };
  setSelectedTemplates: Dispatch<
    SetStateAction<{ [key in number]: Template | null }>
  >;
}

export default function SelectDraftTemplate({
  preview,
  setPreviewMessages,
  index,
  selectedTemplates,
  setSelectedTemplates,
}: SelectTemplateProps) {
  const isFollowUpEmail = index > 0;
  const { emailSettings } = useContext(OrganizationUserContext);
  const [selectedTemplateValue, setSelectedTemplateValue] =
    useState<string>("");
  const { outreachTemplates, followupTemplates, setCreateTemplateDialogOpen } =
    useContext(OutreachTemplatesContext);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const options =
    preview.contentType === TemplateContentType.INBOX
      ? outreachTemplates
      : followupTemplates;

  const getTemplateValue = (t: Template) => {
    if (t.emailTemplateId) {
      return `${t.name}-${t.emailTemplateId}`;
    } else if (t.id) {
      return `${t.name}-${t.id}`;
    } else {
      return `${t.name}`;
    }
  };

  const onChangeTemplate = (e: CustomEvent) => {
    const value = e.target.value;
    if (value === "Add Template +") {
      return;
    }

    trackEvent("Template Changed", {
      "Template Name": value,
      Source: "Compose Email",
    });

    setSelectedTemplateValue(value);

    if (preview.isEdited) {
      setConfirmDialogOpen(true);
      return;
    }

    handleTemplateChange(value);
  };

  const handleTemplateChange = (value: string) => {
    let template: Template | null = null;
    for (const t of options) {
      const templateValue = getTemplateValue(t);
      if (value === templateValue) {
        template = t;
        break;
      }
    }
    setSelectedTemplates((prev) => {
      const copy = { ...prev };
      if (template) {
        copy[index] = template;
      } else {
        copy[index] = null;
      }
      return copy;
    });
    setPreviewMessages((prev: PreviewMessage[]) => {
      const copy = [...prev];
      if (template) {
        copy[index] = {
          templateId: template.id,
          templateName: template.name,
          body: template.body || copy[index].body,
          subject: template.subject,
          attachment: template.attachment,
          contentType:
            index === 0
              ? TemplateContentType.INBOX
              : TemplateContentType.FOLLOWUP,
        };
      } else {
        copy[index] = {
          body: "",
          subject: "",
          contentType:
            index === 0
              ? TemplateContentType.INBOX
              : TemplateContentType.FOLLOWUP,
        };
      }
      return copy;
    });
  };

  const handleTrackAddTemplate = () => {
    trackEvent("Add Template Button Clicked", {
      "Clicked From": "Select Template Compose Component",
    });
    setCreateTemplateDialogOpen(true);
  };

  const handleGenerateTemplate = () => {
    trackEvent("Generate Template Button Clicked", {
      Source: "Compose Email",
    });
    setCreateTemplateDialogOpen(true);
  };

  const hasTemplateSettings =
    emailSettings?.emailSettingTemplates &&
    emailSettings?.emailSettingTemplates?.length > 0;
  const presetTemplate = hasTemplateSettings
    ? emailSettings?.emailSettingTemplates?.find((x) => x.emailOrder === index)
    : null;

  const getSelectValue = () => {
    const currentTemplate = selectedTemplates[index];

    return currentTemplate ? getTemplateValue(currentTemplate) : "";
  };

  const getTemplateName = (template: Template) => {
    return template?.name || template.subject;
  };

  const renderTemplateOption = () => {
    if (options?.length <= 0) {
      return (
        <Button
          variant="contained"
          size="small"
          onClick={handleGenerateTemplate}
          sx={{ height: 25, textTransform: "none" }}
          disableElevation
        >
          Generate {isFollowUpEmail ? "Follow-Up" : "Template"}
        </Button>
      );
    } else {
      return (
        <Select
          onChange={onChangeTemplate}
          value={getSelectValue()}
          displayEmpty
          size="small"
          sx={[
            styles.templateSelect,
            styles.noSelectBorder,
            styles.templateWidth,
          ]}
        >
          <MenuItem
            onClick={handleTrackAddTemplate}
            value="add-template-+"
            sx={{ color: "secondary.main" }}
          >
            <em>Add Template +</em>
          </MenuItem>
          <MenuItem value="">
            <em>No Template</em>
          </MenuItem>
          {options?.map((template: Template, index: number) => (
            <MenuItem
              key={index}
              value={getTemplateValue(template)}
              sx={styles.templateMenuItem}
            >
              <Box sx={styles.templateMenuName(template.isAutogenerated)}>
                {getTemplateName(template)}
              </Box>
              {presetTemplate &&
                template?.id === presetTemplate?.templateId && (
                  <Chip size="small" sx={styles.chip} label="Last Used" />
                )}
            </MenuItem>
          ))}
        </Select>
      );
    }
  };

  return (
    <Grid container justifyContent="space-between" sx={{ pr: 1, mt: 1 }}>
      <Box sx={styles.subjectWrapper}>
        <Typography component="span" sx={[styles.annotation, { pt: "2px" }]}>
          Template:
        </Typography>
        {renderTemplateOption()}
      </Box>

      <ConfirmDialog
        header={`Are you sure?`}
        subheader={
          "Your changes will be overwritten by the new template. Are you sure you want to change templates?"
        }
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={() => {
          handleTemplateChange(selectedTemplateValue);
          setConfirmDialogOpen(false);
        }}
      />
    </Grid>
  );
}
