import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { GmailScope, UserIntegration } from "schemas/dashboard";
import { Map } from "schemas/functions";

import GoogleConnect from "features/Settings/GoogleConnect";

interface IProps {
  openIntegrationDialog: boolean;
  setOpenIntegrationDialog: (newOpen: boolean) => void;
  emailHealth: boolean;
  userIntegrations: UserIntegration[];
  scopes: Map | null;
  onSuccessAction?: () => Promise<void>;
  integrationDialogText?: string;
  title?: string;
}

export default function ConnectGmailDialog({
  openIntegrationDialog,
  setOpenIntegrationDialog,
  emailHealth,
  userIntegrations,
  scopes,
  onSuccessAction,
  integrationDialogText,
  title = "Connect your Gmail account to send emails",
}: IProps) {
  return (
    <Dialog
      maxWidth="sm"
      open={openIntegrationDialog}
      onClose={() => setOpenIntegrationDialog(false)}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {emailHealth ? (
          <>
            <Typography>Success! You are connected.</Typography>
          </>
        ) : (
          <>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography>
                  {!!integrationDialogText ? (
                    integrationDialogText
                  ) : (
                    <>
                      {userIntegrations.length === 0 ? (
                        <>
                          You must allow access so we can send emails and
                          schedule followup emails on your behalf ✨ You can
                          revoke your access at any time.
                        </>
                      ) : userIntegrations &&
                        userIntegrations.length >= 1 &&
                        scopes !== null &&
                        (!scopes[GmailScope.SEND] ||
                          !scopes[GmailScope.READ]) ? (
                        <>
                          <Typography
                            gutterBottom
                            sx={{ color: "warning.main" }}
                            variant="body2"
                          >
                            Insufficient permissions granted to Bento
                          </Typography>
                          In order for you to send emails you must provide
                          additional access. Sign in with Google and select:{" "}
                          <br />
                          {!scopes[GmailScope.SEND] && (
                            <Typography>
                              * Send Email on your behalf.
                            </Typography>
                          )}
                          {!scopes[GmailScope.READ] && (
                            <Typography>
                              * View your email messages and settings. We use
                              this to accurately schedule follow ups.
                            </Typography>
                          )}
                        </>
                      ) : (
                        <>
                          You must re-connect to Gmail so we can send emails and
                          schedule followup emails on your behalf ✨ You can
                          revoke your access at any time.
                        </>
                      )}
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <GoogleConnect onSuccessAction={onSuccessAction} />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {userIntegrations.length === 0 ? (
          <Button
            color="secondary"
            onClick={() => setOpenIntegrationDialog(false)}
          >
            Close
          </Button>
        ) : (
          <Button onClick={() => setOpenIntegrationDialog(false)}>Done</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
