import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ContactProperty, PropertyType, TitleMap } from "schemas/dashboard";

import {
  BuiltInContactPropertyNames,
  BuiltInFilterTypes,
  BuiltInFilters,
  ContactViewName,
  ContactViewProperty,
  FiltersType,
  PropertyFilter,
  PropertySortDirection,
  SelectOption,
} from "features/Influencer/Tracking/schema";
import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { AlertContext } from "./Alert";
import { ContactViewContext } from "./ContactView";
import { ContactViewSearchContext } from "./ContactViewSearch";
import { OrganizationUserContext } from "./Organization";

export type CreateColumnBody = {
  contactPropertyId?: number;
  contactPropertyName?: string;
};

export type UpdateColumnBody = {
  name?: string;
  filter?: PropertyFilter;
  sortDirection?: PropertySortDirection | null;
};

export enum ColumnDialog {
  add = "add",
  edit = "edit",
}

export type SelectMenuItem = {
  name: string;
  id?: number;
  checked: boolean;
  title: string;
  type?: PropertyType;
  selectOptions?: SelectOption[];
  contactViewProperty?: ContactViewProperty;
};

interface TrackingColumnsContextInterface {
  handleHideColumn: (propertyId: number) => void;
  handleCreateColumn: (
    data: CreateColumnBody,
    refetch?: boolean,
  ) => Promise<ContactViewProperty>;
  handleUpdateColumn: (
    prop: ContactViewProperty,
    data: UpdateColumnBody,
    refetchView?: boolean,
  ) => void;
  dialogType: ColumnDialog | null;
  setDialogType: Dispatch<SetStateAction<ColumnDialog | null>>;
  sortedMenuItems: SelectMenuItem[];
  setSortedMenuItems: Dispatch<SetStateAction<SelectMenuItem[]>>;
  contactProperties: ContactProperty[];
  setContactProperties: Dispatch<SetStateAction<ContactProperty[]>>;
  getTitleForHeader: (title?: string) => string;
  getContactProperties: () => void;
  displayedColumns: SelectMenuItem[];
  setDisplayedColumns: Dispatch<SetStateAction<SelectMenuItem[]>>;
  selectedColumns: SelectMenuItem[];
  setSelectedColumns: Dispatch<SetStateAction<SelectMenuItem[]>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  handleHideColumn: defaultContextMissingFunction,
  handleCreateColumn: defaultContextMissingFunction,
  handleUpdateColumn: defaultContextMissingFunction,
  dialogType: null,
  setDialogType: defaultContextMissingFunction,
  sortedMenuItems: [],
  setSortedMenuItems: defaultContextMissingFunction,
  contactProperties: [],
  setContactProperties: defaultContextMissingFunction,
  getTitleForHeader: defaultContextMissingFunction,
  getContactProperties: defaultContextMissingFunction,
  displayedColumns: [],
  setDisplayedColumns: defaultContextMissingFunction,
  selectedColumns: [],
  setSelectedColumns: defaultContextMissingFunction,
};

const TrackingColumnsContext =
  createContext<TrackingColumnsContextInterface>(defaultInterface);

interface TrackingColumnsProviderProps {
  children: React.ReactNode;
}
const TrackingColumnsProvider = ({
  children,
}: TrackingColumnsProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);
  const { selectedView, setSelectedView } = useContext(ContactViewContext);
  const { contactViewParams, handleSearch, setViewLoading } = useContext(
    ContactViewSearchContext,
  );
  const [dialogType, setDialogType] = useState<ColumnDialog | null>(null);

  const builtIns: string[] = Object.values(BuiltInContactPropertyNames);
  const [contactProperties, setContactProperties] = useState<ContactProperty[]>(
    [],
  );
  const [sortedMenuItems, setSortedMenuItems] = useState<SelectMenuItem[]>([]);
  const [displayedColumns, setDisplayedColumns] = useState<SelectMenuItem[]>(
    [],
  );
  const [selectedColumns, setSelectedColumns] = useState<SelectMenuItem[]>([]);

  const getTitleForHeader = (title?: string) => {
    if (!title) return "";
    if (title in TitleMap) {
      return TitleMap[title];
    } else {
      return title;
    }
  };

  const handleCreateColumn = async (
    data: CreateColumnBody,
    refetch?: boolean,
  ) => {
    try {
      setViewLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-view-properties`,
        "POST",
        {},
        { contactViewId: selectedView?.id, ...data },
      );

      if (refetch) {
        handleSearch({ query: contactViewParams.query, page: 1 });
      } else {
        const index = selectedView?.contactViewProperties?.findIndex(
          (cvp) => cvp.id === res.contactViewProperty.id,
        );

        setSelectedView((prev) => {
          const copy = makeDeepCopy(prev);
          if (index !== undefined && index > -1) {
            copy["contactViewProperties"].splice(index, 1);
          }
          copy["contactViewProperties"].push(res.contactViewProperty);
          return copy;
        });
      }

      const menuIndex = sortedMenuItems?.findIndex(
        (menuItem) =>
          menuItem?.contactViewProperty?.id === res.contactViewProperty.id,
      );

      if (menuIndex !== undefined && menuIndex > -1) {
        setSortedMenuItems((prev) => {
          const copy = makeDeepCopy(prev);
          copy[menuIndex]["contactViewProperty"] = res.contactViewProperty;
          copy[menuIndex]["checked"] =
            res?.contactViewProperty?.displayOrder > 0;
          return copy;
        });
      }
      return res.contactViewProperty;
    } catch (error) {
      setErrorAlert(error);
      setViewLoading(false);
    } finally {
      setViewLoading(false);
    }
  };

  const handleUpdateColumn = async (
    contactViewProperty: ContactViewProperty,
    updatedData: UpdateColumnBody,
    refetchView = false,
  ) => {
    const data = updatedData;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-view-properties/${contactViewProperty.id}`,
        "PUT",
        {},
        { ...data, displayOrder: contactViewProperty.displayOrder },
      );
      if (refetchView) {
        // Refetch view for new rows on table after filter is done
        handleSearch({ query: contactViewParams.query, page: 1 });
      } else {
        setViewLoading(false);
      }
    } catch (error) {
      setErrorAlert(error);
      setViewLoading(false);
    }
  };

  const handleHideColumn = async (propertyId: number) => {
    setViewLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-view-properties/${propertyId}`,
        "DELETE",
      );

      setSelectedView((prev) => {
        const copy = makeDeepCopy(prev);
        const index = copy?.contactViewProperties?.findIndex(
          (prop: ContactViewProperty) => prop?.id === propertyId,
        );
        if (res?.contactViewProperty && index !== -1) {
          copy["contactViewProperties"][index] = res?.contactViewProperty;
        } else if (!res?.contactViewProperty && index !== -1) {
          copy["contactViewProperties"].splice(index, 1);
        }
        return copy;
      });

      if (res?.contactViewProperty?.id) {
        const menuIndex = sortedMenuItems?.findIndex(
          (menuItem) => menuItem?.contactViewProperty?.id === propertyId,
        );
        if (menuIndex !== undefined && menuIndex > -1) {
          setSortedMenuItems((prev) => {
            const copy = makeDeepCopy(prev);
            copy[menuIndex]["contactViewProperty"] = res.contactViewProperty;
            copy[menuIndex]["checked"] =
              res?.contactViewProperty?.displayOrder > 0;
            return copy;
          });
        }
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setViewLoading(false);
    }
  };

  const getContactProperties = async () => {
    if (!currentOrg?.id) {
      return;
    }
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-properties`,
      );
      setContactProperties(res.contactProperties);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const getSortedMenuItems = (contactProperties: ContactProperty[]) => {
    const transformed: SelectMenuItem[] = [];
    for (const name of builtIns) {
      const cvp = selectedView?.contactViewProperties?.find(
        (x) => x.contactPropertyName === name,
      );
      const checked = Boolean(
        cvp && cvp?.displayOrder !== undefined && cvp?.displayOrder > 0,
      );
      transformed?.push({
        name,
        checked,
        title: getTitleForHeader(name),
        type: name in BuiltInFilterTypes ? BuiltInFilterTypes[name] : undefined,
        selectOptions: name in BuiltInFilters ? BuiltInFilters[name] : [],
        contactViewProperty: cvp,
      });
    }
    for (const prop of contactProperties) {
      const cvp = selectedView?.contactViewProperties?.find(
        (x) => x.contactPropertyId === prop?.id,
      );
      const checked = Boolean(
        cvp && cvp?.displayOrder !== undefined && cvp?.displayOrder > 0,
      );
      let selectOptions = prop?.selectOptions;
      if (prop?.type === PropertyType.checkbox && !selectOptions) {
        selectOptions = [
          {
            name: "true",
          },
          {
            name: "false",
          },
        ];
      }
      transformed?.push({
        name: prop?.name,
        id: prop?.id,
        checked,
        title: getTitleForHeader(prop.name),
        type: prop?.type,
        selectOptions,
        contactViewProperty: cvp,
      });
    }
    const sorted = transformed.sort((a, b) => a.title.localeCompare(b.title));

    let displayedColumns = sorted?.filter(
      (column) => column?.type && FiltersType?.includes(column.type),
    );
    if (selectedView?.name !== ContactViewName.ALL) {
      displayedColumns =
        displayedColumns?.filter(
          (column) =>
            column?.contactViewProperty?.contactProperty?.automatedProperty !==
            "stage",
        ) || [];
    }

    const selectedColumns = displayedColumns?.filter(
      (column) =>
        (column?.contactViewProperty?.filters?.[0]?.values || [])?.length > 0,
    );
    setSelectedColumns(selectedColumns);
    setDisplayedColumns(displayedColumns);
    return sorted;
  };

  useEffect(() => {
    const sortedMenuItems = getSortedMenuItems(contactProperties);
    setSortedMenuItems(sortedMenuItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedView?.id,
    selectedView?.contactViewProperties?.length,
    contactProperties?.length,
  ]);

  return (
    <TrackingColumnsContext.Provider
      value={{
        handleHideColumn,
        handleCreateColumn,
        handleUpdateColumn,
        dialogType,
        setDialogType,
        sortedMenuItems,
        setSortedMenuItems,
        setContactProperties,
        getTitleForHeader,
        contactProperties,
        getContactProperties,
        displayedColumns,
        setDisplayedColumns,
        selectedColumns,
        setSelectedColumns,
      }}
    >
      {children}
    </TrackingColumnsContext.Provider>
  );
};

export { TrackingColumnsProvider, TrackingColumnsContext };
