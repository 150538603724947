import { grey } from "@mui/material/colors";

const styles = {
  openedColumn: {
    width: { xs: "inherit", md: "100px", lg: "150px" },
    px: 0.5,
    textAlign: { xs: "right", md: "center" },
  },
  openedColumnTracking: {
    px: 1,
    py: 0.5,
    minWidth: "200px",
  },
  openedColumnIcon: { color: grey[500], ml: 0.5 },
  openedColumnGrey: { color: grey[500], fontSize: 11 },
  disabledText: { color: grey[400], fontSize: 11 },
};

export default styles;
