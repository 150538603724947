import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";
import { routes } from "schemas/routes";
import { v4 as uuidv4 } from "uuid";

import BentoBrandsAutocomplete from "components/BentoBrandsAutocomplete";
import LocationAutocomplete from "components/LocationAutocomplete";
import { UserLocation } from "components/LocationAutocomplete/schema";
import ProductsAutocomplete from "components/ProductsAutocomplete";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface RecommendationFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
  isProfilePage?: boolean;
}

const RecommendationForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
  isProfilePage = false,
}: RecommendationFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [brandSizeValidation, setBrandSizeValidation] = useState<
    string | undefined
  >();
  const [pastBrandValidation, setPastBrandValidation] = useState<
    string | undefined
  >();
  const [dreamBrandValidation, setDreamBrandValidation] = useState<
    string | undefined
  >();
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(`/${routes.categoryOnboarding}${location.search}`);
  };

  const handleCheckboxChange = (e: CustomEvent) => {
    const { name, checked } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    const exists = !!copy.preferredBrandSize?.find(
      (size: string) => size === name,
    );
    if ((exists && checked) || (!exists && !checked)) {
      return;
    }
    if (!copy.preferredBrandSize) {
      copy.preferredBrandSize = [];
    }
    if (checked) {
      copy.preferredBrandSize = [...copy.preferredBrandSize, name];
    } else {
      copy.preferredBrandSize = copy.preferredBrandSize.filter(
        (size: string) => size !== name,
      );
    }
    if (copy.preferredBrandSize.length > 0) {
      setBrandSizeValidation(undefined);
    }
    setOnboardingForm(copy);
  };

  const addLocation = () => {
    trackEvent("Onboarding Form - Add Location Button Pressed");
    setOnboardingForm((prev) => {
      const copy = {
        ...prev,
        secondaryLocations: [
          ...(prev.secondaryLocations || []),
          { id: uuidv4() },
        ],
      };
      return copy;
    });
  };

  const removeLocation = (id: string | undefined) => {
    setOnboardingForm((prev) => {
      const copy = {
        ...prev,
        secondaryLocations: prev.secondaryLocations.filter(
          (location: UserLocation) => location.id !== id,
        ),
      };
      return copy;
    });
  };

  const handleLocationChange = (
    newLocation: UserLocation,
    id: string | undefined,
  ) => {
    setOnboardingForm((prev) => {
      const copy = { ...prev };
      copy.secondaryLocations = copy.secondaryLocations.map(
        (location: UserLocation) => {
          if (location.id === id) {
            return {
              ...newLocation,
              id,
            };
          }
          return location;
        },
      );
      return copy;
    });
  };

  const updateWishlistBrands = (newBrands: string[]) => {
    setOnboardingForm((prev) => {
      return {
        ...prev,
        wishlistBrands: newBrands,
      };
    });
    if (newBrands.length > 0) {
      setDreamBrandValidation(undefined);
    }
  };

  const updatePastBrands = (newBrands: string[]) => {
    setOnboardingForm((prev) => {
      return {
        ...prev,
        brands: newBrands,
      };
    });
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    if (
      !onboardingForm.preferredBrandSize ||
      onboardingForm.preferredBrandSize.length === 0
    ) {
      setBrandSizeValidation("Please fill out this field.");
      return;
    }

    if (
      !onboardingForm.wishlistBrands ||
      onboardingForm.wishlistBrands.length === 0
    ) {
      setDreamBrandValidation("Please fill out this field.");
      return;
    }

    saveOnboardingForm(onboardingForm, "recommendations");
  };

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    if (name === "pastBrandValidation") {
      setPastBrandValidation(undefined);
      copy[name] = value === "true";
    }
    setOnboardingForm(copy);
  };

  useEffect(() => {
    if (
      onboardingForm?.brands?.length > 0 &&
      !onboardingForm?.pastBrandValidation
    ) {
      setOnboardingForm((prev) => ({
        ...prev,
        pastBrandValidation: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingForm?.brands?.length]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-bookmark"
          />
          Let us tailor your recommendations
        </Typography>

        <Grid sx={styles.fieldContainer}>
          <FormHelperText error={!!brandSizeValidation} sx={styles.helperText}>
            Preferred brand size for outreach *
          </FormHelperText>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  icon={<Box component="i" className="fa-regular fa-square" />}
                  checkedIcon={
                    <Box component="i" className="fa-solid fa-square-check" />
                  }
                />
              }
              name="small"
              onChange={handleCheckboxChange}
              checked={!!onboardingForm?.preferredBrandSize?.includes("small")}
              label="Smaller, niche brands (more likely to respond)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  icon={<Box component="i" className="fa-regular fa-square" />}
                  checkedIcon={
                    <Box component="i" className="fa-solid fa-square-check" />
                  }
                />
              }
              name="large"
              onChange={handleCheckboxChange}
              checked={!!onboardingForm?.preferredBrandSize?.includes("large")}
              label="Larger, well-known brands (less likely to respond)"
            />
          </FormGroup>
          {brandSizeValidation && (
            <FormHelperText error={!!brandSizeValidation}>
              {brandSizeValidation}
            </FormHelperText>
          )}
        </Grid>

        {isProfilePage && (
          <Grid sx={styles.fieldContainer}>
            <FormHelperText sx={styles.helperText}>
              Locations you frequently travel to (for potential brand
              partnerships)
            </FormHelperText>
            <Grid container rowSpacing={0.5} sx={{ mb: 0.5 }}>
              {onboardingForm.secondaryLocations?.map(
                (location: UserLocation) => (
                  <Grid
                    item
                    alignItems="center"
                    container
                    columnGap={0.5}
                    xs={12}
                    key={location.id}
                  >
                    <Grid item xs>
                      <LocationAutocomplete
                        handleLocationChange={(newLocation: UserLocation) => {
                          handleLocationChange(newLocation, location.id);
                        }}
                        userLocation={location}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <IconButton
                        onClick={() => {
                          removeLocation(location.id);
                        }}
                        sx={styles.removeButton}
                      >
                        <Box
                          component="i"
                          className="fa-regular fa-circle-xmark"
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                ),
              )}
            </Grid>

            <Button size="small" onClick={addLocation} variant="outlined">
              Add{" "}
              <Box component="i" className="fa-solid fa-add" sx={{ ml: 1 }} />
            </Button>
          </Grid>
        )}

        {!isProfilePage && (
          <Grid sx={styles.fieldContainer}>
            <FormHelperText error={!!pastBrandValidation}>
              Have you partnered with any brands before?
            </FormHelperText>
            <FormControl error={!!pastBrandValidation}>
              <FormLabel id="past-brand-validation"></FormLabel>
              <RadioGroup
                onChange={handleChange}
                name="pastBrandValidation"
                row
                aria-labelledby="past-brand-validation"
                value={
                  onboardingForm?.pastBrandValidation === true
                    ? "true"
                    : onboardingForm?.pastBrandValidation === false
                      ? "false"
                      : ""
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              {pastBrandValidation && (
                <FormHelperText sx={{ ml: 0 }}>
                  {pastBrandValidation}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {(onboardingForm?.pastBrandValidation === true || isProfilePage) && (
          <Grid sx={styles.fieldContainer}>
            <FormHelperText sx={styles.helperText}>
              List a few brands you've partnered with before
            </FormHelperText>
            <BentoBrandsAutocomplete
              selectedBentoBrands={onboardingForm?.brands || []}
              updateSelectedBentoBrands={updatePastBrands}
            />
          </Grid>
        )}

        <Grid sx={styles.fieldContainer}>
          <FormHelperText error={!!dreamBrandValidation} sx={styles.helperText}>
            Enter a few dream brands you'd love to partner with *
          </FormHelperText>
          <BentoBrandsAutocomplete
            selectedBentoBrands={onboardingForm?.wishlistBrands || []}
            updateSelectedBentoBrands={updateWishlistBrands}
          />
          {dreamBrandValidation && (
            <FormHelperText sx={{ mt: -2 }} error={!!dreamBrandValidation}>
              {dreamBrandValidation}
            </FormHelperText>
          )}
        </Grid>

        {isProfilePage && (
          <Grid sx={styles.fieldContainer}>
            <FormHelperText sx={styles.helperText}>
              Preferred product types for brand partnerships (e.g., linens, pet
              supplies, skincare, etc.)
            </FormHelperText>
            <ProductsAutocomplete
              value={onboardingForm.preferredProducts || []}
              setValue={(newValue: string[]) => {
                setOnboardingForm((prev) => ({
                  ...prev,
                  preferredProducts: newValue,
                }));
              }}
            />
          </Grid>
        )}

        {!isProfilePage && (
          <Grid item xs="auto" container columnGap={1}>
            <Grid item xs="auto">
              <Button onClick={handleBack} disableElevation>
                Back
              </Button>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <LoadingButton
                disableElevation
                variant="contained"
                type="submit"
                loading={loading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default RecommendationForm;
