import { grey } from "@mui/material/colors";

const styles = {
  addCellContainer: {
    height: "100%",
    border: `1px solid ${grey[300]}`,
    backgroundColor: "#fcfcfc",
    position: "relative",
    zIndex: 1000,
    ml: "-3px",
  },
  addCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "37px",
    borderBottom: `1px solid ${grey[300]}`,
    p: 1,
    width: "50px",
    backgroundColor: "yellow.light",
    "&:hover": {
      backgroundColor: "yellow.main",
      cursor: "pointer",
    },
    fontSize: 13,
  },
  item: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
  back: { mb: 3, fontSize: 12, color: "primary.main" },
};
export default styles;
