import React, { createContext, useState } from "react";

import { delay } from "utils/polling";

enum SlideDirection {
  down = "down",
  up = "up",
}

type SlideAnimationProps = {
  direction: SlideDirection;
  in: boolean;
};

interface SlideAnimationContextInterface {
  slideOutUp: () => Promise<void>;
  slideInUp: () => Promise<void>;
  slideInDown: () => Promise<void>;
  slideOutDown: () => Promise<void>;
  slideProps: SlideAnimationProps;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  slideOutUp: defaultContextMissingFunction,
  slideInUp: defaultContextMissingFunction,
  slideInDown: defaultContextMissingFunction,
  slideOutDown: defaultContextMissingFunction,
  slideProps: {
    direction: SlideDirection.up,
    in: false,
  },
};

const SlideAnimationContext =
  createContext<SlideAnimationContextInterface>(defaultInterface);

interface SlideAnimationProviderProps {
  children: React.ReactElement;
}

export const ENTER_DELAY = 300;
export const EXIT_DELAY = 150;
export const DELAY_BUFFER = 50;

const SlideAnimationProvider = ({ children }: SlideAnimationProviderProps) => {
  const [slideProps, setSlideProps] = useState<SlideAnimationProps>({
    direction: SlideDirection.up,
    in: false,
  });

  const slideOutUp = async () => {
    setSlideProps({
      direction: SlideDirection.down,
      in: false,
    });
    return await delay(EXIT_DELAY + DELAY_BUFFER);
  };

  const slideInUp = async () => {
    setSlideProps({
      direction: SlideDirection.up,
      in: true,
    });
    return await delay(ENTER_DELAY + DELAY_BUFFER);
  };

  const slideInDown = async () => {
    setSlideProps({
      direction: SlideDirection.down,
      in: true,
    });
    return await delay(ENTER_DELAY + DELAY_BUFFER);
  };

  const slideOutDown = async () => {
    setSlideProps({
      direction: SlideDirection.up,
      in: false,
    });
    return await delay(EXIT_DELAY + DELAY_BUFFER);
  };

  return (
    <SlideAnimationContext.Provider
      value={{
        slideOutUp,
        slideInUp,
        slideInDown,
        slideOutDown,
        slideProps,
      }}
    >
      {children}
    </SlideAnimationContext.Provider>
  );
};

export { SlideAnimationProvider, SlideAnimationContext };
