import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { SetAlertType } from "schemas/functions";

import Referral from "features/Settings/Referral";
import SubscriptionButton from "features/Settings/SubscriptionButton";
import { trackEvent } from "utils/tracking";

import Subscription from "./Subscription";

interface IProps {
  open: boolean;
  onClose: () => void;
  setAlert: SetAlertType;
}

export function UpgradeDialog({ open, onClose, setAlert }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    emailSents,
    brandRequestsMade,
    upgradeDialogSource,
    recommendationsRefreshed,
  } = useContext(SubscriptionContext);

  const close = () => {
    onClose();
    setTimeout(() => {
      searchParams.delete("checkout");
      setSearchParams(searchParams);
    }, 500);
  };

  useEffect(() => {
    if (open) {
      trackEvent("Shown Paywall Dialog", {
        Reason: upgradeDialogSource,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="lg"
      fullScreen={isMobileScreen}
    >
      {searchParams.get("checkout") !== "failed" && (
        <>
          <DialogTitle variant="h4">
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {upgradeDialogSource === "Hits Limit"
                ? "It's Time to Upgrade!"
                : upgradeDialogSource === "Hits Brand Request Limit"
                  ? "It's Time to Upgrade!"
                  : "Subscription"}
              <SubscriptionButton />
            </Box>
          </DialogTitle>
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
            {upgradeDialogSource === "Hits Limit" && (
              <Typography paragraph>
                You have sent{" "}
                <Box component="span" sx={{ color: "info.main" }}>
                  <strong>{emailSents}</strong>
                </Box>{" "}
                emails. Please upgrade to send more.
              </Typography>
            )}
            {upgradeDialogSource === "Hits Brand Request Limit" && (
              <Typography paragraph>
                You have made{" "}
                <Box component="span" sx={{ color: "info.main" }}>
                  <strong>{brandRequestsMade}</strong>
                </Box>{" "}
                brand requests. Please upgrade to make additional requests.
              </Typography>
            )}

            {upgradeDialogSource ===
              "Hits Refreshing Recommendations Limit" && (
              <Typography paragraph>
                You have refreshed your recommendations{" "}
                <Box component="span" sx={{ color: "info.main" }}>
                  <strong>{recommendationsRefreshed}</strong>
                </Box>{" "}
                times this month. Please upgrade to refresh.
              </Typography>
            )}
            <Subscription setAlert={setAlert} />

            {![
              "Hits Brand Request Limit",
              "Hits Refreshing Recommendations Limit",
            ]?.includes(upgradeDialogSource) && (
              <>
                <Divider sx={{ my: 4 }} />
                <Referral setAlert={setAlert} />
              </>
            )}
            <Box sx={{ my: 3 }} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
