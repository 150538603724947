import { useAuth } from "@clerk/clerk-react";
import { Box, DialogTitle, Link as MuiLink } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendContactsContext } from "contexts/QuickSendContacts";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
  RecentContactDetails,
} from "contexts/QuickSendDrawer";
import {
  QuickSendEmailDraftsContext,
  QuickSendEmailDraftsProvider,
} from "contexts/QuickSendEmailDrafts";
import { QuickSendErrorContext } from "contexts/QuickSendError";
import { QuickSendOutreachTabsContext } from "contexts/QuickSendOutreachTabs";
import { QuickSendPaginationContext } from "contexts/QuickSendPagination";
import {
  QuickSendScheduleContext,
  QuickSendScheduleProvider,
} from "contexts/QuickSendSchedule";
import { SubscriptionContext } from "contexts/Subscription";
import { TasksContext } from "contexts/Tasks";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { checkNoRecentContact } from "contexts/helpers/QuickSendHelpers";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  BentoContact,
  NewBentoContact,
  OnboardAction,
  PreviewMessage,
  Task,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { Conditions, SendOutreachApiRouteResponse } from "schemas/outreach";
import { isOnPage, routes } from "schemas/routes";

import { fetcherAuth } from "utils/api";
import { isInvalidEmail } from "utils/string";
import { getToday, isIsoStringPriorToCurrentTime } from "utils/time";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useDrafts } from "utils/useDrafts";
import { usePageSize } from "utils/usePageSize";
import { useRequests } from "utils/useRequests";
import useTemplate from "utils/useTemplate";
import { useTracking } from "utils/useTracking";

import { Template } from "../ContactList/schema";
import QuickSendDialogHeader from "./DialogHeader";
import BusinessHourDialog from "./Dialogs/BusinessHourDialog";
import RecentContactDialog from "./Dialogs/RecentContactDialog";
import EmptyPage from "./EmptyPage";
import PreviewPerContact from "./PreviewPerContact";
import SelectOutreach from "./PreviewPerContact/PreviewIndividual/SelectOutreach";
import SharedEmailParts from "./PreviewPerContact/PreviewIndividual/SharedEmailParts";
import QuickSendWrapper from "./Wrapper";
import styles from "./styles";

const QuickSendDrawer = () => {
  const { setAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const { updateTemplateWithProfileVariables } = useTemplate();
  const { tab } = useParams();
  const { getDrafts } = useDrafts();
  const { isLargeRecommendedPage, isRecommendedPage, isOnboardingPage } =
    usePageSize();
  const navigate = useNavigate();
  const location = useLocation();

  const [recentContactDetails, setRecentContactDetails] =
    useState<RecentContactDetails | null>(null);

  // Contexts
  const drafts = getDrafts(tab);
  const { currentOrg, emailSettings, setEmailSettings, profile } = useContext(
    OrganizationUserContext,
  );
  const {
    emailHealth,
    setEmailHealth,
    setOpenIntegrationDialog,
    fetchIntegrationHealth,
  } = useContext(UserIntegrationsContext);

  // All QuickSend related contexts variables
  const { updatePaginationLocalState } = useContext(QuickSendPaginationContext);
  const {
    bentoContact,
    bentoBrand,
    outreachMessages,
    handleFetchPreviews,
    setTemplateForQuickSend,
    templatesToUpdate,
    templateForQuickSend,
    setTemplatesToUpdate,
    setOutreachMessages,
    clearDraftTemplates,
  } = useContext(QuickSendContext);

  const { trackUserHasOnboarded } = useTracking();
  const { updateLastContacted, bentoContacts } = useContext(
    QuickSendContactsContext,
  );
  const { setOutreachTab } = useContext(QuickSendOutreachTabsContext);
  const { refreshDraftsList } = useDrafts();
  const { moveFromUnsentToSent } = useRequests();

  const {
    setView,
    view,
    closeDrawer,
    quickSendBrandId,
    sendFrom,
    isEdited,
    setIsEdited,
    referredEmail,
    setPreviewLoading,
    previewLoading,
  } = useContext(QuickSendDrawerContext);

  const { setErrorsFromMessage } = useContext(QuickSendErrorContext);

  const { getUserScheduledAt, isOutsideBusinessHour } = useContext(
    QuickSendScheduleContext,
  );
  const { createEmailDraft, handleOpenCreateTemplateDialog } = useContext(
    QuickSendEmailDraftsContext,
  );

  const { setTasks } = useContext(TasksContext);
  const { decreaseEmailCount, setUpgradeDialogSource } =
    useContext(SubscriptionContext);
  const { selectedBrand, setSelectedBrand, removeBrandsFromList } =
    useContext(BrandsContext);
  const {
    fetchOutreachTemplates,
    fetchOutreachTemplatesLoading,
    defaultTemplates,
  } = useContext(OutreachTemplatesContext);
  const { handleFetchContacts, fetchContactsParams } = useContext(
    OutreachContactsContext,
  );
  // States
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [updateOrgProfile, setUpdateOrgProfile] = useState<boolean>(false);

  const outreachDataRef = useRef();
  const editRef = useRef();

  const bottomRef = useRef<HTMLDivElement>();

  const handleTestEmail = async (): Promise<boolean> => {
    try {
      setSendLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/send-outreach-test`,
        "POST",
        {},
        {
          emails: outreachMessages,
          bentoBrandId: bentoBrand?.id,
          sendFrom,
        },
      );
      return true;
    } catch (error) {
      const needsToScroll = setErrorsFromMessage(
        outreachMessages,
        error?.message,
      );
      if (needsToScroll) {
        bottomRef?.current?.scroll();
      }
      if (error?.message?.includes("connect to Gmail")) {
        setEmailHealth(false);
        setOpenIntegrationDialog(true);
      } else {
        setAlert(
          error?.message ||
            "Something went wrong. Unable to send outreach email. Please reload and retry again.",
          "error",
        );
      }
      storeCurrentDraft();
      return false;
    } finally {
      setSendLoading(false);
    }
  };

  const handleSendEmail = async (
    sendAt: null | string = null,
    conditions?: Conditions,
    isTest: boolean = false,
  ): Promise<boolean> => {
    if (!bentoBrand?.id) return false;

    if (isTest) {
      return await handleTestEmail();
    }

    if (!bentoContact) {
      setAlert("Please input an email address", "error");
      return false;
    }

    if (sendAt && isIsoStringPriorToCurrentTime(sendAt)) {
      setAlert("You cannot schedule a time that has already passed", "error");
      return false;
    }

    if (
      (!conditions || !conditions.skipsRecentContact) &&
      !(await checkNoRecentContact(
        sendAt,
        bentoContact,
        updateLastContacted,
        setRecentContactDetails,
      ))
    ) {
      return false;
    }

    if (recentContactDetails) setRecentContactDetails(null);
    if (
      (!conditions || !conditions?.skipsBusinessHour) &&
      isOutsideBusinessHour(sendAt)
    ) {
      return false;
    }

    if (sendAt && isIsoStringPriorToCurrentTime(sendAt)) {
      setAlert("You cannot schedule a time that has already passed", "error");
      return false;
    }
    if (isOnPage(routes.emailOnboarding)) {
      trackUserHasOnboarded(OnboardAction.EMAIL_TO_BRAND_SENT);
    }

    const scheduleAt = getUserScheduledAt(sendAt);
    outreachDataRef.current = undefined;

    try {
      setSendLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/send-outreach`,
        "POST",
        {},
        {
          emails: outreachMessages,
          bentoBrandId: bentoBrand?.id,
          previewContact: bentoContact,
          scheduleAt,
          timezone: emailSettings?.timezone,
          hour: emailSettings?.hour,
          sendFrom,
          templatesToUpdate,
        },
      );
      setAlert(
        <>
          Successfully scheduled emails. Check out the{" "}
          <MuiLink
            component={RouterLink}
            to={`/${routes.inbox}`}
            onClick={() => {
              trackEvent("Inbox Page Link Clicked", {
                "Clicked From": "Successful Email Sent Alert",
              });
            }}
            sx={styles.inboxLink}
          >
            inbox page here
          </MuiLink>{" "}
          to see sent emails.
        </>,
        "success",
      );
      setIsEdited(false);
      handleUpdateLocalState(res, scheduleAt);
      return true;
    } catch (error) {
      const needsToScroll = setErrorsFromMessage(
        outreachMessages,
        error?.message,
      );
      if (needsToScroll) {
        bottomRef?.current?.scroll();
      }
      if (error?.message?.includes("connect to Gmail")) {
        setEmailHealth(false);
        setOpenIntegrationDialog(true);
      } else if (error?.message?.includes("upgrade your account")) {
        setUpgradeDialogSource("Hits Limit");
      } else {
        setAlert(
          error?.message ||
            "Something went wrong. Unable to send outreach email. Please reload and retry again.",
          "error",
        );
      }

      storeCurrentDraft();
      return false;
    } finally {
      setSendLoading(false);
    }
  };

  const handleUpdateLocalState = async (
    res: SendOutreachApiRouteResponse,
    scheduleAt?: string | null,
  ) => {
    if (bentoBrand) removeBrandsFromList(bentoBrand);

    if (bentoContact?.email) {
      updateLastContacted([bentoContact?.email], true);
    }
    decreaseEmailCount();
    setEmailSettings(res.emailSetting);

    // update the page to 1, so that the data gets refetched
    const updatedParams = { ...fetchContactsParams };
    updatedParams.page = 1;
    handleFetchContacts(updatedParams);

    // Clear out any outreach-related tasks.
    if (res.completedTaskIds) {
      setTasks((prev) => {
        return prev?.filter(
          (x: Task) => !(x?.id && res.completedTaskIds?.includes(x.id)),
        );
      });
    }
    // Clear out selected brand for Brand drawer view
    if (selectedBrand) {
      setSelectedBrand(null);
    }

    // Update OrganizationProfile if user fill out variables on email templates
    if (updateOrgProfile) {
      if (
        templateForQuickSend &&
        templateForQuickSend?.length > 0 &&
        profile &&
        templateForQuickSend[0]?.id
      ) {
        await updateTemplateWithProfileVariables(
          templateForQuickSend[0]?.id,
          profile,
          setTemplateForQuickSend,
        );
      }
    }

    fetchOutreachTemplates();

    // Onboarding Page, refresh state
    if (isOnboardingPage) {
      closeDrawer();
      navigate(`/${routes.dashboard}${location.search}`);
      return;
    }

    // Move to next BentoBrand
    if (bentoBrand) {
      const copy = makeDeepCopy(bentoBrand);
      if (scheduleAt) {
        copy.earliestPendingSendAt = scheduleAt;
      } else {
        copy.latestEmailCompletedAt = getToday();
      }
      updatePaginationLocalState({
        bentoBrand: copy,
        handleClose,
      });
      moveFromUnsentToSent(copy);

      // Poll for autogenerated drafts if user sent from Recommendations page
      if (isRecommendedPage) {
        refreshDraftsList(bentoBrand);
      }
    }
  };

  const handleClose = async () => {
    if (!isEdited) {
      outreachDataRef.current = undefined;
    }
    closeDrawer();
  };

  useEffect(() => {
    if (emailHealth) {
      setOpenIntegrationDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailHealth]);

  useEffect(() => {
    if (currentOrg?.id) {
      fetchIntegrationHealth(currentOrg.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  useEffect(() => {
    // @ts-ignore
    editRef.current = Boolean(isEdited);

    // Warn user if they try to close the brower but has a draft.
    const unloadCallback = (event: CustomEvent) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (isEdited) {
      window.addEventListener("beforeunload", unloadCallback);
    }
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, [isEdited]);

  const storeCurrentDraft = () => {
    // @ts-ignore: Store the current draft to Ref so it doesn't
    // get destroyed when component unmount.
    outreachDataRef.current = {
      emails: outreachMessages,
      previewContact: bentoContact,
      bentoBrand,
      isAutogenerated: templateForQuickSend[0]?.isAutogenerated,
    };
  };

  useEffect(() => {
    storeCurrentDraft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outreachMessages, bentoBrand?.id, bentoContact]);

  useEffect(() => {
    return () => {
      const refValue = outreachDataRef?.current;
      const isEdited = editRef.current;
      if (refValue && isEdited) {
        // Create email draft if user exits this component
        createEmailDraft(refValue);
        setIsEdited(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickSendBrandId]);

  const fetchPreviewBrand = async (
    brandId: number,
    fetchDraft: boolean,
    updatedTemplates?: Template[],
    updatedDefaultTemplates?: Template[],
  ) => {
    setOutreachTab(0);
    setView(QuickSendTabView.EXPAND);
    let specificTemplates = defaultTemplates;
    if (updatedDefaultTemplates && updatedDefaultTemplates.length > 0) {
      specificTemplates = updatedDefaultTemplates;
    }
    return await handleFetchPreviews(
      brandId,
      {
        email: referredEmail,
        bentoBrandId: brandId,
      },
      specificTemplates,
      fetchDraft,
      updatedTemplates,
    );
  };

  const fetchInitialData = async () => {
    setPreviewLoading(Number(quickSendBrandId));
    setIsEdited(false);
    outreachDataRef.current = undefined;
    setTemplateForQuickSend([]);
    const clearedTemplates = clearDraftTemplates();
    setTemplatesToUpdate([]);

    let updatedTemplates;
    let updatedDefaultTemplates;
    if (clearedTemplates.length === 0) {
      // refretch templates in case a template was created in the background on onboarding, and is not in state yet
      // do not set the loading for outreach templates to avoid triggering a useEffect to fetch this data again
      [updatedTemplates, updatedDefaultTemplates] =
        await fetchOutreachTemplates(true);
    }

    const hasDraft = await fetchPreviewBrand(
      Number(quickSendBrandId),
      true,
      updatedTemplates,
      updatedDefaultTemplates,
    );

    const hasTemplate =
      (clearedTemplates && clearedTemplates.length > 0) ||
      (updatedTemplates && updatedTemplates.length > 0) ||
      hasDraft;

    handleOpenCreateTemplateDialog(hasTemplate);
  };

  useEffect(() => {
    if (quickSendBrandId >= 0 && !fetchOutreachTemplatesLoading) {
      fetchInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickSendBrandId, fetchOutreachTemplatesLoading, currentOrg?.id]);

  // If no QuickSend is open, display an Empty page based on which view the user is on.
  if (
    quickSendBrandId < 0 ||
    view === QuickSendTabView.COLLAPSE ||
    (isLargeRecommendedPage &&
      (outreachMessages?.length === 0 || drafts.length === 0) &&
      previewLoading !== quickSendBrandId)
  ) {
    return <EmptyPage />;
  }

  const isLoading = previewLoading === quickSendBrandId;

  const changePreview = (
    name: string,
    text: string | undefined,
    index: number,
  ) => {
    setIsEdited(true);
    setOutreachMessages((prev: PreviewMessage[]) => {
      const copy = makeDeepCopy(prev);
      copy[index][name] = text;
      return copy;
    });
  };

  const handleAddNewContact = (
    newContact: NewBentoContact,
    handleClose: () => void,
  ) => {
    const invalid = isInvalidEmail(newContact?.email);
    if (invalid) {
      setAlert("Please input a valid email", "error");
      return;
    }
    const alreadyExist = bentoContacts?.find(
      (x: BentoContact | NewBentoContact) => x.email === newContact?.email,
    );
    if (alreadyExist) {
      setAlert(
        `Contact with email ${newContact?.email} already exists`,
        "error",
      );
      return;
    }
    if (bentoBrand?.id && newContact?.email) {
      handleFetchPreviews(bentoBrand?.id, newContact);
    }
    handleClose();
  };

  return (
    <>
      <QuickSendWrapper handleClose={handleClose}>
        <Box sx={styles.quickSendWrapper}>
          <Box sx={styles.titleWrapper}>
            <DialogTitle sx={styles.dialogTitleText}>
              {isLargeRecommendedPage || isOnboardingPage ? (
                <></>
              ) : (
                <QuickSendDialogHeader
                  handleClose={handleClose}
                  isLoading={isLoading}
                  bentoBrand={bentoBrand}
                />
              )}
              <SharedEmailParts
                changePreview={changePreview}
                handleAddNewContact={handleAddNewContact}
              />
              <SelectOutreach />
            </DialogTitle>
          </Box>
          <Box sx={styles.editorWrapper}>
            <Box sx={styles.dialogContent}>
              <PreviewPerContact
                handleSendEmail={handleSendEmail}
                sendLoading={sendLoading}
                changePreview={changePreview}
                setUpdateOrgProfile={setUpdateOrgProfile}
              />
              <Box ref={bottomRef} />
            </Box>
          </Box>
        </Box>
      </QuickSendWrapper>

      <BusinessHourDialog handleSendEmail={handleSendEmail} />

      <RecentContactDialog
        recentContactDetails={recentContactDetails}
        setRecentContactDetails={setRecentContactDetails}
        handleSendEmail={handleSendEmail}
      />
    </>
  );
};

export default function QuickSendIndividualDrawer() {
  return (
    <QuickSendScheduleProvider>
      <QuickSendEmailDraftsProvider>
        <QuickSendDrawer />
      </QuickSendEmailDraftsProvider>
    </QuickSendScheduleProvider>
  );
}
