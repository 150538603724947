import { grey } from "@mui/material/colors";

const styles = {
  datePicker: {
    border: "0",
  },
  option: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: { xs: 240, md: 500 },
    color: "primary.main",
    fontSize: 14,
  },
  noBorder: {
    fontSize: 12,
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  label: {
    color: grey[500],
  },
  timezonePicker: {
    "& fieldset": { border: "none" },
    input: { color: "primary.main" },
    ml: { xs: -2, md: 0 },
    width: 250,
  },
  subtitle: {
    mt: 1,
    color: grey[600],
  },
};

export default styles;
