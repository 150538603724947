import { Box, ButtonBase, Grid, Tooltip } from "@mui/material";
import { DiscoverTabView, DiscoverViewContext } from "contexts/DiscoverView";
import { useContext } from "react";
import { HEART_COLOR } from "styles/oneOffColor";

import { trackEvent } from "utils/tracking";

import { getStyles } from "./styles";

function HomeSideBar() {
  const { discoverTab, tabWidth, setDiscoverTab } =
    useContext(DiscoverViewContext);

  const tabOpen = discoverTab !== null;
  const styles = getStyles(tabOpen ? `${tabWidth}px` : "0px");

  const renderButton = (
    tab: DiscoverTabView,
    tooltip: string,
    icon: string,
    color: string,
  ) => {
    const handleClick = () => {
      trackEvent("Discover Sidebar Clicked", { tab });
      if (tab === discoverTab) {
        setDiscoverTab(null);
      } else {
        setDiscoverTab(tab);
      }
    };

    const active = discoverTab === tab;
    return (
      <Box sx={active ? [styles.active, { borderColor: color }] : {}}>
        <Tooltip title={tooltip} placement="left">
          <ButtonBase onClick={handleClick}>
            <Box component="i" className={icon} sx={[styles.icon, { color }]} />
          </ButtonBase>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box sx={styles.root}>
      <Grid container justifyContent="center" flexDirection="column" gap={2}>
        {renderButton(
          DiscoverTabView.SAVED_BRANDS,
          "View Saved Brands",
          "fa-solid fa-bookmark",
          HEART_COLOR,
        )}
        {renderButton(
          DiscoverTabView.USER_REQUESTS,
          "View Your Requests",
          "fa-solid fa-list",
          "#e8b054",
        )}
        {renderButton(
          DiscoverTabView.TEMPLATES,
          "View Your Templates",
          "fa-solid fa-file-pen",
          "#117A65",
        )}
        {renderButton(
          DiscoverTabView.CHAT,
          "Open AI Chat Helper",
          "fa-solid fa-wand-magic-sparkles",
          "#2A60EC",
        )}
        {renderButton(
          DiscoverTabView.METRICS,
          "View Your Metrics",
          "fa-solid fa-chart-line",
          "#7E98B2",
        )}
      </Grid>
    </Box>
  );
}

export default HomeSideBar;
