import { Box } from "@mui/material";
import {
  QuickSendDrawerContext,
  RecentContactDetails,
} from "contexts/QuickSendDrawer";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { Link } from "react-router-dom";
import { routes } from "schemas/routes";

import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import { HandleSendEmail } from "features/Influencer/ContactList/schema";
import { makeDeepCopy } from "utils/updateLocalState";

interface Props {
  recentContactDetails: RecentContactDetails | null;
  setRecentContactDetails: Dispatch<
    SetStateAction<RecentContactDetails | null>
  >;
  handleSendEmail: HandleSendEmail;
}

const RecentContactDialog: React.FC<Props> = ({
  recentContactDetails,
  setRecentContactDetails,
  handleSendEmail,
}) => {
  const { setConditions } = useContext(QuickSendDrawerContext);

  const handleConfirm = () => {
    setConditions((prev) => {
      const copy = makeDeepCopy(prev);
      copy["skipsRecentContact"] = true;
      handleSendEmail(recentContactDetails?.sendAt, copy);
      return copy;
    });
  };
  return (
    <ConfirmDialog
      header={
        <>
          <Box
            component="i"
            className="fa-regular fa-triangle-exclamation"
            sx={{ color: "warning.main", mr: 1 }}
          />
          Are you sure you want to email{" "}
          {recentContactDetails?.contact?.title ||
            recentContactDetails?.contact?.name}
          ?
        </>
      }
      subheader={
        <>
          We noticed you <Link to={`/${routes.inbox}`}>sent or scheduled</Link>{" "}
          an email to{" "}
          {recentContactDetails?.contact?.title ||
            recentContactDetails?.contact?.name}{" "}
          within the last day.
        </>
      }
      open={recentContactDetails !== null}
      handleClose={() => setRecentContactDetails(null)}
      handleConfirm={handleConfirm}
    />
  );
};

export default RecentContactDialog;
