import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Automation } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

interface AutomationsContextInterface {
  fetchAutomations: () => void;
  automations: Automation[];
  setAutomations: Dispatch<SetStateAction<Automation[]>>;
  fetchLoading: boolean;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  automations: [],
  setAutomations: defaultContextMissingFunction,
  fetchAutomations: defaultContextMissingFunction,
  fetchLoading: false,
};

const AutomationsContext =
  createContext<AutomationsContextInterface>(defaultInterface);

interface AutomationsProviderProps {
  children: React.ReactNode;
}

const AutomationsProvider = ({ children }: AutomationsProviderProps) => {
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);

  const [automations, setAutomations] = useState<Automation[]>([]);
  const [fetchLoading, setFetchLoading] = useState(false);

  const fetchAutomations = async () => {
    if (!currentOrg?.id || !currentOrg?.virtualAssistantQuota) return;
    setFetchLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/automations`,
        "GET",
      );
      setAutomations(res.automations);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <AutomationsContext.Provider
      value={{
        fetchAutomations,
        fetchLoading,
        automations,
        setAutomations,
      }}
    >
      {children}
    </AutomationsContext.Provider>
  );
};

export { AutomationsProvider, AutomationsContext };
