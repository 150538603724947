import moment, { Moment } from "moment";
import "moment-timezone";
import { Interaction, Task } from "schemas/dashboard";
import { SetAlertType, StringMap } from "schemas/functions";

interface formatProps {
  epochTimestamp: number;
  fullDate?: boolean;
  timeZone?: string;
  addYear?: boolean;
}

export const formatEpochTimestamp = ({
  epochTimestamp,
  fullDate,
  timeZone,
  addYear,
}: formatProps) => {
  if (typeof epochTimestamp !== "number") {
    return epochTimestamp;
  }
  if (timeZone) {
    const date = moment.unix(epochTimestamp);
    const epochString = date.tz(timeZone).format("ll hh:mm A");
    return epochString;
  }

  let custom: StringMap;

  if (fullDate) {
    custom = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
  } else {
    custom = {
      month: "short",
      day: "numeric",
    };
  }

  if (addYear) {
    custom["year"] = "numeric";
  }

  return new Date(epochTimestamp * 1000)?.toLocaleString("default", custom);
};

export const isDaysInFuture = (day: string, format = "YYYY-MM-DD") => {
  const dObject = moment(day, format);
  return moment().diff(dObject, "days") <= 0;
};

export const getToday = (format = "YYYY-MM-DD") => {
  return moment(new Date()).format(format);
};

export const getDaysInFuture = (
  startDate?: string,
  daysInFuture = 0,
  format = "YYYY-MM-DD",
) => {
  const newDate = moment(startDate, format).add(daysInFuture, "days");

  return newDate.format(format);
};

export const epochToLocalDate = (epochTimestamp: any) => {
  return moment.utc(epochTimestamp * 1000).format("YYYY-MM-DD");
};

// Convert a DatePicker (HTML Date such as "2020-11-01") to ISO String (datetime.datetime object on back-end)
export const datePickerToIsoString = (dateString: string) => {
  const m = moment(dateString);
  return m.format("YYYY-MM-DDTHH:mm");
};

export const getInteractionTime = (interaction: Interaction) => {
  let interactionTime: any = interaction?.interactedAt;
  if (typeof interaction?.interactedAt == "number") {
    return epochToLocalDate(interaction?.interactedAt);
  } else {
    return interactionTime;
  }
};

export const convertToIsoTime = (dueAt: any, setAlert: SetAlertType) => {
  // There are 3 different time formats that a dueAt date are taken.
  // 1. When fetching from BE, it is returning time in epoch format (number)
  // 2. When we want to edit time on FE however, we have to convert it to local date (YYYY-MM-DD)
  // 3. But when we save it on the BE, we need to convert it to ISO format to meet with datetime.datetime requirements.

  const isDatePickerFormat = dueAt && typeof dueAt === "string";
  const timeInIsoString = isDatePickerFormat
    ? datePickerToIsoString(dueAt)
    : datePickerToIsoString(epochToLocalDate(dueAt));

  if (isDatePickerFormat) {
    const checkValid = isDaysInFuture(dueAt);
    if (!checkValid) {
      setAlert("Date must be in future", "warning");
      return;
    }
  }

  return timeInIsoString;
};

export const getTaskTime = (task: Task) => {
  if (task?.dueAt) {
    if (typeof task?.dueAt === "string") {
      return task?.dueAt;
    } else if (typeof task?.dueAt === "number") {
      return epochToLocalDate(task?.dueAt);
    }
  }
  return "";
};

export const isIsoStringPriorToCurrentTime = (isoString: string) => {
  // Parse the ISO string to create a Date object
  const parsedDate = new Date(isoString);

  // Get the current time
  const currentTime = new Date();

  // Compare the parsed date with the current time
  return parsedDate < currentTime;
};

export const isBusinessHour = (isoDate: string, timezone: string) => {
  const momentObj = moment(isoDate)?.tz(timezone);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = momentObj.day();

  // Check if the day is a weekday (Monday to Friday)
  if (dayOfWeek < 1 || dayOfWeek > 5) {
    return false; // Not a business day
  }

  // Get the hour of the day (0-23)
  const hourOfDay = momentObj.hour();

  // Check if the hour is within business hours (7:00 to 17:00)
  return hourOfDay >= 7 && hourOfDay <= 17;
};

export const getDifferenceFromToday = (epochTimeStamp: number) => {
  const a = moment();
  const b = moment(epochTimeStamp * 1000);
  const differenceInDays = a.diff(b, "days");
  return differenceInDays;
};

export const addBusinessDays = (
  numDaysToAdd: number,
  timeToStartFrom?: any,
) => {
  const sunday = 0;
  const saturday = 6;
  let daysRemaining = numDaysToAdd;

  if (!timeToStartFrom) {
    timeToStartFrom = moment().clone();
  }

  while (daysRemaining > 0) {
    timeToStartFrom.add(1, "days");
    if (
      timeToStartFrom.day() !== sunday &&
      timeToStartFrom.day() !== saturday
    ) {
      daysRemaining--;
    }
  }

  return timeToStartFrom;
};

export const isPastMoment = (m: Moment) => {
  const today = moment();
  return m.isBefore(today);
};

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
