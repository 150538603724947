import { ContactProperty, PropertyType } from "schemas/dashboard";

export enum ContactViewName {
  PITCHING = "Pitching",
  IN_DISCUSSION = "In Discussion",
  COLLABORATION = "Collaboration",
  REACH_OUT_LATER = "Reach Out Later",
  ALL = "All",
  IMPORTS = "Imports",
}

export type ContactView = {
  id: number;
  name: string;
  contactViewProperties: ContactViewProperty[];
};

export type ContactViewProperty = {
  id?: number;
  contactViewId: number;
  contactPropertyId?: number;
  contactPropertyName?: string;
  displayOrder?: number;
  sortDirection?: PropertySortDirection;
  sortPriority?: number;
  contactProperty?: ContactProperty;
  filters: PropertyFilter[];
  isSystemRequired?: boolean;
};

export const ColumnIcons: { [key in PropertyType]: string } = {
  [PropertyType.attachment]: "fa-paperclip",
  [PropertyType.checkbox]: "fa-square-check",
  [PropertyType.number]: "fa-hashtag",
  [PropertyType.singleSelect]: "fa-circle-check",
  [PropertyType.multipleSelect]: "fa-list-check",
  [PropertyType.shortText]: "fa-font",
  [PropertyType.longText]: "fa-quote-left",
  [PropertyType.date]: "fa-calendar",
  [PropertyType.dateTime]: "fa-clock",
};

export const NotEditableFields = ["email", "last_gmail_message_date"];

export type SelectOption = {
  name: string;
  color?: string;
  id?: string;
  automatedName?: string;
};

export enum PropertySortDirection {
  asc = "asc",
  desc = "desc",
}

export type EditRow = {
  property: ContactViewProperty | null;
  index: number;
};

export enum BuiltInContactPropertyNames {
  last_gmail_message_date = "last_gmail_message_date",
  is_important = "is_important",
  contact_name = "contact_name",
  contact_title = "contact_title",
  email = "email",
  email_thread = "email_thread",
  brand_name = "brand_name",
  smart_task = "smart_task",
  latest_sequence_status = "latest_sequence_status",
  move_brand_to = "move_brand_to",
  latest_outreach_template = "latest_outreach_template",
  last_email_open_count = "last_email_open_count",
  actions = "actions",
}

export const SELECT_COLOR_OPTIONS = [
  "#C5B9DE",
  "#D9E8FF",
  "#F6E8B6",
  "#FFF3EC",
  "#F3DFFD",
  "#A7F1C9",
  "#F0F0F0",
  "#EDEAD8",
  "#DDDDDD",
  "#FFDAB8",
];

export const BuiltInFilters: { [key: string]: SelectOption[] } = {
  is_important: [
    {
      name: "true",
    },
    {
      name: "false",
    },
  ],
  latest_sequence_status: [
    { name: "scheduled" },
    { name: "in_progress" },
    { name: "complete" },
    { name: "bounced" },
    { name: "responded" },
  ],
  last_email_open_count: [{ name: "opened" }, { name: "multiple_opens" }],
};

export const BuiltInFilterTypes: { [key: string]: PropertyType } = {
  is_important: PropertyType.checkbox,
  latest_sequence_status: PropertyType.singleSelect,
  last_email_open_count: PropertyType.singleSelect,
};

export const FiltersType = [
  PropertyType.checkbox,
  PropertyType.multipleSelect,
  PropertyType.singleSelect,
];

export const CustomFilterColumns: BuiltInContactPropertyNames[] = [
  BuiltInContactPropertyNames.latest_sequence_status,
  BuiltInContactPropertyNames.last_email_open_count,
];

export enum FilterType {
  selection = "selection",
  customBuiltIn = "custom_built_in",
}

export type PropertyFilter = {
  values: string[];
  type: FilterType;
};

export const EMAIL_COLUMN = "email";
export const BRAND_NAME_COLUMN = "brand_name";

export type ChangedFieldMap = {
  [key: string]: string[];
};
