import { Box, Chip, Typography } from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import lodash from "lodash";
import { useContext, useEffect } from "react";
import {
  BentoBrand,
  MetadataLocationType,
  MetadataType,
  SearchParams,
} from "schemas/dashboard";

import { useAutoCompletePrediction } from "components/LocationAutocomplete/useAutoCompletePrediction";
import { DiscoverSearchChipColor } from "utils/color";
import { DISCOVER_METADATA_TAGS } from "utils/localStorage";
import { useSearchBrand } from "utils/useSearchBrand";

import styles from "./styles";

interface Props {
  selectedBrand: BentoBrand;
}

const BrandLocations = ({ selectedBrand }: Props) => {
  const { handleClickOnTag, handleAddGeopoint } = useSearchBrand();
  const { geopoint, inputValue, setInputValue } = useAutoCompletePrediction();
  const { setSelectedMetadataTags } = useContext(BrandsContext);

  const renderChip = (
    index: number,
    location: string,
    locationType?: MetadataLocationType,
  ) => {
    return (
      <Chip
        key={index}
        size="small"
        sx={{
          backgroundColor:
            DiscoverSearchChipColor[SearchParams.LOCATION_DISCOVER],
        }}
        label={lodash.startCase(location)}
        onClick={(e) =>
          handleClickOnTag(
            e,
            {
              type: MetadataType.location,
              value: lodash.startCase(location),
              locationType,
            },
            setInputValue,
          )
        }
      />
    );
  };

  const getLocation = () => {
    if (
      selectedBrand?.isInternational &&
      selectedBrand?.countries &&
      selectedBrand?.countries?.length > 0
    ) {
      const location = selectedBrand?.countries?.[0];
      return {
        name: "Country of Origin",
        value: renderChip(0, location, MetadataLocationType.country),
      };
    }

    if (
      selectedBrand?.locationNames &&
      selectedBrand.locationNames.length > 0
    ) {
      const locationNamesChips = selectedBrand?.locationNames?.map(
        (location, index) => renderChip(index, location),
      );
      return { name: "Location", value: locationNamesChips };
    }
    if (selectedBrand?.countries && selectedBrand.countries.length > 0) {
      const countriesChips = selectedBrand?.countries?.map((location, index) =>
        renderChip(index, location, MetadataLocationType.country),
      );
      return { name: "Location", value: countriesChips };
    }

    if (selectedBrand?.continents && selectedBrand?.continents.length > 0) {
      const continentChips = selectedBrand?.continents?.map((location, index) =>
        renderChip(index, location, MetadataLocationType.continent),
      );
      return { name: "Location", value: continentChips };
    }
    return { name: "", value: <></> };
  };

  const { name, value } = getLocation();

  useEffect(() => {
    if (geopoint?.country_code && geopoint?.longitude && geopoint?.latitude) {
      handleAddGeopoint(
        geopoint?.country_code,
        geopoint?.latitude,
        geopoint?.longitude,
        inputValue,
      );

      setSelectedMetadataTags((prev) => {
        const exists = prev?.find((x) => x.value === inputValue);
        if (!exists) {
          prev.push({
            ...geopoint,
            type: MetadataType.location,
            value: inputValue,
          });
        }
        sessionStorage.setItem(DISCOVER_METADATA_TAGS, JSON.stringify(prev));
        return prev;
      });

      setInputValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geopoint]);

  return (
    <Typography component="div" gutterBottom>
      {name && (
        <>
          <Box component="span" sx={styles.key}>
            {name}:{" "}
          </Box>{" "}
          <Box component="span">{value}</Box>
        </>
      )}
    </Typography>
  );
};

export default BrandLocations;
