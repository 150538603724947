import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OutreachContact } from "schemas/dashboard";

import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

interface OutreachTemplatesInterface {
  templates: Template[];
  setTemplates: Dispatch<SetStateAction<Template[]>>;
  outreachTemplates: Template[];
  followupTemplates: Template[];
  fetchOutreachTemplatesLoading: boolean;
  setFetchOutreachTemplatesLoading: Dispatch<SetStateAction<boolean>>;
  saveTemplateLoading: boolean;
  setSaveTemplateLoading: Dispatch<SetStateAction<boolean>>;
  currentTemplateContentType: TemplateContentType;
  setCurrentTemplateContentType: Dispatch<SetStateAction<TemplateContentType>>;
  fetchOutreachTemplates: (
    ignoreLoading?: boolean,
  ) => Promise<[Template[] | undefined, Template[] | undefined]>;
  defaultTemplates: Template[];
  setDefaultTemplates: Dispatch<SetStateAction<Template[]>>;
  userHasNoTemplates: boolean;
  setUserHasNoTemplates: Dispatch<SetStateAction<boolean>>;
  templateWithIds: Template[];
  getLatestFollowUpEmail: (contact: OutreachContact) => Promise<string>;
  createTemplateDialogOpen: boolean;
  setCreateTemplateDialogOpen: Dispatch<SetStateAction<boolean>>;
  currentDraftBody: string;
  setCurrentDraftBody: Dispatch<SetStateAction<string>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  templates: [],
  setTemplates: defaultContextMissingFunction,
  outreachTemplates: [],
  followupTemplates: [],
  saveTemplateLoading: false,
  setSaveTemplateLoading: defaultContextMissingFunction,
  fetchOutreachTemplatesLoading: false,
  setFetchOutreachTemplatesLoading: defaultContextMissingFunction,
  currentTemplateContentType: TemplateContentType.OUTREACH,
  setCurrentTemplateContentType: defaultContextMissingFunction,
  fetchOutreachTemplates: defaultContextMissingFunction,
  defaultTemplates: [],
  setDefaultTemplates: defaultContextMissingFunction,
  userHasNoTemplates: false,
  setUserHasNoTemplates: defaultContextMissingFunction,
  templateWithIds: [],
  getLatestFollowUpEmail: defaultContextMissingFunction,
  createTemplateDialogOpen: false,
  setCreateTemplateDialogOpen: defaultContextMissingFunction,
  currentDraftBody: "",
  setCurrentDraftBody: defaultContextMissingFunction,
};

const OutreachTemplatesContext =
  createContext<OutreachTemplatesInterface>(defaultInterface);

interface OutreachTemplatesProviderProps {
  children: React.ReactNode;
}

const DEFAULT_FOLLOWUP_BODY =
  "<p>Hi {First Name},</p> <p> I hope this message finds you well! 🌈 </p> <p> I wanted to follow up on my previous email to see if you had a chance to consider my proposal. I'm truly excited about the potential of collaborating with {Brand Name} and bringing our shared vision to life. </p> <p> Looking forward to hearing from you soon! </p>";

const OutreachTemplatesProvider = ({
  children,
}: OutreachTemplatesProviderProps) => {
  // Template
  const { currentOrg, emailSettings } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [defaultTemplates, setDefaultTemplates] = useState<Template[]>([]);
  const [userHasNoTemplates, setUserHasNoTemplates] = useState<boolean>(false);

  const [currentTemplateContentType, setCurrentTemplateContentType] =
    useState<TemplateContentType>(TemplateContentType.OUTREACH);
  const [fetchOutreachTemplatesLoading, setFetchOutreachTemplatesLoading] =
    useState<boolean>(true);
  const [saveTemplateLoading, setSaveTemplateLoading] =
    useState<boolean>(false);
  const [createTemplateDialogOpen, setCreateTemplateDialogOpen] =
    useState<boolean>(false);
  const [currentDraftBody, setCurrentDraftBody] = useState<string>("");

  const outreachTemplates = useMemo(() => {
    return templates?.filter?.(
      (x: Template) => x?.contentType === TemplateContentType.OUTREACH,
    );
  }, [templates]);

  const followupTemplates = useMemo(() => {
    return templates?.filter?.(
      (x: Template) => x?.contentType === TemplateContentType.FOLLOWUP,
    );
  }, [templates]);

  const templateWithIds = useMemo(() => {
    return templates?.filter?.((x: Template) => x?.id && !isNaN(x?.id));
  }, [templates]);

  const fetchOutreachTemplates = async (
    ignoreLoading: boolean = false,
  ): Promise<[Template[] | undefined, Template[] | undefined]> => {
    if (!currentOrg?.id) {
      return [undefined, undefined];
    }
    try {
      if (!ignoreLoading) {
        setFetchOutreachTemplatesLoading(true);
      }
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates`,
      );
      const fetchedTemplates = res.templates;
      setTemplates(fetchedTemplates);
      let defaultTemplates = res.defaultTemplates || [];
      if (defaultTemplates.length === 0) {
        setUserHasNoTemplates(true);
        defaultTemplates = [
          {
            name: "Default Outreach",
            subject: "",
            body: "",
            contentType: TemplateContentType.OUTREACH,
          },
        ];
      }
      setDefaultTemplates(defaultTemplates);
      return [fetchedTemplates, defaultTemplates];
    } catch (error) {
      setErrorAlert(error);
    } finally {
      if (!ignoreLoading) {
        setFetchOutreachTemplatesLoading(false);
      }
    }
    return [undefined, undefined];
  };

  const getLatestFollowUpEmail = async (currentContact: OutreachContact) => {
    const hasTemplateSettings =
      emailSettings?.emailSettingTemplates &&
      emailSettings?.emailSettingTemplates?.length > 0;
    const presetTemplate = hasTemplateSettings
      ? emailSettings?.emailSettingTemplates?.find((x) => x.emailOrder === 1)
      : null;
    const presetId = presetTemplate?.templateId;

    const name = currentContact?.contactName;
    let firstName = name ? name.split(" ")?.[0] : "";
    let replacedName = firstName || `${currentContact?.brand?.name} Team`;

    let body: string | undefined = followupTemplates?.[0]?.body;
    if (presetId) {
      body = followupTemplates?.find((x) => x.id === presetId)?.body;
    }
    if (!body) body = DEFAULT_FOLLOWUP_BODY;
    body = body.replaceAll("{Brand Name}", currentContact?.brand?.name || "");
    body = body.replaceAll("{First Name}", replacedName);

    return body;
  };

  useEffect(() => {
    fetchOutreachTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <OutreachTemplatesContext.Provider
      value={{
        templates,
        setTemplates,
        outreachTemplates,
        followupTemplates,
        fetchOutreachTemplatesLoading,
        setFetchOutreachTemplatesLoading,
        saveTemplateLoading,
        setSaveTemplateLoading,
        currentTemplateContentType,
        setCurrentTemplateContentType,
        fetchOutreachTemplates,
        defaultTemplates,
        setDefaultTemplates,
        userHasNoTemplates,
        setUserHasNoTemplates,
        templateWithIds,
        getLatestFollowUpEmail,
        createTemplateDialogOpen,
        setCreateTemplateDialogOpen,
        currentDraftBody,
        setCurrentDraftBody,
      }}
    >
      {children}
    </OutreachTemplatesContext.Provider>
  );
};

export { OutreachTemplatesProvider, OutreachTemplatesContext };
