const styles = {
  close: {
    color: "primary.main",
    fontSize: 14,
    position: "absolute",
    top: 10,
    right: 10,
    width: 20,
    height: 20,
  },
};

export default styles;
