import { Box, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ContactViewContext } from "contexts/ContactView";
import { useContext } from "react";

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  contactToDelete: number | undefined;
}

export default function RowMenu({
  anchorEl,
  open,
  handleClose,
  contactToDelete,
}: Props) {
  const {
    outreachContacts,
    setOutreachContacts,
    updateOutreachContactDefaultValues,
    setTotal,
  } = useContext(ContactViewContext);

  const handleDeleteRow = () => {
    if (!contactToDelete) return;
    const outreachContact = outreachContacts.find(
      (contact) => contact.id === contactToDelete,
    );
    if (outreachContact?.id && outreachContact.id > -1) {
      updateOutreachContactDefaultValues(outreachContact, { isArchived: true });
    }
    setOutreachContacts((prev) => {
      return prev.filter((c) => c.id !== contactToDelete);
    });
    setTotal((prev) => prev - 1);
    handleClose();
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuList dense sx={{ outline: "none" }}>
        <MenuItem onClick={handleDeleteRow}>
          <Box sx={{ mr: 1 }} component="i" className="fa-thin fa-trash" />
          Delete this row from table
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
