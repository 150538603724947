import { Box } from "@mui/material";
import { EditorContent, Editor as TipTapEditor } from "@tiptap/react";
import { useRef } from "react";

import styles from "./styles";

interface MenuBarProps {
  editor: any;
  error: boolean;
}

const MenuBar = ({ editor, error }: MenuBarProps) => {
  const editorContainerRef = useRef<HTMLDivElement>(null);

  if (!editor) {
    return null;
  }

  return (
    <Box
      sx={[
        styles.editor,
        styles.subjectEditor,
        styles.plainEditor,
        error && styles.error,
      ]}
      ref={editorContainerRef}
    >
      <EditorContent spellCheck={false} editor={editor} />
    </Box>
  );
};

interface EditorProps {
  editor: TipTapEditor;
  error?: boolean;
}

const SubjectEditor = ({ editor, error = false }: EditorProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      {editor && <MenuBar editor={editor} error={error} />}
    </Box>
  );
};

export default SubjectEditor;
