import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext } from "react";

import Drawer from "components/Drawer";
import { usePageSize } from "utils/usePageSize";

import { getStyles } from "./styles";

interface Props {
  children?: JSX.Element | JSX.Element[];
  handleClose: () => void;
}

export default function QuickSendComposeWrapper({
  children,
  handleClose,
}: Props) {
  const theme = useTheme();
  const { isLargeRecommendedPage } = usePageSize();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { draftView, draftOpen, quickSendBrandId } = useContext(
    QuickSendDrawerContext,
  );
  const { discoverTab } = useContext(DiscoverViewContext);

  const styles = getStyles(
    discoverTab !== null,
    !isLargeRecommendedPage && quickSendBrandId >= 0,
  );

  if (isMobileScreen) {
    return (
      <Drawer open={draftOpen} onClose={handleClose}>
        {children}
      </Drawer>
    );
  } else if (draftView === QuickSendTabView.EXPAND) {
    return <Box sx={styles.root}>{children}</Box>;
  } else {
    return <></>;
  }
}
