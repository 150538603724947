import { grey } from "@mui/material/colors";

const styles = {
  root: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    bottom: { xs: 60, md: 0 },
    overflow: "scroll",
    background: "white",
    zIndex: 1000,
    borderRadius: 3,
  },
  progressContainer: {
    width: "100%",
    pt: 2,
  },
  dialogTitleText: {
    backgroundColor: "yellow.light",
    borderBottom: `1px solid ${grey[300]}`,
  },
  dialogActions: {
    borderTop: `1px solid ${grey[300]}`,
  },
  brandName: {
    width: { xs: 100, md: 400 },
    textAlign: "center",
  },
  dialogContent: {
    my: 0,
    mx: 0,
    px: 0,
  },
  contactInfoContainer: { p: 2, borderRight: 1, borderRightColor: grey[200] },
  clipText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  link: {
    color: "primary.main",
    cursor: "pointer",
    maxWidth: 200,
    textOverflow: "ellipsis",
  },
};

export default styles;
