import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Grid, Popover, Typography } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { OrganizationUserContext } from "contexts/Organization";
import { TrackingColumnsContext } from "contexts/TrackingColumns";
import { TrackingColumnsFiltersContext } from "contexts/TrackingColumnsFilters";
import { useContext, useRef, useState } from "react";

import { PropertyFilter } from "features/Influencer/Tracking/schema";
import { fetcherAuth } from "utils/api";
import { useTrackerFilters } from "utils/useTrackerFilters";

import SingleFilter from "./SingleFilter";

type ContactPropertyWithFilter = {
  contactPropertyName?: string;
  contactPropertyId?: number;
  filter: PropertyFilter;
};

export default function TableFilterPopupMenu() {
  const parentRef = useRef();
  const { getToken } = useAuth();
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { viewLoading } = useContext(ContactViewSearchContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { selectedView } = useContext(ContactViewContext);
  const { displayedColumns, selectedColumns } = useContext(
    TrackingColumnsContext,
  );
  const { handleCloseFilterMenu, open, anchorEl } = useContext(
    TrackingColumnsFiltersContext,
  );

  const { handleSearch, contactViewParams } = useContext(
    ContactViewSearchContext,
  );
  const {
    handleAddFilter,
    onChangeFilter,
    onChangeOption,
    onRemoveFilter,
    getFilterValue,
    notSelectedColumns,
  } = useTrackerFilters();

  const [loading, setLoading] = useState(false);

  const updateFilters = async () => {
    const filtersBody: ContactPropertyWithFilter[] = [];
    for (const column of selectedColumns) {
      const selectedFilter = column?.contactViewProperty?.filters?.[0];
      if (!selectedFilter) {
        continue;
      }
      const filterBody: ContactPropertyWithFilter = {
        filter: selectedFilter,
      };
      if (column?.id) {
        filterBody["contactPropertyId"] = column?.id;
      } else if (column?.contactViewProperty?.contactPropertyName) {
        filterBody["contactPropertyName"] = column?.name;
      }
      filtersBody.push(filterBody);
    }
    try {
      setLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-views/${selectedView?.id}/update-filters`,
        "POST",
        {},
        {
          filters: filtersBody,
        },
      );
      setAlert("Successfully updated filters", "success");
      handleSearch({ query: contactViewParams.query, page: 1 });
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box ref={parentRef}>
      <Popover
        id={"filter-popup-menu"}
        open={open}
        container={parentRef.current} // used so that the loading backdrop appears above this popover
        anchorEl={anchorEl}
        onClose={handleCloseFilterMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 1 }}>
            In this view, filter records by{" "}
          </Typography>

          {selectedColumns?.map((column, index) => (
            <SingleFilter
              key={index}
              column={column}
              index={index}
              displayedColumns={displayedColumns}
              notSelectedColumns={notSelectedColumns}
              getFilterValue={getFilterValue}
              onChangeFilter={onChangeFilter}
              onChangeOption={onChangeOption}
              onRemoveFilter={onRemoveFilter}
            />
          ))}

          {notSelectedColumns?.length > 0 && (
            <LoadingButton
              size="small"
              onClick={handleAddFilter}
              disabled={viewLoading || loading}
            >
              Add Filter +
            </LoadingButton>
          )}

          <Divider sx={{ my: 2, mx: -4 }} />
          <Grid container alignItems="center" gap={1}>
            <LoadingButton
              variant="contained"
              disableElevation
              loading={loading}
              size="small"
              onClick={updateFilters}
            >
              Save Filters
            </LoadingButton>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
}
