import { useAuth } from "@clerk/clerk-react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmailActivity, GmailThread } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import EmailActivityIcon from "components/EmailActivityIcon";
import { formatDate } from "components/EmailThread/helpers";
import { fetcherAuth } from "utils/api";
import { sanitize } from "utils/sanitize";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface Props {
  gmailThread: GmailThread;
  selected?: boolean;
}

const EmailThreadRow = ({ gmailThread, selected = false }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getToken } = useAuth();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { currentContact } = useContext(OutreachContactsContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const [isTracked, setIsTracked] = useState<boolean>(false);
  const [emailActivities, setEmailActivities] = useState<EmailActivity[]>([]);

  const { setErrorAlert } = useContext(AlertContext);

  const openEmailDrawer = (event: CustomEvent) => {
    event.stopPropagation();
    trackEvent("Outreach Contact Email Thread Row Clicked", {
      "Gmail Thread ID": gmailThread?.id,
      contactId: currentContact?.id,
    });
    navigate(`email/${gmailThread.id}`);
  };

  const toField = gmailThread.messages
    .map((message) =>
      message.is_sent_by_user
        ? "me"
        : message.from_.name
          ? message.from_.name.split(" ")[0]
          : message.from_.email.split("@")[0],
    )
    .join(", ");

  const subject =
    (gmailThread.messages.length > 0 && gmailThread.messages[0].subject) ||
    "(no subject)";
  const snippet =
    gmailThread.messages.length > 0
      ? sanitize(gmailThread.messages[gmailThread.messages.length - 1].snippet)
      : "";
  const formattedDate =
    gmailThread.messages.length > 0
      ? formatDate(
          gmailThread.messages[gmailThread.messages.length - 1].date,
          true,
        )
      : "--";

  const fetchEmailActivities = async () => {
    if (!currentOrg || !currentContact) {
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${currentContact.id}/threads/${gmailThread.id}/email-activity`,
        "GET",
        {},
        {},
        true,
        false,
        false,
      );
      if (res.ok) {
        const { emailActivities } = await res.json();
        setEmailActivities(emailActivities);
        setIsTracked(true);
      } else {
        setIsTracked(false);
      }
    } catch (error) {
      setErrorAlert(error);
    }
  };

  useEffect(() => {
    fetchEmailActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, currentContact?.id, gmailThread.id]);

  const pendingEmailsCount = gmailThread?.pendingEmailsCount > 0 && (
    <Typography
      component="span"
      sx={{ fontSize: 12, ml: 1, color: "info.main" }}
    >
      | {gmailThread?.pendingEmailsCount} Scheduled
    </Typography>
  );

  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={1}
      onClick={openEmailDrawer}
      sx={[styles.accordion, selected && styles.selected]}
    >
      <Grid item xs="auto">
        <EmailActivityIcon
          id={gmailThread.id}
          type="thread"
          emailActivities={emailActivities}
          isTracked={isTracked}
        />
      </Grid>
      {!isMobileScreen && (
        <>
          <Grid item xs="auto">
            <Box sx={styles.toFieldWrapper}>
              <Typography component="div" variant="caption" sx={styles.toField}>
                {toField}{" "}
              </Typography>

              {gmailThread.messages.length > 1 && (
                <Typography
                  component="span"
                  variant="caption"
                  color="textSecondary"
                  sx={styles.numberOfEmails}
                >
                  {gmailThread.messages.length}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item sx={styles.subjectAndSnippet} xs>
            <Typography variant="caption">{subject}</Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: ` - ${snippet}`,
              }}
              noWrap
            ></Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography
              noWrap
              variant="caption"
              color="textSecondary"
              sx={styles.formattedDate}
            >
              {formattedDate}
            </Typography>

            {pendingEmailsCount}
          </Grid>
        </>
      )}
      {isMobileScreen && (
        <>
          <Grid item xs>
            <Box sx={styles.toFieldWrapperMobile}>
              <Typography component="div" sx={styles.toFieldMobile}>
                {toField}{" "}
              </Typography>

              {gmailThread.messages.length > 1 && (
                <Typography
                  component="span"
                  variant="caption"
                  color="textSecondary"
                  sx={styles.numberOfEmails}
                >
                  {gmailThread.messages.length}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Typography
              noWrap
              variant="caption"
              color="textSecondary"
              sx={styles.formattedDate}
            >
              {formattedDate}
            </Typography>
            {pendingEmailsCount}
          </Grid>
          <Grid item sx={styles.subjectAndSnippet} xs={11}>
            <Typography variant="caption">{subject}</Typography>
          </Grid>
          <Grid item sx={styles.subjectAndSnippet} xs={11}>
            <Typography
              variant="caption"
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: snippet,
              }}
              noWrap
            ></Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default EmailThreadRow;
