import { Box, Button } from "@mui/material";
import { SubscriptionContext, SubscriptionTab } from "contexts/Subscription";
import { useContext } from "react";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

const SubscriptionButton = () => {
  const { tab, setTab } = useContext(SubscriptionContext);

  const switchTab = (tabValue: SubscriptionTab) => {
    trackEvent("Subscription Plan Tab Changed", { Tab: tabValue });
    setTab(tabValue);
  };
  return (
    <Box sx={styles.container}>
      <Button
        onClick={() => switchTab(SubscriptionTab.monthly)}
        sx={tab === SubscriptionTab.monthly ? styles.active : styles.inactive}
      >
        Pay monthly
      </Button>
      <Button
        onClick={() => switchTab(SubscriptionTab.yearly)}
        sx={tab === SubscriptionTab.yearly ? styles.active : styles.inactive}
      >
        Pay yearly
        <Box sx={styles.yearlyText}>save 25%</Box>
      </Button>
    </Box>
  );
};

export default SubscriptionButton;
