import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { CustomEvent } from "schemas/functions";

import { TABLE_FILTER } from "features/Influencer/Tracking/events";
import { ContactViewProperty } from "features/Influencer/Tracking/schema";

import { ContactViewContext } from "./ContactView";

interface TrackingColumnsFiltersContextInterface {
  handleOpenFilterMenu: (e: CustomEvent) => void;
  handleCloseFilterMenu: () => void;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  highlightColumn: ContactViewProperty | null;
  setHighlightColumn: Dispatch<SetStateAction<ContactViewProperty | null>>;
  filterButtonRef: any;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  handleCloseFilterMenu: defaultContextMissingFunction,
  handleOpenFilterMenu: defaultContextMissingFunction,
  open: false,
  anchorEl: null,
  highlightColumn: null,
  setHighlightColumn: defaultContextMissingFunction,
  filterButtonRef: null,
};

const TrackingColumnsFiltersContext =
  createContext<TrackingColumnsFiltersContextInterface>(defaultInterface);

interface TrackingColumnsFiltersProviderProps {
  children: React.ReactNode;
}

const TrackingColumnsFiltersProvider = ({
  children,
}: TrackingColumnsFiltersProviderProps) => {
  const [highlightColumn, setHighlightColumn] =
    useState<ContactViewProperty | null>(null);
  const { trackTable } = useContext(ContactViewContext);
  const filterButtonRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpenFilterMenu = (event: CustomEvent) => {
    trackTable(TABLE_FILTER);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
    setHighlightColumn(null);
  };

  const open = Boolean(anchorEl);

  return (
    <TrackingColumnsFiltersContext.Provider
      value={{
        handleOpenFilterMenu,
        handleCloseFilterMenu,
        open,
        anchorEl,
        highlightColumn,
        setHighlightColumn,
        filterButtonRef,
      }}
    >
      {children}
    </TrackingColumnsFiltersContext.Provider>
  );
};

export { TrackingColumnsFiltersProvider, TrackingColumnsFiltersContext };
