import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface Props {
  handleClose: () => void;
  isLoading?: boolean;
  bentoBrand?: BentoBrand | null;
  isDraft?: boolean;
}

export default function QuickSendDialogHeader({
  handleClose,
  isLoading,
  bentoBrand,
  isDraft = false,
}: Props) {
  const { setView, setBrandView, setDraftView } = useContext(
    QuickSendDrawerContext,
  );
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleCloseDrawer = (e: CustomEvent) => {
    e.stopPropagation();
    trackEvent("QuickSend Navigation Button Clicked", {
      "Bento Brand ID": bentoBrand?.id,
      button: "Close View",
    });
    handleClose();
  };

  const handleHideDrawer = (e: CustomEvent) => {
    trackEvent("Hide QuickSend Button Clicked", {
      "Bento Brand ID": bentoBrand?.id,
      button: "Hide View",
    });
    if (isMobileScreen) {
      handleClose();
    } else {
      if (isDraft) {
        setDraftView(QuickSendTabView.COLLAPSE);
      } else {
        setView(QuickSendTabView.COLLAPSE);
        setBrandView(QuickSendTabView.COLLAPSE);
      }
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      onClick={handleHideDrawer}
      sx={styles.dialogTitleText}
    >
      <Typography component="div" noWrap>
        {isLoading ? (
          <Skeleton animation="wave" width={100} />
        ) : (
          bentoBrand?.brandName || "New Message"
        )}
      </Typography>

      <Box>
        <IconButton sx={styles.icon} onClick={handleCloseDrawer}>
          <Box component="i" className="fa-solid fa-xmark" />
        </IconButton>
      </Box>
    </Grid>
  );
}
