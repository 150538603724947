import { Box, Link, Tooltip, Typography } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import moment from "moment";
import { forwardRef, useContext, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";
import { OutreachContact, TaskType } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { ContactViewProperty } from "features/Influencer/Tracking/schema";
import { isDaysInFuture } from "utils/time";
import useTableMenu from "utils/useTableMenu";

import NotEditableMenu from "../../NotEditableMenu";
import { taskCellStyles as styles } from "./styles";

interface Props {
  property: ContactViewProperty;
  outreachContact: OutreachContact;
}
const TaskCell = forwardRef(({ property, outreachContact }: Props, ref) => {
  const navigate = useNavigate();
  const { trackCell } = useContext(ContactViewContext);
  const { anchorEl, setAnchorEl, open, handleClose, popperRef, menuOnBlur } =
    useTableMenu();

  const handleClick = (e: CustomEvent) => {
    if (task) {
      clickTask();
    } else {
      setAnchorEl(anchorEl ? null : e.currentTarget);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      childRefClick(e: CustomEvent) {
        handleClick(e);
      },
      childRefKeyPressed(e: CustomEvent) {
        handleClick(e);
      },
      childRefOnBlur(e: CustomEvent) {
        menuOnBlur(e);
      },
    };
  });

  const goToThread = (
    property: ContactViewProperty,
    outreachContact: OutreachContact,
  ) => {
    trackCell(property, outreachContact, {
      Action: "View Email Task",
    });
    let url = outreachContact?.lastGmailThreadId
      ? `${outreachContact?.id}/email/${outreachContact?.lastGmailThreadId}`
      : `${outreachContact?.id}/email`;
    navigate(url);
  };

  const task = outreachContact?.nextPendingTask;

  const clickTask = () => {
    trackCell(property, outreachContact, {
      Action: "Task Clicked",
    });
    if (task?.type === TaskType.EMAIL) {
      goToThread(property, outreachContact);
    }
  };
  const dueDate = task?.dueAt;
  const due =
    dueDate && typeof dueDate === "number"
      ? moment(dueDate * 1000).format("MMM DD, YYYY")
      : "";
  const isInFuture = due ? isDaysInFuture(due, "MMM DD, YYYY") : true;

  const getTaskDescription = () => {
    if (task?.description) return task?.description;
    if (task?.type === TaskType.EMAIL) {
      return "You need to send an email to this contact";
    } else {
      return "You have a task for this contact";
    }
  };

  const getIcon = () => {
    if (isInFuture) {
      return (
        <Link sx={styles.upcoming}>
          <Box
            component="i"
            className="fa-regular fa-note-sticky"
            sx={{ mr: 1 }}
          />
        </Link>
      );
    } else {
      return (
        <Link sx={styles.pending}>
          <Box component="i" className="fa-regular fa-warning" sx={{ mr: 1 }} />
        </Link>
      );
    }
  };

  return (
    <Box sx={{ px: 1 }}>
      <NotEditableMenu
        open={open}
        ref={popperRef}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
      {task ? (
        <Box onClick={clickTask}>
          <Tooltip
            title={
              due
                ? `You have a${
                    isInFuture ? "n upcoming" : " past due"
                  } task due on ${due}`
                : ""
            }
          >
            <Typography sx={{ fontSize: 12, minWidth: 200, cursor: "pointer" }}>
              {getIcon()}
              {getTaskDescription()}
            </Typography>
          </Tooltip>
        </Box>
      ) : (
        <Box
          sx={{ height: 30 }}
          onClick={(e: CustomEvent) =>
            setAnchorEl(anchorEl ? null : e.currentTarget)
          }
        />
      )}
    </Box>
  );
});

export default TaskCell;
