import { grey } from "@mui/material/colors";

const size = 30;

const styles = {
  brandContainer: {
    display: "flex",
    width: "100%",
    p: 2,
    gap: 2,
    alignItems: "center",
    borderBottom: `1px solid ${grey[200]}`,
    cursor: "pointer",
    "&:last-of-type": {
      borderBottom: 0,
    },
  },
  selected: {
    backgroundColor: grey[50],
  },
  avatar: {
    backgroundColor: "green.dark",
    width: size,
    height: size,
    fontSize: 13,
  },
  brandImage: {
    "& > img": { width: size },
    width: size,
    height: size,
  },
  brandName: {
    color: "primary.dark",
    display: "block",
    fontSize: 12,
  },
  removeButton: {
    color: "secondary.main",
    fontSize: 12,
    width: 20,
    height: 20,
  },
  lastContactPerson: {
    fontSize: { xs: 11, md: 12 },
    color: grey[600],
  },
  availableContact: {
    color: "info.main",
    fontSize: { xs: 11, md: 12 },
  },
  draft: {
    color: "error.main",
  },
  frozen: {
    color: grey[500],
  },
};

export default styles;
