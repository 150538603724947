import { Box, Drawer, Grid, IconButton } from "@mui/material";
import { UserRequestsContext } from "contexts/UserRequests";
import { useContext, useState } from "react";
import { SavedBrandCollection } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";
import { useCollection } from "utils/useCollection";

import UserRequestsList from "../UserRequestsList";
import FavoriteListTabMobile from "./ListHeader/MobileTab";
import styles from "./styles";

enum Tab {
  saved_brand = "saved_brand",
  user_requests = "user_requests",
}

interface Props {
  collections: JSX.Element;
  handleBack: () => void;
  selectedCollection: SavedBrandCollection | null;
  header: JSX.Element;
  footer: JSX.Element;
}

const FavoritesListMobile = ({
  collections,
  handleBack,
  selectedCollection,
  header,
  footer,
}: Props) => {
  const [tab, setTab] = useState<Tab>(Tab.saved_brand);

  const { totalUnsent, totalSent } = useCollection();
  const { total: totalRequests } = useContext(UserRequestsContext);
  const total = Number(totalUnsent) + Number(totalSent);
  const requests = (totalRequests.sent || 0) + (totalRequests.unsent || 0);

  const [expanded, setExpanded] = useState<boolean>(false);

  const onClose = () => {
    setExpanded((prevExpand) => !prevExpand);
  };

  const handleChange = (event: CustomEvent, newValue: Tab) => {
    event.stopPropagation();
    trackEvent("Saved Brand Tab Clicked", { tab: newValue });
    setTab(newValue);
    setExpanded(true);
  };

  return (
    <Drawer
      open={expanded}
      onClose={onClose}
      variant="permanent"
      anchor={"bottom"}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={styles.header}
      >
        <Grid container justifyContent="flex-start" item xs={10}>
          {selectedCollection && (
            <IconButton
              onClick={handleBack}
              sx={{ color: "white", fontSize: 13 }}
            >
              <Box component="i" className="fa-regular fa-arrow-left" />
            </IconButton>
          )}
          <FavoriteListTabMobile
            label="Saved"
            isActive={tab === Tab.saved_brand}
            total={total}
            handleChange={(e: CustomEvent) => handleChange(e, Tab.saved_brand)}
          />
          <FavoriteListTabMobile
            label="Requests"
            total={requests}
            isActive={tab === Tab.user_requests}
            handleChange={(e: CustomEvent) =>
              handleChange(e, Tab.user_requests)
            }
          />
        </Grid>
        <IconButton sx={styles.headerButton} onClick={onClose}>
          <Box
            component="i"
            className={`fa-solid fa-chevron-${expanded ? "down" : "up"}`}
          />
        </IconButton>
      </Grid>

      {expanded && tab !== Tab.user_requests && (
        <Grid container sx={styles.mobileContainer} gap={1}>
          {header}
          {collections}
          {footer}
        </Grid>
      )}

      {expanded && tab === Tab.user_requests && <UserRequestsList />}
    </Drawer>
  );
};

export default FavoritesListMobile;
