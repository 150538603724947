import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import styles from "./styles";

const DraftLoader = () => {
  const statements = [
    "Generating your email draft",
    "Checking for spelling mistakes",
    "Adding a personal touch",
    "Crafting a compelling subject line",
    "Reviewing email content for clarity",
  ];

  const [currentStatement, setCurrentStatement] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStatement((prev) => (prev + 1) % statements.length);
    }, 2500);

    return () => clearInterval(interval); // Clean up on component unmount
  }, [statements.length]);

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Typography sx={styles.text}>{statements[currentStatement]}</Typography>
      <Box sx={styles.dots}>
        <Box sx={[styles.dot, styles.dot1]}></Box>
        <Box sx={[styles.dot, styles.dot2]}></Box>
        <Box sx={[styles.dot, styles.dot3]}></Box>
      </Box>
    </Box>
  );
};

export default DraftLoader;
