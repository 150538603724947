import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import {
  AutogeneratedDraftsContext,
  TabPage,
} from "contexts/AutogenerateDrafts";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { useDrafts } from "utils/useDrafts";
import { usePageSize } from "utils/usePageSize";

const EmptyPage = () => {
  const { isLargeRecommendedPage } = usePageSize();
  const { tab } = useParams();
  const { isPolling } = useContext(AutogeneratedDraftsContext);
  const { getDrafts } = useDrafts();

  const drafts = getDrafts(tab);

  const getMessage = () => {
    if (tab && isPolling[tab as TabPage]) {
      return (
        <Typography component="div" variant="h6" textAlign="center">
          Please wait while we generate{" "}
          {tab === TabPage.bento_recommendations
            ? "personalized recommendations"
            : "outreach emails"}{" "}
          for you
          <Box sx={{ my: 2 }}>
            <LinearProgress />
          </Box>
        </Typography>
      );
    } else if (tab === TabPage.bento_recommendations && drafts?.length === 0) {
      return (
        <Typography component="div" variant="h6" textAlign="center">
          No Recommendations Found. Please come back later to see more
          personalized recommendations for you
        </Typography>
      );
    } else if (tab === TabPage.favorite_drafts && drafts?.length === 0) {
      return (
        <Typography component="div" variant="h6" textAlign="center">
          No Drafts from your Saved Brands List. To create a draft from your
          Saved Brands, first bookmark a brand from Brands, then click on{" "}
          <strong>Write Me Drafts</strong> button at the end of your Saved
          Brands list.
        </Typography>
      );
    } else {
      return <Typography>Please select a brand from the list.</Typography>;
    }
  };

  return isLargeRecommendedPage ? (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      item
      xs={12}
      sx={{ height: "90vh", width: "100%", px: 10 }}
    >
      {getMessage()}
    </Grid>
  ) : (
    <></>
  );
};

export default EmptyPage;
