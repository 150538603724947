import { useAuth } from "@clerk/clerk-react";
import { Box, DialogTitle } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import {
  QuickSendErrorContext,
  QuickSendErrorProvider,
} from "contexts/QuickSendError";
import {
  QuickSendScheduleContext,
  QuickSendScheduleProvider,
} from "contexts/QuickSendSchedule";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useRef, useState } from "react";
import { PreviewMessage } from "schemas/dashboard";
import { EmailAddress } from "schemas/email";

import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { SCHEDULED_DATE_EMAIL } from "utils/localStorage";
import { isInvalidEmail } from "utils/string";
import { isIsoStringPriorToCurrentTime } from "utils/time";
import { makeDeepCopy } from "utils/updateLocalState";

import QuickSendDialogHeader from "../DialogHeader";
import DraftBody from "../PreviewPerContact/DraftBody";
import DraftEmailHeader from "../PreviewPerContact/PreviewIndividual/DraftEmailHeader";
import SelectDraftsTab from "../PreviewPerContact/PreviewIndividual/SelectDraftTabs";
import QuickSendComposeWrapper from "../Wrapper/compose";
import styles from "../styles";

function ComposeDraftComponent() {
  const { setRemoveScheduled, setScheduleDialogSource } = useContext(
    QuickSendScheduleContext,
  );
  const { handleCloseDraftWindow } = useContext(QuickSendDrawerContext);
  const [toContacts, setToContacts] = useState<EmailAddress[]>([]);
  const [ccContacts, setCcContacts] = useState<EmailAddress[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<{
    [key in number]: Template | null;
  }>({});

  const { setAlert } = useContext(AlertContext);
  const { currentOrg, emailSettings, setEmailSettings } = useContext(
    OrganizationUserContext,
  );
  const { handleFetchContacts, fetchContactsParams } = useContext(
    OutreachContactsContext,
  );
  const { errors, setErrors, setErrorsFromMessage } = useContext(
    QuickSendErrorContext,
  );
  const { getToken } = useAuth();

  const {
    emailHealth,
    setEmailHealth,
    setOpenIntegrationDialog,
    fetchIntegrationHealth,
  } = useContext(UserIntegrationsContext);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const bottomRef = useRef<HTMLDivElement>();

  const [emailMessages, setEmailMessages] = useState<PreviewMessage[]>([
    {
      body: "",
      subject: "",
      contentType: TemplateContentType.INBOX,
    },
  ]);
  const [draftTab, setDraftTab] = useState<number>(0);

  const handleClose = () => {
    if (emailMessages.length > 1 || emailMessages[0].body.length > 1) {
      setConfirmDialogOpen(true);
      return;
    }

    handleCloseDraftWindow();
  };

  const validateSend = () => {
    for (const contact of ccContacts) {
      if (isInvalidEmail(contact.email)) {
        setAlert(
          `"${contact.email}" in your "cc" is not a properly formatted email. Please make sure it is properly formatted before sending`,
          "error",
        );
        return true;
      }
    }
    for (const contact of toContacts) {
      if (isInvalidEmail(contact.email)) {
        setAlert(
          `"${contact.email}" in your "to" is not a properly formatted email. Please make sure it is properly formatted before sending`,
          "error",
        );
        return true;
      }
    }
    return false;
  };

  const getUserScheduledAt = (sendAt?: null | string) => {
    if (sendAt && !isIsoStringPriorToCurrentTime(sendAt)) {
      localStorage.setItem(
        `${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`,
        sendAt?.toString(),
      );
      setRemoveScheduled(false);
    }

    setScheduleDialogSource(null);
    return sendAt;
  };

  const handleSendEmail = async (sendAt?: null | string): Promise<boolean> => {
    if (!currentOrg?.id) {
      return false;
    }

    if (validateSend()) {
      return false;
    }

    if (!emailHealth) {
      setOpenIntegrationDialog(true);
      return false;
    }

    try {
      setSendLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails`,
        "POST",
        {},
        {
          toContacts,
          ccContacts,
          emails: emailMessages,
          scheduleAt: getUserScheduledAt(sendAt),
          timezone: emailSettings?.timezone,
          hour: emailSettings?.hour,
        },
      );
      setEmailSettings(res.emailSetting);
      setAlert(
        "Successfully scheduled email! It will be sent shortly and can be viewed in your sent folder",
        "success",
      );
      handleCloseDraftWindow();
      // update the page to 1, so that the data gets refetched
      const updatedParams = { ...fetchContactsParams };
      updatedParams.page = 1;
      handleFetchContacts(updatedParams);
      return true;
    } catch (error) {
      const needsToScroll = setErrorsFromMessage(emailMessages, error?.message);
      if (needsToScroll) {
        bottomRef?.current?.scroll();
      }
      if (error?.message?.includes("connect to Gmail")) {
        setEmailHealth(false);
        setOpenIntegrationDialog(true);
      } else {
        setAlert(
          error?.message ||
            "Something went wrong. Unable to send outreach email. Please reload and retry again.",
          "error",
        );
      }
      return false;
    } finally {
      setSendLoading(false);
    }
  };

  useEffect(() => {
    if (emailHealth) {
      setOpenIntegrationDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailHealth]);

  useEffect(() => {
    if (currentOrg?.id) {
      fetchIntegrationHealth(currentOrg.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const changePreview = (
    name: string,
    text: string | undefined,
    index: number,
  ) => {
    setEmailMessages((prev: PreviewMessage[]) => {
      const copy = makeDeepCopy(prev);
      copy[index][name] = text;
      return copy;
    });
  };

  return (
    <>
      <QuickSendComposeWrapper handleClose={handleClose}>
        <Box sx={styles.quickSendWrapper}>
          <Box sx={styles.titleWrapper}>
            <DialogTitle sx={styles.dialogTitleText}>
              <QuickSendDialogHeader
                handleClose={handleClose}
                isLoading={false}
                isDraft
              />
              <DraftEmailHeader
                preview={emailMessages[0]}
                toContacts={toContacts}
                setToContacts={setToContacts}
                ccContacts={ccContacts}
                setCcContacts={setCcContacts}
                changeSubject={(newSubject: string) => {
                  setEmailMessages((prev) => {
                    const copy = [...prev];
                    copy[0].subject = newSubject;
                    return copy;
                  });
                }}
                errors={errors}
                setErrors={setErrors}
              />
              <SelectDraftsTab
                emailMessages={emailMessages}
                setEmailMessages={setEmailMessages}
                draftTab={draftTab}
                setDraftTab={setDraftTab}
                setSelectedTemplates={setSelectedTemplates}
              />
            </DialogTitle>
          </Box>
          <Box sx={styles.editorWrapper}>
            <Box sx={styles.dialogContent}>
              <DraftBody
                handleSendEmail={handleSendEmail}
                sendLoading={sendLoading}
                changePreview={changePreview}
                emailMessages={emailMessages}
                setEmailMessages={setEmailMessages}
                draftTab={draftTab}
                errors={errors}
                setErrors={setErrors}
                selectedTemplates={selectedTemplates}
                setSelectedTemplates={setSelectedTemplates}
              />
              <Box ref={bottomRef} />
            </Box>
          </Box>
        </Box>
      </QuickSendComposeWrapper>

      <ConfirmDialog
        header={`Are you sure?`}
        subheader={
          "You have an unsaved draft and your changes will be lost. Are you sure you want to navigate away from this page?"
        }
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={() => handleCloseDraftWindow()}
      />
    </>
  );
}

export default function ComposeDraftDrawer() {
  return (
    <QuickSendScheduleProvider>
      <QuickSendErrorProvider>
        <ComposeDraftComponent />
      </QuickSendErrorProvider>
    </QuickSendScheduleProvider>
  );
}
