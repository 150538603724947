import { useAuth } from "@clerk/clerk-react";
import { AlertContext } from "contexts/Alert";
import {
  AutogeneratedDraftsContext,
  TabPage,
} from "contexts/AutogenerateDrafts";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { BentoBrand, OutreachDraft } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "./api";
import { makeDeepCopy } from "./updateLocalState";
import { useCollection } from "./useCollection";
import { usePageSize } from "./usePageSize";

export const useDrafts = () => {
  const { tab } = useParams();
  const { getToken } = useAuth();
  const { isTwoColumnView } = usePageSize();
  const { removeDraftFromCollection } = useCollection();

  const { currentOrg } = useContext(OrganizationUserContext);
  const { bentoBrand } = useContext(QuickSendContext);
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { handleOpenQuickSendIndividual, closeDrawer, setIsEdited } =
    useContext(QuickSendDrawerContext);
  const {
    pollForNewDrafts,
    setRemoveLoading,
    setDraftsMap,
    draftsMap,
    getMapsKey,
    savedBrandCollectionId,
    brandsToTab,
    setBrandsToTab,
  } = useContext(AutogeneratedDraftsContext);

  const allSaved = `${TabPage.favorite_drafts}_0`;

  const openNextDraft = (updatedDraftList?: OutreachDraft[], index = 0) => {
    let bentoBrandToOpen = bentoBrand;
    if (updatedDraftList) {
      bentoBrandToOpen = updatedDraftList[index].bentoBrand;
    }
    if (!bentoBrandToOpen) return;
    handleOpenQuickSendIndividual(
      bentoBrandToOpen.id,
      "Autogenerated Drafts",
      undefined,
      !isTwoColumnView,
    );
  };

  const removeDraftApiCall = async (
    e: CustomEvent,
    bentoBrandToDelete: BentoBrand,
    source = "Recommendations",
    refreshList = true,
  ) => {
    if (!bentoBrandToDelete) return;
    e.stopPropagation();
    setRemoveLoading(Number(bentoBrandToDelete.id));
    setIsEdited(false);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-drafts/bento-brands/${bentoBrandToDelete.id}`,
        "DELETE",
        {},
        { source },
      );
      setAlert("Removed draft from your list", "success");
      if (refreshList) {
        refreshDraftsList(bentoBrandToDelete);
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setRemoveLoading(-1);
    }
  };

  const removeDrafts = (bentoBrand: BentoBrand) => {
    // Remove draft on the sidebar when user manually press Delete.
    setDraftsMap((prev) => {
      const key = getMapsKey(tab, savedBrandCollectionId);
      const copy = makeDeepCopy(prev);
      const { updatedDrafts: newDrafts, nextIndex } = _removed(
        bentoBrand.id,
        copy[key]?.drafts || [],
      );
      if (newDrafts?.length > 0) {
        openNextDraft(newDrafts, nextIndex);
      } else {
        closeDrawer();
      }
      if (key in copy) {
        copy[key] = { ...copy[key], drafts: newDrafts };
        if (key?.includes(TabPage.favorite_drafts)) {
          copy[allSaved] = { ...copy[key], drafts: newDrafts };
        }
      } else {
        copy[key] = { drafts: newDrafts };
      }
      return copy;
    });
  };

  const removeDraftFromList = (bentoBrand: BentoBrand, collectionId = 0) => {
    // Remove an OutreachDraft generated by user from all their Saved List if user delete
    // a brand from SavedBrandCollection.
    const bentoBrandId = bentoBrand.id;
    const key =
      bentoBrandId in brandsToTab ? brandsToTab[bentoBrandId] : allSaved;
    setDraftsMap((prev) => {
      const copy = makeDeepCopy(prev);

      if (copy?.[key]?.drafts) {
        const { updatedDrafts } = _removed(bentoBrandId, copy[key]?.drafts);
        copy[key]["drafts"] = updatedDrafts;
      }

      // Remove a draft from AllSaved list as well.
      if (key !== allSaved && copy?.[allSaved]?.drafts) {
        const { updatedDrafts: favoriteDrafts } = _removed(
          bentoBrandId,
          copy[allSaved]["drafts"],
        );
        copy[allSaved]["drafts"] = favoriteDrafts;
      }
      return copy;
    });

    setBrandsToTab((prev) => {
      const copy = makeDeepCopy(prev);
      delete copy[bentoBrandId];
      return copy;
    });
    removeDraftFromCollection(collectionId, bentoBrand);
  };

  const refreshDraftsList = async (bentoBrandToDelete: BentoBrand) => {
    // Clear out the deleted draft and refetching the list. Move to the next brand
    // after the list if brand is deleted.
    removeDrafts(bentoBrandToDelete);
    setBrandsToTab((prev) => {
      const copy = makeDeepCopy(prev);
      if (Number(bentoBrandToDelete.id) in copy) {
        delete copy[Number(bentoBrandToDelete.id)];
      }
      return copy;
    });
    if (tab) {
      pollForNewDrafts(tab as TabPage);
    }
  };

  const getDrafts = (tab: TabPage | string | undefined): OutreachDraft[] => {
    return (_retrieveMap(tab, "drafts") || []) as OutreachDraft[];
  };

  const getHitsLimit = (tab: TabPage | string | undefined): boolean => {
    return (_retrieveMap(tab, "hitsLimit") as boolean) || false;
  };

  const getCursor = (tab: TabPage | string | undefined): number[] | null => {
    return (_retrieveMap(tab, "cursor") as number[]) || null;
  };

  const updateMap = (
    tab: TabPage | string | undefined,
    property: "drafts" | "hitsLimit" | "cursor",
    newValue: any,
  ) => {
    const key = getMapsKey(tab, savedBrandCollectionId);
    setDraftsMap((prev) => {
      const copy = makeDeepCopy(prev);
      if (!(key in copy) || !(property in copy[key])) {
        return copy;
      }
      copy[key][property] = newValue;
      return copy;
    });
  };

  const moveDraftsFromOneListToAnother = (
    brands: BentoBrand[],
    newCollectionId: number,
    oldCollectionId: number,
  ) => {
    setDraftsMap((prev) => {
      const copy = makeDeepCopy(prev);
      const oldKey = `${TabPage.favorite_drafts}_${oldCollectionId}`;
      const newKey = `${TabPage.favorite_drafts}_${newCollectionId}`;
      const ids = brands?.map((x) => Number(x.id));

      let drafts = [];

      if (oldKey in copy) {
        drafts = copy[oldKey]?.drafts?.filter((draft: OutreachDraft) =>
          ids?.includes(draft?.bentoBrandId),
        );
        if (oldCollectionId > 0) {
          copy[oldKey]["drafts"] = copy[oldKey]?.drafts?.filter(
            (draft: OutreachDraft) => !ids?.includes(draft?.bentoBrandId),
          );
        }
      }
      if (newKey in copy) {
        copy[newKey]["drafts"] = [...(copy[newKey]["drafts"] || []), ...drafts];
      }
      return copy;
    });
  };

  const _retrieveMap = (
    tab: TabPage | string | undefined,
    property: "drafts" | "hitsLimit" | "cursor",
  ) => {
    const key = getMapsKey(tab, savedBrandCollectionId);
    if (key in draftsMap && property in draftsMap[key]) {
      // @ts-ignore
      return draftsMap[key][property];
    }
  };

  const _removed = (bentoBrandId: number, arr: OutreachDraft[]) => {
    const index = arr.findIndex(
      (draft) => Number(draft.bentoBrandId) === Number(bentoBrandId),
    );
    let nextIndex = 0;
    if (index > -1) {
      arr.splice(index, 1);
      nextIndex = Math.min(index, arr.length - 1);
    }
    return { updatedDrafts: arr, nextIndex };
  };

  return {
    openNextDraft,
    removeDraftApiCall,
    refreshDraftsList,
    getDrafts,
    getHitsLimit,
    getCursor,
    updateMap,
    removeDraftFromList,
    moveDraftsFromOneListToAnother,
  };
};
