import { Box, Button, Grid, Typography } from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { Dispatch, SetStateAction, useContext, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { BentoBrand, SavedBrandCollection } from "schemas/dashboard";

import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import BrandList from "./BrandList";
import Collection from "./SingleCollection";
import styles from "./styles";

interface Props {
  selectedCollection: SavedBrandCollection | null;
  setSelectedCollection: Dispatch<SetStateAction<SavedBrandCollection | null>>;
  isBulkMode: boolean;
  handleCreateNewList: (bulkMove?: boolean) => void;
  getTotal: (collectionId: number) => JSX.Element;
  displayedBrands: BentoBrand[];
}

const Collections = ({
  selectedCollection,
  setSelectedCollection,
  isBulkMode,
  handleCreateNewList,
  getTotal,
  displayedBrands,
}: Props) => {
  const {
    collections,
    totalsMap,
    pagesMap,
    setPagesMap,
    fetchLoading,
    fetchSavedBrandsForCollection,
    currentTab,
  } = useContext(SavedBrandCollectionsContext);
  const { moveLoading } = useContext(SavedBrandContext);

  const headRef = useRef<HTMLDivElement>(null);
  const [paginationRef, isPaginationRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const id = Number(selectedCollection?.id);
  const total = totalsMap(currentTab)[id];
  const page = pagesMap(currentTab)[id];

  const loading =
    total === undefined ||
    fetchLoading === id ||
    moveLoading ||
    page === undefined;

  useEffect(() => {
    if (
      isPaginationRefVisible &&
      selectedCollection &&
      displayedBrands &&
      displayedBrands.length < (total || 0) &&
      !loading
    ) {
      setPagesMap(currentTab)((prev) => {
        const copy = makeDeepCopy(prev);
        const updatedValue = copy[selectedCollection?.id] + 1;
        fetchSavedBrandsForCollection(selectedCollection?.id, updatedValue);
        const updated = {
          ...prev,
          [selectedCollection?.id]: updatedValue,
        };
        return updated;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaginationRefVisible, selectedCollection?.id, total, page]);

  useEffect(() => {
    if (headRef && headRef.current) {
      headRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection?.id]);

  if (selectedCollection) {
    return (
      <Grid>
        <div ref={headRef} />
        {displayedBrands && displayedBrands?.length > 0 ? (
          <BrandList
            displayedBrands={displayedBrands || []}
            loading={total === undefined}
            collectionId={selectedCollection.id}
            isBulkMode={isBulkMode}
            ref={paginationRef}
          />
        ) : (
          <Typography sx={styles.caption}>
            <em>You do not have any {currentTab} brands on this list</em>
          </Typography>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid sx={styles.container}>
        {collections?.map((collection) => (
          <Collection
            key={collection.id}
            collection={collection}
            setSelectedCollection={() => {
              setSelectedCollection(collection);
              trackEvent("Favorites List - Collection Viewed", {
                "Collection Name": collection.name,
                "Collection ID": collection.id,
              });
            }}
            getTotal={getTotal}
          />
        ))}
        {
          <Collection
            collection={{ id: 0, name: "All Saved" }}
            setSelectedCollection={() => {
              setSelectedCollection({ id: 0, name: "All Saved" });
              trackEvent("Favorites List - Collection Viewed", {
                "Collection Name": "All Saved",
              });
            }}
            getTotal={getTotal}
          />
        }
        <Box sx={styles.newList} onClick={() => handleCreateNewList(false)}>
          <Button sx={{ textTransform: "none" }}>New List +</Button>
        </Box>
      </Grid>
    );
  }
};

export default Collections;
