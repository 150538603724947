import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import React, { createContext, useContext, useEffect } from "react";

import { extensions } from "components/Editor/utils";
import { containsMergeTags } from "utils/string";
import { makeDeepCopy } from "utils/updateLocalState";

import { OutreachTemplatesContext } from "./OutreachTemplates";
import { QuickSendContext } from "./QuickSend";
import { QuickSendDrawerContext } from "./QuickSendDrawer";
import { QuickSendErrorContext } from "./QuickSendError";
import { QuickSendOutreachTabsContext } from "./QuickSendOutreachTabs";
import {
  replaceBodyCopied,
  replaceVariableWithMergeTag,
} from "./helpers/QuickSendHelpers";

interface QuickSendEditorContextInterface {
  editor: TipTapEditor | null;
}

const defaultInterface = {
  editor: null,
};

const QuickSendEditorContext =
  createContext<QuickSendEditorContextInterface>(defaultInterface);

interface QuickSendEditorProps {
  children: React.ReactNode;
}

const QuickSendEditorProvider = ({ children }: QuickSendEditorProps) => {
  const { setIsEdited } = useContext(QuickSendDrawerContext);
  const { errors, setErrors } = useContext(QuickSendErrorContext);
  const { outreachTab } = useContext(QuickSendOutreachTabsContext);
  const {
    bentoBrand,
    contactName,
    outreachMessages,
    setOutreachMessages,
    setTemplateForQuickSend,
  } = useContext(QuickSendContext);

  const preview = outreachMessages[outreachTab];
  const index = outreachTab;

  const { currentDraftBody, setCurrentDraftBody } = useContext(
    OutreachTemplatesContext,
  );

  // Set up TipTap Editor
  const editor = useEditor(
    {
      extensions: extensions({
        placeholderText: "Write an email to this company here...",
      }),
      content: preview?.body,
      onUpdate({ editor }) {
        setIsEdited(true);
        const hasMergeTag = containsMergeTags(editor.getHTML());
        if (
          hasMergeTag ||
          errors.outreachBody === index ||
          errors.mergeTag === index
        ) {
          setErrors((prev) => {
            const copy = makeDeepCopy(prev);
            copy.outreachBody = -1;
            if (hasMergeTag) {
              copy.mergeTag = index;
            } else {
              copy.mergeTag = -1;
            }
            return copy;
          });
        }
      },
      onBlur({ editor }) {
        let body = editor
          ? editor?.isEmpty
            ? ""
            : editor?.getHTML()
          : preview?.body;

        if (body !== preview?.body) {
          setOutreachMessages((prev) => {
            const copy = [...prev];
            copy[index]["body"] = replaceBodyCopied(
              contactName,
              bentoBrand?.brandName,
              body,
            );
            return copy;
          });

          setTemplateForQuickSend((prev) => {
            const copyQuickSend = [...prev];
            copyQuickSend[index]["body"] = contactName
              ? replaceVariableWithMergeTag(body, contactName)
              : body;
            return copyQuickSend;
          });
        }
      },
    },
    [
      !!preview?.body,
      preview?.templateId,
      preview?.templateName,
      index,
      contactName,
      bentoBrand?.brandName,
    ],
  ) as TipTapEditor;

  useEffect(() => {
    if (
      currentDraftBody?.length > 0 &&
      editor &&
      editor.getHTML() !== currentDraftBody
    ) {
      editor.commands.setContent(currentDraftBody, true);
      setCurrentDraftBody("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDraftBody, editor?.getHTML()]);

  return (
    <QuickSendEditorContext.Provider
      value={{
        editor,
      }}
    >
      {children}
    </QuickSendEditorContext.Provider>
  );
};

export { QuickSendEditorProvider, QuickSendEditorContext };
