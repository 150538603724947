import { Box, ButtonBase, IconButton } from "@mui/material";
import { useState } from "react";

import { trackEvent } from "utils/tracking";

import VideoDialog from "./VideoDialog";

const size = 120;
const styles = {
  bg: {
    width: size,
    height: size,
    borderRadius: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
  },
  image: {
    objectFit: "cover",
    width: size,
    height: size,
  },
  icon: {
    background: "rgba(0, 0, 0, 0.4)",
    color: "white",
    fontSize: 15,
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 30,
    height: 30,
    justifyContent: "center",
    transform: "translate(-50%, -50%)",
  },
};

interface Props {
  image: string;
  link: string;
  title: string;
  videoPreview?: string;
}

const Thumbnail = ({ image, link, title, videoPreview }: Props) => {
  const [open, setOpen] = useState(false);
  const playVideo = () => {
    trackEvent("Onboarding Video Clicked", { title });
    setOpen(true);
  };
  return (
    <>
      <ButtonBase
        onClick={playVideo}
        sx={{ width: size, height: size, borderRadius: 50 }}
      >
        <Box sx={styles.bg}>
          <Box src={image} component="img" sx={styles.image} />

          <IconButton sx={styles.icon}>
            <Box component="i" className="fa-solid fa-play" />
          </IconButton>
        </Box>
      </ButtonBase>

      <VideoDialog
        title={title}
        videoPreview={videoPreview}
        open={open}
        handleClose={() => setOpen(false)}
        link={link}
      />
    </>
  );
};

export default Thumbnail;
