import { useAuth } from "@clerk/clerk-react";
import { Button, CircularProgress } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import googleOauthImage from "assets/oauth/google.svg";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useState } from "react";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

interface GoogleConnectProps {
  onSuccessAction?: () => Promise<void>;
}

export default function GoogleConnect({ onSuccessAction }: GoogleConnectProps) {
  const { getToken } = useAuth();
  const { setAlert } = useContext(AlertContext);
  const { currentOrg, currentUser, setUserIntegrations } = useContext(
    OrganizationUserContext,
  );
  const { setScopes, setEmailHealth, setUserIntegration } = useContext(
    UserIntegrationsContext,
  );

  const [loading, setLoading] = useState<boolean>(false);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      if (!currentOrg) return;
      try {
        setLoading(true);

        const res = await fetcherAuth(
          getToken,
          `/api/oauth/google/${currentOrg?.id}/callback`,
          "POST",
          {},
          tokenResponse,
        );

        setScopes(res.scopes);
        setEmailHealth(res.emailHealth);
        setUserIntegration(res.userIntegration);
        setUserIntegrations(res.userIntegrations);
        if (onSuccessAction && res.emailHealth) {
          await onSuccessAction();
        }
      } catch (error) {
        setAlert(
          error.message ? error.message : "Unable to connect with Google",
          "error",
        );
      } finally {
        setLoading(false);
      }
    },
    onError: () => {
      setAlert("Unable to connect with Google", "error");
      setLoading(false);
    },
    hint: currentUser?.email,
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
  });

  const handleConnectToGoogle = () => {
    trackEvent("Connect To Google Button Clicked");
    googleLogin();
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Button onClick={handleConnectToGoogle}>
      <img src={googleOauthImage} alt="Connect with Google" />
    </Button>
  );
}
