import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { useContext, useState } from "react";
import { CustomEvent } from "schemas/functions";

import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { trackEvent } from "utils/tracking";

import SingleTemplate from "./SingleTemplate";
import styles from "./styles";

const TemplateList = () => {
  const { setDiscoverTab } = useContext(DiscoverViewContext);
  const { templateWithIds, setCreateTemplateDialogOpen } = useContext(
    OutreachTemplatesContext,
  );
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );

  const handleAddTemplate = () => {
    trackEvent("Add Template Button Clicked", { from: "Sidebar" });
    setCreateTemplateDialogOpen(true);
  };

  const handleSwitchTemplate = (template: Template) => {
    trackEvent("Template Selected", {
      "Template ID": template?.id,
      from: "Sidebar",
    });
    setSelectedTemplate(template);
  };

  const renderTemplateRow = (template: Template) => {
    return (
      <Grid
        container
        justifyContent="center"
        sx={styles.templateRow}
        alignItems="center"
        onClick={() => handleSwitchTemplate(template)}
        key={template.id}
      >
        <Grid item xs={11}>
          <Typography noWrap sx={styles.templateName}>
            {template?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color:
                template?.contentType === TemplateContentType.FOLLOWUP
                  ? "secondary.main"
                  : "green.main",
            }}
          >
            {capitalize(template?.contentType)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Tooltip title="View Template">
            <IconButton sx={{ color: "primary.main", fontSize: 12 }}>
              <Box component="i" className="fa-regular fa-arrow-right" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const closeDialog = () => {
    setDiscoverTab(null);
  };

  const goBack = (e: CustomEvent) => {
    setSelectedTemplate(null);
    e.stopPropagation();
  };

  const renderView = () => {
    if (selectedTemplate && selectedTemplate?.body) {
      return (
        <SingleTemplate
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      );
    } else {
      return (
        <>
          {templateWithIds?.map((template) => renderTemplateRow(template))}

          <Grid item xs={12} sx={styles.addTemplate}>
            <Link sx={styles.templateLink} onClick={handleAddTemplate}>
              Create New Template +
            </Link>
          </Grid>
        </>
      );
    }
  };

  return (
    <Box sx={styles.root}>
      <Grid
        container
        justifyContent="space-between"
        onClick={closeDialog}
        alignItems="center"
        sx={styles.header}
      >
        {selectedTemplate && (
          <IconButton sx={styles.close} onClick={goBack}>
            <Box component="i" className="fa-regular fa-arrow-left" />
          </IconButton>
        )}
        <Typography noWrap sx={{ maxWidth: 300, fontSize: 14 }} variant="h6">
          {selectedTemplate ? selectedTemplate?.name : "TEMPLATES"}
        </Typography>
        <IconButton sx={styles.close}>
          <Box component="i" className="fa-regular fa-xmark" />
        </IconButton>
      </Grid>
      {renderView()}
    </Box>
  );
};

export default TemplateList;
