import { useAuth } from "@clerk/clerk-react";
import { Grid, Slide, useTheme } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import {
  ENTER_DELAY,
  EXIT_DELAY,
  SlideAnimationContext,
  SlideAnimationProvider,
} from "contexts/SlideAnimation";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { BentoBrand, OrganizationType, SearchParams } from "schemas/dashboard";
import { Map } from "schemas/functions";
import { routes } from "schemas/routes";

import Alert from "components/Alert";
import Loading from "components/Loading";
import { PAGE_VISITED } from "constants/trackingProps";
import { TemplateHighlight } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";
import { fetcherAuth } from "utils/api";
import { getSource, identifyUser, trackEvent } from "utils/tracking";
import { useAlert } from "utils/useAlert";

import AboutForm from "./AboutForm";
import CategoryForm from "./CategoryForm";
import CustomizePartOneEmail from "./CustomizePartOneEmail";
import CustomizePartTwoEmail from "./CustomizePartTwoEmail";
import EmailBrandForm from "./EmailBrandForm";
import Introduction from "./Introduction";
import PitchEmailForm from "./PitchEmailForm";
import RecommendationForm from "./RecommendationForm";
import SelectBrandForm from "./SelectBrandForm";
import styles from "./styles";

const OnboardingInfluencer = () => {
  const {
    currentOrg,
    currentUser,
    setCurrentUser,
    setCurrentOrg,
    setUserNotifications,
    setUserIntegrations,
    setEmailSettings,
    setHideCityDialog,
    setProfile,
  } = useContext(OrganizationUserContext);
  const theme = useTheme();
  const { setSubscription, subscription } = useContext(SubscriptionContext);
  const [searchParams] = useSearchParams();
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);

  const { slideOutUp, slideInUp, slideProps } = useContext(
    SlideAnimationContext,
  );

  const referralCode = searchParams.get(SearchParams.REFERRAL_CODE);

  const [message, severity, setAlert, closeAlert] = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const { getToken, signOut } = useAuth();

  const [loading, setLoading] = useState(false);
  const [fetchUserLoading, setFetchUserLoading] = useState(true);
  const [firstDraftLoaded, setFirstDraftLoaded] = useState(false);
  const [recommendedBrands, setRecommendedBrands] = useState<BentoBrand[]>([]);
  const [cursor, setCursor] = useState<number[]>([]);
  const [selectedBrandId, setSelectedBrandId] = useState<number | undefined>();

  const source = getSource();

  const [onboardingForm, setOnboardingForm] = useState<Map>({
    name: currentUser?.name || "",
    location: "",
    brands: [],
    wishlistBrands: [],
    categories: [],
    isUgcCreator: null,
    pastBrandValidation: null,
    primarySocialMediaPlatform: "instagram",

    // These fields are auto-filled when user sign up
    email: "",
    organizations: [],
    organizationId: "",
    organizationName: "",
    type: "influencer",
  });

  const fetchUserInfo = async () => {
    try {
      const res = await fetcherAuth(getToken, `/api/organization/auth/user`);
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setSubscription(res.subscription);
      setUserIntegrations(res.userIntegrations);
      setUserNotifications(res.userNotifications);
      setEmailSettings(res.emailSetting);
      if (res.organizationProfile) {
        const copy = { ...res.organizationProfile };
        setProfile(copy);
      }

      setFetchUserLoading(false);

      const hasOnboarded = Boolean(res?.organizationProfile?.hasOnboarded);

      // Edge case: user has not sent an email, but want to book an onboarding call
      // then after they checkout, redirect back to dashboard instead of making
      // them go to onboarding even if they hasn't send an email.
      const isCheckingOut = searchParams.get("checkout");

      // Redirect to dashboard if they already onboard.
      if (hasOnboarded || isCheckingOut) {
        navigate(`/${routes.brands}/all${location.search}`);
        return;
      }

      setHideCityDialog(true);

      if (res.organizationProfile) {
        setOnboardingForm((prev) => ({
          ...prev,
          ...res.organizationProfile,
          name: res.organizationUser.name,
        }));
      } else {
        setOnboardingForm((prev) => ({
          ...prev,
          name: res.organizationUser.name,
        }));
      }

      slideInUp();

      if (!res?.organizationProfile) {
        navigate(`/${routes.aboutOnboarding}${location.search}`);
        return;
      }

      const hasCategories =
        res.organizationProfile.categories.length > 0 ||
        res.organizationProfile.selectedCategories.length > 0;
      if (!hasCategories) {
        navigate(`/${routes.categoryOnboarding}${location.search}`);
        return;
      }

      if (
        !new RegExp(`${routes.onboardingInfluencer}/?$`).test(location.pathname)
      ) {
        return;
      }

      if (res?.organizationProfile?.introduction) {
        // If the user is coming back to the app and hasn't sent their first email,
        // but has an introduction, then skip to the email selection page as opposed
        // to making the user go through the whole onboarding again
        navigate(`/${routes.selectBrandOnboarding}${location.search}`);
        return;
      }

      if (
        res?.organizationProfile?.hasPitchEmail !== null &&
        res?.organizationProfile?.hasPitchEmail !== undefined
      ) {
        if (res?.organizationProfile?.hasPitchEmail) {
          navigate(`/${routes.selectBrandOnboarding}${location.search}`);
        } else {
          navigate(`/${routes.customizePartOneOnboarding}${location.search}`);
        }
        return;
      }

      if (
        res?.organizationProfile?.preferredBrandSize &&
        res?.organizationProfile?.preferredBrandSize.length > 0
      ) {
        navigate(`/${routes.pitchOnboarding}${location.search}`);
        return;
      }

      if (
        res?.organizationProfile?.categories &&
        res?.organizationProfile?.categories.length > 0
      ) {
        navigate(`/${routes.recommendationOnboarding}${location.search}`);
        return;
      }

      navigate(`/${routes.aboutOnboarding}${location.search}`);
    } catch (error) {
      handleClerkUserSignUp();
    }
  };

  const handleClerkUserSignUp = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/auth/signup?type=${OrganizationType.INFLUENCER}`,
        "POST",
        {},
        {
          referralCode,
          source: referralCode ? "referral" : source,
        },
      );
      identifyUser(res.organizationUser.id);
      setCurrentUser(res.organizationUser);
      setCurrentOrg(res.organization);
      setOnboardingForm((prev) => ({
        ...prev,
        name: res.organizationUser.name,
      }));

      setHideCityDialog(true);
      navigate(`/${routes.aboutOnboarding}${location.search}`);
      slideInUp();
    } catch (error) {
      // the user needs to be logged out now
      signOut();
      navigate(`/${routes.signIn}`);
      setAlert(
        error?.message || "Unable to fetch information from user.",
        "error",
      );
    } finally {
      setFetchUserLoading(false);
    }
  };

  const saveOnboardingForm = async (onboardingForm: Map, page: string) => {
    setLoading(true);

    const onboardingFormReq = {
      ...onboardingForm,
      source,
      page,
    };
    if (page) {
      trackEvent("Onboarding Form - Next Button Pressed", { page });
    }
    try {
      const { organizationProfile } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding`,
        "PUT",
        {},
        onboardingFormReq,
      );

      await slideOutUp();

      setOnboardingForm((prev) => ({
        ...prev,
        ...organizationProfile,
      }));
      setProfile({ ...organizationProfile });

      if (page === "about") {
        navigate(`/${routes.categoryOnboarding}${location.search}`);
      } else if (page === "category") {
        navigate(`/${routes.recommendationOnboarding}${location.search}`);
      } else if (page === "recommendations") {
        navigate(`/${routes.pitchOnboarding}${location.search}`);
      } else if (page === "pitch") {
        if (!onboardingForm.hasPitchEmail) {
          navigate(`/${routes.introductionOnboarding}${location.search}`);
        } else {
          navigate(`/${routes.selectBrandOnboarding}${location.search}`);
        }
      } else if (page === "introduction") {
        navigate(`/${routes.customizePartOneOnboarding}${location.search}`);
      } else if (page === "emailPartOne") {
        if (
          onboardingForm?.defaultTemplateHighlight ===
          TemplateHighlight.PORTFOLIO
        ) {
          navigate(`/${routes.selectBrandOnboarding}${location.search}`);
        } else {
          navigate(`/${routes.customizePartTwoOnboarding}${location.search}`);
        }
      } else if (page === "emailPartTwo") {
        navigate(`/${routes.selectBrandOnboarding}${location.search}`);
      }
      slideInUp();
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to save your information. Please reload and try again",
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchBrandRecommendations = async (restart: boolean = false) => {
    setLoading(true);
    setSelectedBrandId(undefined);
    if (restart) {
      setCursor([]);
    }
    try {
      let url = `/api/organization/${currentOrg?.id}/onboarding/brand-recommendations`;
      if (cursor && !restart) {
        url += `?cursor=${JSON.stringify(cursor)}`;
      }
      const res = await fetcherAuth(getToken, url, "GET");
      setCursor(res.cursor);
      setRecommendedBrands(res.bentoBrands);
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to fetch brand recommendations. Please reload and try again",
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  const enqueueGenerateRecommendations = async () => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-drafts/generate`,
        "POST",
      );
    } catch (error) {}
  };

  const fetchEmailPreview = async (selectedBrandId: number) => {
    setLoading(true);
    setSelectedBrandId(selectedBrandId);
    const fetchUserRequired = !subscription;
    if (fetchUserRequired) {
      try {
        const res = await fetcherAuth(getToken, `/api/organization/auth/user`);
        setSubscription(res.subscription);
        setUserIntegrations(res.userIntegrations);
        setUserNotifications(res.userNotifications);
        setEmailSettings(res.emailSetting);
        if (res.organizationProfile) {
          const copy = { ...res.organizationProfile };
          setProfile(copy);
        }
      } catch (error) {
        setAlert(
          error?.message ||
            "Unable to fetch brand email. Please reload and try again",
          "error",
        );
        setLoading(false);
        return;
      }
    }
    try {
      const url = `/api/organization/${currentOrg?.id}/onboarding/bento-brands/${selectedBrandId}/generate-draft`;
      await fetcherAuth(getToken, url, "POST");
      setLoading(false);
      if (fetchUserRequired && !firstDraftLoaded) {
        setFirstDraftLoaded(true);
      } else {
        handleOpenQuickSendIndividual(selectedBrandId, "Onboarding Form");
      }
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to fetch brand email. Please reload and try again",
        "error",
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    // The firstDraftLoaded variable is used if the fetching of user is required before opening
    // quicksend. Since quicksend requires additional state updates to happen first after fetching the user, we need to
    // open it in a useEffect after all the state updates completed
    if (firstDraftLoaded && selectedBrandId) {
      handleOpenQuickSendIndividual(selectedBrandId, "Onboarding Form");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDraftLoaded, selectedBrandId]);

  useEffect(() => {
    fetchUserInfo();
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fetchUserLoading ? (
    <Loading />
  ) : (
    <>
      <Grid container sx={styles.root}>
        <Slide
          in={slideProps.in}
          direction={slideProps.direction}
          easing={{
            enter: theme.transitions.easing.easeInOut,
            exit: theme.transitions.easing.easeIn,
          }}
          timeout={{
            enter: ENTER_DELAY,
            exit: EXIT_DELAY,
          }}
        >
          <Grid sx={styles.formContainer} item xs={12}>
            <Routes>
              <Route
                path="/about"
                element={
                  <AboutForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/category"
                element={
                  <CategoryForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/recommendation"
                element={
                  <RecommendationForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/pitch"
                element={
                  <PitchEmailForm
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/introduction"
                element={
                  <Introduction
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/customize/1"
                element={
                  <CustomizePartOneEmail
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/customize/2"
                element={
                  <CustomizePartTwoEmail
                    onboardingForm={onboardingForm}
                    setOnboardingForm={setOnboardingForm}
                    saveOnboardingForm={saveOnboardingForm}
                    loading={loading}
                  />
                }
              />
              <Route
                path="/select-brand"
                element={
                  <SelectBrandForm
                    onboardingForm={onboardingForm}
                    loading={loading}
                    selectedBrandId={selectedBrandId}
                    setSelectedBrandId={setSelectedBrandId}
                    refreshBrands={fetchBrandRecommendations}
                    recommendedBrands={recommendedBrands}
                    enqueueGenerateRecommendations={
                      enqueueGenerateRecommendations
                    }
                  />
                }
              />
              <Route
                path="/email-brand/:brandId"
                element={
                  <EmailBrandForm
                    loading={loading}
                    fetchEmailPreview={fetchEmailPreview}
                  />
                }
              />
            </Routes>
          </Grid>
        </Slide>
      </Grid>

      <Alert message={message} severity={severity} closeAlert={closeAlert} />
    </>
  );
};

export default function Onboarding() {
  return (
    <SlideAnimationProvider>
      <OnboardingInfluencer />
    </SlideAnimationProvider>
  );
}
