import { Box, CardHeader, Grid, Typography } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { BentoBrand } from "schemas/dashboard";

import BrandLogo from "features/Influencer/Discover/Shared/BrandLogo";
import BrandTitle from "features/Influencer/Discover/Shared/BrandTitle";

import styles from "./styles";

interface Props {
  bentoBrand: BentoBrand;
  similarBrands: BentoBrand[];
}
const BrandSimilar = ({ similarBrands }: Props) => {
  const { handleOpenBrandDetails, handleOpenQuickSendIndividual } = useContext(
    QuickSendDrawerContext,
  );
  const { setBrandNavigationHistory } = useContext(DiscoverViewContext);

  const onClick = (brand: BentoBrand) => {
    setBrandNavigationHistory((prev) => [...prev, brand]);
    handleOpenBrandDetails(brand, "Similar Brands");
    handleOpenQuickSendIndividual(
      brand?.id,
      "Similar Brands",
      undefined,
      false,
    );
  };

  return (
    <>
      <Typography variant="h6" paragraph component="div">
        Similar Brands
      </Typography>
      <Grid gap={2}>
        {similarBrands?.map((brand, index) => (
          <Box key={index} sx={styles.card} onClick={() => onClick(brand)}>
            <CardHeader
              sx={{
                ".MuiCardHeader-avatar": {
                  alignSelf: "flex-start",
                  mt: 1.5,
                },
              }}
              avatar={<BrandLogo bentoBrand={brand} />}
              title={
                <Grid
                  container
                  alignItems="center"
                  gap={1}
                  justifyContent="space-between"
                >
                  <BrandTitle bentoBrand={brand} hideMetadata={true} />
                </Grid>
              }
            />
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default BrandSimilar;
