import { grey } from "@mui/material/colors";

const styles = {
  templateContainer: {
    borderRadius: 2,
    overflow: "hidden",
    "&:hover": {
      cursor: "pointer",
    },
  },
  exampleBody: {
    p: 2,
    "& p": {
      m: 0,
    },
    "& p:empty::after": {
      content: '"\\00A0"',
    },
  },
  active: {
    width: "50%",
    backgroundColor: "primary.main",
    color: "white",
    borderRadius: 0,
    "&:hover": {
      color: "black",
    },
  },
  inactive: {
    width: "50%",
    backgroundColor: grey[200],
    color: "black",
    borderRadius: 0,
  },
};

export default styles;
