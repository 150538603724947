import { Box, Grid, Skeleton } from "@mui/material";
import { inRange } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { PreviewMessage } from "schemas/dashboard";
import {
  HandleChangePreviewFunction,
  OutreachEmailErrors,
} from "schemas/outreach";

import {
  HandleSendEmail,
  Template,
} from "features/Influencer/ContactList/schema";

import IndividualDraftBody from "./PreviewIndividual/IndividualDraftBody";
import styles from "./styles";

interface DraftBodyProps {
  handleSendEmail: HandleSendEmail;
  sendLoading: boolean;
  changePreview: HandleChangePreviewFunction;
  emailMessages: PreviewMessage[];
  setEmailMessages: Dispatch<SetStateAction<PreviewMessage[]>>;
  draftTab: number;
  errors: OutreachEmailErrors;
  setErrors: Dispatch<SetStateAction<OutreachEmailErrors>>;
  selectedTemplates: { [key in number]: Template | null };
  setSelectedTemplates: Dispatch<
    SetStateAction<{ [key in number]: Template | null }>
  >;
}

export default function DraftBody({
  handleSendEmail,
  sendLoading,
  changePreview,
  emailMessages,
  setEmailMessages,
  draftTab,
  errors,
  setErrors,
  selectedTemplates,
  setSelectedTemplates,
}: DraftBodyProps) {
  const outreach = emailMessages?.[0];
  let followups: PreviewMessage[];

  if (emailMessages?.length > 1) {
    followups = emailMessages.slice(1, emailMessages?.length);
  }

  const skeleton = (
    <Grid container sx={styles.skeletonGroup} gap={2}>
      <Skeleton animation="wave" variant="rounded" height={30} width="80%" />
      <Skeleton animation="wave" variant="rounded" height={30} width="100%" />
      <Skeleton animation="wave" variant="rounded" height={200} width="100%" />
    </Grid>
  );

  const renderPage = () => {
    if (draftTab > 0 && inRange(draftTab - 1, 0, followups?.length)) {
      const followup = followups[draftTab - 1];
      return (
        <IndividualDraftBody
          preview={followup}
          changePreview={changePreview}
          setEmailMessages={setEmailMessages}
          index={draftTab}
          handleSendEmail={handleSendEmail}
          sendLoading={sendLoading}
          errors={errors}
          setErrors={setErrors}
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
        />
      );
    } else if (outreach) {
      return (
        <IndividualDraftBody
          preview={outreach}
          setEmailMessages={setEmailMessages}
          changePreview={changePreview}
          index={0}
          handleSendEmail={handleSendEmail}
          sendLoading={sendLoading}
          errors={errors}
          setErrors={setErrors}
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
        />
      );
    } else {
      return skeleton;
    }
  };

  return <Box>{renderPage()}</Box>;
}
