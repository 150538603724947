import { useAuth } from "@clerk/clerk-react";
import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";

import Alert from "components/Alert";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import StyledPage from "components/Styled/Page";
import { fetcherAuth } from "utils/api";
import { useAlert } from "utils/useAlert";

import styles from "./styles";

const AdminElasticsearch = () => {
  const { getToken } = useAuth();
  const [message, severity, setAlert, closeAlert] = useAlert();

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  enum Index {
    BENTO_BRANDS = "bento-brands",
  }

  const putIndex = async (index: Index, del: boolean) => {
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/admin/elasticsearch/${index}/index`,
        "PUT",
        {},
        { delete: del },
        false,
      );
      setAlert(`Saved ${index} index`, "success");
    } catch (error) {
      setAlert(error?.message, "warning");
    } finally {
      setLoading(false);
    }
  };

  const putMapping = async (index: Index) => {
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/admin/elasticsearch/${index}/mapping`,
        "PUT",
        {},
        false,
      );
      setAlert(`Saved ${index} mapping`, "success");
    } catch (error) {
      setAlert(error?.message, "warning");
    } finally {
      setLoading(false);
    }
  };

  const putAll = async (index: Index) => {
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/admin/elasticsearch/${index}/all`,
        "POST",
        {},
        false,
      );
      setAlert(`Enqueued tasks to update all documents in ${index}`, "success");
    } catch (error) {
      setAlert(error?.message, "warning");
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledPage
      content={
        <Grid sx={{ mt: 2 }}>
          <Typography variant="h3" gutterBottom>
            <strong>Elasticsearch</strong>
          </Typography>
          <Typography variant="h4" gutterBottom>
            <strong>Bento Brands</strong>
          </Typography>
          <Grid container>
            <Button
              variant="contained"
              color="primary"
              sx={styles.esButton}
              disabled={loading}
              onClick={() => {
                putIndex(Index.BENTO_BRANDS, false);
              }}
            >
              Create Index
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={styles.esButton}
              disabled={loading}
              onClick={() => {
                setShowConfirmDialog(true);
              }}
            >
              Create Index (w/ Delete)
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={styles.esButton}
              disabled={loading}
              onClick={() => {
                putMapping(Index.BENTO_BRANDS);
              }}
            >
              Update Mapping
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={styles.esButton}
              disabled={loading}
              onClick={() => {
                putAll(Index.BENTO_BRANDS);
              }}
            >
              Update All Documents
            </Button>
          </Grid>
          <Alert
            message={message}
            severity={severity}
            closeAlert={closeAlert}
          />
          <ConfirmDialog
            header={`Recreate Index`}
            subheader={`Are you sure you want to delete and recreate the index?`}
            open={showConfirmDialog}
            handleClose={() => {
              setShowConfirmDialog(false);
            }}
            handleConfirm={() => {
              setShowConfirmDialog(false);
              putIndex(Index.BENTO_BRANDS, true);
            }}
            loading={loading}
          />
        </Grid>
      }
    />
  );
};

export default AdminElasticsearch;
