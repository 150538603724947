import { grey } from "@mui/material/colors";

const styles = {
  iconButton: {
    fontSize: 14,
    color: "secondary.main",
    width: 20,
    height: 20,
    mt: 2,
  },
  highlight: {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#59C0A4",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "59C0A4",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "59C0A4",
    },
  },
  box: {
    mt: 1,
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    color: grey[400],
  },
};

export default styles;
