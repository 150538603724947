import {
  Box,
  Checkbox,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import {
  ColumnDialog,
  SelectMenuItem,
  TrackingColumnsContext,
} from "contexts/TrackingColumns";
import lodash from "lodash";
import { useContext, useState } from "react";
import { PropertyType } from "schemas/dashboard";

import Drawer from "components/Drawer";
import { NEW_COLUMN_TYPE_SELECTED } from "features/Influencer/Tracking/events";
import { ColumnIcons } from "features/Influencer/Tracking/schema";

import CustomColumnDialog from "./CustomColumn";
import styles from "./styles";

interface Props {
  open: boolean;
  handleClose: () => void;
}
export default function AddColumnMenu({ open, handleClose }: Props) {
  const { trackTable, selectedView } = useContext(ContactViewContext);
  const { viewLoading } = useContext(ContactViewSearchContext);
  const { sortedMenuItems, dialogType } = useContext(TrackingColumnsContext);
  const { handleCreateColumn, handleHideColumn } = useContext(
    TrackingColumnsContext,
  );

  const [selectedType, setSelectedType] = useState<PropertyType | null>(null);

  const props = selectedView?.contactViewProperties;

  const handleClick = (item: SelectMenuItem) => {
    if (viewLoading) return;
    if (item.checked) {
      const sProp = props?.find(
        (x) =>
          x.contactPropertyName === item.name ||
          x.contactPropertyId === item.id,
      );
      if (!sProp || !sProp.id) {
        return;
      }
      handleHideColumn(sProp.id);
    } else {
      if (item.id) {
        handleCreateColumn({
          contactPropertyId: item.id,
        });
      } else {
        handleCreateColumn(
          {
            contactPropertyName: item.name,
          },
          item.name === "brand_name",
        );
      }
    }
  };

  const openColumn = (type: PropertyType) => {
    setSelectedType(type);
    trackTable(NEW_COLUMN_TYPE_SELECTED, {
      "Column Type": type,
    });
  };

  const renderType = (icon: string, title: string, type: PropertyType) => {
    return (
      <Box
        key={type}
        sx={[styles.item, { my: 1 }]}
        onClick={() => openColumn(type)}
      >
        <Box component="i" className={`fa-thin ${icon}`} sx={{ mr: 1 }} />
        {title}
      </Box>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      width={300}
    >
      <DialogContent>
        <Tooltip title="Back">
          <IconButton onClick={handleClose} sx={styles.back}>
            <Box component="i" className="fa-regular fa-arrow-left" />
          </IconButton>
        </Tooltip>

        {dialogType === ColumnDialog.add && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6">Add New Column</Typography>

            {Object.values(PropertyType)?.map((type) =>
              renderType(ColumnIcons[type], lodash.startCase(type), type),
            )}
          </Box>
        )}

        {dialogType === ColumnDialog.edit && (
          <>
            <Typography variant="h6">Hide / Show Columns</Typography>
            <DialogContentText>
              {sortedMenuItems?.map((item, index) => (
                <Box
                  key={index}
                  sx={styles.item}
                  onClick={() => handleClick(item)}
                >
                  <Checkbox
                    size="small"
                    icon={
                      <Box component="i" className="fa-regular fa-square" />
                    }
                    checkedIcon={
                      <Box component="i" className="fa-solid fa-square-check" />
                    }
                    checked={item.checked}
                    disabled={
                      viewLoading ||
                      (item.name === "email" && selectedView?.name === "All")
                    }
                  />
                  {item.title}
                </Box>
              ))}
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <CustomColumnDialog
        open={selectedType !== null}
        selectedType={selectedType}
        handleClose={() => setSelectedType(null)}
        setSelectedType={setSelectedType}
        closeDrawer={handleClose}
      />
    </Drawer>
  );
}
