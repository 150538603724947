export const getStyles = (
  isSavedBrandOpen: boolean,
  isQuickSendOpen: boolean,
) => {
  let position = {
    width: {},
    right: {},
  };
  if (!isQuickSendOpen) {
    position = {
      width: { md: 325, lg: isSavedBrandOpen ? 325 : 400, xl: 500 },
      right: { xs: isSavedBrandOpen ? 424 : 100 },
    };
  } else if (!isSavedBrandOpen) {
    // quick send is open, toolbar is closed
    position = {
      width: { md: 380, lg: 380, xl: 500 },
      right: {
        md: 520,
        xl: 628,
      },
    };
  } else {
    // quick send and toolbar is open
    position = {
      width: { md: 210, lg: 300, xl: 500 },
      right: {
        md: 680,
        lg: 850,
        xl: 978,
      },
    };
  }

  return {
    root: {
      position: "fixed",
      bottom: 0,
      zIndex: 3,
      boxShadow: 3,
      backgroundColor: "primary.main",
      py: 1,
      px: 2,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      cursor: "pointer",
      ...position,
    },
    recommendationPage: {},
    icon: {
      color: "white",
      fontSize: 12,
    },
    text: {
      color: "white",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: { xs: 80, md: 250 },
    },
  };
};
