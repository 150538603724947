import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SubscriptionContext } from "contexts/Subscription";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import lodash from "lodash";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { CustomEvent } from "schemas/functions";

import { Template } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { isDaysInFuture } from "utils/time";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useCollection } from "utils/useCollection";

import TemplateScreen from "../CustomizationDialog/TemplateScreen";
import styles from "./styles";
import { useTimeCalculation } from "./useTimeCalculation";

interface Props {
  open: boolean;
  handleClose: () => void;
  setIsBulkMode: Dispatch<SetStateAction<boolean>>;
}

export default function BulkSendDialog({
  open,
  handleClose,
  setIsBulkMode,
}: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [templates, setTemplates] = useState<Template[]>([]);

  const { bulkMoveToSent } = useCollection();
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { bulkBrands, setBulkBrands, setSelectAll } =
    useContext(SavedBrandContext);
  const { currentOrg, emailSettings, setEmailSettings } = useContext(
    OrganizationUserContext,
  );
  const { getToken } = useAuth();
  const { getFutureHours, convertTimeToUtc, getDateOptions } =
    useTimeCalculation();
  const { setUpgradeDialogSource, subscription, setSubscription } =
    useContext(SubscriptionContext);
  const { emailHealth, setOpenIntegrationDialog, fetchIntegrationHealth } =
    useContext(UserIntegrationsContext);

  const dateOptions = getDateOptions();
  const [loading, setLoading] = useState(false);
  const [datePicked, setDatePicked] = useState(dateOptions[0]);
  const [customDatePicked, setCustomDatePicked] = useState({
    start: "",
    end: "",
  });
  const [timePicked, setTimePicked] = useState({
    start: "",
    end: "",
  });

  const timezone = emailSettings?.timezone || moment.tz.guess();
  const handleTimezoneChange = (
    event: CustomEvent,
    newValue: string | null,
  ) => {
    if (newValue) {
      trackEvent("Timezone Changed", { timezone: newValue });
      setEmailSettings((prev) =>
        prev
          ? {
              ...prev,
              timezone: newValue,
            }
          : prev,
      );
    }
  };

  const close = () => {
    handleClose();
    setIsBulkMode(false);
    setBulkBrands([]);
    setSelectAll(false);
  };

  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    trackEvent("Bulk Schedule Date Selected", { Option: value });
    setDatePicked(value);
  };

  const handlePickTime = (e: CustomEvent, name: string) => {
    const { value } = e.target;
    trackEvent("Bulk Schedule Date Selected", { Option: value });
    setTimePicked((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const selectCustomeTime = (e: CustomEvent, name: string) => {
    const { value } = e.target;
    if (value && !isDaysInFuture(value)) {
      setAlert("Please pick a date in future", "error");
      return;
    }
    setCustomDatePicked((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validHours = getFutureHours(datePicked);

  const startSend = async () => {
    // Check Paywall
    if (
      subscription?.remainingMessages &&
      bulkBrands?.length > subscription?.remainingMessages
    ) {
      setAlert(
        `It looks like you're trying to send ${bulkBrands?.length} messages, but your current plan allows only ${subscription?.remainingMessages} more this billing cycle. Please reduce the number of messages or upgrade your plan to send more.`,
        "warning",
      );
      setUpgradeDialogSource("Hits Bulk Send Limit");
      return;
    }

    if (!emailHealth) {
      setOpenIntegrationDialog(true);
      return;
    }

    if (
      datePicked === "customized" &&
      !(customDatePicked?.end && customDatePicked?.start)
    ) {
      setAlert("Please pick a start and end date", "error");
      return;
    }

    if (!(timePicked?.start && timePicked?.end)) {
      setAlert("Please pick a start and end time", "error");
      return;
    }

    const indexOfStart = validHours?.findIndex((x) => x === timePicked?.start);
    const indexOfEnd = validHours?.findIndex((x) => x === timePicked?.end);
    if (datePicked === "today" && indexOfStart >= indexOfEnd) {
      setAlert("Start time must be before end time", "error");
      return;
    }

    const { startDate, endDate, startTime, endTime } = convertTimeToUtc(
      datePicked,
      customDatePicked,
      timePicked,
      timezone,
    );

    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/emails/bulk-schedule`,
        "POST",
        {},
        {
          startDate,
          endDate,
          startTime,
          endTime,
          templateIds: templates?.map((x) => x.id),
          bentoBrandIds: bulkBrands?.map((x) => x.id),
        },
      );
      bulkMoveToSent(makeDeepCopy(bulkBrands));
      setAlert(
        "Successfully scheduled your emails. You can see them in Inbox page, under Scheduled tab. It might take a few minutes see all emails",
        "success",
      );
      setSubscription((prev) => {
        if (prev) {
          prev["remainingMessages"] = Math.max(
            (prev?.remainingMessages || 0) - bulkBrands?.length,
            0,
          );
        }
        return prev;
      });
      close();
    } catch (error) {
      if (error?.message?.includes("Google integration")) {
        setOpenIntegrationDialog(true);
      } else {
        setErrorAlert(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (emailHealth) {
      setOpenIntegrationDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailHealth]);

  useEffect(() => {
    if (currentOrg?.id) {
      fetchIntegrationHealth(currentOrg.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const timeWindowLabel = (
    <Typography component="span" sx={styles.label}>
      Time Window:
    </Typography>
  );

  return (
    <Dialog open={open} onClose={close} fullScreen={isMobileScreen}>
      <DialogTitle>
        <Typography variant="h6">
          Schedule {bulkBrands?.length} Emails
        </Typography>
      </DialogTitle>

      <Box sx={{ px: 3 }}>
        <strong>Select Templates</strong>
        <Typography variant="body2" sx={styles.subtitle}>
          Bento will automatically populate your outreach emails using your
          templates.
        </Typography>

        <TemplateScreen templates={templates} setTemplates={setTemplates} />
      </Box>

      <Grid container sx={{ px: 3, pt: 2 }} alignItems="center">
        <strong>Select Date and Time</strong>
        <Typography variant="body2" sx={styles.subtitle}>
          Bento will automatically spread out your emails through the selected
          timeframe (business days).
        </Typography>

        <Box alignItems="center">
          <Typography component="span" sx={styles.label}>
            Send All:
          </Typography>
          <Select
            onChange={handleChange}
            value={datePicked}
            sx={styles.noBorder}
            size="small"
          >
            {dateOptions?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Box sx={styles.option}>{lodash.startCase(item)}</Box>
              </MenuItem>
            ))}
          </Select>
        </Box>

        {datePicked === "customized" && (
          <Grid container alignItems="center" gap={1}>
            <Typography sx={[styles.label, { fontSize: 12 }]}>
              Start Date
            </Typography>
            <Box
              component="input"
              sx={styles.datePicker}
              type="date"
              value={customDatePicked?.start}
              onChange={(e) => selectCustomeTime(e, "start")}
            />
            <Typography sx={[styles.label, { fontSize: 12 }]}>
              End Date
            </Typography>
            <Box
              component="input"
              sx={styles.datePicker}
              type="date"
              value={customDatePicked?.end}
              onChange={(e) => selectCustomeTime(e, "end")}
            />
          </Grid>
        )}

        <Grid container alignItems="center">
          <Typography sx={styles.label}>Select Timezone:</Typography>

          <Autocomplete
            options={moment.tz.names()}
            value={timezone}
            onChange={handleTimezoneChange}
            renderInput={(params) => (
              <TextField {...params} sx={styles.timezonePicker} />
            )}
            disableClearable
            size="small"
          />
        </Grid>

        <Grid container alignItems="center">
          {isMobileScreen ? (
            <Grid item xs={12}>
              {timeWindowLabel}
            </Grid>
          ) : (
            timeWindowLabel
          )}

          <Select
            onChange={(e) => handlePickTime(e, "start")}
            value={timePicked?.start}
            sx={[styles.noBorder, isMobileScreen ? { ml: -2 } : {}]}
            displayEmpty
            size="small"
          >
            <MenuItem value="">
              <Box sx={styles.option}>Pick Start Time</Box>
            </MenuItem>
            {validHours?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Box sx={styles.option}>{item}</Box>
              </MenuItem>
            ))}
          </Select>
          <Typography sx={[styles.label, { fontSize: 12 }]}>to</Typography>
          <Select
            onChange={(e) => handlePickTime(e, "end")}
            value={timePicked?.end}
            sx={styles.noBorder}
            displayEmpty
            size="small"
          >
            <MenuItem value="">
              <Box sx={styles.option}>Pick End Time</Box>
            </MenuItem>
            {validHours?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Box sx={styles.option}>{item}</Box>
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <DialogActions>
        <Button onClick={close} size="small" color="secondary">
          Cancel
        </Button>

        <LoadingButton
          disableElevation
          size="small"
          variant="contained"
          loading={loading}
          onClick={startSend}
        >
          Start Send{" "}
          <Box
            sx={{ ml: 1 }}
            component="i"
            className="fa-regular fa-paper-plane"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
