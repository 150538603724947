import { green, grey, red } from "@mui/material/colors";

const styles = {
  root: {
    overflow: "auto",
    height: "100vh",
  },
  header: {
    py: 1.5,
    px: 2,
    borderBottom: `1px solid ${grey[300]}`,
  },
  close: {
    fontSize: 14,
    width: 30,
    height: 30,
  },
  tab: {
    fontSize: 12,
    color: grey[500],
    px: 1,
    py: 0,
    minHeight: "40px",
    height: "40px",
  },
  tabs: { minHeight: "40px", height: "40px" },
  tabsWrapper: {
    px: 2,
    py: 1,
  },
  metricsWrapper: {
    px: 3,
    py: 1,
  },
  descriptionText: {
    ml: 2,
    color: grey[500],
  },
  metricText: {
    mb: 0.5,
  },
  title: {
    mt: 5,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 12,
  },
  infoIcon: {
    ml: 0.5,
    color: grey[700],
  },
  arrowUp: {
    mr: 1,
    color: green[500],
  },
  arrowDown: {
    mr: 1,
    color: red[500],
  },
};

export default styles;
