import { grey } from "@mui/material/colors";

const styles = {
  header: {
    py: 1.5,
    px: 2,
    borderBottom: `1px solid ${grey[300]}`,
  },
  tabContainer: {
    borderBottom: `1px solid ${grey[200]}`,
    alignItems: "center",
    width: { xs: "100vw", md: "auto" },
  },
  tab: {
    fontSize: 13,
  },
  close: {
    fontSize: 14,
    width: 30,
    height: 30,
  },
  container: {
    maxHeight: "calc(100vh - 130px)",
    overflow: "auto",
    pb: { xs: 0, md: 15 },
  },
  footer: {
    position: "absolute",
    backgroundColor: "white",
    bottom: 0,
  },
  newRequest: {
    cursor: "pointer",
    fontSize: 12,
    display: "flex",
    width: "100%",
    p: 2,
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderColor: grey[200],
  },
};

export default styles;
