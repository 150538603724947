import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { SelectMenuItem } from "contexts/TrackingColumns";
import { TrackingColumnsFiltersContext } from "contexts/TrackingColumnsFilters";
import { useContext } from "react";
import { PropertyType } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { FilterType, SelectOption } from "features/Influencer/Tracking/schema";
import { titleCase } from "utils/string";

import styles from "./styles";

interface Props {
  column: SelectMenuItem;
  index: number;
  displayedColumns: SelectMenuItem[];
  notSelectedColumns: SelectMenuItem[];
  getFilterValue: (column: SelectMenuItem) => string[];
  onChangeFilter: (
    oldColumn: SelectMenuItem,
    newColumn: SelectMenuItem,
    index: number,
  ) => void;
  onChangeOption: (
    column: SelectMenuItem,
    index: number,
    value: string[],
  ) => void;
  onRemoveFilter: (column: SelectMenuItem, index: number) => void;
}

const SingleFilter = ({
  column,
  index,
  displayedColumns,
  notSelectedColumns,
  getFilterValue,
  onChangeFilter,
  onChangeOption,
  onRemoveFilter,
}: Props) => {
  const type = column?.contactViewProperty?.filters?.[0]?.type;
  const isCheckbox = column?.type === PropertyType.checkbox;
  const { highlightColumn } = useContext(TrackingColumnsFiltersContext);

  const handleChangeFilter = (e: CustomEvent) => {
    const { value } = e.target;
    const matchedColumn = displayedColumns?.find(
      (column) => column.name === value,
    );
    if (matchedColumn) {
      onChangeFilter(column, matchedColumn, index);
    }
  };

  const handleSelectOption = (e: CustomEvent) => {
    let { value } = e.target;
    onChangeOption(column, index, value);
  };

  const renderSelectedValue = (selected: string[] | boolean[]) => {
    if (
      selected?.length > 0 &&
      (type === FilterType.customBuiltIn || isCheckbox)
    ) {
      return selected?.map((s) => titleCase(s?.toString())).join(", ");
    } else if (selected?.length > 0) {
      return selected
        .map(
          (id) =>
            column?.selectOptions?.find((option) => option.id === id)?.name,
        )
        .join(", ");
    } else {
      return <em>Select Filter</em>;
    }
  };

  const isChecked = (option: SelectOption) => {
    return Boolean(
      getFilterValue(column)?.find(
        (x) =>
          x?.toString()?.toLowerCase() === option?.id ||
          x?.toString()?.toLowerCase() === option?.name,
      ),
    );
  };

  const addHighlight =
    column?.contactViewProperty?.id &&
    column.contactViewProperty?.id === highlightColumn?.id;

  return (
    <Grid container gap={1} alignItems="center">
      <FormControl sx={{ width: 200 }}>
        <FormHelperText sx={{ ml: 0 }}>Column Name</FormHelperText>
        <Select
          sx={addHighlight ? styles.highlight : {}}
          size="small"
          onChange={handleChangeFilter}
          value={column.name}
        >
          <MenuItem value={column.name} key={column.name}>
            {column.title}
          </MenuItem>
          {notSelectedColumns?.map((x) => (
            <MenuItem value={x.name} key={x.name}>
              {x.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ width: 300 }}>
        <FormHelperText sx={{ ml: 0 }}>Filtered By</FormHelperText>
        <Select
          size="small"
          onChange={handleSelectOption}
          value={getFilterValue(column)}
          sx={addHighlight ? styles.highlight : {}}
          multiple
          displayEmpty
          renderValue={(selected) => renderSelectedValue(selected)}
        >
          <MenuItem disabled value="">
            <em>Select Filter</em>
          </MenuItem>
          {column?.selectOptions?.map((option, index) => (
            <MenuItem value={option?.id || option?.name} key={index}>
              <Checkbox checked={isChecked(option)} />
              {titleCase(option?.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip title="Remove filter">
        <IconButton
          sx={styles.iconButton}
          onClick={() => onRemoveFilter(column, index)}
        >
          <Box component="i" className="fa-regular fa-xmark" />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default SingleFilter;
