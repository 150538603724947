import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import {
  QuickSendScheduleContext,
  ScheduleTimeDialogSource,
} from "contexts/QuickSendSchedule";
import { Moment } from "moment";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { SCHEDULED_DATE_EMAIL } from "utils/localStorage";
import {
  addBusinessDays,
  isBusinessHour,
  isIsoStringPriorToCurrentTime,
  isPastMoment,
} from "utils/time";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface ScheduleDialogProps {
  open: boolean;
  onSchedule: (sendAt: string) => void;
}

export default function ScheduleDialog({
  open,
  onSchedule,
}: ScheduleDialogProps) {
  const { scheduleDialogSource, setScheduleDialogSource } = useContext(
    QuickSendScheduleContext,
  );
  const { setAlert } = useContext(AlertContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { emailSettings, setEmailSettings } = useContext(
    OrganizationUserContext,
  );

  const { currentOrg } = useContext(OrganizationUserContext);

  const [dateValue, setDateValue] = useState<Moment>(
    moment().startOf("hour").add(1, "hour"),
  );
  const presetSendAt = localStorage.getItem(
    `${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`,
  );

  const timezone = emailSettings?.timezone || moment.tz.guess();
  const setTimezone = (timezone: string) => {
    // @ts-ignore
    setEmailSettings({
      ...emailSettings,
      timezone,
    });
  };

  const handleSchedule = (e: CustomEvent) => {
    const isPast = isPastMoment(dateValue);
    if (isPast) {
      setAlert("The time you select is in the past", "error");
    } else {
      localStorage.setItem(
        `${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`,
        dateValue.toISOString(),
      );
      e.stopPropagation();

      if (scheduleDialogSource !== ScheduleTimeDialogSource.CHANGE) {
        onSchedule(dateValue.toISOString());
      }
      setScheduleDialogSource(null);
    }
  };

  const handleTimezoneChange = (
    event: CustomEvent,
    newValue: string | null,
  ) => {
    event.stopPropagation();
    if (newValue) {
      trackEvent("Timezone Changed", { timezone: newValue });
      setTimezone(newValue);
    }
  };

  useEffect(() => {
    if (presetSendAt && !isIsoStringPriorToCurrentTime(presetSendAt)) {
      setDateValue(moment(presetSendAt).tz(timezone));
    } else if (emailSettings?.scheduledAt) {
      const hour = moment(emailSettings?.scheduledAt, "hh:mm");
      const businessNextDay = addBusinessDays(1, hour.clone());
      setDateValue(businessNextDay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSettings?.scheduledAt]);

  useEffect(() => {
    const hour = dateValue?.hour();
    // @ts-ignore
    setEmailSettings((prev) => ({
      ...prev,
      hour,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);

  return (
    <Dialog
      open={open}
      onClose={() => setScheduleDialogSource(null)}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobileScreen}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          Schedule Email
          <Button variant="contained" disableElevation onClick={handleSchedule}>
            {scheduleDialogSource === ScheduleTimeDialogSource.CHANGE
              ? "Select Time"
              : "Schedule"}
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={styles.content}
        onClick={(e: CustomEvent) => e.stopPropagation()}
      >
        <Box sx={styles.timezonePicker}>
          <Autocomplete
            options={moment.tz.names()}
            value={timezone}
            onChange={handleTimezoneChange}
            renderInput={(params) => <TextField {...params} label="Timezone" />}
          />
        </Box>

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
          <StaticDateTimePicker
            value={dateValue}
            timezone={timezone}
            onChange={(newValue: Moment | null) =>
              newValue && setDateValue(newValue)
            }
            disablePast
            slotProps={{
              actionBar: {
                actions: [],
              },
            }}
            views={["day", "hours"]}
            sx={{
              ".MuiDateTimePickerToolbar-ampmSelection .MuiButton-text .Mui-selected":
                {
                  color: "#7db986 !important",
                },
            }}
          />

          <FormHelperText sx={{ my: 1 }}>
            Follow-up emails will be spaced out 4 business days after each other
          </FormHelperText>
        </LocalizationProvider>

        {!isBusinessHour(dateValue.toISOString(), timezone) && (
          <Box sx={styles.tipContainer}>
            <Typography variant="body2">
              ℹ️ Tip: Brands are more likely to respond when you email them
              during <strong>business hours</strong>!
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
