import moment from "moment";

import { addBusinessDays } from "utils/time";

type DateTimePicker = {
  start: string;
  end: string;
};

enum Day {
  today = "today",
  tomorrow = "tomorrow",
  this_week = "this week",
  next_week = "next week",
  customized = "customized",
}

export const useTimeCalculation = () => {
  const getFutureHours = (datePicked: string) => {
    const hoursArray = [];

    const businessStartTime = 7; // 7 AM
    const businessEndTime = 20; // 7 PM
    const hoursPerHalfDay = 12;

    const _getCurrentHour = () => {
      if (datePicked === Day.today) {
        const currentDate = new Date();
        let currentHour = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();

        // If minutes are greater than 0, move to the next hour
        if (currentMinutes > 0) {
          currentHour++;
        }
        return currentHour;
      } else {
        return businessStartTime;
      }
    };

    const currentHour = _getCurrentHour();

    for (let i = currentHour; i < businessEndTime; i++) {
      // Convert to 12-hour format
      const hour =
        i % hoursPerHalfDay === 0 ? hoursPerHalfDay : i % hoursPerHalfDay;
      const period = i >= hoursPerHalfDay ? "PM" : "AM";
      const timeString = `${hour}:00 ${period}`;
      hoursArray.push(timeString);
    }
    return hoursArray;
  };

  const getDateStartAndEndTime = (
    datePicked: string,
    customDatePicked: DateTimePicker,
  ) => {
    let start: any = moment();
    let end: any = moment();
    if (datePicked === Day.tomorrow || datePicked === Day.next_week) {
      start = addBusinessDays(1);
      end = addBusinessDays(1);
    } else if (datePicked === Day.this_week) {
      start = addBusinessDays(1);
      end = moment(start).day(5);
    } else if (datePicked === Day.customized) {
      start = moment(customDatePicked?.start);
      end = moment(customDatePicked?.end);
    }
    return { start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") };
  };

  const convertTimeToUtc = (
    datePicked: string,
    customDatePicked: DateTimePicker,
    timePicked: DateTimePicker,
    timezone: string,
  ) => {
    const { start, end } = getDateStartAndEndTime(datePicked, customDatePicked);
    const startInUtc = moment
      .tz(`${start} ${timePicked?.start}`, "YYYY-MM-DD hh:mm A", timezone)
      .utc();
    const endInUtc = moment
      .tz(`${end} ${timePicked?.end}`, "YYYY-MM-DD hh:mm A", timezone)
      .utc();

    const startDate = startInUtc.format("YYYY-MM-DD");
    const endDate = endInUtc.format("YYYY-MM-DD");
    const startTime = startInUtc.format("HH:mm");

    const maxStartInUtc = moment.utc(`${end} 22:00`, "YYYY-MM-DD HH:mm");
    const maxEndInUtc = moment.utc(`${end} 23:00`, "YYYY-MM-DD HH:mm");
    if (startInUtc.isAfter(maxStartInUtc)) {
      startInUtc.set({
        hour: 22,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
    if (endInUtc.isAfter(maxEndInUtc)) {
      endInUtc.set({
        hour: 23,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
    const endTime = endInUtc.format("HH:mm");

    return { startDate, endDate, startTime, endTime };
  };

  const getDateOptions = () => {
    let dateOptions = [];
    const dayOfWeek = moment().day();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    const isFriday = dayOfWeek === 5;
    if (!_isAfterBusinessHour() && !isWeekend) {
      dateOptions.push(Day.today);
    }

    if (!isFriday && !isWeekend) {
      dateOptions.push(Day.tomorrow);
    }
    if (dayOfWeek > 0 && dayOfWeek < 5) {
      dateOptions.push(Day.this_week);
    }
    if (isFriday || isWeekend) {
      dateOptions.push(Day.next_week);
    }

    dateOptions = [...dateOptions, Day.customized];
    return dateOptions;
  };

  const _isAfterBusinessHour = () => {
    const now = moment();
    const sixPM = moment().set({ hour: 18, minute: 0, second: 0 });
    return now.isAfter(sixPM);
  };

  return {
    getFutureHours,
    getDateStartAndEndTime,
    convertTimeToUtc,
    getDateOptions,
  };
};
