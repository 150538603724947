import { Moment } from "moment";

export function formatDate(
  dateStr: string | Moment,
  isShortened: boolean = false,
  alwaysShowFullDate: boolean = false,
): string {
  let date;
  if (typeof dateStr === "string") {
    date = new Date(dateStr);
  } else {
    date = dateStr.toDate();
  }
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const diffDays = diff / (1000 * 60 * 60 * 24);
  const diffHours = diff / (1000 * 60 * 60);
  const diffMinutes = diff / (1000 * 60);
  let timeAgo = "";

  if (diffDays < 1) {
    timeAgo =
      diffHours < 1
        ? `${Math.floor(diffMinutes)} minute${
            Math.floor(diffMinutes) !== 1 ? "s" : ""
          } ago`
        : `${Math.floor(diffHours)} hour${
            Math.floor(diffHours) !== 1 ? "s" : ""
          } ago`;
  } else if (diffDays < 7) {
    timeAgo = `${Math.round(diffDays)} day${
      Math.round(diffDays) !== 1 ? "s" : ""
    } ago`;
  }

  const fullDate = date.toLocaleString("default", {
    month: "short",
    day: "numeric",
    ...(isShortened
      ? {}
      : { weekday: "short", hour: "numeric", minute: "numeric" }),
  });

  return alwaysShowFullDate && timeAgo
    ? `${fullDate} (${timeAgo})`
    : timeAgo || fullDate;
}
