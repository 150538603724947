import { Box, Link, Typography } from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";

import { titleCase } from "utils/string";

import styles from "./styles";

export default function ProgressBar() {
  const { subscription, setUpgradeDialogSource } =
    useContext(SubscriptionContext);

  const color = "primary.dark";

  return (
    <Box sx={styles.root}>
      {subscription !== undefined && subscription?.monthlyLimit && (
        <Typography
          variant="caption"
          color="textSecondary"
          sx={[styles.title, { color }]}
        >
          Subscription Plan:{" "}
          <strong>
            {subscription?.subscription?.planName &&
              titleCase(subscription?.subscription?.planName)}
          </strong>
          .{" "}
          <Link
            onClick={() => setUpgradeDialogSource("Change Plan")}
            sx={{ cursor: "pointer" }}
            color="secondary"
          >
            Change Plan?
          </Link>
        </Typography>
      )}
    </Box>
  );
}
