import { LoadingButton } from "@mui/lab";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { OrganizationUserContext } from "contexts/Organization";
import {
  EmailOption,
  QuickSendScheduleContext,
  ScheduleTimeDialogSource,
} from "contexts/QuickSendSchedule";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useMemo, useRef, useState } from "react";
import { CustomEvent } from "schemas/functions";

import ConnectGmailDialog from "components/ConnectGmailDialog";
import { HandleSendEmail } from "features/Influencer/ContactList/schema";
import { trackEvent } from "utils/tracking";

import ScheduleDialog from "../../../ScheduleDialog";
import TestEmailSuccessDialog from "../TestEmailSuccessDialog";

interface Props {
  index: number;
  handleSendEmail: HandleSendEmail;
  sendLoading: boolean;
  disabled: boolean;
  hideTestButton: boolean;
  showTestEmail: boolean;
}

type IntegrationDialogState = {
  isOpen: boolean;
  postConnectAction: EmailOption | null;
  sendAt?: string | null;
};

export default function QuickSendButtonGroup({
  index,
  handleSendEmail,
  sendLoading,
  disabled,
  hideTestButton,
  showTestEmail,
}: Props) {
  const { userIntegrations } = useContext(OrganizationUserContext);
  const { emailHealth, scopes } = useContext(UserIntegrationsContext);
  const [userIntegrationDialogState, setUserIntegrationDialogState] =
    useState<IntegrationDialogState>({
      isOpen: false,
      postConnectAction: null,
    });
  const {
    selectedOption,
    hasScheduled,
    setSelectedOption,
    presetSendAt,
    setScheduleDialogSource,
    scheduleDialogSource,
  } = useContext(QuickSendScheduleContext);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const hideButton = !isLargeScreen || hideTestButton;

  const [open, setOpen] = useState(false);
  const [showTestEmailSuccessDialog, setShowTestEmailSuccessDialog] =
    useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const options = useMemo(() => {
    if (hideButton && index === 0 && showTestEmail) {
      return [EmailOption.send, EmailOption.test, EmailOption.schedule];
    } else {
      return [EmailOption.send, EmailOption.schedule];
    }
  }, [hideButton, index, showTestEmail]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: EmailOption,
  ) => {
    event.stopPropagation();
    setSelectedOption(option);
    setOpen(false);
  };

  const handleToggle = (e: CustomEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    event.stopPropagation();
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleClick = (e: CustomEvent, selectedOption: EmailOption) => {
    e.stopPropagation();
    if (selectedOption === EmailOption.send) {
      if (!emailHealth) {
        setUserIntegrationDialogState({
          isOpen: true,
          postConnectAction: EmailOption.send,
        });
        return;
      }
      handleSendEmail(null);
    } else if (selectedOption === EmailOption.schedule) {
      if (hasScheduled && presetSendAt) {
        handleScheduleEmail(presetSendAt);
      } else {
        setScheduleDialogSource(ScheduleTimeDialogSource.SCHEDULE_BUTTON);
      }
    } else {
      handleSendTest();
    }
  };

  const handleScheduleEmail = (sendAt: string) => {
    if (!emailHealth) {
      setUserIntegrationDialogState({
        isOpen: true,
        postConnectAction: EmailOption.schedule,
        sendAt,
      });
      return;
    }
    handleSendEmail(sendAt);
  };

  const handleSendTest = async () => {
    trackEvent("Send Test Email Button Clicked");
    if (!emailHealth) {
      setUserIntegrationDialogState({
        isOpen: true,
        postConnectAction: EmailOption.test,
      });
      return;
    }
    sendTestEmail();
  };

  const onEmailConnected = async () => {
    setUserIntegrationDialogState((prev) => ({ ...prev, isOpen: false }));
    if (userIntegrationDialogState.postConnectAction === EmailOption.send) {
      await handleSendEmail(null);
    } else if (
      userIntegrationDialogState.postConnectAction === EmailOption.schedule &&
      userIntegrationDialogState.sendAt
    ) {
      await handleSendEmail(userIntegrationDialogState.sendAt);
    } else if (
      userIntegrationDialogState.postConnectAction === EmailOption.test
    ) {
      await sendTestEmail();
    }
  };

  const sendTestEmail = async () => {
    const success = await handleSendEmail(null, undefined, true);
    if (success) {
      setShowTestEmailSuccessDialog(true);
    }
  };

  return (
    <Grid>
      <Grid container alignItems="center">
        {!hideButton && showTestEmail && index === 0 && (
          <LoadingButton
            sx={{ mr: 1 }}
            onClick={(e: CustomEvent) => {
              e.stopPropagation();
              handleSendTest();
            }}
            loading={sendLoading}
            size="small"
          >
            Send Test
          </LoadingButton>
        )}
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          size="small"
          disableElevation
          sx={{
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              borderColor: "secondary.light",
            },
          }}
          disabled={disabled}
        >
          <LoadingButton
            variant="contained"
            size="small"
            loading={sendLoading}
            onClick={(e: CustomEvent) => handleClick(e, selectedOption)}
            sx={{ height: 30 }}
          >
            {selectedOption}
          </LoadingButton>
          <Button size="small" onClick={handleToggle}>
            <Box component="i" className="fa-regular fa-chevron-down" />
          </Button>
        </ButtonGroup>
      </Grid>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === selectedOption}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ScheduleDialog
        open={scheduleDialogSource !== null}
        onSchedule={(sendAt) => {
          handleScheduleEmail(sendAt);
        }}
      />

      {/* Create a custom gmail connect in order to perform action automatically
      after connecting */}
      <ConnectGmailDialog
        openIntegrationDialog={userIntegrationDialogState.isOpen}
        setOpenIntegrationDialog={(newOpen) => {
          setUserIntegrationDialogState({
            isOpen: newOpen,
            postConnectAction: null,
          });
        }}
        userIntegrations={userIntegrations}
        scopes={scopes}
        emailHealth={emailHealth}
        onSuccessAction={onEmailConnected}
      />

      <TestEmailSuccessDialog
        open={showTestEmailSuccessDialog}
        handleClose={() => {
          setShowTestEmailSuccessDialog(false);
        }}
      />
    </Grid>
  );
}
