import { Box, Grid, Typography } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";

import { getStyles } from "./styles";

function DraftEmailBar() {
  const {
    draftView,
    setDraftView,
    handleOpenDraftWindow,
    draftOpen,
    quickSendBrandId,
  } = useContext(QuickSendDrawerContext);
  const { isLargeRecommendedPage } = usePageSize();

  const { discoverTab } = useContext(DiscoverViewContext);

  const styles = getStyles(
    discoverTab !== null,
    !isLargeRecommendedPage && quickSendBrandId >= 0,
  );
  if (!draftOpen || draftView !== QuickSendTabView.COLLAPSE) {
    return <></>;
  }

  const handleToggleExpand = (e: CustomEvent) => {
    e.stopPropagation();
    handleOpenDraftWindow();

    trackEvent("QuickSend Email Bar Clicked", {
      button: "Expand View",
      isDraft: draftOpen,
    });
    setDraftView(QuickSendTabView.EXPAND);
  };

  return (
    <Box sx={styles.root}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        onClick={handleToggleExpand}
      >
        <Typography sx={styles.text} noWrap>
          New Message
        </Typography>
      </Grid>
    </Box>
  );
}

export default DraftEmailBar;
