import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { CustomEvent } from "schemas/functions";

import { MAXIMUM_FOLLOWUP_ALLOWED } from "constants/templates";
import { Template } from "features/Influencer/ContactList/schema";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  templates: Template[];
  setTemplates: Dispatch<SetStateAction<Template[]>>;
  setDisplayTemplateScreen?: Dispatch<SetStateAction<boolean>>;
}

export default function TemplateScreen({
  templates,
  setTemplates,
  setDisplayTemplateScreen,
}: Props) {
  const { emailSettings } = useContext(OrganizationUserContext);
  const {
    templates: allTemplates,
    outreachTemplates,
    followupTemplates,
    fetchOutreachTemplates,
  } = useContext(OutreachTemplatesContext);

  const filteredOutreachTemplates = outreachTemplates?.filter(
    (t) => !t.emailTemplateId,
  );
  const filteredFollowUps = followupTemplates?.filter(
    (t) => !t.emailTemplateId,
  );

  const handleChange = (e: CustomEvent, index: number) => {
    const { value } = e.target;
    setTemplates((prev) => {
      const copy = makeDeepCopy(prev);
      copy[index] = allTemplates?.find((x) => x.id === value);
      return copy;
    });
  };

  const selectedFollowups = templates?.slice(1, templates?.length);

  const addFollowUp = () => {
    trackEvent(
      "Favorites List - Add Follow Up Button Pressed on Customization Dialog",
    );
    setTemplates((prev) => [...prev, followupTemplates?.[0]]);
  };

  const removeFollowUp = (index: number) => {
    trackEvent("Favorites List - Remove Follow-Up on Customization Dialog");
    setTemplates((prev) => {
      const copy = makeDeepCopy(prev);
      copy.splice(index, 1);
      return copy;
    });
  };

  const getText = (index: number) => {
    if (index === 1) return "st";
    if (index === 2) return "nd";
    if (index === 3) return "rd";
    return "th";
  };

  const initializeEmails = () => {
    if (templates?.length > 0) return;
    if (emailSettings?.emailSettingTemplates && allTemplates?.length > 0) {
      const matchedTemplates = [];
      for (const settingTemplate of emailSettings?.emailSettingTemplates) {
        const template = allTemplates?.find(
          (x) => x.id === settingTemplate.templateId,
        );
        if (template) {
          matchedTemplates.push(template);
        }
      }
      if (matchedTemplates?.length > 0) {
        setTemplates(matchedTemplates);
        return;
      }
    }

    if (filteredOutreachTemplates?.length > 0) {
      setTemplates([filteredOutreachTemplates[0]]);
    } else if (filteredOutreachTemplates?.length === 0) {
      fetchOutreachTemplates();
    }
  };

  useEffect(() => {
    initializeEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templates?.length,
    filteredOutreachTemplates,
    emailSettings?.emailSettingTemplates?.length,
  ]);

  return (
    <>
      {setDisplayTemplateScreen && (
        <Grid container alignItems="center" gap={1} sx={{ mb: 1 }}>
          <IconButton sx={{ fontSize: 13, color: "black" }}>
            <Box
              component="i"
              className="fa-regular fa-arrow-left"
              onClick={() => setDisplayTemplateScreen(false)}
            />
          </IconButton>
          <Typography variant="h6">Select Templates</Typography>
        </Grid>
      )}
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography sx={styles.label}>Outreach:</Typography>
        </Grid>
        {templates[0] && (
          <Select
            value={templates[0]?.id || undefined}
            onChange={(e) => handleChange(e, 0)}
            sx={styles.noBorder}
            defaultValue={templates[0]?.id}
            size="small"
          >
            {filteredOutreachTemplates?.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                <Box sx={styles.templateName}>{template.name}</Box>
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>

      {selectedFollowups?.map((followup, index) => (
        <Grid container alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography sx={styles.label}>
              {index + 1}
              {getText(index + 1)} Follow-Up:
            </Typography>
          </Grid>
          <Select
            onChange={(e) => handleChange(e, index + 1)}
            value={followup?.id}
            sx={styles.noBorder}
            size="small"
          >
            {filteredFollowUps?.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                <Box sx={styles.templateName}>{template.name}</Box>
              </MenuItem>
            ))}
          </Select>

          <IconButton
            sx={{ fontSize: 13, color: "black" }}
            onClick={() => removeFollowUp(index + 1)}
          >
            <Box component="i" className="fa-regular fa-trash" />
          </IconButton>
        </Grid>
      ))}

      {followupTemplates?.length > 0 &&
        templates?.length < MAXIMUM_FOLLOWUP_ALLOWED + 1 && (
          <Grid container alignItems="center">
            <Grid item xs={12} md={3} />
            <Grid item xs md>
              <Button
                disableElevation
                size="small"
                onClick={addFollowUp}
                sx={{
                  fontSize: 12,
                  textTransform: "none",
                  ml: { xs: -1, md: 1 },
                }}
              >
                Add Follow Up +
              </Button>
            </Grid>
          </Grid>
        )}
    </>
  );
}
