import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { BrandsContext } from "contexts/Brands";
import { forwardRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { BentoBrand, SearchParams } from "schemas/dashboard";

import BrandCard from "../../BrandCard";
import { BrandCardSource } from "../../schema";
import styles from "../styles";

const REF_INDEX = 9; // The index used to start loading more recommendations

interface Props {
  brandsLoading: boolean;
  fetchMoreLoading: boolean;
}

export default forwardRef<HTMLDivElement, Props>(
  ({ brandsLoading, fetchMoreLoading }, ref) => {
    const { setAlert } = useContext(AlertContext);
    const [searchParams] = useSearchParams();
    const brandQuery = searchParams.get(SearchParams.QUERY_DISCOVER);
    const isSearching = brandQuery && brandQuery?.length > 0;

    const { similarBrands } = useContext(BrandsContext);

    return (
      <Box sx={{ pt: 1 }}>
        {similarBrands.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={styles.searchingText}
                gutterBottom
                textAlign="left"
              >
                {isSearching ? `Similar to "${brandQuery}"` : "Similar Brands"}
              </Typography>
            </Grid>
            {!brandsLoading &&
              !fetchMoreLoading &&
              similarBrands.length === 0 && <>No similar brands found</>}

            <Grid container justifyContent="center" gap={1}>
              {similarBrands?.map((brand: BentoBrand, idx: number) => (
                <BrandCard
                  /* This ref tracks when user scroll to bottom of the page */
                  ref={
                    (
                      similarBrands.length > REF_INDEX
                        ? idx === similarBrands.length - REF_INDEX
                        : idx === similarBrands.length - 1
                    )
                      ? ref
                      : undefined
                  }
                  key={`brand-card-similar-${brand.id}-${idx}`}
                  brand={brand}
                  setAlert={setAlert}
                  source={BrandCardSource.SIMILAR_BRANDS}
                />
              ))}
            </Grid>
          </>
        )}

        {!brandsLoading && fetchMoreLoading && (
          <Grid
            container
            justifyContent={"center"}
            sx={[styles.skeletonContainer, { mt: 2 }]}
          >
            {Array.from({ length: 3 }, (x, i) => (
              <Skeleton
                key={i}
                variant="rounded"
                width={"100%"}
                height={100}
                sx={{ mb: 2 }}
              />
            ))}
          </Grid>
        )}
      </Box>
    );
  },
);
