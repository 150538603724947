import { Typography } from "@mui/material";
import { AllPlans, SubscriptionTab } from "contexts/Subscription";
import { Subscription, SubscriptionType } from "schemas/dashboard";
import { Plans } from "schemas/payments";

import { titleCase } from "utils/string";
import { formatEpochTimestamp } from "utils/time";

type PlanData = {
  emailSents: number;
  brandRequestsMade: number;
  customizedMessagesDrafted: number;
  recommendationsRefreshed: number;
  tab: SubscriptionTab;
};

export const disabledCurrentPlan = (
  plan: Plans,
  subscription: Subscription | null,
) => {
  return (
    plan === Plans.FREE ||
    (plan === Plans.PRO && subscription?.overrideSubscription)
  );
};

export const getCurrentPlanButton = (
  plan: Plans,
  subscription: Subscription | null,
) => {
  const isButtonDisabled = disabledCurrentPlan(plan, subscription);
  if (isButtonDisabled) {
    return "Current Plan";
  } else if (subscription?.subscription?.status === SubscriptionType.CANCELED) {
    return "Resubscribe";
  } else if (subscription?.subscription.cancelAt) {
    return "Renew Subscription";
  } else {
    return "Manage Current Plan";
  }
};

export const getOtherPlanButton = (tier: Plans, userCurrentPlan?: Plans) => {
  const orders = {
    [Plans.FREE]: 0,
    [Plans.STARTER]: 1,
    [Plans.STARTER_YEARLY]: 1,
    [Plans.PRO]: 2,
    [Plans.PRO_YEARLY]: 2,
  };
  if (userCurrentPlan && orders[userCurrentPlan] >= orders[tier]) {
    return "Choose This Plan";
  } else {
    return "Upgrade";
  }
};

export const getStatusChipLabel = (subscription: Subscription | null) => {
  const stripePlan = subscription?.subscription;
  if (stripePlan?.status === SubscriptionType.TRIALING) {
    return "Active";
  } else {
    return titleCase(stripePlan?.status || "");
  }
};

export const getSubscriptionInformation = (
  plan: Plans,
  plans: AllPlans | null,
  subscription: Subscription | null,
  data: PlanData,
) => {
  const {
    emailSents,
    brandRequestsMade,
    customizedMessagesDrafted,
    recommendationsRefreshed,
  } = data;
  const stripePlan = subscription?.subscription;
  const isCurrentPlan = stripePlan?.planName === plan;
  const price = plans ? plans[plan].price : 0;
  const resubscribe =
    getCurrentPlanButton(plan, subscription) === "Resubscribe";
  const renew =
    getCurrentPlanButton(plan, subscription) === "Renew Subscription";

  if (!plans)
    return {
      stripePlan,
      isCurrentPlan,
      price,
      description: <></>,
      resubscribe,
      renew,
    };

  const totalEmails =
    plans[plan].monthly_limit + Number(subscription?.referralsBoost || 0);
  const totalBrandRequests = plans[plan].brand_requests_monthly_limit;
  const totalAIMessages = plans[plan].chat_monthly_limit;
  const totalRefreshes = plans[plan].refresh_recommendations_limit;

  const description = (
    <>
      {isCurrentPlan ? (
        <>
          {subscription?.overrideSubscription && isCurrentPlan && (
            <>
              <strong>
                To try out the platform, we have gifted you the Pro plan.
              </strong>
              <br />
              <br />
            </>
          )}
          <Typography component="span" sx={{ color: "info.main" }}>
            <strong>{emailSents}</strong>
          </Typography>
          /{totalEmails} Emails Sent used this month{" "}
          {subscription && subscription.referralsBoost > 0 && (
            <> (including +{subscription.referralsBoost} from referrals)</>
          )}
          <br />
          <Typography component="span" sx={{ color: "info.main" }}>
            <strong>{customizedMessagesDrafted}</strong>
          </Typography>
          /{totalAIMessages} AI-customized Emails used this month
          <br />
          <Typography component="span" sx={{ color: "info.main" }}>
            <strong>{brandRequestsMade}</strong>
          </Typography>
          /{totalBrandRequests} Brand Requests used this month
          <br />
          <Typography component="span" sx={{ color: "info.main" }}>
            <strong>{recommendationsRefreshed}</strong>
          </Typography>
          /{totalRefreshes} Recommendations Refreshes used this month
        </>
      ) : (
        <>
          Send {totalEmails} Emails per month
          <br />
          Get {totalAIMessages} AI-customized emails per month
          <br />
          Get {totalBrandRequests} brand requests per month
          <br />
          Get {totalRefreshes} Recommendations Refreshes per month
        </>
      )}{" "}
      {plan !== Plans.FREE && (
        <>
          <br />
          1:1 onboarding call to personalize email template
        </>
      )}
      {plan === Plans.PRO && (
        <>
          <br />
          Access to "Reply Score" feature
        </>
      )}
      {subscription && isCurrentPlan && (
        <>
          <br />
          <br />
          Your plan's limits will reset on{" "}
          <strong>
            {formatEpochTimestamp({
              epochTimestamp: subscription.creditsCycle[1],
              addYear: true,
            })}
          </strong>
          .{" "}
        </>
      )}
    </>
  );

  return {
    stripePlan,
    isCurrentPlan,
    price,
    description,
    resubscribe,
    renew,
  };
};
