import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Dispatch, SetStateAction, useState } from "react";

import { VariableAttributes } from "..";

interface Props {
  variable: VariableAttributes;
  closeModal: () => void;
  open: boolean;
  onSave: (text: string) => void;
  setUpdateOrgProfile?: Dispatch<SetStateAction<boolean>>;
}

export function VariableModal({
  closeModal,
  open,
  variable,
  onSave,
  setUpdateOrgProfile,
}: Props) {
  const [value, setValue] = useState(variable.value || "");

  const handleSave = () => {
    onSave(value);
    setValue("");
    if (setUpdateOrgProfile) {
      setUpdateOrgProfile(true);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={closeModal}>
      <IconButton
        sx={{
          color: "primary.main",
          fontSize: 14,
          position: "absolute",
          right: 10,
          top: 10,
        }}
        onClick={closeModal}
      >
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogContent>
        <Typography gutterBottom>{variable.instruction || `Enter:`}</Typography>
        <TextField
          placeholder={variable.example || variable.label}
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          multiline={variable.id === "introduction"}
          minRows={4}
          inputProps={{
            sx: {
              "&::placeholder": {
                color: grey[600],
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onMouseDown={(e) => e.preventDefault()} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
