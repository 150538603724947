import {
  Box,
  Button,
  Grid,
  Menu,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import styles from "./styles";

interface IProps {
  id: string;
  label: string;
  children: ReactNode;
  close?: boolean;
  setClose?: Dispatch<SetStateAction<boolean>>;
}

export default function DropdownSelect({
  id,
  label,
  children,
  close,
  setClose,
}: IProps) {
  const theme = useTheme();
  const { discoverTab } = useContext(DiscoverViewContext);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen =
    isSmallScreen || (isLargeScreen && discoverTab !== null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showChildren, setShowChildren] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (setClose) setClose(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idName = open ? id : undefined;

  const displaySubmenu = () => {
    setShowChildren((prev) => !prev);
  };

  useEffect(() => {
    if (close) {
      handleClose();
    }
  }, [close]);

  const arrowIcon = (
    <Box
      component="i"
      className={`fa-regular fa-chevron-${open ? "up" : "down"}`}
      sx={styles.arrow}
    />
  );

  const dropdownBtn = (
    <Button
      variant="text"
      size="small"
      color={open ? "success" : "inherit"}
      sx={{ textTransform: "none" }}
      aria-describedby={idName}
      onClick={handleClick}
    >
      {label} {arrowIcon}
    </Button>
  );

  return (
    <>
      {isMobileScreen ? (
        <Grid
          onClick={displaySubmenu}
          container
          justifyContent="space-between"
          alignItems="center"
          sx={styles.mobileList}
        >
          <Box sx={{ fontSize: 16 }}>{label}</Box> {arrowIcon}
        </Grid>
      ) : (
        dropdownBtn
      )}

      {!isMobileScreen && (
        <Menu
          id={idName}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          {children}
        </Menu>
      )}

      {isMobileScreen && showChildren && (
        <Box sx={styles.children}>{children}</Box>
      )}
    </>
  );
}
