import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import React, { useContext, useEffect, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

enum TimePeriod {
  today = "today",
  yesterday = "yesterday",
  thisWeek = "this_week",
  lastWeek = "last_week",
  thisMonth = "this_month",
  lastMonth = "last_month",
}

const TimePeriods = [
  TimePeriod.today,
  TimePeriod.yesterday,
  TimePeriod.thisWeek,
  TimePeriod.lastWeek,
  TimePeriod.thisMonth,
  TimePeriod.lastMonth,
];

type Metrics = {
  outreachCount: number;
  followUpCount: number;
  replyCount: number;
  openedCount: number;
  openedPercentage: number;
  respondedCount: number;
  respondedPercentage: number;
};

const compareTimePeriod = (timePeriod: TimePeriod) => {
  if (timePeriod === TimePeriod.today) {
    return TimePeriod.yesterday;
  } else if (timePeriod === TimePeriod.thisMonth) {
    return TimePeriod.lastMonth;
  } else if (timePeriod === TimePeriod.thisWeek) {
    return TimePeriod.lastWeek;
  }
  return undefined;
};

function MetricText({
  compareDiff,
  countValue,
  description,
  showAsPercentage = false,
}: {
  compareDiff: number | undefined;
  countValue: number | undefined;
  description: string;
  showAsPercentage?: boolean;
}) {
  return (
    <Box sx={styles.metricText}>
      {compareDiff !== undefined &&
        compareDiff !== 0 &&
        (compareDiff > 0 ? (
          <Box
            component="i"
            className="fa-solid fa-arrow-up"
            sx={styles.arrowUp}
          />
        ) : (
          <Box
            component="i"
            className="fa-solid fa-arrow-down"
            sx={styles.arrowDown}
          />
        ))}
      <Typography component="span">
        {!showAsPercentage
          ? countValue
          : `${((countValue || 0) * 100).toFixed(1)}%`}
      </Typography>
      <Typography component="span" sx={styles.descriptionText}>
        {description}
      </Typography>
    </Box>
  );
}

export default function MetricsHelper() {
  const { currentUser, currentOrg } = useContext(OrganizationUserContext);
  const { setAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const { setDiscoverTab } = useContext(DiscoverViewContext);
  const [tabValues, setTabValues] = useState<TimePeriod[]>([
    TimePeriod.today,
    TimePeriod.thisWeek,
    TimePeriod.thisMonth,
  ]);
  const [metricsLoading, setMetricsLoading] = useState<{
    [key in TimePeriod]: boolean;
  }>({
    today: true,
    yesterday: true,
    this_week: true,
    last_week: true,
    this_month: true,
    last_month: true,
  });
  const [metrics, setMetrics] = useState<{
    [key in TimePeriod]: Metrics | undefined;
  }>({
    today: undefined,
    yesterday: undefined,
    this_week: undefined,
    last_week: undefined,
    this_month: undefined,
    last_month: undefined,
  });

  const closeDialog = () => {
    setDiscoverTab(null);
  };

  const firstName =
    currentUser && currentUser.name && currentUser.name.split(" ")[0];

  const handleChange =
    (index: number) => (event: CustomEvent, newValue: TimePeriod) => {
      event.stopPropagation();
      trackEvent("Stats Helper Tab Clicked", { tab: newValue });
      setTabValues((prev) => {
        const copy = [...prev];
        copy[index] = newValue;
        return copy;
      });
      if (
        [TimePeriod.today, TimePeriod.thisWeek, TimePeriod.thisMonth].includes(
          newValue,
        )
      ) {
        fetchMetrics(newValue, false);
      }
    };

  const fetchMetrics = async (
    timePeriod: TimePeriod,
    showLoading: boolean = true,
  ) => {
    try {
      if (showLoading) {
        setMetricsLoading((prev) => ({ ...prev, [timePeriod]: true }));
      }
      const metrics = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-metrics?time_period=${timePeriod}`,
        "GET",
        {},
      );
      setMetrics((prev) => ({ ...prev, [timePeriod]: metrics }));
    } catch (error) {
      setAlert(
        "Something went wrong and your metrics could not be fetch. Please refresh the page and try again",
        "error",
      );
    } finally {
      setMetricsLoading((prev) => ({ ...prev, [timePeriod]: false }));
    }
  };

  useEffect(() => {
    TimePeriods.forEach((period) => fetchMetrics(period));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.root}>
      <Grid container>
        {/* Top Bar */}
        <Grid item xs={12} sx={styles.header}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography
              variant="h6"
              sx={{ fontSize: 14, textTransform: "uppercase" }}
            >
              {firstName
                ? `${firstName}'${firstName.endsWith("s") ? "" : "s"} `
                : ""}
              Metrics
            </Typography>
            <IconButton sx={styles.close} onClick={closeDialog}>
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
          </Grid>
        </Grid>
        {tabValues.map((timePeriod: TimePeriod, index: number) => {
          const currentPeriodMetrics = metrics[timePeriod];
          const currentPeriodCompare = compareTimePeriod(timePeriod);
          const currentPeriodCompareMetrics =
            currentPeriodCompare && metrics[currentPeriodCompare];

          return (
            <React.Fragment key={timePeriod}>
              <Grid item xs={12} sx={styles.tabsWrapper}>
                <Tabs
                  sx={styles.tabs}
                  value={timePeriod}
                  onChange={handleChange(index)}
                >
                  {index === 0 && (
                    <Tab
                      sx={styles.tab}
                      value={TimePeriod.today}
                      label="TODAY"
                    />
                  )}
                  {index === 0 && (
                    <Tab
                      sx={styles.tab}
                      value={TimePeriod.yesterday}
                      label="YESTERDAY"
                    />
                  )}
                  {index === 1 && (
                    <Tab
                      sx={styles.tab}
                      value={TimePeriod.thisWeek}
                      label="THIS WEEK"
                    />
                  )}
                  {index === 1 && (
                    <Tab
                      sx={styles.tab}
                      value={TimePeriod.lastWeek}
                      label="LAST WEEK"
                    />
                  )}
                  {index === 2 && (
                    <Tab
                      sx={styles.tab}
                      value={TimePeriod.thisMonth}
                      label="THIS MONTH"
                    />
                  )}
                  {index === 2 && (
                    <Tab
                      sx={styles.tab}
                      value={TimePeriod.lastMonth}
                      label="LAST MONTH"
                    />
                  )}
                </Tabs>
              </Grid>
              {metricsLoading[timePeriod] && (
                <Grid
                  container
                  justifyContent="center"
                  rowGap={1}
                  sx={styles.metricsWrapper}
                >
                  {timePeriod === TimePeriod.today ||
                  timePeriod === TimePeriod.yesterday ? (
                    <>
                      <Skeleton variant="rounded" height={21} width="100%" />
                      <Skeleton variant="rounded" height={21} width="100%" />
                    </>
                  ) : (
                    <>
                      <Skeleton variant="rounded" height={19} width="100%" />
                      <Skeleton variant="rounded" height={19} width="100%" />
                      <Skeleton variant="rounded" height={19} width="100%" />
                      <Skeleton variant="rounded" height={19} width="100%" />
                    </>
                  )}
                </Grid>
              )}
              {!metricsLoading[timePeriod] && currentPeriodMetrics && (
                <Box sx={styles.metricsWrapper}>
                  <MetricText
                    compareDiff={
                      currentPeriodCompareMetrics &&
                      currentPeriodMetrics.outreachCount -
                        currentPeriodCompareMetrics.outreachCount
                    }
                    countValue={currentPeriodMetrics.outreachCount}
                    description="Emails Sent"
                  />
                  <MetricText
                    compareDiff={
                      currentPeriodCompareMetrics &&
                      currentPeriodMetrics.followUpCount -
                        currentPeriodCompareMetrics.followUpCount
                    }
                    countValue={currentPeriodMetrics.followUpCount}
                    description="Follow-ups Sent"
                  />
                  <MetricText
                    compareDiff={
                      currentPeriodCompareMetrics &&
                      currentPeriodMetrics.replyCount -
                        currentPeriodCompareMetrics.replyCount
                    }
                    countValue={currentPeriodMetrics.replyCount}
                    description="Replies Sent"
                  />
                  {timePeriod !== TimePeriod.today &&
                    timePeriod !== TimePeriod.yesterday && (
                      <>
                        <MetricText
                          compareDiff={
                            currentPeriodCompareMetrics &&
                            currentPeriodMetrics.openedPercentage -
                              currentPeriodCompareMetrics.openedPercentage
                          }
                          countValue={currentPeriodMetrics.openedPercentage}
                          showAsPercentage
                          description={"Opened"}
                        />
                        <MetricText
                          compareDiff={
                            currentPeriodCompareMetrics &&
                            currentPeriodMetrics.respondedPercentage -
                              currentPeriodCompareMetrics.respondedPercentage
                          }
                          countValue={currentPeriodMetrics.respondedCount}
                          description={`Responses (${(
                            currentPeriodMetrics.respondedPercentage * 100
                          ).toFixed(1)}%)`}
                        />
                      </>
                    )}
                </Box>
              )}
            </React.Fragment>
          );
        })}

        <Grid item xs={12} sx={styles.metricsWrapper}>
          <Typography sx={styles.title} paragraph>
            Bento Best Practice Framework
          </Typography>
          <Typography variant="body2">Send at least 30 emails/week</Typography>
          <Typography variant="body2">Set 1-2 follow-ups</Typography>
          <Typography variant="body2">
            Follow up if a contact opens the email multiple times
          </Typography>
          <Typography variant="body2">
            Respond to replies within 1 hour
          </Typography>
          <>
            <Typography sx={styles.title} paragraph>
              Bento Average Industry Metrics
              <Tooltip
                title={
                  "If your metrics differ greatly from these industry metrics, there may be an issue. Contact us at hello@onbento.com and we will help you troubleshoot."
                }
              >
                <Box
                  component="i"
                  sx={styles.infoIcon}
                  className="fa-regular fa-circle-info"
                />
              </Tooltip>
            </Typography>
            <Typography variant="body2">55.9% open rate</Typography>
            <Typography variant="body2" paragraph>
              9.4% response rate
            </Typography>
          </>
        </Grid>
      </Grid>
    </Box>
  );
}
