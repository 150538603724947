import { grey } from "@mui/material/colors";

export const getStyles = (
  isSavedBrandOpen: boolean,
  isQuickSendOpen?: boolean,
) => {
  let position = {
    width: {},
    right: {},
  };
  if (!isQuickSendOpen) {
    position = {
      width: { md: 450, xl: 550 },
      right: { xs: isSavedBrandOpen ? 420 : 70 },
    };
  } else if (!isSavedBrandOpen) {
    // quick send is open, toolbar is closed
    position = {
      width: { md: 380, lg: 450, xl: 500 },
      right: { md: 450 + 60, xl: 600 + 80 },
    };
  } else {
    // quick send and toolbar is open
    position = {
      width: { md: 470, lg: 380, xl: 500 },
      right: { md: 420, lg: 830, xl: 950 },
    };
  }

  return {
    root: {
      position: "fixed",
      bottom: 0,
      boxShadow: 3,
      backgroundColor: "white",
      border: `1px solid ${grey[300]}`,
      zIndex: 250,
      cursor: "pointer",
      overflowY: "auto",
      minHeight: "60svh",
      maxHeight: "100svh",
      display: "flex",
      flexDirection: "column",
      ...position,
    },
    recommendationRoot: {
      height: "100%",
    },
  };
};
