import {
  Backdrop,
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import {
  InboxSidebarOptionKey,
  InboxSidebarStatusMap,
  OutreachContactsContext,
} from "contexts/OutreachContacts";
import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link as RouterLink } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { OutreachContact } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";
import { routes } from "schemas/routes";

import OutreachContactDrawer from "../OutreachContactDrawer";
import ContactRow from "./ContactRow";
import ScheduledContactRow from "./ContactRow/scheduled";
import SortSelect from "./Filters/SortSelect";
import StarredSelect from "./Filters/StarredSelect";
import ScheduledTabs from "./ScheduledTabs";
import SearchBar from "./SearchBar";
import Sidebar from "./Sidebar";
import styles from "./styles";

interface Props {
  setAlert: SetAlertType;
}

const REF_INDEX = 20;

const OutreachTable = ({ setAlert }: Props) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const {
    contacts,
    fetchLoading,
    fetchMoreLoading,
    hitsLimit,
    fetchContactsParams,
    currentSidebarOption,
    isFiltering,
    handleFetchContacts,
    fetchTotals,
  } = useContext(OutreachContactsContext);
  const { discoverTab } = useContext(DiscoverViewContext);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen =
    isMobileScreen || (isLargeScreen && discoverTab !== null);

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const [ref, isRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const clearFilters = () => {
    const updatedParams = { ...fetchContactsParams };
    updatedParams.page = 1;
    updatedParams.selectedSuggestion = null;
    updatedParams.importantOnly = false;
    handleFetchContacts(updatedParams);
  };

  const openSent = () => {
    const updatedParams = { ...fetchContactsParams };
    updatedParams.page = 1;
    updatedParams.selectedSuggestion = null;
    updatedParams.importantOnly = false;
    updatedParams.statuses = InboxSidebarStatusMap.sent;
    handleFetchContacts(updatedParams);
  };

  useEffect(() => {
    if (isRefVisible) {
      if (!hitsLimit && !fetchLoading) {
        const updatedParams = { ...fetchContactsParams };
        updatedParams.page += 1;
        handleFetchContacts(updatedParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefVisible, fetchLoading]);

  useEffect(() => {
    handleFetchContacts();
    fetchTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const toggleSidebar = (newOpen: boolean) => () => {
    setSidebarOpen(newOpen);
  };

  return (
    <>
      <Grid container>
        {!isSmallScreen && (
          <Grid item container md={2}>
            <Box sx={{ mt: 8, width: "100%", pr: 1.5 }}>
              <Sidebar />
            </Box>
          </Grid>
        )}
        <Grid
          item
          container
          alignItems="flex-start"
          sx={{ mt: 2 }}
          xs={12}
          md={isSmallScreen ? 12 : 10}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            item
            xs={12}
            sx={{ pb: 2 }}
          >
            {isSmallScreen && (
              <Grid item xs="auto">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={toggleSidebar(true)}
                >
                  <Box component="i" className="fa-solid fa-bars" />
                </IconButton>
                <Drawer
                  PaperProps={{
                    sx: { minWidth: "200px" },
                  }}
                  open={sidebarOpen}
                  onClose={toggleSidebar(false)}
                >
                  <Sidebar onSelect={toggleSidebar(false)} isSmallScreen />
                </Drawer>
              </Grid>
            )}

            <Grid item xs>
              <SearchBar setAlert={setAlert} />
            </Grid>
            <Grid item xs={12} md="auto">
              <Box sx={{ width: isSmallScreen ? "inherit" : 200 }}>
                <SortSelect />
              </Box>
            </Grid>
            {currentSidebarOption?.key === InboxSidebarOptionKey.inbox && (
              <Grid item xs={12}>
                <StarredSelect />
              </Grid>
            )}
            {currentSidebarOption?.key === InboxSidebarOptionKey.scheduled && (
              <ScheduledTabs />
            )}
          </Grid>
          {!fetchLoading && contacts && contacts.length === 0 && (
            <Box sx={styles.noContacts}>
              <Typography component="div" variant="h4" gutterBottom>
                {isFiltering
                  ? `No contacts found`
                  : `Empty ${currentSidebarOption?.label.toLowerCase()} folder`}
              </Typography>
              {isFiltering ? (
                <>
                  <Typography component="div" paragraph variant="h6">
                    Your filters are hiding your results, try{" "}
                    <Link sx={{ cursor: "pointer" }} onClick={clearFilters}>
                      clearing filters
                    </Link>
                    .
                  </Typography>
                </>
              ) : (
                <>
                  {currentSidebarOption?.key !== "sent" ? (
                    <>
                      <Typography component="div" paragraph variant="h6">
                        To see all of your sent emails,{" "}
                        <Link sx={{ cursor: "pointer" }} onClick={openSent}>
                          open your sent folder
                        </Link>
                        .
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography component="div" paragraph variant="h6">
                        Send emails in the{" "}
                        <RouterLink to={`/${routes.brands}`}>
                          brands tab
                        </RouterLink>{" "}
                        and monitor their status in this tab.
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
          <Grid
            item
            xs={12}
            sx={[
              styles.contacts,
              {
                height: {
                  xs:
                    currentSidebarOption?.key === InboxSidebarOptionKey.inbox ||
                    currentSidebarOption?.key ===
                      InboxSidebarOptionKey.scheduled
                      ? "calc(100vh - 270px)"
                      : "calc(100vh - 220px)",
                  lg:
                    currentSidebarOption?.key === InboxSidebarOptionKey.inbox ||
                    currentSidebarOption?.key ===
                      InboxSidebarOptionKey.scheduled
                      ? "calc(100vh - 225px)"
                      : "calc(100vh - 175px)",
                },
              },
            ]}
          >
            {contacts &&
              contacts.length > 0 &&
              contacts.map((row: OutreachContact, idx: number) => (
                <Grid
                  item
                  xs={12}
                  key={row?.id}
                  ref={
                    (
                      contacts.length > REF_INDEX
                        ? idx === contacts.length - REF_INDEX
                        : idx === contacts.length - 1
                    )
                      ? ref
                      : undefined
                  }
                >
                  {currentSidebarOption?.key ===
                  InboxSidebarOptionKey.scheduled ? (
                    <ScheduledContactRow contact={row} setAlert={setAlert} />
                  ) : (
                    <ContactRow contact={row} setAlert={setAlert} />
                  )}
                </Grid>
              ))}
            {!fetchLoading && fetchMoreLoading && (
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ mt: 2, mb: 10 }}
              >
                <CircularProgress size={30} disableShrink />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {fetchLoading && (
        <Backdrop sx={{ zIndex: 9999 }} open={fetchLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      <Routes>
        <Route
          path=":outreachContactId/*"
          element={<OutreachContactDrawer />}
        />
      </Routes>
    </>
  );
};
export default OutreachTable;
