import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { BentoContact, NewBentoContact } from "schemas/dashboard";
import { PreviewQuickSendBody } from "schemas/outreach";

import { fetcherAuth } from "utils/api";

import { OrganizationUserContext } from "./Organization";
import { LastContactedDate } from "./QuickSend";

interface QuickSendContactsInterface {
  getLastContacted: (emailAddress: string) => LastContactedDate | undefined;
  updateLastContacted: (
    emailAddresses: string[],
    override?: boolean,
  ) => Promise<LastContactedDate[]>;
  bentoContacts: BentoContact[];
  setBentoContacts: Dispatch<SetStateAction<BentoContact[]>>;
  getPreviewContacts: (
    brandId: number,
    data: PreviewQuickSendBody,
    bentoContact?: BentoContact | null,
    refetchContacts?: boolean,
  ) => Promise<{ contacts: BentoContact[]; data: PreviewQuickSendBody }>;
  updateBentoContacts: (
    selectableContacts: BentoContact[],
    bentoContact: BentoContact,
    resetBentoContacts: boolean,
  ) => void;
  fromMultipleCountries: boolean;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  getLastContacted: defaultContextMissingFunction,
  updateLastContacted: defaultContextMissingFunction,
  bentoContacts: [],
  setBentoContacts: defaultContextMissingFunction,
  getPreviewContacts: defaultContextMissingFunction,
  updateBentoContacts: defaultContextMissingFunction,
  fromMultipleCountries: false,
};

const QuickSendContactsContext =
  createContext<QuickSendContactsInterface>(defaultInterface);

interface Props {
  children: React.ReactNode;
}

const QuickSendContactsProvider = ({ children }: Props) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const bcid = searchParams.get("bcid");

  const [lastContactedMap, setLastContactedMap] = useState(
    new Map<string, LastContactedDate>(),
  );
  const [bentoContacts, setBentoContacts] = useState<BentoContact[]>([]);
  const [fromMultipleCountries, setFromMultipleCountries] =
    useState<boolean>(false);

  const getLastContacted = useCallback(
    (emailAddress: string) => {
      return lastContactedMap.get(emailAddress.toLowerCase());
    },
    [lastContactedMap],
  );

  const updateLastContacted = async (
    emailAddresses: string[],
    override: boolean = false,
  ): Promise<LastContactedDate[]> => {
    const emailsToFetch = [];
    const existingResults: { [key in string]: LastContactedDate } = {};

    for (const emailAddress of emailAddresses) {
      const lastContacted = getLastContacted(emailAddress);
      if (override || lastContacted === undefined) {
        emailsToFetch.push(emailAddress);
      } else {
        existingResults[emailAddress] = lastContacted;
      }
    }

    const fetchedLastContacted = await fetchLastContactDate(emailsToFetch);

    const finalResults: LastContactedDate[] = [];

    for (const email of emailAddresses) {
      if (email in existingResults) {
        finalResults.push(existingResults[email]);
      } else {
        finalResults.push(fetchedLastContacted[email.toLowerCase()]);
      }
    }

    return finalResults;
  };

  const fetchLastContactDate = async (
    emailAddresses: string[],
  ): Promise<{ [key in string]: LastContactedDate }> => {
    if (!emailAddresses || emailAddresses?.length === 0) {
      return {};
    }
    try {
      const queryString = emailAddresses
        .map((email) => `emails=${encodeURIComponent(email)}`)
        .join("&");
      const { lastContacted } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach/last-contacted?${queryString}`,
        "GET",
      );
      setLastContactedMap((prev) => {
        const updatedMap = new Map(prev);
        for (const email in lastContacted) {
          updatedMap.set(email, lastContacted[email]);
        }
        return updatedMap;
      });
      return lastContacted;
    } catch (error) {
      setLastContactedMap((prev) => {
        const updatedMap = new Map(prev);
        for (const email of emailAddresses) {
          updatedMap.set(email, null);
        }
        return updatedMap;
      });
    }
    return {};
  };

  const getPreviewContacts = async (
    brandId: number,
    previewData: PreviewQuickSendBody,
    specificContact?: BentoContact | NewBentoContact | null,
    refetchContacts?: boolean,
  ) => {
    let data = { ...previewData };

    let contacts = bentoContacts;
    let bestContact = null;
    if (refetchContacts || bcid) {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${brandId}/contacts`,
        "GET",
      );
      contacts = res.selectableContacts;
      updateLastContacted(contacts?.map((x) => x.email));
      bestContact = res.bestContact;
      setFromMultipleCountries(res.fromMultipleCountries);
    }
    // User click on a link from "Found New Contact" email

    const hasBcid = bcid && !isNaN(parseInt(bcid));
    const contactFromBcId = hasBcid
      ? contacts?.find((x) => x.id === Number(bcid))
      : undefined;

    if (contactFromBcId) {
      data.previewContact = contactFromBcId;
    } else if (specificContact?.email) {
      data.previewContact = specificContact;
    } else if (bestContact) {
      data.previewContact = bestContact;
    } else if (bentoContacts[0]?.bentoBrandId === Number(brandId)) {
      data.previewContact = bentoContacts[0];
    }
    if (hasBcid && !contactFromBcId) {
      searchParams.delete("bcid");
      setSearchParams(searchParams);
    }
    return { contacts, data };
  };

  const updateBentoContacts = (
    selectableContacts: BentoContact[],
    previewContact: BentoContact,
    resetBentoContacts: boolean,
  ) => {
    // If user add their own contact or from referred email,
    // update `selectableContacts` for this BentoBrand.
    const contacts = selectableContacts;
    const exists = contacts?.find(
      (x: BentoContact) => x?.email === previewContact?.email,
    );
    if (!exists && previewContact?.email) {
      updateLastContacted([previewContact?.email]);
      contacts.push(previewContact);
    }
    if (resetBentoContacts || !exists) {
      setBentoContacts(contacts);
    }
  };

  return (
    <QuickSendContactsContext.Provider
      value={{
        getLastContacted,
        updateLastContacted,
        bentoContacts,
        setBentoContacts,
        getPreviewContacts,
        updateBentoContacts,
        fromMultipleCountries,
      }}
    >
      {children}
    </QuickSendContactsContext.Provider>
  );
};

export { QuickSendContactsProvider, QuickSendContactsContext };
