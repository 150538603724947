import { useAuth } from "@clerk/clerk-react";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext } from "react";
import {
  BentoBrand,
  SavedBrand,
  SavedBrandCollection,
  SavedBrandStatus,
} from "schemas/dashboard";

import { fetcherAuth } from "./api";
import { makeDeepCopy } from "./updateLocalState";

export const useCollection = () => {
  const {
    setTotalsMap,
    collectionsMap,
    setCollectionsMap,
    setCollections,
    totalsMap,
    currentTab,
    selectedCollection,
    initializeList,
    fetchSavedBrandsForCollection,
  } = useContext(SavedBrandCollectionsContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setAlert, setErrorAlert } = useContext(AlertContext);

  const allUnsentBrands = collectionsMap(SavedBrandStatus.unsent)[0] || [];
  const allSentBrands = collectionsMap(SavedBrandStatus.sent)[0] || [];
  const allSavedBrands = [...allSentBrands, ...allUnsentBrands];

  const updateTotal = (
    collectionId: number,
    action: "add" | "remove",
    type: SavedBrandStatus,
    change = 1,
  ) => {
    setTotalsMap(type)((prev) => {
      if (prev[collectionId]) {
        prev[collectionId] =
          action === "add"
            ? // @ts-ignore: Collection will not be undefined here.
              prev[collectionId] + change
            : // @ts-ignore: Collection will not be undefined here.
              prev[collectionId] - change;
      } else {
        prev[collectionId] = action === "add" ? change : 0;
      }
      return prev;
    });
  };

  const isSavedBrand = (brand: BentoBrand) => {
    return (
      allSavedBrands.findIndex(
        (x) => Number(brand.id) === Number(x?.bentoBrandId),
      ) > -1
    );
  };

  const modifySavedBrands = (
    brand: SavedBrand,
    action: "add" | "remove",
    collectionId: number,
    type?: SavedBrandStatus,
  ) => {
    if (action === "add") {
      addToCollectionTab(brand, collectionId, type || currentTab);
    } else {
      _remove(brand);
    }
  };

  const addDraftToCollection = (bentoBrand: BentoBrand) => {
    const collectionId = bentoBrand?.savedBrandCollectionId || 0;
    _modifyProperty(collectionId, bentoBrand, "hasOutreachDraft", true, true);
  };

  const removeDraftFromCollection = (
    collectionId: number,
    bentoBrand: BentoBrand,
  ) => {
    _modifyProperty(collectionId, bentoBrand, "hasOutreachDraft", false, false);
  };

  const isDraftBrand = (bentoBrand: BentoBrand) => {
    return Boolean(
      allSavedBrands.find(
        (sb) =>
          Number(sb?.bentoBrandId) === Number(bentoBrand.id) &&
          sb?.bentoBrand.hasOutreachDraft === true,
      ),
    );
  };

  const moveAllToList = (brands: BentoBrand[], newCollectionId: number) => {
    setCollectionsMap(currentTab)((prev) => {
      const copy = makeDeepCopy(prev);
      const savedBrands = brands?.map((b) => ({
        bentoBrand: { ...b, savedBrandCollectionId: newCollectionId },
        bentoBrandId: b.id,
      }));
      copy[newCollectionId] = savedBrands;
      updateTotal(newCollectionId, "add", currentTab, brands?.length);
      return copy;
    });
  };

  const moveBrandToSent = (bentoBrand: BentoBrand) => {
    const { customCollectionId } = _findInCollection(bentoBrand);
    const savedBrand = {
      bentoBrand: {
        ...bentoBrand,
        savedBrandCollectionId: customCollectionId || 0,
      },
      bentoBrandId: bentoBrand.id,
    };
    // Add to custom collection and all saved
    if (customCollectionId !== undefined) {
      addToCollectionTab(savedBrand, customCollectionId, SavedBrandStatus.sent);
    }
    addToCollectionTab(savedBrand, 0, SavedBrandStatus.sent);

    // Remove from unsent
    _remove(savedBrand, SavedBrandStatus.unsent);
  };

  const createOrUpdateCollection = async (
    name: string,
    collectionId?: number,
    isBulkMode?: boolean,
  ) => {
    if (!currentOrg?.id) return;
    let url = collectionId
      ? `/api/organization/${currentOrg.id}/saved-brand-collections/${collectionId}`
      : `/api/organization/${currentOrg.id}/saved-brand-collections`;
    try {
      const res = await fetcherAuth(
        getToken,
        url,
        collectionId ? "PUT" : "POST",
        {},
        { name },
      );
      if (collectionId) {
        setCollections((prev) => {
          const copy = makeDeepCopy(prev);
          const index = copy.findIndex(
            (x: SavedBrandCollection) => x.id === collectionId,
          );
          if (index > -1) {
            copy[index] = res.savedBrandCollection;
          }
          return copy;
        });
        if (!isBulkMode)
          setAlert("Successfully updated your collection", "success");
      } else {
        setCollections((prev) => [...prev, res.savedBrandCollection]);
        initializeList(res.savedBrandCollection, SavedBrandStatus.sent);
        initializeList(res.savedBrandCollection, SavedBrandStatus.unsent);
        if (!isBulkMode)
          setAlert("Successfully created your collection", "success");
      }
      return res.savedBrandCollection;
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const deleteCollection = async (collectionId: number) => {
    if (!currentOrg?.id) return;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/saved-brand-collections/${collectionId}`,
        "DELETE",
      );
      setCollections((prev) => prev?.filter((x) => x.id !== collectionId));
      setCollectionsMap(SavedBrandStatus.sent)((prev) => {
        if (collectionId in prev) {
          delete prev[collectionId];
        }
        return prev;
      });
      setCollectionsMap(SavedBrandStatus.unsent)((prev) => {
        if (collectionId in prev) {
          delete prev[collectionId];
        }
        return prev;
      });
      await refreshList();
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const getTotal = (collectionId: number, type: SavedBrandStatus) => {
    const total = totalsMap(type)[collectionId];
    if (total !== undefined) {
      return total;
    } else {
      return undefined;
    }
  };

  const totalUnsent = getTotal(
    selectedCollection?.id || 0,
    SavedBrandStatus.unsent,
  );
  const totalSent = getTotal(
    selectedCollection?.id || 0,
    SavedBrandStatus.sent,
  );

  const refreshList = async (type?: SavedBrandStatus) => {
    let brandsToFetch: Array<void> = [];
    const unsent = Object.keys(collectionsMap(SavedBrandStatus.unsent)).map(
      (collectionId) =>
        fetchSavedBrandsForCollection(
          Number(collectionId),
          1,
          SavedBrandStatus.unsent,
        ),
    );
    unsent.push(fetchSavedBrandsForCollection(0, 1, SavedBrandStatus.unsent));
    const sent = Object.keys(collectionsMap(SavedBrandStatus.sent)).map(
      (collectionId) =>
        fetchSavedBrandsForCollection(
          Number(collectionId),
          1,
          SavedBrandStatus.sent,
        ),
    );
    sent.push(fetchSavedBrandsForCollection(0, 1, SavedBrandStatus.sent));
    if (!type) {
      brandsToFetch = [...unsent, ...sent];
    } else if (type === SavedBrandStatus.sent) {
      brandsToFetch = unsent;
    } else if (type === SavedBrandStatus.unsent) {
      brandsToFetch = sent;
    }
    return await Promise.all(brandsToFetch);
  };

  const bulkMoveToSent = (bentoBrands: BentoBrand[]) => {
    setCollectionsMap(SavedBrandStatus.unsent)((prev) => {
      const copy = makeDeepCopy(prev);
      for (const brand of bentoBrands) {
        if (brand?.savedBrandCollectionId) {
          const uniqueIndex = _findBentoBrand(
            copy[brand?.savedBrandCollectionId] || [],
            brand,
          );
          if (uniqueIndex !== undefined && uniqueIndex > -1) {
            copy[brand?.savedBrandCollectionId]?.splice(uniqueIndex, 1);
            updateTotal(
              brand?.savedBrandCollectionId,
              "remove",
              SavedBrandStatus.unsent,
            );
          }
        }
        const allSavedIndex = _findBentoBrand(copy[0] || [], brand);
        if (allSavedIndex !== undefined && allSavedIndex > -1) {
          copy[0]?.splice(allSavedIndex, 1);
          updateTotal(0, "remove", SavedBrandStatus.unsent);
        }
      }
      return copy;
    });

    setCollectionsMap(SavedBrandStatus.sent)((prev) => {
      const copy = makeDeepCopy(prev);
      for (const brand of bentoBrands) {
        const savedBrand = {
          bentoBrand: brand,
          bentoBrandId: brand.id,
        };
        if (brand?.savedBrandCollectionId) {
          const uniqueIndex = _findBentoBrand(
            prev[brand?.savedBrandCollectionId] || [],
            brand,
          );

          if (uniqueIndex === -1 || uniqueIndex === undefined) {
            copy[brand?.savedBrandCollectionId]?.push(savedBrand);
            updateTotal(
              brand?.savedBrandCollectionId,
              "add",
              SavedBrandStatus.sent,
            );
          }
        }
        const allSavedIndex = _findBentoBrand(prev[0] || [], brand);
        if (allSavedIndex === -1 || allSavedIndex === undefined) {
          copy[0]?.push(savedBrand);
          updateTotal(0, "add", SavedBrandStatus.sent);
        }
      }
      return copy;
    });
  };

  const addToCollectionTab = (
    brand: SavedBrand,
    collectionId: number,
    type: SavedBrandStatus,
  ) => {
    setCollectionsMap(type)((prev) => {
      const copy = makeDeepCopy(prev);
      const index = _findBentoBrand(copy[collectionId], brand.bentoBrand);
      const exists = index === 0 || (index && index > -1);
      if (!exists) {
        copy[collectionId] = [brand, ...copy[collectionId]];
        updateTotal(collectionId, "add", type, 1);
      } else {
        copy[collectionId][index] = brand;
      }
      return copy;
    });
  };

  const removeFromCollectionTab = (
    brand: SavedBrand,
    collectionId: number,
    type: SavedBrandStatus,
  ) => {
    setCollectionsMap(type)((prev) => {
      const copy = makeDeepCopy(prev);
      const index = _findBentoBrand(copy[collectionId], brand.bentoBrand);
      const exists = index === 0 || (index && index > -1);
      if (exists) {
        copy[collectionId].splice(index, 1);
        updateTotal(collectionId, "remove", type, 1);
      }
      return copy;
    });
  };

  const _remove = (brand: SavedBrand, oneType?: SavedBrandStatus) => {
    const _updateCollectionMap = (type: SavedBrandStatus) => {
      setCollectionsMap(type)((prev) => {
        const copy = makeDeepCopy(prev);
        for (const collectionId in copy) {
          const index = _findBentoBrand(copy[collectionId], brand.bentoBrand);
          if (index > -1) {
            copy[collectionId].splice(index, 1);
            updateTotal(Number(collectionId), "remove", type);
          }
        }
        return copy;
      });
    };
    if (oneType) {
      _updateCollectionMap(oneType);
    } else {
      _updateCollectionMap(SavedBrandStatus.unsent);
      _updateCollectionMap(SavedBrandStatus.sent);
    }
  };

  const _findInCollection = (bentoBrand: BentoBrand) => {
    let customCollectionId = allSavedBrands.find(
      (sb) =>
        Number(sb?.bentoBrandId) === Number(bentoBrand.id) &&
        sb?.bentoBrand.savedBrandCollectionId !== 0,
    )?.bentoBrand?.savedBrandCollectionId;

    const unsavedResult = _find(
      bentoBrand,
      SavedBrandStatus.unsent,
      customCollectionId,
    );
    const savedResult = _find(
      bentoBrand,
      SavedBrandStatus.sent,
      customCollectionId,
    );
    if (unsavedResult.found) {
      return { ...unsavedResult };
    } else if (savedResult.found) {
      return { ...savedResult };
    } else {
      return {
        indexOfCustomCollection: undefined,
        customCollectionId: undefined,
        type: undefined,
        indexOfAllSaved: undefined,
        found: false,
      };
    }
  };

  const _find = (
    bentoBrand: BentoBrand,
    type: SavedBrandStatus,
    customCollectionId: number | undefined,
  ) => {
    let indexOfCustomCollection: number | undefined = undefined;
    if (customCollectionId) {
      const brands = collectionsMap(type)[customCollectionId];
      indexOfCustomCollection = brands?.findIndex(
        (sb) => Number(sb?.bentoBrandId) === Number(bentoBrand.id),
      );
    }
    let indexOfAllSaved = collectionsMap(type)[0]?.findIndex(
      (sb) => Number(sb?.bentoBrandId) === Number(bentoBrand.id),
    );
    return {
      indexOfCustomCollection,
      customCollectionId,
      type,
      indexOfAllSaved,
      found:
        (indexOfCustomCollection !== undefined &&
          indexOfCustomCollection !== -1) ||
        (indexOfAllSaved !== undefined && indexOfAllSaved !== -1),
    };
  };

  const _modifyProperty = (
    collectionId: number,
    bentoBrand: BentoBrand,
    property: string, // a key such as 'hasOutreachDraft'
    value: any, // value for that key,
    addToList = true,
    listType = SavedBrandStatus.unsent,
  ) => {
    const {
      type,
      indexOfCustomCollection,
      customCollectionId,
      indexOfAllSaved,
    } = _findInCollection(bentoBrand);

    if (!type && addToList) {
      setCollectionsMap(listType)((prev) => {
        const copy = makeDeepCopy(prev);
        copy[collectionId].push({
          bentoBrand: { ...bentoBrand, [property]: value },
          bentoBrandId: bentoBrand.id,
        });
        return copy;
      });
    } else if (type) {
      setCollectionsMap(type)((prev) => {
        const copy = makeDeepCopy(prev);
        if (
          indexOfCustomCollection !== undefined &&
          indexOfCustomCollection > -1 &&
          customCollectionId !== undefined
        ) {
          copy[customCollectionId][indexOfCustomCollection]["bentoBrand"][
            property
          ] = value;
        }
        if (indexOfAllSaved !== undefined && indexOfAllSaved > -1) {
          copy[0][indexOfAllSaved]["bentoBrand"][property] = value;
        }
        return copy;
      });
    }
  };

  const _findBentoBrand = (array: SavedBrand[], bentoBrand: BentoBrand) => {
    return array?.findIndex(
      (x) => Number(x.bentoBrandId) === Number(bentoBrand.id),
    );
  };

  return {
    updateTotal,
    isSavedBrand,
    modifySavedBrands,
    addDraftToCollection,
    removeDraftFromCollection,
    isDraftBrand,
    moveAllToList,
    totalSent,
    totalUnsent,
    createOrUpdateCollection,
    deleteCollection,
    refreshList,
    moveBrandToSent,
    bulkMoveToSent,
    addToCollectionTab,
    removeFromCollectionTab,
  };
};
