import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { OrganizationUserContext } from "contexts/Organization";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import OutreachContactDrawer from "features/Influencer/ContactList/OutreachContactDrawer";
import EditTemplateDialog from "features/Influencer/Templates/TemplatesTable/EditTemplate";
import ImportDialog from "features/Influencer/Tracking/ImportDialog";
import { navigateBackPath } from "utils/navigation";
import { trackEvent } from "utils/tracking";

import DraggableTable from "./DraggableTable";
import ImportsDialog from "./ImportsDialog";

const ContactView = () => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const {
    selectedView,
    outreachContacts,
    hasShownImportDialog,
    setHasShownImportDialog,
  } = useContext(ContactViewContext);
  const { userIntegration } = useContext(UserIntegrationsContext);
  const { handleSearch } = useContext(ContactViewSearchContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showImportsDialog, setShowImportsDialog] = useState<boolean>(false);

  const { contactViewId } = useParams();

  useEffect(() => {
    trackEvent("Tracking Tab Viewed", {
      viewName: selectedView?.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSearch({ query: "", page: 1 }, Number(contactViewId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactViewId, currentOrg?.id]);

  useEffect(() => {
    if (
      selectedView?.name === "Imports" &&
      userIntegration?.partnershipsSearchedAt &&
      outreachContacts?.length > 0 &&
      !hasShownImportDialog
    ) {
      setShowImportsDialog(true);
      setHasShownImportDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView?.id, outreachContacts.length]);

  const onTemplateSuccess = () => {
    navigate(navigateBackPath(location.pathname, 2), { replace: true });
  };

  return (
    <>
      {selectedView?.name && <DraggableTable />}
      <Routes>
        <Route
          path=":outreachContactId/*"
          element={<OutreachContactDrawer />}
        />
        <Route
          path="templates/:templateId"
          element={<EditTemplateDialog onSuccess={onTemplateSuccess} />}
        />
        <Route path="pitch-tracker/import/*" element={<ImportDialog />} />
      </Routes>

      <ImportsDialog
        open={showImportsDialog}
        handleClose={() => {
          setShowImportsDialog(false);
        }}
      />
    </>
  );
};

export default ContactView;
