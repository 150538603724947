import { Box, List } from "@mui/material";
import { BENTO_BRAND_CATEGORIES } from "schemas/dashboard";

import MainCategory from "./MainCategory";
import styles from "./styles";

export default function CategoryFilter() {
  return (
    <Box sx={styles.root}>
      <List dense>
        {BENTO_BRAND_CATEGORIES?.map((category, index) => (
          <MainCategory key={index} category={category} />
        ))}
      </List>
    </Box>
  );
}
