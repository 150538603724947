import { MERGE_TAGS } from "constants/templates";

export const titleCase = (s: string) =>
  // Convert a string such as "scheduling_demo" into Scheduling Demo
  s
    ?.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    ?.replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group: string) =>
      group.toUpperCase().replace("-", "").replace("_", ""),
    );

export const camelToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
export const isInvalidEmail = (email: string) => {
  // Returns true if an email is invalid.
  const EMAIL_REGEX = /(.+)@(.+){2,}\.(.+){2,}/;
  return email.endsWith(".") || email.match(EMAIL_REGEX) === null;
};

export const isValidHttpUrl = (url: string) => {
  // Validate a http or https url
  try {
    const validateUrl = new URL(url);

    return (
      validateUrl.protocol === "http:" || validateUrl.protocol === "https:"
    );
  } catch (_) {
    return false;
  }
};

export const isValidInstagramUsername = (url: string) => {
  const regex = /(?:instagram\.com)\/([a-zA-Z0-9_.]{1,30})(\/|\?|$)/;
  const matches = regex.exec(url);
  const extractedUsername = matches && matches.length > 1 ? matches[1] : null;
  if (extractedUsername !== null) return extractedUsername;
  return false;
};

export const isUrlWithoutProtocol = (url: string) => {
  const regex = /^(?!https?:\/\/)([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]+(\/[^/]+)*$/i;
  return regex.test(url);
};

export const addProtocolToUrl = (url: string) => {
  if (isValidHttpUrl(url)) return url;
  return `https://${url}`;
};

export const generateInitials = (name: string) => {
  // Given a string such as "Thomas Mackenzie", return "TM".
  const shortenedName = name
    ?.split(" ")
    .map((n) => n[0])
    .join("");
  if (shortenedName?.length > 2) {
    return shortenedName?.substring(0, 2);
  } else {
    return shortenedName;
  }
};

export const hideEmail = (email: string): string => {
  return email.replace(/(\w{3})[\w+.-]+@([\w.]+\w)/, "$1***@$2");
};

export const formatNumber = (num: number) => {
  // Given a number such as 1000, format it to be 1K
  let formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(num);
};

export const getVariablesFromHtml = (html?: string) => {
  if (!html) return [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const textContent = doc.body.textContent || "";
  const reg = /\{[^}]+\}/g;
  const matches = textContent.match(reg) || [];
  const variables = matches.map((match) => match.slice(1, -1).trim());
  return variables;
};

export const containsMergeTags = (text?: string) => {
  if (!text) return false;

  const MERGE_TAGS_INSTANCES = [
    "first name",
    "brand name",
    "firstname",
    "brandname",
    "first_name",
    "brand_name",
  ];

  const regex = new RegExp(`${MERGE_TAGS_INSTANCES.join("|")}`);

  const reg = /<span.*?>(.*?)<\/span>/gm;
  const removedSpanTag = text.replace(reg, "$1");

  if (removedSpanTag) {
    return regex.test(removedSpanTag?.toLowerCase());
  }

  return false;
};

export const containsMergeTagsTemplates = (templateBody: string) => {
  const regex = new RegExp(`${MERGE_TAGS.join("|")}`);
  if (templateBody) {
    return regex.test(templateBody);
  }

  return false;
};

export const splitJsonObjects = (input: string): Array<string> => {
  const jsonObjects: Array<any> = [];
  let braceCount = 0;
  let currentObject = "";

  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    currentObject += char;

    if (char === "{") {
      braceCount++;
    } else if (char === "}") {
      braceCount--;
    }

    if (braceCount === 0 && currentObject.trim() !== "") {
      try {
        jsonObjects.push(currentObject.trim());
      } catch (e) {
        console.error(`Error parsing JSON string: ${currentObject}`, e);
      }
      currentObject = "";
    }
  }

  return jsonObjects;
};
