import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { Moment } from "moment";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { CustomEvent } from "schemas/functions";

import { isPastMoment } from "utils/time";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface ScheduleDialogProps {
  open: boolean;
  handleClose: () => void;
  onSchedule: (sendAt: string) => void;
  defaultTime: Moment;
}

export default function ScheduleDialog({
  open,
  handleClose,
  onSchedule,
  defaultTime,
}: ScheduleDialogProps) {
  const { setAlert } = useContext(AlertContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { emailSettings, setEmailSettings } = useContext(
    OrganizationUserContext,
  );

  const [dateValue, setDateValue] = useState<Moment>(defaultTime);

  const timezone = emailSettings?.timezone || moment.tz.guess();
  const setTimezone = (timezone: string) => {
    // @ts-ignore
    setEmailSettings({
      ...emailSettings,
      timezone,
    });
  };

  const handleSchedule = (e: CustomEvent) => {
    const isPast = isPastMoment(dateValue);
    if (isPast) {
      setAlert("The time you select is in the past", "error");
    } else {
      e.stopPropagation();

      onSchedule(dateValue.toISOString());
    }
  };

  const handleTimezoneChange = (
    event: CustomEvent,
    newValue: string | null,
  ) => {
    event.stopPropagation();
    if (newValue) {
      trackEvent("Timezone Changed", { timezone: newValue });
      setTimezone(newValue);
    }
  };

  useEffect(() => {
    const hour = dateValue?.hour();
    // @ts-ignore
    setEmailSettings((prev) => ({
      ...prev,
      hour,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobileScreen}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          Schedule Email
          <Button variant="contained" disableElevation onClick={handleSchedule}>
            Schedule
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={styles.content}
        onClick={(e: CustomEvent) => e.stopPropagation()}
      >
        <Box sx={styles.timezonePicker}>
          <Autocomplete
            options={moment.tz.names()}
            value={timezone}
            onChange={handleTimezoneChange}
            renderInput={(params) => <TextField {...params} label="Timezone" />}
          />
        </Box>

        <Typography color="textSecondary">
          We recommend scheduling your email around the same time and day as the
          last response in this thread. To help, we've selected a default time
          based on this approach.
        </Typography>

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
          <StaticDateTimePicker
            value={dateValue}
            timezone={timezone}
            onChange={(newValue: Moment | null) =>
              newValue && setDateValue(newValue)
            }
            disablePast
            slotProps={{
              actionBar: {
                actions: [],
              },
            }}
            views={["day", "hours"]}
            sx={{
              ".MuiDateTimePickerToolbar-ampmSelection .MuiButton-text .Mui-selected":
                {
                  color: "#7db986 !important",
                },
            }}
          />
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
}
