import { grey } from "@mui/material/colors";

const styles = {
  moveTo: {
    pt: 1,
    borderTop: `1px solid ${grey[200]}`,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    textTransform: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    mb: 1,
    color: "primary.main",
  },
};

export default styles;
