import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  capitalize,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendContext } from "contexts/QuickSend";
import { useContext, useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { ALL } from "schemas/forms";
import { SetAlertType } from "schemas/functions";
import { routes } from "schemas/routes";

import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import StyledButton from "components/Styled/Button";
import { StyledTableCell } from "components/Styled/Table/sharedStyles";
import { PAGE_VISITED } from "constants/trackingProps";
import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { formatEpochTimestamp } from "utils/time";
import { trackEvent } from "utils/tracking";
import { removeFromList } from "utils/updateLocalState";

import EditTemplateDialog from "./EditTemplate";
import FilterMenu from "./FilterMenu";
import styles from "./styles";

interface TemplatesTableProps {
  setAlert: SetAlertType;
}
export default function TemplatesTable({ setAlert }: TemplatesTableProps) {
  const { getToken } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { discoverTab } = useContext(DiscoverViewContext);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen =
    isMediumScreen || (isLargeScreen && discoverTab !== null);

  const { setErrorAlert } = useContext(AlertContext);

  const { currentOrg } = useContext(OrganizationUserContext);
  const {
    fetchOutreachTemplates,
    templates,
    setTemplates,
    fetchOutreachTemplatesLoading,
    outreachTemplates,
    setCreateTemplateDialogOpen,
  } = useContext(OutreachTemplatesContext);
  const { setNewlyAddedTemplateIndex } = useContext(QuickSendContext);

  const [templateToDelete, setTemplateToDelete] = useState<Template | null>(
    null,
  );
  const [deleteTemplateLoading, setDeleteTemplateLoading] = useState(false);
  const [filter, setFilter] = useState(ALL);

  useEffect(() => {
    fetchOutreachTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  useEffect(() => {
    trackEvent(PAGE_VISITED);
  }, []);

  const getFilteredTemplates = () => {
    let templatesWithIds = templates?.filter((x) => x.id);
    if (
      filter === TemplateContentType.OUTREACH ||
      filter === TemplateContentType.FOLLOWUP
    ) {
      templatesWithIds = templatesWithIds?.filter(
        (x) => x.contentType === filter,
      );
    }
    return templatesWithIds;
  };

  const handleOpenDeleteTemplate = (template: Template) => {
    trackEvent("Delete Template Button Clicked");
    setTemplateToDelete(template);
  };
  const handleDeleteTemplate = async () => {
    if (
      outreachTemplates?.length <= 1 &&
      templateToDelete?.contentType === TemplateContentType.OUTREACH
    ) {
      setAlert("Need at least one outreach template", "warning");
      return;
    }
    setDeleteTemplateLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/${templateToDelete?.id}`,
        "DELETE",
      );
      removeFromList(templates, setTemplates, templateToDelete, "id");
      handleClose();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setDeleteTemplateLoading(false);
    }
  };

  const handleClose = () => {
    setTemplateToDelete(null);
  };

  const trackCreateTemplate = () => {
    setCreateTemplateDialogOpen(true);
    setNewlyAddedTemplateIndex(-1);
    trackEvent("Add Template Button Clicked", {
      "Clicked From": "Templates Table View",
    });
  };

  const onTemplateSuccess = () => {
    navigate(`/${routes.templates}`, { replace: true });
  };

  return (
    <Box sx={styles.root}>
      <Grid
        container
        sx={styles.boardHeaderContainer}
        justifyContent={isMobileScreen ? "flex-start" : "space-between"}
        alignItems="center"
      >
        <Grid item xs={12} md={isMobileScreen ? 12 : 6}>
          {!isMobileScreen && (
            <Typography sx={styles.pageHeader} component="div">
              <strong>Your Templates</strong>
            </Typography>
          )}
          <Typography sx={{ mb: 2 }}>
            On this page, you can edit or create your own email templates.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={isMobileScreen ? 12 : true}
          container
          gap={1}
          justifyContent={isMobileScreen ? "flex-start" : "flex-end"}
        >
          <StyledButton onClick={trackCreateTemplate}>
            <Box component="i" className="fa-regular fa-plus" sx={{ mr: 1 }} />{" "}
            Create Template
          </StyledButton>
          <FilterMenu filter={filter} setFilter={setFilter} />
        </Grid>
      </Grid>

      {fetchOutreachTemplatesLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer sx={styles.tableContainer}>
          <Table
            sx={[
              {
                maxWidth: { xs: "100%", md: isMobileScreen ? "100%" : "auto" },
                minWidth: { xs: "100%", md: isMobileScreen ? "100%" : 650 },
              },
              { backgroundColor: "white" },
            ]}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Template Name</StyledTableCell>
                {!isMobileScreen && (
                  <StyledTableCell align="right">Last Modified</StyledTableCell>
                )}
                {!isMobileScreen && (
                  <StyledTableCell align="right">Template Type</StyledTableCell>
                )}
                <StyledTableCell align="right">Edit</StyledTableCell>
                <StyledTableCell align="right">Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getFilteredTemplates().map(
                (template: Template, index: number) => (
                  <TableRow key={index} sx={styles.row}>
                    <TableCell component="th" scope="row">
                      {template?.name}
                      {isMobileScreen && (
                        <Typography
                          sx={{
                            fontSize: 12,
                            color:
                              template?.contentType ===
                              TemplateContentType.OUTREACH
                                ? "primary.main"
                                : "secondary.main",
                          }}
                        >
                          {template?.contentType}
                        </Typography>
                      )}
                    </TableCell>

                    {!isMobileScreen && (
                      <TableCell align="right">
                        {template?.updatedAt
                          ? formatEpochTimestamp({
                              epochTimestamp: template?.updatedAt,
                            })
                          : "--"}
                      </TableCell>
                    )}

                    {!isMobileScreen && (
                      <TableCell align="right" size="small">
                        <Chip
                          label={capitalize(template?.contentType)}
                          sx={{
                            backgroundColor:
                              template?.contentType ===
                              TemplateContentType.OUTREACH
                                ? "green.light"
                                : "secondary.light",
                          }}
                        />
                      </TableCell>
                    )}

                    <TableCell align="right">
                      <IconButton
                        component={Link}
                        to={`/${routes.templates}/${template.id}`}
                        sx={styles.editButton}
                        onClick={() => {
                          trackEvent("Edit Template Button Clicked");
                        }}
                      >
                        <Box
                          component="i"
                          className="fa-regular fa-pen-to-square"
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <Box>
                        <IconButton
                          onClick={() => handleOpenDeleteTemplate(template)}
                          sx={styles.editButton}
                        >
                          <Box
                            component="i"
                            className="fa-regular fa-trash-can"
                          />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Routes>
        <Route
          path=":templateId"
          element={<EditTemplateDialog onSuccess={onTemplateSuccess} />}
        />
      </Routes>

      <ConfirmDialog
        header={`Delete Template`}
        subheader={`Are you sure you want to delete ${templateToDelete?.name}?`}
        open={templateToDelete !== null}
        handleClose={handleClose}
        handleConfirm={handleDeleteTemplate}
        loading={deleteTemplateLoading}
      />
    </Box>
  );
}
