import { LoadingButton } from "@mui/lab";
import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";
import { routes } from "schemas/routes";

import Editor from "components/Editor";
import { extensions } from "components/Editor/utils";
import { getGoals, getHighlights, getTones } from "utils/templates";

import styles from "../styles";

interface CustomizePartOneEmailFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
  isProfilePage?: boolean;
}

const CustomizePartOneEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
  isProfilePage = false,
}: CustomizePartOneEmailFormProps) => {
  const [defaultGoalValidation, setDefaultGoalValidation] = useState("");
  const [defaultToneValidation, setDefaultToneValidation] = useState("");
  const [defaultHighlightValidation, setDefaultHighlightValidation] =
    useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(`/${routes.introductionOnboarding}${location.search}`);
  };

  const handleButtonChange = (field: string, value: string) => {
    if (field === "defaultGoal") setDefaultGoalValidation("");
    if (field === "defaultTemplateTone") setDefaultToneValidation("");
    if (field === "defaultTemplateHighlight") setDefaultHighlightValidation("");
    setOnboardingForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const editor = useEditor(
    {
      extensions: extensions({ hardBreaksOnly: true }),
      content: onboardingForm?.signature,
      onUpdate({ editor }) {
        setOnboardingForm((prev) => {
          return { ...prev, signature: editor.getHTML() };
        });
      },
    },
    [!!onboardingForm?.signature],
  ) as TipTapEditor;

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    const goals = getGoals();
    const tones = getTones();
    const highlights = getHighlights(onboardingForm?.isUgcCreator);

    const validGoal = goals?.find((x) => x.key === onboardingForm?.defaultGoal);
    if (!validGoal) {
      setDefaultGoalValidation("Please pick an option");
    }
    const validTone = tones?.find(
      (x) => x.key === onboardingForm?.defaultTemplateTone,
    );
    if (!validTone) {
      setDefaultToneValidation("Please pick an option");
    }
    const validHighlight = highlights?.find(
      (x) => x.key === onboardingForm?.defaultTemplateHighlight,
    );
    if (!validHighlight) {
      setDefaultHighlightValidation("Please pick an option");
    }
    if (!validGoal || !validTone || !validHighlight) {
      return;
    }
    saveOnboardingForm(onboardingForm, "emailPartOne");
  };

  return (
    <>
      {!isProfilePage && (
        <Typography variant="h3" sx={{ mb: 3 }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-envelope"
          />
          Let us help you write customized emails
        </Typography>
      )}

      <form onSubmit={handleSubmit}>
        {!isProfilePage && (
          <>
            <Grid sx={styles.fieldContainer}>
              <FormHelperText
                sx={styles.helperText}
                error={!!defaultGoalValidation}
              >
                What is your current main pitching goal? *{" "}
                {defaultGoalValidation && <>({defaultGoalValidation})</>}
              </FormHelperText>
              <Grid container gap={1}>
                {getGoals().map((goal) => (
                  <Button
                    key={goal.key}
                    onClick={() => {
                      handleButtonChange("defaultGoal", goal.key);
                    }}
                    size="small"
                    variant="outlined"
                    sx={[
                      styles.button,
                      onboardingForm.defaultGoal === goal.key &&
                        styles.selected,
                    ]}
                  >
                    {goal.value}
                  </Button>
                ))}
              </Grid>
            </Grid>

            <Grid sx={styles.fieldContainer}>
              <FormHelperText
                sx={styles.helperText}
                error={!!defaultToneValidation}
              >
                Preferred tone of voice *{" "}
                {defaultToneValidation && <>({defaultToneValidation})</>}
              </FormHelperText>
              <Grid container gap={1}>
                {getTones().map((tone) => (
                  <Button
                    onClick={() => {
                      handleButtonChange("defaultTemplateTone", tone.key);
                    }}
                    key={tone.key}
                    variant="outlined"
                    size="small"
                    sx={[
                      styles.button,
                      onboardingForm.defaultTemplateTone === tone.key &&
                        styles.selected,
                    ]}
                  >
                    {tone.value}
                  </Button>
                ))}
              </Grid>
            </Grid>

            <Grid sx={styles.fieldContainer}>
              <FormHelperText
                sx={styles.helperText}
                error={!!defaultHighlightValidation}
              >
                What do you typically highlight in pitch emails? *{" "}
                {defaultHighlightValidation && (
                  <>({defaultHighlightValidation})</>
                )}
              </FormHelperText>
              <Grid container gap={1}>
                {getHighlights(onboardingForm?.isUgcCreator).map(
                  (highlight) => (
                    <Button
                      onClick={() => {
                        handleButtonChange(
                          "defaultTemplateHighlight",
                          highlight.key,
                        );
                      }}
                      key={highlight.key}
                      variant="outlined"
                      size="small"
                      sx={[
                        styles.button,
                        onboardingForm.defaultTemplateHighlight ===
                          highlight.key && styles.selected,
                      ]}
                    >
                      {highlight.value}
                    </Button>
                  ),
                )}
              </Grid>
            </Grid>
          </>
        )}

        <Grid sx={styles.fieldContainer}>
          <FormHelperText sx={styles.helperText}>
            Enter your email signature (image or text)
          </FormHelperText>
          <Editor editor={editor} allowAttachment={false} allowImage={true} />
        </Grid>

        {!isProfilePage && (
          <Grid item xs="auto" container columnGap={1}>
            <Grid item xs="auto">
              <Button onClick={handleBack} disableElevation>
                Back
              </Button>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <LoadingButton
                disableElevation
                variant="contained"
                type="submit"
                loading={loading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default CustomizePartOneEmailForm;
