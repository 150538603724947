import { Theme, darken } from "@mui/material";
import { grey } from "@mui/material/colors";

const styles = {
  button: {
    height: 25,
    textTransform: "none",
    fontSize: 13,
    color: grey[700],
    borderColor: grey[400],
  },
  filterColor: {
    backgroundColor: "primary.light",
    color: "black",
    borderColor: "primary.main",
    "&:hover": (theme: Theme) => ({
      backgroundColor: darken(theme.palette.primary.light, 0.1),
    }),
  },
  clearButton: {
    fontSize: 14,
    height: 25,
    width: 25,
    ml: 1,
  },
  update: {
    fontSize: 12,
    color: grey[600],
    alignItems: "center",
    display: "flex",
  },
  circle: {
    ml: 1,
    color: grey[600],
  },
};

export default styles;
