import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { Plans } from "schemas/payments";

import { formatEpochTimestamp } from "utils/time";

interface CancelDialogProps {
  loading: boolean;
  plan: Plans;
  open: boolean;
  onClose: () => void;
  handleCheckout: (
    plan: Plans,
    flow?: string,
    openTarget?: "_self" | "_blank",
  ) => void;
}

export default function CancelDialog({
  loading,
  plan,
  open,
  onClose,
  handleCheckout,
}: CancelDialogProps) {
  const { subscription } = useContext(SubscriptionContext);
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <IconButton
        sx={{
          color: "primary.main",
          fontSize: 14,
          position: "absolute",
          right: 10,
          top: 10,
        }}
        onClick={onClose}
      >
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle>Before You Go</DialogTitle>

      <DialogContent>
        <Typography gutterBottom>
          <strong>Warning:</strong> All follow-ups and brand requests beyond
          your cancellation date will be deleted.
        </Typography>
        <Typography paragraph>Do you want to continue?</Typography>
        <Box display="flex" gap={1} justifyContent="center" sx={{ mb: 2 }}>
          <Button variant="contained" onClick={onClose}>
            Keep Subscription
          </Button>
          <LoadingButton
            loading={loading}
            color="secondary"
            onClick={() => {
              handleCheckout(plan, "subscription_cancel", "_blank");
            }}
          >
            Continue to Cancel
          </LoadingButton>
        </Box>
        {subscription?.billingCycle[1] && (
          <Typography paragraph color="textSecondary" variant="caption">
            Your subscription will remain active until the end of your current
            billing period on{" "}
            <strong>
              {formatEpochTimestamp({
                epochTimestamp: subscription?.billingCycle[1],
                addYear: true,
              })}
            </strong>
            .
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}
