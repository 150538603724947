import { grey } from "@mui/material/colors";

export const getStyles = (hasContacts: boolean) => {
  return {
    addRow: {
      backgroundColor: "#fcfcfc",
      py: 0,
      borderTop: hasContacts ? `1px solid ${grey[300]}` : 0,
    },
    addRowHover: {
      "&:hover": {
        backgroundColor: grey[100],
        cursor: "pointer",
      },
    },
    emptyBoxGmailImport: {
      minHeight: "300px",
      display: "block",
    },
    addRowCell: {
      position: "sticky",
      left: 0,
      background: "#fcfcfc",
      borderTop: `1px solid ${grey[300]}`,
    },
    newRowButton: {
      textTransform: "none",
      color: grey[800],
    },
    noContactCell: {
      backgroundColor: "#fcfcfc",
      borderRight: `1px solid ${grey[300]}`,
      py: 1,
    },
  };
};
