import { fetcherAuth } from "./api";

export const delay = (ms: number): Promise<void> =>
  new Promise<void>((res) => setTimeout(res, ms));

export const checkPolling = async (
  interval: number,
  timeout: number,
  getToken: any,
  url: string,
  verifyCondition: (res: any) => boolean,
  errorMessage: string,
  method = "GET",
) => {
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      const res = await fetcherAuth(getToken, url, method);
      if (verifyCondition(res)) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        resolve(res);
      }
    }, interval);

    // Reject on timeout.
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      reject(errorMessage);
    }, timeout);
  });
};
