import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { KeyboardEvent, useContext, useEffect, useRef } from "react";
import { CustomEvent } from "schemas/functions";

import { TABLE_SEARCH_CLEARED } from "features/Influencer/Tracking/events";

import styles from "./styles";

const SearchBar = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { contactViewParams, handleSearch } = useContext(
    ContactViewSearchContext,
  );
  const { trackTable } = useContext(ContactViewContext);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSearch({ query: inputRef.current?.value || "", page: 1 });
    }
  };

  useEffect(() => {
    const handleListen = (e: CustomEvent) => {
      // Check if user press Ctrl + F, if so autofocus on Search Bar
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 70) {
        e.preventDefault();
        setTimeout(() => {
          // @ts-ignore
          inputRef.current.focus();
        }, 100);
      }
    };
    window.addEventListener("keydown", handleListen);

    return () => window.removeEventListener("keydown", handleListen);
  }, []);

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      key={contactViewParams.query}
      defaultValue={contactViewParams.query}
      variant="outlined"
      size="small"
      sx={{ maxWidth: { lg: "600px" }, float: "right" }}
      placeholder="Search brands, contacts, notes..."
      onKeyDown={handleKeyDown}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box component="i" className="fa-regular fa-search" />
          </InputAdornment>
        ),
        endAdornment: contactViewParams.query && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                trackTable(TABLE_SEARCH_CLEARED);
                handleSearch({ query: "", page: 1 });
              }}
              sx={styles.clearButton}
            >
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
