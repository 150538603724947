import { TagLabel } from "contexts/Brands";
import { isArray } from "lodash";
import lodash from "lodash";
import {
  BENTO_BRAND_CATEGORIES,
  BESearchParametersToFEParams,
  BentoBrandCategoryMap,
  BentoBrandMetadataTags,
  BentoBrandSearchParameters,
  CompanySize,
  CompanyType,
  DISCOVER_FILTER,
  FollowingSizeRange,
  Location,
  MetadataType,
  Sort,
} from "schemas/dashboard";
import { Map } from "schemas/functions";

import { titleCase } from "./string";

export const getSubcategories = (
  mainCategory: string,
  type: "label" | "key" = "label",
) => {
  const subCategories = BENTO_BRAND_CATEGORIES.find(
    (x) => x.mainCategory.key === mainCategory,
  )?.subCategories;
  if (subCategories) {
    const subLabels = subCategories?.map((sub) => sub[type]);
    return subLabels;
  } else {
    return type === "label"
      ? [BentoBrandCategoryMap[mainCategory]]
      : [mainCategory];
  }
};

export const getCategoriesParams = (
  selectedCategories: TagLabel[],
  encoded?: boolean,
) => {
  let categoryQueries: string[] = [];
  for (const category of selectedCategories) {
    const isMainCategory = Boolean(
      category.key.includes(":all") ||
        BENTO_BRAND_CATEGORIES?.find(
          (x) => x.mainCategory.key === category.key,
        ),
    );
    if (isMainCategory) {
      let mainCategory = category.key;
      if (category.key.includes(":all")) {
        mainCategory = category.key.split(":")[0];
      }
      let subs = getSubcategories(mainCategory);
      if (encoded) {
        subs = subs?.map((s) => encodeURIComponent(s));
      }
      categoryQueries = categoryQueries.concat(subs);
    } else {
      if (encoded) {
        categoryQueries.push(
          encodeURIComponent(BentoBrandCategoryMap[category.key]),
        );
      } else {
        categoryQueries.push(BentoBrandCategoryMap[category.key]);
      }
    }
  }
  return categoryQueries;
};

export const getMinAndMaxInstagram = (
  selectedInstagramFollowings: TagLabel[],
) => {
  const followers: Map = {
    min_instagram_followers: undefined,
    max_instagram_followers: undefined,
  };
  const min = Math.min(
    ...selectedInstagramFollowings?.map((x) => FollowingSizeRange[x.key]?.min),
  );
  const max = Math.max(
    ...selectedInstagramFollowings?.map((x) => FollowingSizeRange[x.key]?.max),
  );

  if (min && !isNaN(min) && min && isFinite(min)) {
    followers["min_instagram_followers"] = min;
  }
  if (max !== Math.max() && !isNaN(max) && isFinite(max)) {
    followers["max_instagram_followers"] = max;
  }
  return followers;
};

export const convertFilterParamsToQuery = (
  discoverFilterParams: Map,
  selectedMetadataTags: BentoBrandMetadataTags[],
  searchQuery?: string,
) => {
  let query = searchQuery || "";
  if (selectedMetadataTags?.length > 0) {
    const clickedTags = selectedMetadataTags?.filter(
      (x) => x.type === MetadataType.tags,
    );
    query += clickedTags?.map((x) => x.value);
  }
  const converted: BentoBrandSearchParameters = {
    categories: getCategoriesParams(discoverFilterParams?.selectedCategories),
    company_types: discoverFilterParams?.selectedCompanyTypes?.map(
      (x: TagLabel) => x.key as CompanyType,
    ),
    locations: discoverFilterParams?.selectedLocations?.map(
      (x: TagLabel) => x.key as Location,
    ),
    geopoints: discoverFilterParams?.selectedGeopoints?.map(
      (x: TagLabel) => x.key,
    ),
    company_sizes: discoverFilterParams?.selectedCompanySizes?.map(
      (x: TagLabel) => x.key as CompanySize,
    ),
    sort: (discoverFilterParams?.selectedSort?.key as Sort) || Sort.Recommended,
    city: discoverFilterParams?.selectedCity?.key || null,
    query,
  };
  const followers = getMinAndMaxInstagram(
    discoverFilterParams?.selectedInstagramFollowings,
  );
  if (followers?.min_instagram_followers) {
    converted["min_instagram_followers"] = followers.min_instagram_followers;
  }
  if (followers?.max_instagram_followers) {
    converted["max_instagram_followers"] = followers.max_instagram_followers;
  }
  return converted;
};

const getTagLabel = (key: string, v: string | number) => {
  // Given a key such as `company_size`, and a value such as `sm`,
  // returns the label `small`. For value with no corresponding tag,
  // returns it as is.
  if (key in BESearchParametersToFEParams) {
    const queryParams = BESearchParametersToFEParams[key];
    if (queryParams in DISCOVER_FILTER && v in DISCOVER_FILTER[queryParams]) {
      const label = DISCOVER_FILTER[queryParams][v]?.name;
      return label;
    }
  }
  if (typeof v === "string") {
    return titleCase(v);
  }
  return v?.toString();
};

export const getTagLabelFromParams = (
  key: string,
  searchParameters: BentoBrandSearchParameters,
) => {
  // Give a `key` such as `company_sizes` and a list of value `[md, sm]`
  // returns readable labels for that key: ["medium", "small"]
  const value = searchParameters[key as keyof BentoBrandSearchParameters];
  const toDisplay: string[] = [];
  if (isArray(value)) {
    for (const v of value) {
      const label = getTagLabel(key, v);
      toDisplay.push(label);
    }
  } else if (value) {
    const label = getTagLabel(key, value);
    toDisplay.push(label);
  }
  return toDisplay;
};

export const isEqualParams = (
  params1: BentoBrandSearchParameters,
  params2: BentoBrandSearchParameters,
) => {
  const p1 = lodash.pickBy(params1, lodash.identity);
  const p2 = lodash.pickBy(params2, lodash.identity);
  return lodash.isEqual(p1, p2);
};

export const parseQuery = (str: string) => {
  const include: string[] = [];
  const exclude: string[] = [];

  // Regular expression to match exclusions
  const exclusionPattern = /-(?:"([^"]+)"|(\w+))/g;
  let match;

  while ((match = exclusionPattern.exec(str)) !== null) {
    // Check if it's a quoted string or a single word
    exclude.push(match[1] || match[2]);
  }

  // Split the string by spaces, filter exclusions, and add remaining words to include
  str.split(/\s+/).forEach((word) => {
    // Clean the word of quotes or other symbols
    const cleanWord = word.replace(/^["']|["']$/g, "");
    if (!exclude.includes(cleanWord) && !word.startsWith("-")) {
      include.push(cleanWord);
    }
  });

  return { include, exclude };
};
