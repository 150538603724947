import { Dispatch, SetStateAction, createContext, useState } from "react";
import {
  BentoContact,
  NewBentoContact,
  PreviewMessage,
} from "schemas/dashboard";
import { OutreachEmailErrors } from "schemas/outreach";

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

export type RecentContactDetails = {
  contact: BentoContact | NewBentoContact | null;
  sendAt: null | string;
};

interface QuickSendErrorContextInterface {
  setErrorsFromMessage: (emails: PreviewMessage[], msg?: string) => boolean;
  setErrors: Dispatch<SetStateAction<OutreachEmailErrors>>;
  errors: OutreachEmailErrors;
}

const defaultInterface = {
  setErrorsFromMessage: defaultContextMissingFunction,
  setErrors: defaultContextMissingFunction,
  errors: {},
};

const QuickSendErrorContext =
  createContext<QuickSendErrorContextInterface>(defaultInterface);

interface QuickSendErrorProviderProps {
  children: React.ReactNode;
}

const QuickSendErrorProvider = ({ children }: QuickSendErrorProviderProps) => {
  const [errors, setErrors] = useState<OutreachEmailErrors>({});

  const isBodyEmpty = (body: string) => {
    return body.trim() === "" || body.replace(/(<([^>]+)>)/gi, "") === "";
  };

  const setErrorsFromMessage = (emails: PreviewMessage[], message?: string) => {
    const err: OutreachEmailErrors = {};
    if (message?.includes("subject")) {
      err["outreachSubject"] = true;
    }
    let needsToScroll = false;
    if (message?.includes("body")) {
      err["outreachBody"] = emails?.findIndex((x) => isBodyEmpty(x.body));
      needsToScroll = true;
    }
    setErrors(err);
    return needsToScroll;
  };

  return (
    <QuickSendErrorContext.Provider
      value={{
        setErrorsFromMessage,
        errors,
        setErrors,
      }}
    >
      {children}
    </QuickSendErrorContext.Provider>
  );
};

export { QuickSendErrorProvider, QuickSendErrorContext };
