import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { PreviewMessage } from "schemas/dashboard";
import {
  HandleChangePreviewFunction,
  OutreachEmailErrors,
} from "schemas/outreach";

import Editor from "components/Editor/quicksend";
import { extensions } from "components/Editor/utils";
import { MERGE_TAGS } from "constants/templates";
import AttachmentManager from "features/Influencer/ContactList/AttachmentManager";
import {
  HandleSendEmail,
  Template,
} from "features/Influencer/ContactList/schema";
import { containsMergeTags, getVariablesFromHtml } from "utils/string";
import { makeDeepCopy } from "utils/updateLocalState";

import BottomToolbar from "../BottomToolbar";
import SelectDraftTemplate from "./SelectDraftTemplate";
import styles from "./styles";

interface PreviewIndividualProps {
  preview: PreviewMessage;
  changePreview: HandleChangePreviewFunction;
  index: number;
  setEmailMessages: Dispatch<SetStateAction<PreviewMessage[]>>;
  handleSendEmail: HandleSendEmail;
  sendLoading: boolean;
  errors: OutreachEmailErrors;
  setErrors: Dispatch<SetStateAction<OutreachEmailErrors>>;
  selectedTemplates: { [key in number]: Template | null };
  setSelectedTemplates: Dispatch<
    SetStateAction<{ [key in number]: Template | null }>
  >;
}

export default function IndividualDraftBody({
  preview,
  changePreview,
  index,
  handleSendEmail,
  setEmailMessages,
  sendLoading,
  errors,
  setErrors,
  selectedTemplates,
  setSelectedTemplates,
}: PreviewIndividualProps) {
  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);
  const [selectImageOpen, setSelectImageOpen] = useState<boolean>(false);
  const { currentDraftBody, setCurrentDraftBody } = useContext(
    OutreachTemplatesContext,
  );

  const editor = useEditor(
    {
      extensions: extensions({
        placeholderText: "",
        suggestionOptions: MERGE_TAGS,
      }),
      content: preview?.body,
      onUpdate({ editor }) {
        const hasMergeTag = containsMergeTags(editor.getHTML());
        if (
          hasMergeTag ||
          errors.outreachBody === index ||
          errors.mergeTag === index
        ) {
          setErrors((prev) => {
            const copy = makeDeepCopy(prev);
            copy.outreachBody = -1;
            if (hasMergeTag) {
              copy.mergeTag = index;
            } else {
              copy.mergeTag = -1;
            }
            return copy;
          });
        }
      },
      onBlur({ editor }) {
        let body = editor
          ? editor?.isEmpty
            ? ""
            : editor?.getHTML()
          : preview?.body;

        if (body !== preview?.body) {
          setEmailMessages((prev) => {
            const copy = [...prev];
            copy[index]["body"] = body;
            copy[index].isEdited = true;
            return copy;
          });
        }
      },
    },
    [!!preview?.body, preview?.templateId, preview?.templateName, index],
  ) as TipTapEditor;

  const redErrorHighlight = Number(errors?.outreachBody) === index;

  const removeAttachment = () => {
    changePreview("attachment", undefined, index);
  };

  const renderMergeTag = (mergeTag: string) => {
    return (
      <span
        style={{
          background: `rgb(175, 191, 176)`,
          borderRadius: "0.4rem",
          boxDecorationBreak: "clone",
          padding: "0.2rem 0.3rem",
        }}
      >
        {mergeTag}
      </span>
    );
  };

  const displayWarningForEmail = () => {
    const hasMergeTag =
      containsMergeTags(preview?.subject) || errors.mergeTag === index;

    const errorVariables = getVariablesFromHtml(
      editor?.getHTML() || preview?.body,
    );

    if (hasMergeTag) {
      return (
        <Typography variant="body2" sx={styles.errorWarningContainer}>
          Make sure you also fill out {renderMergeTag("{Brand Name}")} and{" "}
          {renderMergeTag("{First Name}")} in the email and subject line.
        </Typography>
      );
    }

    if (errorVariables.length > 0) {
      return (
        <Typography variant="body2" sx={styles.errorWarningContainer}>
          We noticed some templating characters (for example{" "}
          {renderMergeTag(`${errorVariables[0]}`)}) remaining in your message.
          Please edit these before sending.
        </Typography>
      );
    }
  };

  const attachment = preview?.attachment;
  const attachmentProps = {
    allowAttachment: true,
    openAttachmentManager: () => {
      setAttachmentDialogOpen(true);
    },
    attachment,
    removeAttachment,
    setSelectImageOpen,
  };

  const selectedImage = (imageUrl: string) => {
    if (editor) {
      editor.chain().focus().setImage({ src: imageUrl, size: "large" }).run();
    }
  };

  useEffect(() => {
    if (
      editor &&
      currentDraftBody?.length > 0 &&
      editor.getHTML() !== currentDraftBody
    ) {
      editor.commands.setContent(makeDeepCopy(currentDraftBody), true);
      setCurrentDraftBody("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDraftBody, editor?.getHTML()]);

  return (
    <>
      {redErrorHighlight && (
        <Typography variant="body2" sx={styles.errorText}>
          Please fill in the body of this email
        </Typography>
      )}
      <Box
        sx={[
          { position: "relative" },
          redErrorHighlight ? styles.errorContent : {},
        ]}
      >
        <SelectDraftTemplate
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
          index={index}
          preview={preview}
          setPreviewMessages={setEmailMessages}
        />

        <Box sx={styles.editorWrapper}>
          {!editor ? (
            <Box sx={{ p: 2 }}>
              <Skeleton variant="rounded" height={400} width={"100%"} />
            </Box>
          ) : (
            <Editor editor={editor} />
          )}

          {attachment && editor && (
            <Box sx={styles.attachmentContainer}>
              <Box className="fa-regular fa-paperclip" sx={{ mr: 1 }} />{" "}
              <Typography color="text.secondary" sx={styles.attachmentName}>
                {attachment.split("/")[attachment.split("/").length - 1]}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={attachmentProps.openAttachmentManager}
              >
                <Box component="i" className="fa-solid fa-edit" />
              </IconButton>
              {removeAttachment && (
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={removeAttachment}
                >
                  <Box component="i" className="fa-solid fa-trash" />
                </IconButton>
              )}
            </Box>
          )}
          {displayWarningForEmail()}
        </Box>
      </Box>

      {/* Files Editor */}
      <AttachmentManager
        enteredSelection={(attachment: string) => {
          changePreview("attachment", attachment, index);
        }}
        open={attachmentDialogOpen}
        setOpen={setAttachmentDialogOpen}
      />

      {/* Image Editor */}
      <AttachmentManager
        enteredSelection={selectedImage}
        open={selectImageOpen}
        setOpen={setSelectImageOpen}
        imageOnly={true}
      />

      {editor && (
        <BottomToolbar
          handleSendEmail={handleSendEmail}
          sendLoading={sendLoading}
          editor={editor}
          attachmentProps={attachmentProps}
          preview={preview}
          index={index}
          hideCustomizeHelper={true}
        />
      )}
    </>
  );
}
