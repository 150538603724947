import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import CategoryFilter from ".";

interface Props {
  openMobileCategoryFilter: boolean;
  setOpenMobileCategoryFilter: Dispatch<SetStateAction<boolean>>;
}

const MobileCategoryFilterDialog = ({
  openMobileCategoryFilter,
  setOpenMobileCategoryFilter,
}: Props) => {
  return (
    <Dialog
      open={openMobileCategoryFilter}
      onClose={() => setOpenMobileCategoryFilter(false)}
      fullScreen
    >
      <DialogTitle sx={{ backgroundColor: "secondary.light", py: 1 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: 13 }}>CATEGORIES</Typography>
          <IconButton
            sx={{ color: "black", fontSize: 13 }}
            onClick={() => setOpenMobileCategoryFilter(false)}
          >
            <Box component="i" className="fa-regular fa-xmark" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <CategoryFilter />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          disableElevation
          onClick={() => setOpenMobileCategoryFilter(false)}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileCategoryFilterDialog;
