import {
  TemplateContent,
  TemplateGoal,
  TemplateHighlight,
  TemplateTone,
} from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";

export const getContents = () => {
  const influencer = {
    key: TemplateContent.INFLUENCER,
    value: "Influencer Content",
  };
  const ugc = {
    key: TemplateContent.UGC,
    value: "UGC Content",
  };
  return [influencer, ugc];
};

export const getGoals = () => {
  const paid = {
    key: TemplateGoal.PAID,
    value: "Paid collaborations",
  };
  const gift = {
    key: TemplateGoal.GIFT,
    value: "Gifted opportunities",
  };
  return [paid, gift];
};

export const getHighlights = (isUgcCreator: boolean) => {
  const portfolio = {
    key: TemplateHighlight.PORTFOLIO,
    value: "Your portfolio",
  };
  const engagement = {
    key: TemplateHighlight.ENGAGEMENT_NUMBERS,
    value: "Your engagement numbers or following count",
  };
  const caseStudy = {
    key: TemplateHighlight.CASE_STUDY,
    value: "A successful past partnership",
  };
  const namedBrands = {
    key: TemplateHighlight.PAST_PARTNERSHIPS,
    value: "Big name brands you've worked with",
  };

  if (isUgcCreator) {
    return [portfolio, caseStudy, namedBrands];
  } else {
    return [portfolio, engagement, caseStudy, namedBrands];
  }
};

export const getTones = () => {
  return [
    {
      key: TemplateTone.UPBEAT,
      value: "Upbeat & Sweet",
    },
    {
      key: TemplateTone.CASUAL,
      value: "Casual & Friendly",
    },
    {
      key: TemplateTone.PROFESSIONAL,
      value: "Professional & Thorough",
    },
  ];
};
