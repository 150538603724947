import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { OutreachContact } from "schemas/dashboard";

import { formatEpochTimestamp } from "utils/time";

const noneText = (
  <Typography sx={{ color: grey[500], fontSize: 12 }}>--</Typography>
);
export const getFormattedDate = (contact: OutreachContact) => {
  return contact?.lastGmailMessageDate
    ? formatEpochTimestamp({
        epochTimestamp: Number(contact?.lastGmailMessageDate),
        fullDate: true,
      })
    : contact?.createdAt
      ? formatEpochTimestamp({
          epochTimestamp: Number(contact?.createdAt),
          fullDate: true,
        })
      : noneText;
};
