import {
  Box,
  CircularProgress,
  Grid,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import moment from "moment";
import { useContext, useState } from "react";
import { EmailActivity, OutreachContact } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { formatDate } from "components/EmailThread/helpers";
import { formatEpochTimestamp } from "utils/time";

import styles from "./styles";

interface Props {
  contact: OutreachContact;
  onTrackingPage?: boolean;
}

const OpenedColumn = ({ contact, onTrackingPage = false }: Props) => {
  const { emailSettings } = useContext(OrganizationUserContext);

  const {
    fetchEmailActivities,
    currentContactEmailActivities,
    currentContactEmailActivitiesLoading,
  } = useContext(OutreachContactsContext);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (contact.id) {
      fetchEmailActivities(contact.id);
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!contact.hasTrackedEmails) {
    return (
      <Box
        sx={[
          !onTrackingPage && styles.openedColumn,
          onTrackingPage && styles.openedColumnTracking,
        ]}
        onClick={(e: CustomEvent) => {
          e.stopPropagation();
        }}
      >
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={6000}
          title="No tracking data available for your lastest sent email to this contact. Only emails sent through the Bento app are tracked."
        >
          <Typography sx={styles.disabledText} component="div">
            Unavailable. Send your response on Bento so we can begin tracking
          </Typography>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box
      aria-owns={open ? "mouse-over-popover-open-details" : undefined}
      aria-haspopup="true"
      sx={[
        !onTrackingPage && styles.openedColumn,
        onTrackingPage && styles.openedColumnTracking,
      ]}
      onClick={(e: CustomEvent) => {
        e.stopPropagation();
      }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography component="div" variant={onTrackingPage ? "body2" : "body1"}>
        {contact.lastEmailOpenCount} Open
        {contact.lastEmailOpenCount !== 1 ? "s" : ""}{" "}
        <Box
          component="i"
          sx={styles.openedColumnIcon}
          className="fa-regular fa-eye"
        />
      </Typography>
      {!!contact.lastEmailOpenedAt && (
        <Typography
          sx={styles.openedColumnGrey}
          variant="caption"
          component="div"
        >
          Last open on{" "}
          {formatEpochTimestamp({
            epochTimestamp: contact.lastEmailOpenedAt,
            fullDate: true,
            timeZone: emailSettings?.timezone,
          })}
        </Typography>
      )}
      <Popover
        id="mouse-over-popover-open-details"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container sx={{ maxWidth: "400px", p: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              component="div"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Your latest tracked email has been opened{" "}
              {contact.lastEmailOpenCount} time
              {contact.lastEmailOpenCount !== 1 ? "s" : ""}.
            </Typography>
            <Typography variant="caption" component="div" paragraph>
              Bento tracks all email opens, including when you open them in
              Gmail. To avoid tracking your own opens, view the thread directly
              in Bento instead.
            </Typography>
          </Grid>
          {currentContactEmailActivitiesLoading && (
            <CircularProgress size={20} />
          )}
          {!currentContactEmailActivitiesLoading &&
            currentContactEmailActivities.map((activity: EmailActivity) => (
              <Grid item xs={12} key={activity.id}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  Opened {formatDate(moment.unix(activity.createdAt))}
                  {activity.formattedAddress &&
                  activity.formattedAddress !== "Unknown"
                    ? `, ${activity.formattedAddress}`
                    : ""}
                  {activity.userDevice ? `, ${activity.userDevice}` : ""}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Popover>
    </Box>
  );
};
export default OpenedColumn;
