const styles = {
  video: {
    margin: "auto",
    border: 0,
    borderTop: "2px solid #014339",
    width: "100%",
  },
  videoSection: {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  controls: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1300,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    width: "45px",
    height: "45px",
    ml: "-22.5px",
    mt: "-22.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  },
};

export default styles;
