import { grey } from "@mui/material/colors";

const styles = {
  brandContainer: {
    display: "flex",
    width: "100%",
    p: 2,
    gap: 2,
    alignItems: "center",
    borderBottom: `1px solid ${grey[200]}`,
  },
  removeButton: {
    color: "secondary.main",
    fontSize: 12,
    width: 20,
    height: 20,
  },
  description: {
    textOverflow: "ellipsis",
    maxWidth: 200,
    fontWeight: 700,
    fontSize: 12,
  },
};

export default styles;
