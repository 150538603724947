import { CustomEvent } from "schemas/functions";

import { TemplateContentType } from "features/Influencer/ContactList/schema";

export type BentoTemplate = {
  contentType: TemplateContentType;
  id: number;
  subject?: string;
  body: BentoTemplateBody[];
  tags: string[];
};

export type BentoTemplateBody = {
  example: string;
  placeholder: string;
  type: string;
  variable: string;
  text: string;
  organization_profile_field: string;
  subtype?: string;
};

export enum SurveyStep {
  GOAL = 0,
  SIGNATURE = 1,
  PICKED_TEMPLATE = 2,
  SAVE_OUTREACH = 3,
  SAVE_FOLLOWUP = 4,
}

export enum SurveyFollowUpStep {
  GOAL = 0,
  PICKED_TEMPLATE = 1,
  SAVE_FOLLOWUP = 2,
}

export const StepNames = [
  "Goal",
  "Signature",
  "Pick Template",
  "Save Outreach",
  "Save Followup",
];

export const FollowUpStepNames = [
  "Goal",
  "Pick Follow-Up Template",
  "Save Follow-Up",
];

export type TemplateSurvey = {
  content?: TemplateContent;
  tone?: TemplateTone;
  goal?: TemplateGoal;
  highlight?: TemplateHighlight;
  about_me?: string;
  signature?: string;
  past_partnerships?: string;
  most_impressive_partnerships?: string;
  engagement?: string;
  followers?: string;
  portfolio?: string;
  case_study?: string;
  post_example?: string;
};

export enum TemplateContent {
  UGC = "ugc",
  INFLUENCER = "influencer",
}

export enum TemplateTone {
  UPBEAT = "upbeat",
  CASUAL = "casual",
  PROFESSIONAL = "professional",
}

export enum TemplateGoal {
  GIFT = "gift",
  PAID = "paid",
}

export enum TemplateHighlight {
  PORTFOLIO = "portfolio",
  ENGAGEMENT_NUMBERS = "engagement",
  CASE_STUDY = "case_study",
  PAST_PARTNERSHIPS = "past_brands",
}

export type HandleSurveyChangeFunction = (
  e: CustomEvent,
  orgProfileField?: string,
) => void;

export type BentoTemplates = {
  outreaches: BentoTemplate[];
  followups: BentoTemplate[];
};

export type SelectedTemplates = {
  outreach: BentoTemplate | null;
  followup: BentoTemplate | null;
};

export type SelectedIndex = {
  outreach: number;
  followup: number;
};
