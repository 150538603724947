import { Box, Link } from "@mui/material";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import parse, { DOMNode, Element, domToReact } from "html-react-parser";
import { useContext } from "react";
import React from "react";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface IProps {
  body: string;
}

const EmailBody: React.FC<IProps> = ({ body }) => {
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);
  const { currentContact } = useContext(OutreachContactsContext);

  const handleEmailClick = (email: string): void => {
    trackEvent("Referred Email Clicked", {
      contactId: currentContact?.id,
      referredEmail: email,
    });
    if (currentContact?.bentoBrand?.id) {
      handleOpenQuickSendIndividual(
        currentContact.bentoBrand.id,
        "Email Body",
        email,
      );
    } else {
      window.open(`mailto:${email}`, "_blank");
    }
  };

  const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;

  const convertBreakPoint = (text: any) => {
    if (!text) return;
    // Regular expression to match HTML tags
    const regex = /<[^>]+>/g;
    const isHTML = regex.test(text);
    if (typeof text === "string" && !isHTML) {
      return text.replace(/\n+([^\n]+)\n+/g, "<p>$1</p>");
    }
    return text;
  };

  return (
    <Box>
      {parse(convertBreakPoint(body), {
        replace(domNode: DOMNode) {
          if (
            domNode instanceof Element &&
            domNode.type === "tag" &&
            domNode.name === "a"
          ) {
            if (domNode.attribs.href?.startsWith("mailto:")) {
              const email = domNode.attribs.href.replace("mailto:", "");
              return (
                <Link onClick={() => handleEmailClick(email)} sx={styles.link}>
                  {domToReact(domNode.children as DOMNode[])}
                  <Box
                    component="i"
                    sx={{ ml: 0.5 }}
                    className="fa-solid fa-xs fa-arrow-up-right-from-square"
                  />
                </Link>
              );
            } else {
              return (
                <Link
                  href={domNode.attribs.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={styles.link}
                >
                  {domToReact(domNode.children as DOMNode[])}
                </Link>
              );
            }
          }
        },
        transform(reactNode, domNode, index) {
          if (domNode.type === "text" && emailRegex.test(domNode.data)) {
            const newText: React.ReactNode[] = [];
            let lastIndex = 0;
            domNode.data.replace(emailRegex, (match, offset) => {
              newText.push(domNode.data.slice(lastIndex, offset));
              newText.push(
                <Link
                  key={offset}
                  onClick={() => handleEmailClick(match)}
                  sx={styles.link}
                >
                  {match}
                  <Box
                    component="i"
                    sx={{ ml: 1 }}
                    className="fa-solid fa-xs fa-arrow-up-right-from-square"
                  />
                </Link>,
              );
              lastIndex = offset + match.length;
              return match;
            });
            newText.push(domNode.data.slice(lastIndex));

            return (
              <>
                {newText.map((part, index) => (
                  <React.Fragment key={index}>{part}</React.Fragment>
                ))}
              </>
            );
          } else {
            return <>{reactNode}</>;
          }
        },
      })}
    </Box>
  );
};

export default EmailBody;
