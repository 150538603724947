import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  NameMap,
  NumbersMap,
  SavedBrandCollection,
  SavedBrandStatus,
  SavedCollectionsMap,
} from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

interface SavedBrandCollectionsContextInterface {
  setCollectionsMap: (
    type: SavedBrandStatus,
  ) => Dispatch<SetStateAction<SavedCollectionsMap>>;
  setTotalsMap: (
    type: SavedBrandStatus,
  ) => Dispatch<SetStateAction<NumbersMap>>;
  setPagesMap: (type: SavedBrandStatus) => Dispatch<SetStateAction<NumbersMap>>;
  pagesMap: (type: SavedBrandStatus) => NumbersMap;
  totalsMap: (type: SavedBrandStatus) => NumbersMap;
  collectionsMap: (type: SavedBrandStatus) => SavedCollectionsMap;
  collectionsNames: NameMap;
  setCollectionsNames: Dispatch<SetStateAction<NameMap>>;
  selectedCollection: SavedBrandCollection | null;
  setSelectedCollection: Dispatch<SetStateAction<SavedBrandCollection | null>>;
  currentTab: SavedBrandStatus;
  setCurrentTab: Dispatch<SetStateAction<SavedBrandStatus>>;
  collections: SavedBrandCollection[];
  setCollections: Dispatch<SetStateAction<SavedBrandCollection[]>>;
  fetchSavedBrandsForCollection: (
    collectionId: number,
    pageToFetch?: number,
    type?: SavedBrandStatus,
  ) => void;
  fetchLoading: number;
  initializeList: (
    collection: SavedBrandCollection,
    type: SavedBrandStatus,
  ) => void;
  fetchCollections: () => void;
}

const PER_PAGE = 20;

const defaultInterface = {
  setCollectionsMap: defaultContextMissingFunction,
  setTotalsMap: defaultContextMissingFunction,
  setPagesMap: defaultContextMissingFunction,
  pagesMap: defaultContextMissingFunction,
  totalsMap: defaultContextMissingFunction,
  collectionsMap: defaultContextMissingFunction,

  currentTab: SavedBrandStatus.unsent,
  setCurrentTab: defaultContextMissingFunction,
  collectionsNames: {},
  setCollectionsNames: defaultContextMissingFunction,
  selectedCollection: null,
  setSelectedCollection: defaultContextMissingFunction,
  fetchSavedBrandsForCollection: defaultContextMissingFunction,
  collections: [],
  setCollections: defaultContextMissingFunction,
  fetchLoading: -1,
  initializeList: defaultContextMissingFunction,
  fetchCollections: defaultContextMissingFunction,
};

const SavedBrandCollectionsContext =
  createContext<SavedBrandCollectionsContextInterface>(defaultInterface);

interface SavedBrandCollectionsProviderProps {
  children: React.ReactNode;
}

const SavedBrandCollectionsProvider = ({
  children,
}: SavedBrandCollectionsProviderProps) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);

  const [collections, setCollections] = useState<SavedBrandCollection[]>([]);
  const [collectionsNames, setCollectionsNames] = useState<NameMap>({});
  const [selectedCollection, setSelectedCollection] =
    useState<SavedBrandCollection | null>(null);
  const [currentTab, setCurrentTab] = useState<SavedBrandStatus>(
    SavedBrandStatus.unsent,
  );

  // Unsent states
  const [unsentCollectionsMap, setUnsentCollectionsMap] =
    useState<SavedCollectionsMap>({});
  const [unsentPagesMap, setUnsentPagesMap] = useState<NumbersMap>({});
  const [unsentTotalsMap, setUnsentTotalsMap] = useState<NumbersMap>({});

  // Sent states
  const [sentCollectionsMap, setSentCollectionsMap] =
    useState<SavedCollectionsMap>({});
  const [sentPagesMap, setSentPagesMap] = useState<NumbersMap>({});
  const [sentTotalsMap, setSentTotalsMap] = useState<NumbersMap>({});

  const [fetchLoading, setFetchLoading] = useState(-1);

  const fetchCollections = async () => {
    if (!currentOrg) return;
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/saved-brand-collections`,
        "GET",
      );
      setCollections(res.savedBrandCollections);
      if (res.savedBrandCollections?.length > 0) {
        for (const collection of res.savedBrandCollections) {
          initializeList(collection, SavedBrandStatus.sent);
          initializeList(collection, SavedBrandStatus.unsent);
        }
      }

      const allSavedList = { id: 0, name: "All Saved" };
      initializeList(allSavedList, SavedBrandStatus.sent);
      initializeList(allSavedList, SavedBrandStatus.unsent);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const fetchSavedBrandsForCollection = async (
    collectionId: number,
    pageToFetch?: number,
    type?: SavedBrandStatus,
  ) => {
    if (!currentOrg?.id) return;

    let page = pageToFetch;
    if (!type) {
      type = currentTab;
    }
    if (!pageToFetch) {
      page = pagesMap(type)[collectionId];
    }
    setFetchLoading(collectionId);
    let url = `/api/organization/${currentOrg?.id}/saved-brands?page=${page}&per_page=${PER_PAGE}`;
    if (collectionId > 0) {
      url += `&saved_brand_collection_id=${collectionId}`;
    }
    if (type === SavedBrandStatus.sent) {
      url += `&sent=${true}`;
    } else {
      url += `&sent=${false}`;
    }

    try {
      const res = await fetcherAuth(getToken, url, "GET");
      if (page === 1) {
        setCollectionsMap(type)((prev) => ({
          ...prev,
          [collectionId]: res.brands,
        }));
      } else {
        setCollectionsMap(type)((prev) => ({
          ...prev,
          [collectionId]: [...(prev[collectionId] || []), ...res.brands],
        }));
      }
      setTotalsMap(type)((prev) => ({
        ...prev,
        [collectionId]: res.total,
      }));
      if (
        pageToFetch !== undefined &&
        pageToFetch !== pagesMap(type)[collectionId]
      ) {
        setPagesMap(type)((prev) => ({
          ...prev,
          [collectionId]: pageToFetch,
        }));
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setFetchLoading(-1);
    }
  };

  const collectionsMap = (type: SavedBrandStatus) => {
    if (type === SavedBrandStatus.sent) {
      return sentCollectionsMap;
    } else {
      return unsentCollectionsMap;
    }
  };

  const pagesMap = (type: SavedBrandStatus) => {
    if (type === SavedBrandStatus.sent) {
      return sentPagesMap;
    } else {
      return unsentPagesMap;
    }
  };

  const totalsMap = (type: SavedBrandStatus) => {
    if (type === SavedBrandStatus.sent) {
      return sentTotalsMap;
    } else {
      return unsentTotalsMap;
    }
  };

  const setCollectionsMap = (type: SavedBrandStatus) => {
    if (type === SavedBrandStatus.sent) {
      return setSentCollectionsMap;
    } else {
      return setUnsentCollectionsMap;
    }
  };

  const setTotalsMap = (type: SavedBrandStatus) => {
    if (type === SavedBrandStatus.sent) {
      return setSentTotalsMap;
    } else {
      return setUnsentTotalsMap;
    }
  };

  const setPagesMap = (type: SavedBrandStatus) => {
    if (type === SavedBrandStatus.sent) {
      return setSentPagesMap;
    } else {
      return setUnsentPagesMap;
    }
  };

  const initializeList = (
    collection: SavedBrandCollection,
    type: SavedBrandStatus,
  ) => {
    setPagesMap(type)((prev) => {
      return collection.id in prev ? prev : { ...prev, [collection.id]: 1 };
    });
    setTotalsMap(type)((prev) => {
      return collection.id in prev
        ? prev
        : { ...prev, [collection.id]: undefined };
    });
    setCollectionsMap(type)((prev) => {
      return collection.id in prev
        ? prev
        : { ...prev, [collection.id]: undefined };
    });
  };

  useEffect(() => {
    const fetchBrands = async (type: SavedBrandStatus) => {
      const collectionsWithoutBrands = Object.keys(
        collectionsMap(type),
      )?.filter((key) => collectionsMap(type)[Number(key)] === undefined);
      const brandsToFetch = collectionsWithoutBrands.map((c) =>
        fetchSavedBrandsForCollection(Number(c), undefined, type),
      );
      await Promise.all(brandsToFetch);
    };

    fetchBrands(SavedBrandStatus.unsent);
    fetchBrands(SavedBrandStatus.sent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(sentCollectionsMap)?.length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(unsentCollectionsMap)?.length,
  ]);

  useEffect(() => {
    const copy = makeDeepCopy(collectionsNames);
    for (const collection of collections) {
      if (!(collection.id in copy)) {
        copy[collection.id] = collection.name;
      }
    }
    setCollectionsNames(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections?.length]);

  return (
    <SavedBrandCollectionsContext.Provider
      value={{
        setCollectionsMap,
        setTotalsMap,
        setPagesMap,
        pagesMap,
        totalsMap,
        collectionsMap,
        currentTab,
        setCurrentTab,
        collectionsNames,
        setCollectionsNames,
        selectedCollection,
        setSelectedCollection,
        collections,
        setCollections,
        initializeList,
        fetchSavedBrandsForCollection,
        fetchLoading,
        fetchCollections,
      }}
    >
      {children}
    </SavedBrandCollectionsContext.Provider>
  );
};

export { SavedBrandCollectionsProvider, SavedBrandCollectionsContext };
