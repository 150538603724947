import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { CustomEvent } from "schemas/functions";

import { TABLE_SORT } from "features/Influencer/Tracking/events";
import {
  ContactViewProperty,
  PropertySortDirection,
} from "features/Influencer/Tracking/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import { ContactViewContext } from "./ContactView";
import { TrackingColumnsContext } from "./TrackingColumns";

interface TrackingColumnsSortContextInterface {
  handleOpenSortMenu: (e: CustomEvent) => void;
  handleCloseSortMenu: () => void;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  selectedSort: ContactViewProperty | null;
  setSelectedSort: Dispatch<SetStateAction<ContactViewProperty | null>>;
  handleAddSort: (
    sortDirection: PropertySortDirection | null,
    columnToSort: ContactViewProperty,
  ) => void;
  sortableColumns?: ContactViewProperty[];
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  handleCloseSortMenu: defaultContextMissingFunction,
  handleOpenSortMenu: defaultContextMissingFunction,
  open: false,
  anchorEl: null,
  selectedSort: null,
  setSelectedSort: defaultContextMissingFunction,
  handleAddSort: defaultContextMissingFunction,
  sortableColumns: [],
};

const TrackingColumnsSortContext =
  createContext<TrackingColumnsSortContextInterface>(defaultInterface);

interface TrackingColumnsSortProviderProps {
  children: React.ReactNode;
}

const TrackingColumnsSortProvider = ({
  children,
}: TrackingColumnsSortProviderProps) => {
  const { selectedView, trackTable } = useContext(ContactViewContext);
  const { handleUpdateColumn } = useContext(TrackingColumnsContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpenSortMenu = (event: CustomEvent) => {
    trackTable(TABLE_SORT);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };
  const [selectedSort, setSelectedSort] = useState<ContactViewProperty | null>(
    null,
  );

  const open = Boolean(anchorEl);
  const props = selectedView?.contactViewProperties;

  const sortableColumns = useMemo(() => {
    if (props && props?.length > 0) {
      return props?.filter(
        (x) =>
          !(
            x.contactPropertyName &&
            [
              "email_thread",
              "send_follow_up",
              "actions",
              "latest_sequence_status",
              "move_brand_to",
              "latest_outreach_template",
            ]?.includes(x.contactPropertyName)
          ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.length]);

  const handleAddSort = (
    direction: PropertySortDirection | null,
    selectedSort: ContactViewProperty,
  ) => {
    const copy = makeDeepCopy(selectedSort);
    copy.sortDirection = direction;
    setSelectedSort(copy);
    handleUpdateColumn(
      selectedSort,
      {
        sortDirection: direction,
        filter: copy.filters[0],
      },
      true,
    );
  };

  return (
    <TrackingColumnsSortContext.Provider
      value={{
        handleOpenSortMenu,
        handleCloseSortMenu,
        open,
        anchorEl,
        selectedSort,
        setSelectedSort,
        handleAddSort,
        sortableColumns,
      }}
    >
      {children}
    </TrackingColumnsSortContext.Provider>
  );
};

export { TrackingColumnsSortProvider, TrackingColumnsSortContext };
