import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext } from "react";

export default function TestEmailSuccessDialog({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const { userIntegration } = useContext(UserIntegrationsContext);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Test Email Successfully Sent 🎉</DialogTitle>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        A test email was sent to your email address{" "}
        <strong>{userIntegration?.email}</strong>. Check your inbox to see how
        it looks!
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
