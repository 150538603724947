import { grey } from "@mui/material/colors";
import { HOME_BODY_COLOR } from "styles/oneOffColor";
import { paragraphFontFamily } from "styles/theme";

const styles = {
  root: {
    background: HOME_BODY_COLOR,
    position: "sticky",
    top: -1,
    zIndex: 30,
    px: 1,
    pb: { xs: 1, md: 2 },
    pt: { xs: 0, md: 2 },
  },
  chipRoot: {
    position: "fixed",
    top: 90,
    zIndex: 9,
    pt: 2,
    pr: 4,
  },
  filterOptionContainer: {
    pt: { xs: 0, md: 1 },
  },
  formControl: {
    minWidth: "80px",
    maxWidth: {
      md: "120px",
      xl: "None",
    },
  },
  typeFilter: {
    maxWidth: {
      md: "120px",
      xl: "None",
    },
  },
  locationFilter: {
    minWidth: "100px",
    maxWidth: {
      md: "150px",
      xl: "None",
    },
  },
  companySizeFilter: {
    minWidth: "120px",
    maxWidth: {
      md: "150px",
      xl: "None",
    },
  },
  instagramFollowingFilter: {
    minWidth: "150px",
    maxWidth: {
      md: "180px",
      xl: "None",
    },
  },
  sortLabel: {
    minWidth: "100px",
    maxWidth: {
      md: "180px",
      xl: "None",
    },
  },
  chip: {
    borderRadius: "4px",
    fontSize: "0.625rem",
  },
  collapse: {
    textAlign: "center",
    pt: 2,
    fontSize: "0.8rem",
    width: "100%",
  },
  drawer: {
    minHeight: "100svh",
    width: "80%",
    right: 0,
    position: "fixed",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  dollarSign: {
    color: grey[500],
  },
  icon: {
    fontSize: { xs: 14, sm: 16 },
    mr: 1,
  },
  paidPartnershipBadge: {
    color: "#32885F",
  },
  worksWithUgcBadge: {
    color: "#388BED",
  },
  tiktok: {
    color: "#010101",
  },
  bolt: {
    color: "#786EEB",
  },
  star: {
    color: "#EDDB38",
  },
  nonGeneric: {
    color: "#F6BB75",
  },
  search: {
    border: `1px solid ${grey[400]}`,
    borderRadius: 1,
    height: 30,
    py: 2,
    px: 2,
    width: 350,
    fontFamily: paragraphFontFamily,
    "&:hover, &:focus": {
      outline: "none",
    },
  },
  requestButton: {
    backgroundColor: "primary.light",
    color: "primary.dark",
    borderRadius: 2,
    mr: 2,
    "&:hover": {
      backgroundColor: "#EAFAF1",
    },
    fontSize: { xs: 11, md: 14 },
  },
  background: {
    background: HOME_BODY_COLOR,
  },
  mobileList: {
    px: 3,
    py: 1,
    borderBottom: `1px solid ${grey[100]}`,
  },
};

export default styles;
