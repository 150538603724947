import { LoadingButton } from "@mui/lab";
import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { Map } from "schemas/functions";
import { Plans, SubscriptionMessages } from "schemas/payments";
import theme from "styles/theme";

import { SubscriptionColor } from "utils/color";
import { formatEpochTimestamp } from "utils/time";
import { trackEvent } from "utils/tracking";

import CancelDialog from "./CancelDialog";
import ManageDialog from "./ManagePlanDialog";
import {
  disabledCurrentPlan,
  getCurrentPlanButton,
  getOtherPlanButton,
  getStatusChipLabel,
  getSubscriptionInformation,
} from "./helpers";
import styles from "./styles";

interface IProps {
  plan: Plans;
  loading: boolean;
  handleCheckout: (
    plan: Plans,
    flow?: string,
    openTarget?: "_self" | "_blank",
  ) => void;
  renewSubscription: (plan: Plans) => void;
  allLoading: Map;
}

export function Plan({
  loading,
  handleCheckout,
  renewSubscription,
  plan,
  allLoading,
}: IProps) {
  const {
    subscription,
    plans,
    isProPlan,
    emailSents,
    brandRequestsMade,
    customizedMessagesDrafted,
    recommendationsRefreshed,
    tab,
  } = useContext(SubscriptionContext);
  const { discoverTab } = useContext(DiscoverViewContext);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen =
    isMediumScreen || (isLargeScreen && discoverTab !== null);

  const { stripePlan, isCurrentPlan, price, description, resubscribe, renew } =
    getSubscriptionInformation(plan, plans, subscription, {
      emailSents,
      brandRequestsMade,
      customizedMessagesDrafted,
      recommendationsRefreshed,
      tab,
    });

  const [cancelDialog, setCancelDialog] = useState<boolean>(false);
  const [manageDialog, setManageDialog] = useState<boolean>(false);

  const backgroundColor = stripePlan?.status
    ? SubscriptionColor[stripePlan?.status]
    : "";

  const openCancel = () => {
    trackEvent(
      "Manage Subscription Dialog Cancel Subscription Button Pressed",
      {
        plan,
      },
    );
    setManageDialog(false);
    setCancelDialog(true);
  };

  const closeManageDialog = () => {
    trackEvent("Manage Subscription Dialog Closed", {
      plan,
    });
    setManageDialog(false);
  };

  const closeCancelDialog = () => {
    trackEvent("Cancel Subscription Dialog Closed", {
      plan,
    });
    setCancelDialog(false);
  };

  const handleModifyCurrentPlan = () => {
    if (resubscribe) {
      trackEvent("Resubscribe Subscription Button Pressed", {
        plan,
      });
      handleCheckout(plan);
    } else if (renew) {
      trackEvent("Renew Subscription Button Pressed", {
        plan,
      });
      renewSubscription(plan);
    } else {
      trackEvent("Manage Plan Button Pressed", {
        plan,
      });
      setManageDialog(true);
    }
  };

  const handleUpgradeTonNewPlan = () => {
    trackEvent(`Switch Plan Button Pressed`, {
      plan,
    });
    handleCheckout(plan, "subscription_update", "_blank");
  };

  const renderPrice = () => {
    const currentPlanPrice = <>${price.toFixed(2)}/month</>;

    if (price === 0) {
      return "$0";
    } else if (plan === Plans.PRO_YEARLY) {
      return (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={styles.strike}>
            <Box sx={{ color: "black" }}>
              ${plans && plans[Plans.PRO]?.price}/month
            </Box>
          </Box>
          {currentPlanPrice}
        </Box>
      );
    } else if (plan === Plans.STARTER_YEARLY) {
      return (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={styles.strike}>
            <Box sx={{ color: "black" }}>
              ${plans && plans[Plans.STARTER]?.price}/month
            </Box>
          </Box>{" "}
          {currentPlanPrice}
        </Box>
      );
    } else {
      return currentPlanPrice;
    }
  };

  return (
    <Grid
      item
      xs={12}
      md={isMobileScreen ? 12 : 4}
      container
      direction="column"
    >
      <Grid item container xs="auto" sx={{ mb: 3 }}>
        <Grid item xs="auto" sx={{ mr: 1 }}>
          <Typography variant="h5">
            {plans && plans[plan].display_name}
            {isCurrentPlan && (
              <Chip
                size="small"
                sx={[styles.chip, { backgroundColor }]}
                label={getStatusChipLabel(subscription)}
              />
            )}
            <br />
            {renderPrice()}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs sx={styles.textContainer}>
        <Typography sx={styles.planDescription}>{description}</Typography>
        {isCurrentPlan && (
          <>
            {subscription && subscription?.remainingMessages <= 0 ? (
              <Typography paragraph sx={styles.warningText}>
                Please upgrade to send more this month
              </Typography>
            ) : (
              <Typography paragraph sx={styles.warningText}>
                <i>
                  {stripePlan?.status &&
                    stripePlan?.status in SubscriptionMessages &&
                    SubscriptionMessages[stripePlan?.status]}
                  {stripePlan?.cancelAt &&
                    ` Your subscription is set to cancel on ${formatEpochTimestamp(
                      {
                        epochTimestamp: stripePlan.cancelAt,
                        addYear: true,
                      },
                    )}. Currently, all follow-ups and brand requests beyond your
                            cancellation date will be deleted.`}
                </i>
              </Typography>
            )}
          </>
        )}
      </Grid>
      <Grid item xs="auto">
        {isCurrentPlan ? (
          <LoadingButton
            loading={resubscribe && loading}
            color="primary"
            variant={resubscribe || renew ? "contained" : "outlined"}
            fullWidth
            disabled={disabledCurrentPlan(plan, subscription)}
            onClick={handleModifyCurrentPlan}
            disableElevation
          >
            {getCurrentPlanButton(plan, subscription)}
          </LoadingButton>
        ) : (
          <>
            {plan !== Plans.FREE && !subscription?.overrideSubscription && (
              <LoadingButton
                loading={loading}
                onClick={handleUpgradeTonNewPlan}
                color={
                  isProPlan &&
                  (plan === Plans.STARTER || plan === Plans.STARTER_YEARLY)
                    ? "secondary"
                    : "primary"
                }
                variant={
                  isProPlan &&
                  (plan === Plans.STARTER || plan === Plans.STARTER_YEARLY)
                    ? "outlined"
                    : "contained"
                }
                fullWidth
                disableElevation
              >
                {getOtherPlanButton(plan, subscription?.subscription?.planName)}
              </LoadingButton>
            )}
          </>
        )}

        <ManageDialog
          loading={loading}
          open={manageDialog}
          openCancel={openCancel}
          onClose={closeManageDialog}
          handleCheckout={handleCheckout}
          plan={plan}
          allLoading={allLoading}
        />

        <CancelDialog
          loading={loading}
          open={cancelDialog}
          onClose={closeCancelDialog}
          handleCheckout={handleCheckout}
          plan={plan}
        />
      </Grid>
    </Grid>
  );
}
