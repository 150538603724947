import { Node, mergeAttributes } from "@tiptap/core";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";
import { nodeInputRule, nodePasteRule } from "@tiptap/react";
import Suggestion, { SuggestionOptions } from "@tiptap/suggestion";

import { trackEvent } from "utils/tracking";

export type MergeTagOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: MergeTagOptions;
    node: ProseMirrorNode;
  }) => string;
  suggestionOptions: string[];
  suggestion: Omit<SuggestionOptions, "editor">;
  toggleTooltip?: (
    view?: EditorView | null,
    pos?: number,
    node?: ProseMirrorNode,
  ) => void;
  displayMergeTagWarning?: (mergeTag: string) => void;
};

function createRegexFromOptions(options: string[], isInputRule: boolean) {
  let regexPattern = `\\{(${options.join("|")})\\}`;
  if (isInputRule) {
    regexPattern = `(\\{(${options.join("|")})\\})$`;
  }
  return new RegExp(regexPattern, "gm");
}

export const MergeTagPluginKey = new PluginKey("merge-tag");

export const MergeTag = Node.create<MergeTagOptions>({
  name: "merge-tag",

  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        return `{${node.attrs.label ?? node.attrs.id}}`;
      },
      pluginKey: MergeTagPluginKey,
      suggestionOptions: [],
      suggestion: {
        char: "{",
        pluginKey: MergeTagPluginKey,
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: { label: props?.label || props?.id, ...props },
              },
            ])
            .run();

          window.getSelection()?.collapseToEnd();
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);

          return allow;
        },
      },
    };
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: createRegexFromOptions(this.options.suggestionOptions, false),
        type: this.type,
        getAttributes: (match) => {
          const key = match[1];
          return {
            id: key,
            label: key,
          };
        },
      }),
    ];
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: createRegexFromOptions(this.options.suggestionOptions, true),
        type: this.type,
        getAttributes: (match) => {
          const key = match[2];
          return {
            id: key,
            label: key,
          };
        },
      }),
    ];
  },

  group: "inline",

  inline: true,

  selectable: true,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            "data-id": attributes.id,
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            "data-label": attributes.label,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
        getAttrs: (element: HTMLElement | string) => {
          if (typeof element === "string") {
            return false;
          }
          if (
            element.textContent === `{${element.getAttribute("data-label")}}`
          ) {
            return null;
          }
          return false;
        },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(
        { "data-type": this.name },
        this.options.HTMLAttributes,
        HTMLAttributes,
      ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMergeTag = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          const { displayMergeTagWarning } = this.options;

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMergeTag = true;
              tr.insertText("", pos, pos + node.nodeSize);

              trackEvent("Email Template Deleted Merge Tag", {
                label: node?.attrs?.label,
              });

              if (displayMergeTagWarning)
                displayMergeTagWarning(node?.attrs?.label);

              return false;
            }
          });
          return isMergeTag;
        }),
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
      new Plugin({
        key: new PluginKey("mergeTagTooltip"),
        props: {
          handleDOMEvents: {
            mouseover: (view, event) => {
              const { toggleTooltip } = this.options;
              if (!view || !toggleTooltip) return;
              const target = event.target as HTMLElement;
              const pos = view.posAtDOM(target, 0);
              const node = view.state.doc.nodeAt(pos);
              if (node?.type.name === this.name) {
                trackEvent("Email Template Hovered Over Merge Tag", {
                  label: node?.attrs?.label,
                });
                toggleTooltip(view, pos, node);
              } else {
                toggleTooltip();
              }
            },
          },
          handleClickOn: (view, pos, node, nodePos, event, direct) => {
            const { toggleTooltip } = this.options;
            if (!direct || !view || !toggleTooltip) return;

            if (node.type.name === this.name) {
              trackEvent("Email Template Clicked On Merge Tag", {
                label: node?.attrs?.label,
              });
              toggleTooltip(view, pos, node);
            }
          },
        },
      }),
    ];
  },
});
