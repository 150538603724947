import { green } from "@mui/material/colors";

const styles = {
  root: { ml: -1, mr: -1, overflow: "hidden" },
  tab: {
    border: 1,
    borderRight: 0,
    width: "180px",
    "&.Mui-selected": {
      backgroundColor: "primary.main",
      color: "white",
      borderColor: "primary.main",
    },
  },
  firstTab: {
    borderTopLeftRadius: "10px",
  },
  lastTab: {
    borderTopRightRadius: "10px",
    borderRight: 1,
  },
  importTab: {
    flexDirection: "row",
  },
  newChip: {
    color: green[700],
    border: `1px solid ${green[700]}`,
    height: "20px",
    backgroundColor: "primary.light",
    ml: 1,
  },

  tableContainer: {
    height: {
      xs: "calc(100vh - 176px)",
      md: "calc(100vh - 125px)",
    },
    px: 3,
    py: 2,
    backgroundColor: "white",
  },
};

export default styles;
