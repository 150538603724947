import { Box, Button, Typography } from "@mui/material";
import { BubbleMenu, Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { Dispatch, SetStateAction } from "react";
import { PreviewMessage } from "schemas/dashboard";
import { EmailAddress } from "schemas/email";
import { OutreachEmailErrors } from "schemas/outreach";

import { VariableModal } from "components/Editor/Variable/VariableModal";
import SubjectEditor from "components/Editor/subject";
import { extensions } from "components/Editor/utils";
import RecipientAutocomplete from "components/EmailThread/EmailDraft/RecipientAutocomplete";
import { MERGE_TAGS } from "constants/templates";
import { trackEvent } from "utils/tracking";
import { useVariablEditor } from "utils/useVariablesEditor";

import styles from "./styles";

interface Props {
  preview: PreviewMessage;
  ccContacts: EmailAddress[];
  toContacts: EmailAddress[];
  setCcContacts: Dispatch<SetStateAction<EmailAddress[]>>;
  setToContacts: Dispatch<SetStateAction<EmailAddress[]>>;
  changeSubject: (newSubject: string) => void;
  errors: OutreachEmailErrors;
  setErrors: Dispatch<SetStateAction<OutreachEmailErrors>>;
}

const DraftEmailHeader = ({
  preview,
  ccContacts,
  toContacts,
  setCcContacts,
  setToContacts,
  changeSubject,
  errors,
  setErrors,
}: Props) => {
  const handleCcContactsChange = (newContacts: EmailAddress[]) => {
    trackEvent(`Compose Email ccContacts Input Changed`, {
      ccContacts,
    });
    setCcContacts(newContacts);
  };

  const handleToContactsChange = (newContacts: EmailAddress[]) => {
    trackEvent(`Compose Email toContacts Input Changed`, {
      toContacts,
    });
    setToContacts(newContacts);
  };

  const subjectEditor = useEditor(
    {
      extensions: extensions({
        isPlain: true,
        suggestionOptions: MERGE_TAGS,
        placeholderText: errors?.outreachSubject
          ? "Please fill in the subject"
          : "",
      }),
      content: preview.subject,
      onUpdate({ editor }) {
        changeSubject(editor.getHTML());
      },
      onBlur() {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            outreachSubject: false,
          };
        });
      },
    },
    [preview.templateId, preview.templateName, errors?.outreachSubject],
  ) as TipTapEditor;

  const {
    variable,
    variableModalIsOpen,
    closeVariableModal,
    handleSaveVariable,
    openVariableFilledModal,
    deleteVariableFilled,
  } = useVariablEditor({ editor: subjectEditor });

  return (
    <Box>
      <Box sx={[styles.subjectWrapper, styles.border, { height: "auto" }]}>
        <Typography component="span" sx={styles.annotation}>
          To:
        </Typography>
        <RecipientAutocomplete
          autoFocus={toContacts.length === 0}
          value={toContacts}
          updateValue={handleToContactsChange}
          variant="noborder"
        />
      </Box>
      <Box sx={[styles.subjectWrapper, styles.border, { height: "auto" }]}>
        <Typography component="span" sx={styles.annotation}>
          Cc:
        </Typography>
        <RecipientAutocomplete
          value={ccContacts}
          updateValue={handleCcContactsChange}
          variant="noborder"
        />
      </Box>

      <Box sx={[styles.subjectWrapper, { height: 40 }]}>
        <Typography component="span" sx={styles.annotation}>
          Subject:
        </Typography>
        <SubjectEditor error={errors?.outreachSubject} editor={subjectEditor} />

        <VariableModal
          variable={variable}
          open={variableModalIsOpen}
          closeModal={closeVariableModal}
          onSave={handleSaveVariable}
        />

        {subjectEditor && (
          <BubbleMenu
            tippyOptions={{ duration: 50, zIndex: 1201, placement: "bottom" }}
            editor={subjectEditor}
            shouldShow={({ editor }) => {
              return (
                editor.isActive("variable") || editor.isActive("merge-tag")
              );
            }}
          >
            <Button
              onMouseDown={(e) => e.preventDefault()}
              sx={styles.fillVariable}
              onClick={openVariableFilledModal}
            >
              Fill
              <Box component="i" className="fa-regular fa-pen" sx={{ ml: 1 }} />
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              color="secondary"
              onClick={deleteVariableFilled}
              onMouseDown={(e) => e.preventDefault()}
            >
              Delete
              <Box
                component="i"
                className="fa-regular fa-trash"
                sx={{ ml: 1 }}
              />
            </Button>
          </BubbleMenu>
        )}
      </Box>
    </Box>
  );
};

export default DraftEmailHeader;
