import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { BrandsContext, DiscoverParams, TagLabel } from "contexts/Brands";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { UserRequestsContext } from "contexts/UserRequests";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import {
  COMPANY_TYPES,
  FOLLOWING_SIZES,
  SORTS,
  SearchParams,
} from "schemas/dashboard";
import { ALL } from "schemas/forms";

import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";
import { useSearchBrand } from "utils/useSearchBrand";

import VirtualAssistant from "../VirtualAssistant";
import AutomationVA from "./AutomationVA";
import DiscoverChips from "./Chips";
import CompanySizeFilter from "./CompanySizeFilter";
import LocationFilter from "./LocationFilter";
import SelectFilter from "./SelectFilter";
import { companyTypeIcons } from "./helpers";
import styles from "./styles";

interface Props {
  setOpenMobileCategoryFilter: Dispatch<SetStateAction<boolean>>;
}

export default function DiscoverFilter({ setOpenMobileCategoryFilter }: Props) {
  const { setOpen } = useContext(UserRequestsContext);
  const { canMakeBrandRequest, setUpgradeDialogSource } =
    useContext(SubscriptionContext);
  const { currentOrg } = useContext(OrganizationUserContext);

  const { addCategoryQueryToSearch, hasTags } = useSearchBrand();

  const { discoverFilterParams, selectedMetadataTags } =
    useContext(BrandsContext);

  const { navigationWidth, breaksDiscoverScreen } = usePageSize();

  const [close, setClose] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const selectedTags = Object.keys(discoverFilterParams).reduce(
    (acc: TagLabel[], key: string) => {
      return acc
        .concat(discoverFilterParams[key as keyof DiscoverParams])
        .filter(
          (tag) =>
            tag?.name &&
            tag?.params !== SearchParams.SORT_DISCOVER &&
            tag?.key !== ALL,
        );
    },
    [],
  );

  const isOptionSelected = (key: string, params: string) => {
    return Boolean(
      selectedTags?.find((x) => x?.key === key && x.params === params),
    );
  };

  const handleAddOption = (name: string, value: string) => {
    addCategoryQueryToSearch(name, value);
    setClose(true);
  };

  const selectedOptionIcon = (
    <Box
      component="i"
      className="fa-regular fa-check"
      sx={{ mr: 2, color: "success.main" }}
    />
  );

  const sortFilter = (
    <SelectFilter
      label={"Sort By"}
      options={SORTS}
      params={SearchParams.SORT_DISCOVER}
      setClose={setClose}
      close={close}
      isOptionSelected={isOptionSelected}
      selectedOptionIcon={selectedOptionIcon}
    />
  );

  const openCategoryFilter = () => {
    trackEvent("Cateogry Filter Toggled on Mobile View");
    setOpenMobileCategoryFilter((prev) => !prev);
  };

  const openRequest = () => {
    trackEvent("Request Button Clicked");
    if (!canMakeBrandRequest()) {
      setUpgradeDialogSource("Hits Brand Request Limit");
      return;
    }
    setOpen(true);
  };

  const filtersList = (
    <>
      <SelectFilter
        label={"Type"}
        options={COMPANY_TYPES}
        params={SearchParams.TYPE_DISCOVER}
        setClose={setClose}
        close={close}
        isOptionSelected={isOptionSelected}
        selectedOptionIcon={selectedOptionIcon}
        iconsMap={companyTypeIcons}
      />
      <LocationFilter
        handleAddOption={handleAddOption}
        close={close}
        setClose={setClose}
        selectedOptionIcon={selectedOptionIcon}
        isOptionSelected={isOptionSelected}
      />
      <CompanySizeFilter
        handleAddOption={handleAddOption}
        close={close}
        setClose={setClose}
        selectedOptionIcon={selectedOptionIcon}
        isOptionSelected={isOptionSelected}
      />
      <SelectFilter
        label={"Instagram Following"}
        options={FOLLOWING_SIZES}
        params={SearchParams.FOLLOWING_DISCOVER}
        setClose={setClose}
        close={close}
        isOptionSelected={isOptionSelected}
        selectedOptionIcon={selectedOptionIcon}
      />
    </>
  );

  const buttons = (
    <>
      {currentOrg?.virtualAssistantQuota ? (
        <AutomationVA />
      ) : (
        <VirtualAssistant />
      )}
      <Button
        size="small"
        variant="contained"
        sx={styles.requestButton}
        disableElevation
        onClick={openRequest}
      >
        <Box component="i" className="fa-regular fa-message" sx={{ mr: 1 }} />
        {breaksDiscoverScreen ? "Request" : "Request Brand"}
      </Button>
    </>
  );

  if (breaksDiscoverScreen) {
    const totalDisplay =
      selectedTags?.filter(
        (tag) =>
          tag?.name &&
          tag?.params !== SearchParams.SORT_DISCOVER &&
          tag?.key !== ALL,
      )?.length + Number(selectedMetadataTags?.length);

    return (
      <>
        <Box sx={styles.root}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              {hasTags ? (
                <Button
                  disableElevation
                  onClick={() => setOpenFilter(true)}
                  variant="contained"
                  size={"small"}
                  color="secondary"
                  sx={{ fontSize: { xs: 11, md: 14 } }}
                >
                  Filters ({totalDisplay})
                </Button>
              ) : (
                <Button
                  onClick={() => setOpenFilter(true)}
                  size={"small"}
                  sx={{ fontSize: { xs: 11, md: 14 } }}
                >
                  Filters{" "}
                  <Box
                    component="i"
                    className="fa-solid fa-filter"
                    sx={{ ml: 1 }}
                  />
                </Button>
              )}
            </Grid>

            <Grid item container xs justifyContent="flex-end">
              {buttons}
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openFilter}
          fullScreen={true}
          onClose={() => setOpenFilter(false)}
        >
          <DialogTitle sx={{ backgroundColor: "secondary.light", py: 1 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              FILTERS
              <IconButton
                onClick={() => setOpenFilter(false)}
                sx={{ width: 20, height: 20, fontSize: 14 }}
              >
                <Box component="i" className="fa-regular fa-xmark" />
              </IconButton>
            </Grid>
          </DialogTitle>

          <DialogContent sx={{ px: 0 }}>
            <Grid
              onClick={openCategoryFilter}
              container
              justifyContent="space-between"
              alignItems="center"
              sx={styles.mobileList}
            >
              <Box sx={{ fontSize: 16 }}>Categories</Box>{" "}
              <Box component="i" className="fa-regular fa-arrow-right" />
            </Grid>

            {filtersList}
            {sortFilter}
            <DiscoverChips
              selectedTags={selectedTags}
              selectedMetadataTags={selectedMetadataTags}
              isMobileScreen={true}
            />
          </DialogContent>

          <DialogActions sx={{ py: 2 }}>
            <Button
              variant="contained"
              size="small"
              disableElevation
              onClick={() => setOpenFilter(false)}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <Box sx={styles.root}>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item md={1.5} sx={{ pb: 0.5 }}>
              <Typography component="span">
                <strong>Categories</strong>
              </Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}
              md
              sx={styles.filterOptionContainer}
              alignItems="center"
            >
              {filtersList}
              {sortFilter}
            </Grid>
            <Box sx={{ pr: 2 }}>{buttons}</Box>
          </Grid>
        </Box>

        {(selectedMetadataTags.length > 0 || selectedTags.length > 0) && (
          <Grid container sx={[styles.chipRoot, { width: navigationWidth }]}>
            <Grid item md={1.5} />
            <DiscoverChips
              selectedMetadataTags={selectedMetadataTags}
              isMobileScreen={false}
              selectedTags={selectedTags}
            />
          </Grid>
        )}
      </>
    );
  }
}
