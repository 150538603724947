import { useAuth } from "@clerk/clerk-react";
import React, {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserIntegration } from "schemas/dashboard";
import { Map } from "schemas/functions";

import ConnectGmailDialog from "components/ConnectGmailDialog";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

interface UserIntegrationContextInterface {
  emailHealth: boolean;
  fetchIntegrationHealth: (organizationId: number) => void;
  setOpenIntegrationDialog: React.Dispatch<SetStateAction<boolean>>;
  setIntegrationDialogText: React.Dispatch<SetStateAction<string | undefined>>;
  userIntegration: UserIntegration | null;
  fetchIntegrationLoading: boolean;
  scopes: Map | null;
  setScopes: React.Dispatch<SetStateAction<Map | null>>;
  setUserIntegration: React.Dispatch<SetStateAction<UserIntegration | null>>;
  setEmailHealth: React.Dispatch<SetStateAction<boolean>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  emailHealth: false,
  fetchIntegrationHealth: defaultContextMissingFunction,
  setOpenIntegrationDialog: defaultContextMissingFunction,
  setIntegrationDialogText: defaultContextMissingFunction,
  userIntegration: null,
  fetchIntegrationLoading: true,
  scopes: null,
  setScopes: defaultContextMissingFunction,
  setUserIntegration: defaultContextMissingFunction,
  setEmailHealth: defaultContextMissingFunction,
};

const UserIntegrationsContext =
  createContext<UserIntegrationContextInterface>(defaultInterface);

interface UserIntegrationsProviderProps {
  children: React.ReactNode;
}

const UserIntegrationsProvider = ({
  children,
}: UserIntegrationsProviderProps) => {
  const { getToken } = useAuth();
  const { setAlert } = useContext(AlertContext);

  const { userIntegrations } = useContext(OrganizationUserContext);

  const [emailHealth, setEmailHealth] = useState<boolean>(false);
  const [openIntegrationDialog, setOpenIntegrationDialog] =
    useState<boolean>(false);
  const [integrationDialogText, setIntegrationDialogText] = useState<string>();
  const [userIntegration, setUserIntegration] =
    useState<UserIntegration | null>(null);
  const [fetchIntegrationLoading, setFetchIntegrationLoading] = useState(true);
  const [scopes, setScopes] = useState<Map | null>(null);

  useEffect(() => {
    // on closing the dialog, reset integrationDialogText
    if (!openIntegrationDialog) {
      setIntegrationDialogText(undefined);
    } else {
      trackEvent("Connect To Gmail Dialog Opened");
    }
  }, [openIntegrationDialog]);

  const fetchIntegrationHealth = async (organizationId: number) => {
    if (!organizationId) {
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${organizationId}/user-integrations/health-check`,
      );

      setScopes(res.scopes);
      setEmailHealth(res.emailHealth);
      setUserIntegration(res.userIntegration);
    } catch (error) {
      setAlert(
        "Unable to check if you are connected to Google. Please refresh the page and try again. Contact hello@onbento.com if the problem persists.",
        "error",
      );
    } finally {
      setFetchIntegrationLoading(false);
    }
  };

  return (
    <UserIntegrationsContext.Provider
      value={{
        emailHealth,
        fetchIntegrationHealth,
        setOpenIntegrationDialog,
        setIntegrationDialogText,
        userIntegration,
        fetchIntegrationLoading,
        scopes,
        setScopes,
        setUserIntegration,
        setEmailHealth,
      }}
    >
      {children}

      <ConnectGmailDialog
        openIntegrationDialog={openIntegrationDialog}
        setOpenIntegrationDialog={setOpenIntegrationDialog}
        integrationDialogText={integrationDialogText}
        userIntegrations={userIntegrations}
        scopes={scopes}
        emailHealth={emailHealth}
      />
    </UserIntegrationsContext.Provider>
  );
};

export { UserIntegrationsProvider, UserIntegrationsContext };
