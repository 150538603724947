import {
  Box,
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { BrandsContext } from "contexts/Brands";
import { useContext, useState } from "react";
import { DiscoverCategory, SearchParams } from "schemas/dashboard";

import { CATEGORY_ALL_FILTER } from "utils/localStorage";
import { trackEvent } from "utils/tracking";
import { useSearchBrand } from "utils/useSearchBrand";

interface Props {
  category: DiscoverCategory;
}

const ALL = "All";

const MainCategory = ({ category }: Props) => {
  const allQueryString = sessionStorage.getItem(CATEGORY_ALL_FILTER);
  const allQueries = allQueryString ? JSON.parse(allQueryString) : {};

  const {
    addCategoryQueryToSearch,
    addAllQueryToSearch,
    removeOptionWithAllSelected,
  } = useSearchBrand();
  const { discoverFilterParams } = useContext(BrandsContext);

  const selectedCategories = discoverFilterParams.selectedCategories;

  const [open, setOpen] = useState(false);
  const handleClick = (value: string) => {
    trackEvent("Subcategory Expanded", { "Main Category": value });
    setOpen((prev) => !prev);
  };

  const handleSelectCategory = (category: DiscoverCategory, value: string) => {
    if (value === ALL) {
      addAllQueryToSearch(
        SearchParams.CATEGORY_DISCOVER,
        category.mainCategory.key,
      );
    } else {
      if (isAllSelected) {
        removeOptionWithAllSelected(
          SearchParams.CATEGORY_DISCOVER,
          value,
          category.mainCategory.key,
        );
      } else {
        addCategoryQueryToSearch(SearchParams.CATEGORY_DISCOVER, value);
      }
    }
  };

  const isAllSelected =
    category.mainCategory.key in allQueries ||
    Boolean(
      selectedCategories?.find((x) => x.key === category.mainCategory.key),
    );

  return (
    <Box key={category.mainCategory.key}>
      <ListItemButton
        sx={{ py: 1, px: 1 }}
        disableRipple
        onClick={() => handleClick(category.mainCategory.key)}
      >
        <Typography sx={{ fontSize: { xs: 14, md: 12 } }}>
          {category.mainCategory.label}
          {open ? (
            <Box component="i" className="fa-thin fa-minus" sx={{ ml: 1 }} />
          ) : (
            <Box component="i" className="fa-thin fa-plus" sx={{ ml: 1 }} />
          )}
        </Typography>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {category?.subCategories?.map((subCategory) => (
            <ListItemButton
              key={subCategory.key}
              sx={{ pl: 2, py: 0 }}
              onClick={() => handleSelectCategory(category, subCategory.key)}
            >
              <Checkbox
                edge="start"
                checked={
                  Boolean(
                    selectedCategories?.find((x) => x.key === subCategory.key),
                  ) || isAllSelected
                }
                tabIndex={-1}
                disableRipple
                icon={<Box component="i" className="fa-regular fa-square" />}
                checkedIcon={
                  <Box component="i" className="fa-solid fa-square-check" />
                }
                disabled={isAllSelected}
              />

              <ListItemText primary={subCategory.label} />
            </ListItemButton>
          ))}

          <ListItemButton
            disableRipple
            sx={{ pl: 2, py: 0 }}
            onClick={() => handleSelectCategory(category, ALL)}
          >
            <Checkbox
              edge="start"
              checked={isAllSelected}
              tabIndex={-1}
              disableRipple
              icon={<Box component="i" className="fa-regular fa-square" />}
              checkedIcon={
                <Box component="i" className="fa-solid fa-square-check" />
              }
            />
            <ListItemText primary={"All"} />
          </ListItemButton>
        </List>
      </Collapse>
    </Box>
  );
};

export default MainCategory;
