import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  MobileStepper,
  Switch,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { CustomEvent } from "schemas/functions";

import { TemplateContentType } from "features/Influencer/ContactList/schema";
import { trackEvent } from "utils/tracking";

import {
  BentoTemplate,
  BentoTemplates,
  SelectedIndex,
  SelectedTemplates,
} from "../schema";
import TemplatePreview from "./TemplatePreview";
import styles from "./styles";

interface DisplayPickerProps {
  contentType: TemplateContentType;
  templates: BentoTemplate[];
}

interface PickedTemplateStepProps {
  bentoTemplates: BentoTemplates;
  enabledFollowUp: boolean;
  setEnabledFollowUp: Dispatch<SetStateAction<boolean>>;
  setSelectedIndex: Dispatch<SetStateAction<SelectedIndex>>;
  selectedIndex: SelectedIndex;
  selectedTemplates: SelectedTemplates;
  setSelectedTemplates: Dispatch<SetStateAction<SelectedTemplates>>;
  isGeneratingFollowUp?: boolean;
}

export default function PickedTemplateStep({
  bentoTemplates,
  enabledFollowUp,
  setEnabledFollowUp,
  setSelectedIndex,
  selectedIndex,
  selectedTemplates,
  setSelectedTemplates,
  isGeneratingFollowUp,
}: PickedTemplateStepProps) {
  const outreaches = bentoTemplates?.outreaches;
  const followups = bentoTemplates?.followups;

  const DisplayPicker = ({ contentType, templates }: DisplayPickerProps) => {
    const index = selectedIndex[contentType as keyof SelectedIndex];

    const handleNext = () => {
      trackEvent("Template Generation - Next Button Pressed");
      setSelectedIndex((prev) => ({
        ...prev,
        [contentType]: prev[contentType as keyof SelectedIndex] + 1,
      }));
    };

    const handleBack = () => {
      trackEvent("Template Generation - Back Button Pressed");
      setSelectedIndex((prev) => ({
        ...prev,
        [contentType]: prev[contentType as keyof SelectedIndex] - 1,
      }));
    };

    const template = templates[index];
    if (!template) return <></>;

    return (
      <Grid container alignItems="center">
        <Grid item xs>
          <TemplatePreview template={template} index={index} />
        </Grid>

        <Grid item xs={12}>
          <MobileStepper
            variant="dots"
            steps={templates?.length}
            position="static"
            activeStep={index}
            nextButton={
              <IconButton
                onClick={handleNext}
                sx={styles.buttonBase}
                disabled={index === templates?.length - 1}
              >
                <Box component="i" className="fa-regular fa-arrow-right" />
              </IconButton>
            }
            backButton={
              <IconButton
                onClick={handleBack}
                sx={styles.buttonBase}
                disabled={index === 0}
              >
                <Box component="i" className="fa-regular fa-arrow-left" />
              </IconButton>
            }
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    setSelectedTemplates((prev) => ({
      ...prev,
      outreach: outreaches[selectedIndex?.outreach],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex?.outreach]);

  useEffect(() => {
    if (enabledFollowUp || isGeneratingFollowUp) {
      setSelectedTemplates((prev) => ({
        ...prev,
        followup: followups[selectedIndex?.followup],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex?.followup, enabledFollowUp, isGeneratingFollowUp]);

  return (
    <Box sx={{ my: 2 }}>
      {!isGeneratingFollowUp && (
        <>
          <DisplayPicker
            contentType={TemplateContentType.OUTREACH}
            templates={outreaches}
          />
          <FormControlLabel
            sx={{ my: 2, ml: { xs: 0, md: 2 } }}
            control={
              <Switch
                size="small"
                checked={enabledFollowUp}
                onChange={(e: CustomEvent) =>
                  setEnabledFollowUp((prev) => !prev)
                }
              />
            }
            label={<>Enable follow-up</>}
          />
        </>
      )}

      {(enabledFollowUp || isGeneratingFollowUp) && (
        <DisplayPicker
          contentType={TemplateContentType.FOLLOWUP}
          templates={followups}
        />
      )}
    </Box>
  );
}
