import { grey } from "@mui/material/colors";

const styles = {
  desktopHeader: {
    borderBottom: `1px solid ${grey[200]}`,
    py: 1.5,
    px: 2,
  },
  close: {
    fontSize: 14,
  },
  footer: {
    position: "absolute",
    backgroundColor: "white",
    bottom: 0,
    pb: { xs: 0, md: "8px" },
  },

  // Mobile
  header: {
    position: "sticky",
    zIndex: 2,
    backgroundColor: "primary.main",
    py: 0,
    px: 2,
  },
  headerButton: {
    color: "white",
    fontSize: 16,
  },
  mobileContainer: {
    maxHeight: "100svh",
    overflow: "hidden",
  },
};

export default styles;
