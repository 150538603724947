import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  InboxSidebarOption,
  InboxSidebarOptionKey,
  InboxSidebarOptions,
  OutreachContactsContext,
  ScheduledType,
} from "contexts/OutreachContacts";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface IProps {
  onSelect?: () => void;
  isSmallScreen?: boolean;
}

export default function Sidebar({ onSelect, isSmallScreen = false }: IProps) {
  const {
    currentSidebarOption,
    fetchContactsParams,
    handleFetchContacts,
    getSortOptions,
    totals,
  } = useContext(OutreachContactsContext);
  const { handleOpenDraftWindow } = useContext(QuickSendDrawerContext);

  const openCompose = () => {
    handleOpenDraftWindow();
    if (onSelect) {
      onSelect();
    }
  };

  const handleChange = (option: InboxSidebarOption) => {
    trackEvent("Inbox Table Changed Folder", {
      folder: option.label,
    });
    if (onSelect) {
      onSelect();
    }
    const updatedParams = { ...fetchContactsParams };
    const sortOption = getSortOptions(option.key)[0];
    updatedParams.page = 1;
    updatedParams.selectedSuggestion = null;
    updatedParams.importantOnly = false;
    updatedParams.statuses = option.statuses;
    updatedParams.order = sortOption.order;
    updatedParams.orderBy = sortOption.orderBy;
    if (option.key === InboxSidebarOptionKey.scheduled) {
      updatedParams.scheduledType = ScheduledType.outreach;
    }
    handleFetchContacts(updatedParams);
  };

  return (
    <>
      <Box sx={[isSmallScreen && styles.smallScreenButton]}>
        <Button
          onClick={openCompose}
          disableElevation
          size="small"
          sx={styles.button}
          variant="contained"
        >
          <Box
            component="i"
            className="fa-regular fa-pen"
            sx={{ mr: 1, minWidth: "25px" }}
          />
          Compose
        </Button>
      </Box>
      <List sx={{ width: "100%" }}>
        {InboxSidebarOptions.map((option) => (
          <ListItem
            key={option.key}
            sx={styles.listItem}
            disablePadding
            secondaryAction={
              <Typography
                variant="caption"
                sx={[
                  currentSidebarOption?.key === option.key && {
                    fontWeight: "bold",
                  },
                ]}
              >
                {totals[option.key]}
              </Typography>
            }
          >
            <ListItemButton
              onClick={() => handleChange(option)}
              selected={currentSidebarOption?.key === option.key}
            >
              <ListItemIcon sx={{ minWidth: "25px" }}>
                <Box component="i" className={option.icon} />
              </ListItemIcon>
              <ListItemText sx={styles.textItem} primary={option.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
