import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { ChatHelperContext } from "contexts/ChatHelper";
import { DiscoverTabView, DiscoverViewContext } from "contexts/DiscoverView";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext, useState } from "react";
import { BentoBrand } from "schemas/dashboard";

import { CustomizerOption } from "./schema";
import styles from "./styles";

export default function CustomizerHelper({
  bentoBrand,
}: {
  bentoBrand: BentoBrand;
}) {
  const { handleOpenBrandDetails } = useContext(QuickSendDrawerContext);
  const { discoverTab, setDiscoverTab } = useContext(DiscoverViewContext);
  const [selectedOption, setSelectedOption] = useState<CustomizerOption | null>(
    null,
  );
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const openChat = discoverTab === DiscoverTabView.CHAT;

  const { outreachMessages } = useContext(QuickSendContext);
  const { personalizeAction } = useContext(ChatHelperContext);

  const isBrandViewOpen = window.location.href?.includes(`/brand/view`);

  const openChatHelper = () => {
    setSelectedOption(CustomizerOption.CUSTOMIZE_FOR_ME);
    setDiscoverTab(DiscoverTabView.CHAT);
    personalizeAction(outreachMessages[0]?.body);
  };

  const openBrandInfo = () => {
    handleOpenBrandDetails(bentoBrand, "Customize Helper");
    setSelectedOption(CustomizerOption.GET_BRAND_INFO);
  };

  return (
    <Box>
      <Grid container gap={1}>
        {isLargeScreen && outreachMessages?.length > 0 && (
          <Button
            variant="outlined"
            sx={
              openChat && selectedOption === CustomizerOption.CUSTOMIZE_FOR_ME
                ? styles.active
                : styles.productOption
            }
            size="small"
            onClick={openChatHelper}
            disableElevation
            onMouseDown={(e) => e.preventDefault()}
          >
            <Box
              component="i"
              className="fa-solid fa-wand-magic-sparkles"
              sx={{ mr: 1 }}
            />{" "}
            {CustomizerOption.CUSTOMIZE_FOR_ME}
          </Button>
        )}

        {!isLargeScreen && (
          <Button
            size="small"
            variant="outlined"
            sx={
              selectedOption === CustomizerOption.GET_BRAND_INFO &&
              isBrandViewOpen
                ? styles.active
                : styles.productOption
            }
            onClick={openBrandInfo}
            disableElevation
            onMouseDown={(e) => e.preventDefault()}
          >
            <Box
              component="i"
              className="fa-regular fa-search"
              sx={{ mr: 1 }}
            />{" "}
            {CustomizerOption.GET_BRAND_INFO}
          </Button>
        )}
      </Grid>
    </Box>
  );
}
