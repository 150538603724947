import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { forwardRef, useState } from "react";
import { BentoBrand, CombinedRequest, RequestStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { RequestStatusColor } from "utils/color";

import SelectedBrand from "../SelectedBrand";
import styles from "./styles";

const REF_OFFSET = 5;

interface Props {
  request: CombinedRequest;
  bentoBrand?: BentoBrand;
  requestWebsiteLink?: string;
  onDelete: () => void;
  status: RequestStatus;
  label: string;
  displayedLength: number;
  index: number;
  bcid?: number;
}

const SingleRequestCard = forwardRef(
  (
    {
      request,
      bentoBrand,
      requestWebsiteLink,
      onDelete,
      status,
      label,
      displayedLength,
      index,
      bcid,
    }: Props,
    ref,
  ) => {
    const [removeLoading, setRemoveLoading] = useState(false);

    const handleDelete = async (e: CustomEvent) => {
      e.stopPropagation();
      setRemoveLoading(true);
      await onDelete();
      setRemoveLoading(false);
    };

    return bentoBrand ? (
      <SelectedBrand
        request={request}
        removeLoading={removeLoading}
        handleDelete={(e: CustomEvent) => handleDelete(e)}
        bentoBrand={bentoBrand}
        status={status}
        label={label}
        ref={displayedLength - REF_OFFSET === index ? ref : null}
        bcid={bcid}
      />
    ) : (
      <Box
        sx={styles.brandContainer}
        ref={displayedLength - REF_OFFSET === index ? ref : null}
      >
        {removeLoading ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton onClick={handleDelete} sx={styles.removeButton}>
            <Box component="i" className="fa-regular fa-circle-xmark" />
          </IconButton>
        )}
        <Box sx={{ fontSize: 12, flexGrow: 1 }}>
          Request:
          <Typography noWrap sx={styles.description}>
            {requestWebsiteLink}
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ color: RequestStatusColor[status] }}>
          <strong>{label}</strong>
        </Typography>
      </Box>
    );
  },
);

export default SingleRequestCard;
