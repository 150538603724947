import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";
import { routes } from "schemas/routes";

import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface CustomizePartOneEmailFormProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
  isProfilePage?: boolean;
}

const CustomizePartOneEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
  isProfilePage = false,
}: CustomizePartOneEmailFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(OrganizationUserContext);
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(`/${routes.pitchOnboarding}${location.search}`);
  };

  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    copy[name] = value;
    setOnboardingForm(copy);
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    saveOnboardingForm(onboardingForm, "introduction");
  };

  return (
    <>
      <Typography variant="h3" sx={{ mb: 3 }}>
        <Box
          component="i"
          color="secondary.dark"
          sx={{ pr: 2 }}
          className="fa-solid fa-envelope"
        />
        Let us help you write customized emails
      </Typography>

      {isProfilePage && (
        <Typography>
          The following questions help Bento's AI generate personalized pitch
          emails for you, which will appear on your recommendations page. If
          you'd prefer to craft your own pitch email templates, you can do so on{" "}
          <Link to={`/${routes.templates}`}>your templates page</Link>.
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <FormHelperText sx={styles.helperText}>
          Write a 2-3 sentence "About me" (first sentences in your pitch email)
          *
        </FormHelperText>
        <TextField
          sx={styles.textField}
          fullWidth
          name="introduction"
          placeholder={`My name is ${
            currentUser?.name?.split(" ")?.[0] || `{name}`
          } ✨ and I am a fashion, beauty, and lifestyle content creator from...`}
          onChange={handleChange}
          multiline
          minRows={4}
          required
          value={onboardingForm?.introduction || ""}
        />

        {!isProfilePage && (
          <Grid item xs="auto" container columnGap={1}>
            <Grid item xs="auto">
              <Button onClick={handleBack} disableElevation>
                Back
              </Button>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <LoadingButton
                disableElevation
                variant="contained"
                type="submit"
                loading={loading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default CustomizePartOneEmailForm;
