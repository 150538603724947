import { Grid, Skeleton } from "@mui/material";

export default function SkeletonBrand() {
  return (
    <Grid container sx={{ p: 2 }} gap={1}>
      <Grid item xs={2}>
        <Skeleton variant="rounded" width={50} height={50} />
      </Grid>

      <Grid container item xs gap={1}>
        <Skeleton animation="wave" variant="rounded" height={20} width="100%" />
        <Skeleton animation="wave" variant="rounded" height={20} width="100%" />
      </Grid>
    </Grid>
  );
}
