import { Navigate, Route, Routes } from "react-router-dom";

import RecommendationsPage from "./PageView";

const Page = () => {
  return (
    <>
      <Routes>
        <Route
          path="*"
          element={<Navigate to="/dashboard/recommendations/bento" replace />}
        />
        <Route path=":tab" element={<RecommendationsPage />} />
      </Routes>
    </>
  );
};

export default Page;
