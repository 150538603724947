import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { ChatHelperContext } from "contexts/ChatHelper";
import { DiscoverTabView, DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { MutableRefObject, useContext, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

import ChatMessage from "./ChatMessage";
import TypingLoader from "./TypingLoader";
import styles from "./styles";

const REF_INDEX = 5;

const ChatHelper = () => {
  const { quickSendBrandId } = useContext(QuickSendDrawerContext);
  const { bentoBrand, outreachMessages } = useContext(QuickSendContext);

  const draft = outreachMessages[0]?.body;

  const { discoverTab, setDiscoverTab } = useContext(DiscoverViewContext);
  const scrollContainerRef: MutableRefObject<HTMLDivElement | null> =
    useRef(null);

  const [topRef, isTopVisible] = useInView({
    root: scrollContainerRef.current,
    threshold: 1,
  });

  const {
    chatMessages,
    messageLoading,
    messageLimit,
    fetchChatMessages,
    isTyping,
    personalizeAction,
  } = useContext(ChatHelperContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const closeDialog = () => {
    setDiscoverTab(null);
  };

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (discoverTab === DiscoverTabView.CHAT && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discoverTab, chatMessages?.length]);

  useEffect(() => {
    if (
      (isTopVisible || chatMessages.length === 0) &&
      !messageLimit &&
      !messageLoading
    ) {
      fetchChatMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopVisible, messageLimit, messageLoading, currentOrg?.id]);

  const getRef = (idx: number) => {
    const ref =
      (chatMessages.length > REF_INDEX
        ? idx === chatMessages.length - REF_INDEX
        : idx === chatMessages.length - 1) &&
      !messageLimit &&
      !messageLoading
        ? topRef
        : undefined;
    return ref;
  };

  return (
    <Box>
      <Grid container direction="column">
        {/* Top Bar */}
        <Grid item xs="auto" sx={styles.header}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ fontSize: 14 }}>
              CUSTOMIZE WITH AI
            </Typography>
            <IconButton sx={styles.close} onClick={closeDialog}>
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
          </Grid>
        </Grid>
        {/* Messages */}
        <Grid item xs sx={styles.body} ref={scrollContainerRef}>
          {messageLoading && (
            <Grid container sx={{ px: 1 }}>
              <Grid item container justifyContent="flex-end">
                <Skeleton animation="wave" width="80%" height="80px" />
              </Grid>
              <Grid item container justifyContent="flex-start">
                <Skeleton animation="wave" width="80%" height="80px" />
              </Grid>
            </Grid>
          )}
          <Grid container direction="column-reverse">
            {isTyping && (
              <Grid item xs={12} sx={styles.typing}>
                <TypingLoader />
              </Grid>
            )}

            {draft && chatMessages?.length === 0 && (
              <Box
                sx={styles.draft}
                dangerouslySetInnerHTML={{ __html: draft }}
              ></Box>
            )}
            {!draft && chatMessages?.length === 0 && (
              <Typography
                color="textSecondary"
                sx={styles.emptyText}
                variant="caption"
              >
                You can only message me when drafting an email.
              </Typography>
            )}
            {chatMessages.map((chatMessage, idx) => (
              <ChatMessage
                key={idx}
                chatMessage={chatMessage}
                ref={getRef(idx)}
              />
            ))}
          </Grid>
        </Grid>
        {/* Actions */}
        <Grid justifyContent="flex-end" sx={styles.action}>
          {draft && quickSendBrandId > 0 && (
            <Button
              onClick={() => personalizeAction(draft)}
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              disabled={isTyping}
            >
              {isTyping ? (
                <>
                  Typing...{" "}
                  <CircularProgress
                    color="secondary"
                    size={10}
                    sx={{ ml: 1 }}
                  />
                </>
              ) : (
                <>Generate Email for {bentoBrand?.brandName} ✨</>
              )}
            </Button>
          )}
          <Box>
            {(!draft || quickSendBrandId < 0) && chatMessages.length > 0 && (
              <Typography
                color="textSecondary"
                variant="caption"
                sx={{ pr: 3 }}
              >
                You can only message me when drafting an email.
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatHelper;
