import { grey } from "@mui/material/colors";

const styles = {
  icon: {
    fontSize: 14,
  },
  chip: {
    borderRadius: 2,
    backgroundColor: "white",
    border: `1px solid ${grey[300]}`,
  },
  labelContainer: {
    gap: 1,
    mb: 1,
    display: "flex",
  },
};

export default styles;
