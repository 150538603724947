import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { OutreachContactsContext, SortOption } from "contexts/OutreachContacts";
import { useContext, useMemo } from "react";
import { CustomEvent } from "schemas/functions";

export default function SortSelect() {
  const {
    fetchContactsParams,
    handleFetchContacts,
    currentSidebarOption,
    getSortOptions,
  } = useContext(OutreachContactsContext);

  const SORTS = getSortOptions(currentSidebarOption?.key);

  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    const newValue = SORTS.find((sort) => sort.name === value) || SORTS[0];
    const updatedParams = { ...fetchContactsParams };
    updatedParams.page = 1;
    updatedParams.order = newValue.order;
    updatedParams.orderBy = newValue.orderBy;
    handleFetchContacts(updatedParams);
  };

  const sortValue = useMemo(() => {
    return (
      SORTS.find(
        (sort) =>
          sort.order === fetchContactsParams.order &&
          sort.orderBy === fetchContactsParams.orderBy,
      ) || SORTS[0]
    );
  }, [fetchContactsParams.order, fetchContactsParams.orderBy, SORTS]);

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>Sort By</InputLabel>
      <Select onChange={handleChange} label="Sort By" value={sortValue.name}>
        {SORTS?.map((sort: SortOption) => (
          <MenuItem key={sort.name} value={sort.name}>
            {sort.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
