import { Chip, Grid, Typography } from "@mui/material";
import { TagLabel } from "contexts/Brands";
import lodash from "lodash";
import {
  BentoBrandMetadataTags,
  MetadataType,
  SearchParams,
} from "schemas/dashboard";

import { DiscoverSearchChipColor } from "utils/color";
import { useSearchBrand } from "utils/useSearchBrand";

import styles from "./styles";

interface Props {
  selectedTags: TagLabel[];
  selectedMetadataTags: BentoBrandMetadataTags[];
  isMobileScreen: boolean;
}

const DiscoverChips = ({
  selectedTags,
  selectedMetadataTags,
  isMobileScreen,
}: Props) => {
  const { deleteCategoryFromSearch, deleteMetadataFromSearch, hasTags } =
    useSearchBrand();

  return hasTags ? (
    <>
      {isMobileScreen && (
        <Grid item xs={12} sx={{ p: 2 }}>
          <Typography>List of tags you have selected:</Typography>
        </Grid>
      )}

      <Grid
        container
        wrap={isMobileScreen ? "wrap" : "nowrap"}
        item
        xs={12}
        md
        gap={1}
        sx={styles.chipsContainer}
      >
        {selectedTags?.map((chip: TagLabel, index: number) => (
          <Chip
            key={index}
            label={chip.name}
            onDelete={() => deleteCategoryFromSearch(chip)}
            sx={[
              styles.selectedChip,
              { backgroundColor: DiscoverSearchChipColor[chip.params] },
            ]}
            size={isMobileScreen ? "small" : "medium"}
          />
        ))}
        {selectedMetadataTags?.map(
          (tag: BentoBrandMetadataTags, index: number) => (
            <Chip
              key={index}
              label={lodash.startCase(tag.value)}
              onDelete={() => deleteMetadataFromSearch(tag)}
              sx={[
                styles.selectedChip,
                {
                  backgroundColor:
                    tag.type === MetadataType.location
                      ? DiscoverSearchChipColor[SearchParams.LOCATION_DISCOVER]
                      : DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER],
                },
              ]}
              size={isMobileScreen ? "small" : "medium"}
            />
          ),
        )}
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default DiscoverChips;
