import { Portal } from "@mui/material";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { forwardRef } from "react";

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

interface Props {
  message: string | React.ReactNode;
  severity: AlertColor;
  closeAlert: () => void;
}

export default function CustomizedSnackbars({
  message,
  severity,
  closeAlert,
}: Props) {
  const colorSchema: { [key: string | AlertColor]: string } = {
    success: "primary.main",
    warning: "warning.main",
    error: "error.main",
    info: "info.main",
  };

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={!!message}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert
          onClose={closeAlert}
          severity={severity}
          sx={{
            width: "100%",
            backgroundColor: colorSchema[severity],
            color: "white",
            zIndex: 1000,
          }}
        >
          {message || ""}
        </Alert>
      </Snackbar>
    </Portal>
  );
}
