import { OrganizationProfile } from "schemas/dashboard";

import { MergeTags, Template } from "features/Influencer/ContactList/schema";
import { arrayQuery } from "utils/api";
import { titleCase } from "utils/string";

import { BentoTemplate, TemplateSurvey } from "./schema";

export const previewTemplate = (
  selectedTemplate: BentoTemplate,
  profile: OrganizationProfile | null,
) => {
  let s = "";
  if (!selectedTemplate?.body) return "";

  for (const body of selectedTemplate?.body) {
    if (body.type === "text") {
      s += body.text;
    } else if (body.type === "input") {
      if (body.variable === "signature" && profile?.signature) {
        s += profile?.signature;
      } else if (body.variable === "about_me" && profile?.introduction) {
        s += profile?.introduction;
      } else if (body.variable === "portfolio" && profile?.portfolioUrl) {
        s += `<a href="${profile?.portfolioUrl}" target="_blank">Portfolio</a>`;
      } else if (body.variable === "post_example" && profile?.mostEngagedPost) {
        s += `<a href="${profile?.mostEngagedPost}" target="_blank">Here's</a>`;
      } else {
        s += body.example;
      }
    }
  }
  return s;
};

export const prefillSurvey = (profile: OrganizationProfile | null) => {
  if (!profile) {
    return;
  }

  let brands = profile?.brands;
  if (profile?.brands && Array.isArray(profile?.brands)) {
    brands = profile?.brands?.join(", ");
  }
  const defaultSurvey: TemplateSurvey = {
    goal: undefined,
    tone: undefined,
    highlight: undefined,
    content: undefined,
    about_me: profile?.introduction,
    signature: profile?.signature,
    portfolio: profile?.portfolioUrl,
    post_example: profile?.mostEngagedPost,

    // Active variables in curent BentoTemplates
    past_partnerships: profile?.mostImpressivePartnerships || brands,
    engagement: profile?.engagement,
    followers: profile?.numOfFollowers
      ? profile?.numOfFollowers?.toString()
      : "",
    case_study: profile?.caseStudy,
  };

  return defaultSurvey;
};

export const replaceMergeTag = (content: string, name?: string) => {
  // Replace merge tag in a template body
  let s = content.replaceAll(MergeTags.first_name, `{First Name}`);
  s = s.replaceAll(MergeTags.brand_name, `{Brand Name}`);
  s = s.replaceAll(`{Brand Name}`, "Reformation");
  s = s.replaceAll(`{First Name}`, "Jane");
  if (name) s = s.replaceAll(`{user_name}`, name);
  return s;
};

export const replaceSubjectMergeTag = (subject: string) => {
  // Replace merge tag in a template subject
  let s = subject.replaceAll(MergeTags.first_name, `{First Name}`);
  s = s.replaceAll(MergeTags.brand_name, `{Brand Name}`);
  return s;
};

export const getTags = (survey: TemplateSurvey) => {
  // Return a tag string query for BentoTemplate (e.g `tags=upbeat&tags=professional&tags=pr_list`)
  let tags: string[] = [];
  if (survey?.content) {
    tags.push(survey?.content);
  }
  if (survey?.goal) {
    tags.push(survey?.goal);
  }
  if (survey?.highlight) {
    tags.push(survey?.highlight);
  }
  return {
    followupTags: survey?.tone ? arrayQuery([survey?.tone], "tags") : [],
    outreachTags: arrayQuery(tags, "tags"),
  };
};

export const convertBentoTemplate = (
  template: BentoTemplate,
  survey: TemplateSurvey,
  currentUserName?: string,
) => {
  const templateBody = fillInTemplate(template, survey, currentUserName);
  const templateToCreate: Template = {
    subject: template?.subject || "",
    contentType: template?.contentType,
    body: templateBody,
    bentoTemplateId: template?.id,
    name: "",
  };
  return templateToCreate;
};

const fillInTemplate = (
  bentoTemplate: BentoTemplate,
  survey: TemplateSurvey,
  currentUserName?: string,
) => {
  let s = "";
  for (const body of bentoTemplate?.body) {
    if (body.type === "text") {
      s += body.text;
    } else {
      const prop = body.variable;

      let value = prop in survey ? survey[prop as keyof TemplateSurvey] : "";
      const emptyProp =
        value === undefined ||
        value === null ||
        value === "undefined" ||
        !(typeof value === "string" && value?.length > 0);

      let example = body.example;
      if (currentUserName && example.includes("{user_name}")) {
        example = example.replaceAll("{user_name}", currentUserName);
      }
      const title =
        body.variable === "post_example" ? `Here's` : titleCase(body.variable);
      let emptyRedVar = `<span data-type="variable" class="variable" data-instruction="${body.placeholder}" data-id="${body.organization_profile_field}" data-subtype="${body.subtype}" data-label="${title}" data-example="${example}">{${title}}</span>`;

      if (emptyProp) {
        s += emptyRedVar;
      } else if (value && typeof value === "string") {
        if (body.variable !== "signature") {
          // remove the <p> tag around the content
          value = value.replace(/^<p>([\s\S]*?)<\/p>$/, "$1");
        }
        s += value;
      }
    }
  }

  if (
    survey?.portfolio &&
    s?.includes(survey?.portfolio) &&
    !s?.includes(`href="${survey?.portfolio}"`)
  ) {
    s = s.replaceAll(
      survey?.portfolio,
      `<a href="${survey?.portfolio}" target="_blank">Portfolio</a>`,
    );
  }
  if (
    survey?.post_example &&
    s?.includes(survey?.post_example) &&
    !s?.includes(`href="${survey?.post_example}"`)
  ) {
    s = s.replaceAll(
      survey?.post_example,
      `<a href="${survey?.post_example}" target="_blank">Here's</a>`,
    );
  }
  return s;
};
