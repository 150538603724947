import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Tab,
  Tabs,
} from "@mui/material";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { OrganizationUserContext } from "contexts/Organization";
import { TrackingColumnsContext } from "contexts/TrackingColumns";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";

import { PAGE_VISITED } from "constants/trackingProps";
import { trackEvent } from "utils/tracking";

import ContactView from "./ContactView";
import styles from "./styles";

const InfluencerTracking = () => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { fetchViews, contactViews, allViewsLoading } = useContext(
    ContactViewSearchContext,
  );
  const { userIntegration } = useContext(UserIntegrationsContext);
  const { getContactProperties } = useContext(TrackingColumnsContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchViews();
    getContactProperties();
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  const getView = (viewName: string) => {
    return contactViews.find((view) => view.name === viewName);
  };

  const handleChange = (event: CustomEvent, newValue: string) => {
    event.stopPropagation();
    const newView = getView(newValue);
    if (newView?.id) {
      navigate(`/${routes.trackingView}/${newView.id}`);
    }
  };

  const contactViewId = useMemo(() => {
    const match = location.pathname.match(/\/view\/(\d+)\/?/);
    if (match && match[1]) {
      return Number(match[1]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (contactViews.length > 0 && !contactViewId) {
      const pitchingView = getView("Pitching");
      if (pitchingView) {
        navigate(`/${routes.trackingView}/${pitchingView.id}`, {
          replace: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactViews.length, contactViewId]);

  const contactView = contactViews.find((view) => view.id === contactViewId);

  return (
    <Box sx={styles.root}>
      {allViewsLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={allViewsLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      {contactView?.name && (
        <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
          <Tabs
            sx={{ px: 1 }}
            variant="scrollable"
            scrollButtons="auto"
            value={contactView?.name}
            onChange={handleChange}
          >
            <Tab
              sx={[styles.tab, styles.firstTab]}
              value="Pitching"
              label="Pitching"
            />
            <Tab sx={styles.tab} value="In Discussion" label="In Discussion" />
            <Tab sx={styles.tab} value="Collaboration" label="Collaboration" />
            <Tab
              sx={styles.tab}
              value="Reach Out Later"
              label="Reach Out Later"
            />
            <Tab sx={styles.tab} value="All" label="All" />
            <Tab
              sx={[
                styles.tab,
                !userIntegration?.partnershipsSearchedAt && styles.importTab,
                styles.lastTab,
              ]}
              value="Imports"
              label={
                !userIntegration?.partnershipsSearchedAt ? (
                  <>
                    Imports{" "}
                    <Chip label="new" size="small" sx={styles.newChip} />
                  </>
                ) : (
                  "Imports"
                )
              }
            />
          </Tabs>
        </Box>
      )}
      <Box sx={styles.tableContainer}>
        <Routes>
          <Route path="view/:contactViewId/*" element={<ContactView />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default InfluencerTracking;
