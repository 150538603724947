import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import gmailLogo from "assets/logos/gmail-logo.png";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { fetcherAuth } from "utils/api";
import { navigateBackPath } from "utils/navigation";

const styles = {
  backdrop: {
    backgroundColor: "#fff",
    zIndex: 9999,
    position: "absolute",
    borderRadius: 2,
  },
  link: {
    cursor: "pointer",
  },
  formControl: {
    ml: 2,
  },
  checkbox: {
    color: "green.main",
  },
  wand: {
    mr: 2,
    color: "green.main",
  },
  gmailLogo: {
    height: "25px",
    ml: 2,
  },
};

export default function UploadGmail() {
  const navigate = useNavigate();
  const {
    emailHealth,
    setOpenIntegrationDialog,
    setIntegrationDialogText,
    userIntegration,
    setUserIntegration,
  } = useContext(UserIntegrationsContext);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const { selectedView } = useContext(ContactViewContext);
  const location = useLocation();
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);

  const onBack = () => {
    navigate(-1);
  };

  const handleClose = () => {
    navigate(navigateBackPath(location.pathname, 3));
  };

  const confirmUpload = async () => {
    if (!emailHealth) {
      setIntegrationDialogText(
        "In order to import contacts into Bento, we need read access to your Google account to automatically set their status and create follow-up tasks for you ✨",
      );
      setOpenIntegrationDialog(true);
      return;
    }

    setConfirmLoading(true);

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-integrations/${userIntegration?.id}/import-partnerships`,
        "POST",
        {},
        { contactViewId: selectedView?.id },
      );
      setUserIntegration(res.userIntegration);
      setConfirmed(true);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <DialogTitle component="div" display="flex" alignItems="center">
        <Box
          component="i"
          className="fa-solid fa-wand-magic-sparkles"
          sx={styles.wand}
        />
        <Typography component="span" variant="h5">
          Auto-fill with
        </Typography>
        <Box
          component="img"
          src={gmailLogo}
          alt="Gmail"
          sx={styles.gmailLogo}
        />
      </DialogTitle>
      <DialogContent>
        {confirmed ? (
          <>
            <Typography gutterBottom>
              <strong>Connected Successfully!</strong> This will take several
              minutes. We will send you an email when this has been completed.
              You do not need to stay on this page. Check the Imports tab for
              any contacts that have been successfully imported.
            </Typography>
          </>
        ) : userIntegration?.partnershipsSearchedAt ? (
          <Typography gutterBottom>
            You have already imported contacts using our Gmail integration, and
            we currently support importing only once. If you would like to try
            this again please contact{" "}
            <Link href="mailto:hello@onbento.com">
              <strong>hello@onbento.com</strong>
            </Link>{" "}
            for assistance.
          </Typography>
        ) : (
          <>
            <Typography paragraph>
              Bento will use AI to scan your Gmail for brand collaboration
              emails and automatically populate the "Imports" table with
              relevant details. You can review and edit these fields anytime
              after the information is added.
            </Typography>
            <Typography gutterBottom>Would you like to proceed?</Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {confirmed ? (
          <Button color="secondary" onClick={handleClose}>
            Close
          </Button>
        ) : (
          <>
            <Button color="secondary" onClick={onBack}>
              Back
            </Button>
            {!userIntegration?.partnershipsSearchedAt && (
              <LoadingButton
                variant="contained"
                onClick={confirmUpload}
                loading={confirmLoading}
                disableElevation
              >
                Yes
              </LoadingButton>
            )}
          </>
        )}
      </DialogActions>
    </>
  );
}
